import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  TopBar,
  AddFolderModal,
  CenterModal,
  Button,
} from '@travelpassero/khaos';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import IdeaCardContainer from '@components/containers/IdeaCardContainer';
import CrewList from '@components/sharedComponentContainers/CrewList';
import { openCenterModal } from '@context/actions';

import { selectAppState } from '@context/global/appSlice';
import { useJourney } from '@hooks/useJourney';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import useJourneys from '@hooks/useJourneys';
import useLayout from '@hooks/useLayout';
import { useWindowSize } from '@hooks/useWindowSize';
import useJourneyCrew from '@hooks/useJourneyCrew';
import { PlusOutlined } from '@ant-design/icons';
import { useTracking } from '@hooks/useTracking';
import { useHistory } from 'react-router-dom';
import ManageCrewModal from '@components/sharedComponentContainers/ManageCrewModal';
import PageSpinner from '@components/PageSpinner';
import PageScrollContainer from './PageScrollContainer';

const CrewListContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const IdeaBoardPage = ({ journeyID, splitScreenSide }) => {
  const history = useHistory();
  const { setPageView, setPage } = useTracking();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const useJourneyData = useJourney(journeyID);
  const {
    journeyStatus, title, folders, createFolder,
  } = useJourneyData || {};
  const { globalStateReducer } = useSelector(selectAppState);
  const { modalLoading } = useSelector(selectJourneysState);
  const { crewList, removeCrewMember } = useJourneyCrew(journeyID);
  const [selectedFolderKey, setSelectedFolderKey] = useState();
  const [addToFolderKey, setAddToFolderKey] = useState();
  const { handleCloseModal, handleCloseDrawer } = useLayout();

  // TODO: why is one caps and one lowercase? Let's make this consistent.
  setPageView('Ideas');
  setPage('ideas');

  const {
    isCenterModalVisible,
    modalChildrenType,
  } = globalStateReducer;

  const handleAddCrewMember = () => {
    dispatch(openCenterModal('add crew'));
  };

  const handleManageCrewMembers = () => {
    dispatch(openCenterModal('manage-crew'));
  };

  const onItinerarySplitScreenClick = () => {
    history.push(`/journeys/${journeyID}/ideas-with-itinerary`);
  };

  const onSurveySplitScreenClick = () => {
    history.push(`/journeys/${journeyID}/ideas-with-surveys?create-survey=true`);
  };

  const handleOnFolderAddClick = (parentFolderKey) => {
    setAddToFolderKey(parentFolderKey);
    dispatch(openCenterModal('folder'));
  };

  const handleAddFolderFinish = async (formData) => {
    createFolder({ formData, parentFolderKey: addToFolderKey });
  };

  const handleFolderMenuClick = (id) => {
    setSelectedFolderKey(id);
  };

  const handleHomeFolderClick = () => {
    setSelectedFolderKey('');
  };

  useEffect(() => {
    if (!modalLoading) handleCloseModal();
  }, [folders]);

  // useEffect(() => {
  //   if (refetch) getJourneys();
  // }, [refetch]);

  useEffect(() => () => handleCloseDrawer(), []);

  let Modal;
  switch (modalChildrenType) {
    case 'folder':
      Modal = (
        <AddFolderModal
          onFinish={handleAddFolderFinish}
          onClose={handleCloseModal}
          showModal={isCenterModalVisible}
          selectedFolder={folders?.find((folder) => folder.id === addToFolderKey)}
        />
      );
      break;

    case 'add crew':
      Modal = (
        <ManageCrewModal journeyID={journeyID} />
      );
      break;

    case 'manage-crew':
      Modal = (
        <CenterModal
          title={`People in this Journey (${crewList.length})`}
          showModal={isCenterModalVisible}
          handleOnClose={handleCloseModal}
          children={(
            <Row justify="center" align="middle">
              <Col span={24}>
                <CrewListContainer>
                  <CrewList
                    list={[...crewList]}
                    onRemove={removeCrewMember}
                    removeConfirmText="Remove crew member?"
                  />
                </CrewListContainer>
              </Col>
            </Row>
          )}
          footerButtons={[
            <Button onClick={handleAddCrewMember}>
              <PlusOutlined />
              Add more Crew Members
            </Button>,
          ]}
        />
      );
      break;

    default:
      Modal = null;
  }

  if (journeyStatus !== 'fulfilled') {
    return <PageSpinner />;
  }

  return (
    <>
      <TopBar
        sectionTitle={isMobile ? 'Ideas' : 'Idea Board'}
        tripTitle={title || ''}
        myCrewUsers={crewList || []}
        onAddCrewMember={handleAddCrewMember}
        onManageCrewMembers={handleManageCrewMembers}
        onFolderAddClick={handleOnFolderAddClick}
        onFolderMenuClick={handleFolderMenuClick}
        selectedFolderKey={selectedFolderKey}
        onHomeFolderClick={handleHomeFolderClick}
        folders={folders || []}
        splitScreenOptions={[
          {
            key: 'survey',
            name: 'Create Survey',
            onClick: onSurveySplitScreenClick,
          },
          {
            key: 'itinerary',
            name: 'Itinerary',
            onClick: onItinerarySplitScreenClick,
          },
        ]}
        splitScreenSide={splitScreenSide}
      />
      <PageScrollContainer splitScreenSide={splitScreenSide}>
        <IdeaCardContainer
          useJourneyData={useJourneyData}
          selectedFolderKey={selectedFolderKey}
          handleFolderMenuClick={handleFolderMenuClick}
          isSplitScreen={!!splitScreenSide}
        />
      </PageScrollContainer>

      {isCenterModalVisible && Modal}
    </>
  );
};

export default IdeaBoardPage;
