import React from 'react';

import { ItineraryCardRow } from '@travelpassero/khaos';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '@utils/dragTypes';

function ItineraryIdeaCardDnDContainer({
  idea,
  isSplitScreen,
  onNoteClick,
  onTextAreaChange,
  hasSavedText,
  imgSrc,
  onEditClick,
  onOpenChange,
  onDragCard,
  ...args
}) {
  const { id } = idea;
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.ITINERARYCARD,
    item: { id },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && dropResult.type === 'itinerary') {
        onDragCard(id, dropResult.name);
      }
    },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div ref={drag}>
      <ItineraryCardRow
        ref={drag}
        imgSrc={imgSrc}
        idea={idea}
        onEditClick={onEditClick}
        onNoteClick={onNoteClick}
        onTextAreaChange={onTextAreaChange}
        hasSavedText={hasSavedText}
        hasNoteTextBox={!isSplitScreen}
        isNarrow={isSplitScreen}
        onOpenChange={onOpenChange}
        {...args}
      />
    </div>
  );
}

export default ItineraryIdeaCardDnDContainer;
