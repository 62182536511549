import React from 'react';
import styled from 'styled-components';
import { Typography } from '@travelpassero/khaos';
import { Card as AntCard } from 'antd';

const StyledImg = styled.img`
  min-width: 210px;
  width: auto;
  min-height: 100px;
  height: auto;
`;

const InnerCard = styled(AntCard)`
  width: 100%;
  height: ${(props) => (props.isMobile ? '122px' : '76px')};
  background: var(--white);
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 5px;
  border: 3px solid var(--white);

  .ant-card-body {
    align-items: center;
    justify-content: center;
    height: 100%;
    overflow: hidden;
    border-radius: 4px;
    display: flex;
    flex-wrap: wrap;
  }
`;

const TitleOverlay = styled.div`
  position: absolute;
  bottom: 0;
  z-index: 9;
  background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, .8));
  border-radius: 0 0 4px 4px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

function IdeaFolderCard({
  title,
  imgSrc,
  dragRef,
  isMobile = false,
}) {
  return (
    <div ref={dragRef} className="folder-inner-card-sizing" style={{ margin: '4px 0', padding: '0 4px' }}>
      <InnerCard isMobile={isMobile}>
        <StyledImg src={imgSrc} alt={title} />
        <TitleOverlay>
          <Typography
            type="whiteText"
            size=".75rem"
            style={{
              fontWeight: 'bold',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              textAlign: 'left',
              padding: '4px 5px 0 5px',
              width: '100%',
            }}
          >
            {title}
          </Typography>
        </TitleOverlay>
      </InnerCard>
    </div>
  );
}

export default IdeaFolderCard;
