import useUser from '@hooks/useUser';
import Layout from '@components/Layout';
import DiscoverCategoryPage from './DiscoverCategoryPage';

const DiscoverCategoryPageContainer = () => {
  const { userID } = useUser();

  if (userID) {
    return <Layout><DiscoverCategoryPage /></Layout>;
  }

  return <DiscoverCategoryPage />;
};

export default DiscoverCategoryPageContainer;
