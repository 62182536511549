import { addSurveyEmailThunk } from '@context/journeys/journeysThunk';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const useSurvey = (journeyData = {}) => {
  const { crew, surveys } = journeyData;
  const dispatch = useDispatch();

  const getCurrentSurvey = () => {
    const { id } = useParams();
    const surveyList = surveys || [];

    return [...surveyList].find((item) => item.id === id);
  };

  const sendSurveyEmail = (request) => {
    const { id, invited } = request;

    const emails = invited.map((crewId) => {
      const user = crew.filter((user) => user.id === crewId);
      return user[0]?.email;
    });

    const windowLocation = window.location.href.replace('surveys', '');
    const surveyLink = `${windowLocation}surveys/${id}`;

    const sendEmailAttributes = {
      emails,
      surveyLink,
    };

    dispatch(addSurveyEmailThunk(sendEmailAttributes));
  };

  return {
    getCurrentSurvey,
    sendSurveyEmail,
  };
};

export default useSurvey;
