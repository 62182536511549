import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from '@utils/http';
import * as URL from '@utils/urls';

export const getSamplePhotosThunk = createAsyncThunk('getSamplePhotos',
  async ({ pathName = 'ideaboard' }, { rejectWithValue }) => {
    try {
      const result = await HTTP.get(`${URL.carouselPhotos}${pathName}`);

      const photos = result.map((photo) => ({
        ...photo,
        src: `${URL.photoStorage}${photo.Key}`,
      }));

      return photos;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const searchPhotosThunk = createAsyncThunk('searchPhotosThunk',
  async ({ query = '' }, { rejectWithValue }) => {
    try {
      const result = await HTTP.get(`${URL.photoSearch}?query=${query}`);

      const photos = result.map((photo) => ({
        src: photo.url,
        trackingLink: photo.downloadLocation,
        photographer: {
          name: photo.photographer?.name,
          profile: photo.photographer?.profile,
        },
      }));

      return photos;
    } catch (error) {
      return rejectWithValue(error);
    }
  });

export const sendPhotoTrackingThunk = createAsyncThunk('sendPhotoTracking',
  async (request, { rejectWithValue }) => {
    try {
      await HTTP.post(URL.photoTracking, request);
    } catch (error) {
      return rejectWithValue(error);
    }
  });
