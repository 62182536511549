import { useState, useEffect } from 'react';
import { SignUpForm } from '@travelpassero/khaos';
import { Auth } from '@aws-amplify/auth';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserState, setOnboarding, setUserLoader } from '@context/user/userSlice';

import OnboardingLayout from '@components/layout/OnboardingLayout';
import BrandOnboarding from '@components/layout/BrandOnboarding';
import { OnboardingFormContainer } from '@styles/containers/OnboardingFormContainerStyle';
import SocialNetworkContainer from '@pages/onboarding/SocialNetworkContainer';
import { useAfterLogin } from '@hooks/useAfterLogin';

const SignUpPage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const { redirectTo } = useAfterLogin();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleSignUpFinish = async (formData) => {
    const { email, password } = formData;

    let { firstName, lastName } = formData;

    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    const EMAIL = email.toLowerCase();

    try {
      dispatch(setUserLoader(true));
      await Auth.signUp({
        username: EMAIL,
        password,
        attributes: {
          email: EMAIL,
          'custom:firstName': firstName,
          'custom:lastName': lastName,
        },
      });
      dispatch(setOnboarding({ email: EMAIL }));
      history.push(redirectTo ? `/register/confirm?redirectTo=${redirectTo}` : '/register/confirm');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  const handleOnSignInClick = () => {
    history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
  };

  return (
    <OnboardingLayout page="signup">
      <BrandOnboarding />
      <OnboardingFormContainer>
        <SignUpForm
          onFinish={handleSignUpFinish}
          onBackSignInClick={handleOnSignInClick}
          errorMessage={errorMessage}
        />
        <SocialNetworkContainer type="signup" />
      </OnboardingFormContainer>
    </OnboardingLayout>
  );
};

export default SignUpPage;
