import React, {
  useEffect, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { InView } from 'react-intersection-observer';
import {
  DrawerModal,
  CenterModal,
  IdeaForm,
  AddItineraryNoteForm,
  UploadPhotoForm,
  SetTimeModal,
  TinyIdeaCard,
  DATE_FORMAT,
  TIME_FORMAT,
} from '@travelpassero/khaos';

import useCardPhoto from '@hooks/useCardPhoto';
import useIdea from '@hooks/useIdea';

import {
  openDrawer,
  openCenterModal,
  setDrawerLoadingState,
} from '@context/actions';
import { selectAppState } from '@context/global/appSlice';
import { selectJourneysState } from '@context/journeys/journeysSlice';

import CardImageContainer from '@components/renderContainers/CardImageContainer';

import { dataLayerUtil } from '@utils/dataLayerUtil';

import useLayout from '@hooks/useLayout';
import { useWindowSize } from '@hooks/useWindowSize';
import { selectPhotoState } from '@context/photo/photoSlice';
import { Col } from 'antd';
import useUser from '@hooks/useUser';
import { useItinerary } from '@hooks/useItinerary';
import ModalSpinner from '../ModalSpinner';
import SidePageSpinner from '../SidePageSpinner';
import ItineraryCardSection from './ItineraryCardSection';

function ItineraryCardContainer({
  useJourneyData = {},
  selectedDay,
  folders,
  isSplitScreen,
  onDayFocus,
}) {
  const { isMobile } = useWindowSize();
  const { userID } = useUser();
  const dispatch = useDispatch();
  const {
    globalStateReducer: {
      isCenterModalVisible,
      isDrawerVisible,
      modalChildrenType,
      modalSelectedID,
      drawerChildrenType,
      drawerSelectedID,
      drawerLoadingState,
    },
  } = useSelector(selectAppState);
  const { modalLoading } = useSelector(selectJourneysState);
  const {
    carouselPhotos,
    photo,
    photoSelectionType,
  } = useSelector(selectPhotoState);
  const {
    data,
    itinerary,
    ideas,
    title,
    updateQuickAddItinerary,
    updateIdea,
    updateJourney,
    updateIdeas,
    createIdea,
    editIdea,
  } = useJourneyData;
  const { selectedIdea } = useIdea(drawerSelectedID);
  const {
    pinnedIdeas, getIdeasThisDay, dateRanges, dateRangeStart, dateRangeEnd,
  } = useItinerary(data);
  const { handleCloseModal, handleCloseDrawer } = useLayout();

  const [selectedDayDrawer, setSelectedDayDrawer] = useState();
  const [selectedItineraryIdea, setSelectedItineraryIdea] = useState();
  const [selectedFolderKey, setSelectedFolderKey] = useState();
  const [selectedExistingIdeaIds, setSelectedExistingIdeaIds] = useState([]);

  const {
    getSamplePhotos,
    selectCarouselPhoto,
    selectPhoto,
    searchPhotos,
    postPhoto,
    resetPhotoSelection,
    photoStatus,
  } = useCardPhoto(selectedIdea, 'ideaboard');

  const getQuickAddItem = (id) => itinerary.itineraryQuickAddItems?.find((item) => item.id === id);

  const handleDrawerAction = (action, id, date) => {
    if (date) setSelectedDayDrawer(date);
    dispatch(openDrawer(action, id));
  };

  const handleOpenUploadModal = () => {
    dispatch(openCenterModal('upload'));
  };

  const handleOnTimeChange = async (value) => {
    if (selectedItineraryIdea) {
      const { id, isEndIdea } = selectedItineraryIdea;
      const timeFormatted = value ? moment(value).format(TIME_FORMAT) : null;

      if (getQuickAddItem(id)) {
        updateQuickAddItinerary({
          itineraryQuickAddID: id, itineraryTime: timeFormatted,
        });
      } else {
        updateIdea({
          ideaID: id,
          selectedIdea,
          [isEndIdea ? 'endTime' : 'itineraryTime']: timeFormatted,
        });

        // getJourneys();
      }
    }

    handleCloseModal();
  };

  const handleOnfinishItineraryNote = async (formData = {}) => {
    if (getQuickAddItem(drawerSelectedID)) {
      const { itineraryNote } = formData;

      const attributes = {
        id: drawerSelectedID,
        ...formData,
      };

      updateQuickAddItinerary({
        itineraryQuickAddID: drawerSelectedID, itineraryNote,
      });

      dataLayerUtil('Itinerary Note Quick Add', attributes);
    } else {
      const attributes = {
        id: drawerSelectedID,
        ...formData,
      };

      updateIdea({
        loader: false, ideaID: drawerSelectedID, selectedIdea, ...formData,
      });

      dataLayerUtil('Itinerary Note Idea', attributes);
    }

    handleCloseDrawer();
  };

  const handleOnFolderAddClick = () => {
    dispatch(openCenterModal('folder'));
  };

  const filterAddExistingIdeas = (idea) => {
    // dont show quick add ideas for the selected day
    if (idea.itineraryDate === selectedDayDrawer) return false;

    // only show ideas for current folder
    if (selectedFolderKey) {
      return idea.folderKey === selectedFolderKey;
    }

    return !idea.folderKey;
  };

  const handleUpdateDragItineraryCard = async (ideaID, date) => {
    if (getQuickAddItem(ideaID)) {
      updateQuickAddItinerary({
        itineraryQuickAddID: ideaID, itineraryDate: moment(date).format(DATE_FORMAT),
      });
    } else {
      updateIdea({
        ideaID, itineraryDate: date,
      });
    }
  };

  const handleNoteChange = (value, ideaID) => {
    if (getQuickAddItem(ideaID)) {
      updateQuickAddItinerary({ itineraryQuickAddID: ideaID, itineraryNote: value });
    } else {
      updateIdea({
        ideaID, itineraryNote: value,
      });
    }
  };

  const handleOnRemoveIdea = async (ideaID) => {
    // remove quick add items
    if (getQuickAddItem(ideaID)) {
      const quickAddItems = itinerary?.itineraryQuickAddItems?.filter((item) => item.id !== ideaID);

      updateJourney({
        itinerary: { ...itinerary, itineraryQuickAddItems: quickAddItems },
      });

      dataLayerUtil('Itinerary Quick Add Delete', { modalSelectedID });

      // remove ideas from itinerary only
    } else {
      const [idea] = ideas?.filter((idea) => idea.id === drawerSelectedID);

      updateIdea({
        ideaID: drawerSelectedID,
        selectedIdea: idea,
        loader: false,
        itineraryDate: null,
        itineraryTime: null,
        endDate: null,
        endTime: null,
      });

      dataLayerUtil('Itinerary Idea Delete', { drawerSelectedID });
    }

    handleCloseDrawer();
  };

  const handleFolderMenuClick = (id) => {
    setSelectedFolderKey(id);
  };

  const handleHomeFolderClick = () => {
    setSelectedFolderKey('');
  };

  const handleSelectExistingIdea = (id) => {
    if (!selectedExistingIdeaIds.includes(id)) {
      setSelectedExistingIdeaIds([...selectedExistingIdeaIds, id]);
    } else {
      setSelectedExistingIdeaIds([...selectedExistingIdeaIds].filter((selectedId) => selectedId !== id));
    }
  };

  const handleOnCategoryTypeChange = (ideaCategoryId) => {
    getSamplePhotos(ideaCategoryId);
  };

  const handleResetIdeaForm = () => {
    setSelectedExistingIdeaIds([]);
  };

  const handleOnSelectIdeaFinish = async () => {
    if (selectedExistingIdeaIds?.length) {
      updateIdeas({
        ideaIDs: selectedExistingIdeaIds, loader: false, itineraryDate: selectedDayDrawer,
      });
    }

    handleCloseDrawer();
  };

  const handleOnIdeaFormFinish = async (formData) => {
    dispatch(setDrawerLoadingState(true));
    if (formData) {
      let { key } = photo;
      if (!key) { key = await postPhoto(); }

      const quickAddItem = getQuickAddItem(drawerSelectedID);

      // update quick add items by removing them and adding them as an idea
      if (quickAddItem) {
        const quickAddItems = itinerary?.itineraryQuickAddItems?.filter((item) => item.id !== drawerSelectedID);

        updateJourney({
          itinerary: { ...itinerary, itineraryQuickAddItems: quickAddItems },
        });

        createIdea({
          userID,
          photoData: {
            imgSrcKey: key,
            photographerName: key ? photo.photographerName : null,
            photographerProfile: key ? photo.photographerProfile : null,
          },
          formData: {
            ...quickAddItem,
            ...formData,
            itineraryDate: formData.itineraryDate || selectedDayDrawer,
          },
        });

        // update current idea
      } else if (drawerSelectedID) {
        editIdea({
          drawerSelectedID,
          photoData: {
            imgSrcKey: key,
            photographerName: key ? photo.photographerName : null,
            photographerProfile: key ? photo.photographerProfile : null,
          },
          formData: {
            ...formData,
            itineraryDate: formData.itineraryDate || selectedDayDrawer,
          },
        });
        // add new idea
      } else {
        createIdea({
          userID,
          photoData: {
            imgSrcKey: key,
            photographerName: key ? photo.photographerName : null,
            photographerProfile: key ? photo.photographerProfile : null,
          },
          formData: {
            ...formData,
            itineraryDate: formData.itineraryDate || selectedDayDrawer,
          },
        });
      }

      handleCloseDrawer();
      dispatch(setDrawerLoadingState(false));
    }
  };

  const handleOnUpload = (selectedFile) => {
    selectPhoto(selectedFile);
    handleCloseModal();
  };

  const handleOpenChange = (id, isEndIdea) => {
    dispatch(openCenterModal('time-picker', id));

    const selectedIdea = getQuickAddItem(id) || ideas?.find((item) => item.id === id);
    if (selectedIdea) setSelectedItineraryIdea({ ...selectedIdea, isEndIdea });
  };

  let DrawerChildren;

  switch (drawerChildrenType) {
    case 'new-idea':
      DrawerChildren = (
        <IdeaForm
          tripTitle={title}
          onFormFinish={handleOnIdeaFormFinish}
          onSelectFinish={handleOnSelectIdeaFinish}
          onRemoveIdea={handleOnRemoveIdea}
          onReset={handleResetIdeaForm}
          Ideas={[...ideas].filter(filterAddExistingIdeas)
            .map((idea) => {
              const { title, imgSrcKey, id } = idea;
              return (
                <Col key={id}>
                  <CardImageContainer
                    imgSrcKey={imgSrcKey}
                    render={(imgSrcUrl) => (
                      <TinyIdeaCard
                        title={title}
                        imgSrc={imgSrcUrl}
                        isBodyVisible={false}
                        isSelected={selectedExistingIdeaIds.includes(id)}
                        onSelectClick={() => handleSelectExistingIdea(id)}
                      />
                    )}
                  />
                </Col>
              );
            })}
          folders={folders}
          onFolderMenuClick={handleFolderMenuClick}
          onHomeFolderClick={handleHomeFolderClick}
          itineraryStartDate={dateRangeStart}
          itineraryEndDate={dateRangeEnd}
          data={selectedIdea || getQuickAddItem(drawerSelectedID)}
          selectedFolderKey={selectedFolderKey}
          imgSelectionArray={carouselPhotos}
          onSelectCarouselPhoto={selectCarouselPhoto}
          onSearchPhotos={searchPhotos}
          onOpenUploadModal={handleOpenUploadModal}
          photoStatus={photoStatus}
          onResetPhotoSelection={resetPhotoSelection}
          photoSelectionType={photoSelectionType}
          onCategoryTypeChange={handleOnCategoryTypeChange}
          photo={photo}
        />
      );
      break;

    case 'edit-idea':
      DrawerChildren = (
        <IdeaForm
          tripTitle={title}
          onFormFinish={handleOnIdeaFormFinish}
          onRemoveIdea={handleOnRemoveIdea}
          onReset={handleResetIdeaForm}
          folders={folders}
          itineraryStartDate={dateRangeStart}
          itineraryEndDate={dateRangeEnd}
          data={selectedIdea || getQuickAddItem(drawerSelectedID)}
          selectedFolderKey={selectedFolderKey}
          imgSelectionArray={carouselPhotos}
          onSelectCarouselPhoto={selectCarouselPhoto}
          onSearchPhotos={searchPhotos}
          onOpenUploadModal={handleOpenUploadModal}
          photoStatus={photoStatus}
          onResetPhotoSelection={resetPhotoSelection}
          photoSelectionType={photoSelectionType}
          photo={photo}
          onCategoryTypeChange={handleOnCategoryTypeChange}
          onAddFolderClick={handleOnFolderAddClick}
        />
      );
      break;

    case 'update-note':
      const thisIdea = selectedIdea || getQuickAddItem(drawerSelectedID);

      DrawerChildren = (
        <AddItineraryNoteForm
          itineraryNote={thisIdea?.itineraryNote}
          onFinish={handleOnfinishItineraryNote}
        />
      );
      break;

    default:
      DrawerChildren = null;
  }

  let ModalChildren;
  let Modal;
  switch (modalChildrenType) {
    case 'upload':
      ModalChildren = (
        <UploadPhotoForm
          onClose={handleCloseModal}
          onUpload={handleOnUpload}
        />
      );
      break;

    case 'time-picker':
      Modal = (
        <SetTimeModal
          onFinish={handleOnTimeChange}
          onClose={handleCloseModal}
          showModal={isCenterModalVisible}
          currentTime={selectedItineraryIdea?.isEndIdea
            ? selectedItineraryIdea?.endTime : selectedItineraryIdea?.itineraryTime}
          title={selectedItineraryIdea?.title}
        />
      );
      break;

    default:
      ModalChildren = null;
  }

  const refs = dateRanges.reduce((acc, cur) => {
    acc[cur] = React.createRef();
    return acc;
  }, {});

  const visibleDays = [];
  let dayRangeTracker = [];
  const handleInView = useCallback((isInView, day) => {
    if (!isInView) {
      const index = visibleDays.indexOf(day);
      if (index > -1) {
        visibleDays.splice(index, 1);
      }
    } else if (isInView) {
      const index = visibleDays.indexOf(day);
      if (index < 0) {
        visibleDays.push(day);
      }
      visibleDays.sort();
      const dayRange = [visibleDays[0], visibleDays[visibleDays.length - 1]];
      if (!dayRangeTracker.length
        || ((dayRangeTracker[0] !== dayRange[0] || dayRangeTracker[1] !== dayRange[1]))
      ) {
        onDayFocus(dayRange);
        dayRangeTracker = dayRange;
      }
    }
  }, [dateRangeStart, dateRangeEnd]);

  const handleScrollTo = (index) => {
    if (!index || !refs[index]) return;

    refs[index]?.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  useEffect(() => {
    handleScrollTo(selectedDay);
  }, [selectedDay]);

  return (
    <>
      {dateRanges.map((day, index) => {
        const ideasThisDay = getIdeasThisDay(day);

        return (
          <div key={`${index}_${day}`} ref={refs[day]}>
            <InView
              as="div"
              threshold={[0.5]}
              onChange={(isInView) => handleInView(isInView, day)}
            >
              <ItineraryCardSection
                onDrawerAction={handleDrawerAction}
                onNoteChange={handleNoteChange}
                date={day}
                ideas={ideasThisDay}
                onOpenChange={handleOpenChange}
                onDragCard={handleUpdateDragItineraryCard}
                isSplitScreen={isSplitScreen}
                isMobile={isMobile}
                pinnedIdeas={pinnedIdeas}
              />
            </InView>
          </div>
        );
      })}

      {dateRanges.length === 1 ? (
        <div style={{ padding: '100px' }} />
      ) : null}

      {DrawerChildren ? (
        <DrawerModal
          handleOnClose={handleCloseDrawer}
          showModal={isDrawerVisible}
          children={drawerLoadingState ? <SidePageSpinner /> : DrawerChildren}
          maxWidth="600px"
        />
      ) : null}
      {ModalChildren ? (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={modalLoading ? <ModalSpinner /> : ModalChildren}
          modalWidth="500px"
        />
      ) : Modal || null}
    </>
  );
}

export default ItineraryCardContainer;
