import { selectUserState, updateUserReducer } from '@context/user/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { useEffect, useState } from 'react';
import useLayout from '@hooks/useLayout';
import { updateProfileReducer } from '@context/public/publicSlice';
import { getProfile, updateProfileThunk } from '@context/user/userThunk';

const useUser = () => {
  const [passwordNotValid, setPasswordNotValid] = useState();
  const [cognitoErrorMessage, setCognitoErrorMessage] = useState();
  const dispatch = useDispatch();
  const { handleCloseDrawer, handleCloseModal } = useLayout();
  const { user } = useSelector(selectUserState);
  const { pk: userID, data } = user || {};

  const getLoginSession = async () => {
    try {
      await Auth.currentAuthenticatedUser();

      if (!user) dispatch(getProfile());
    // eslint-disable-next-line no-empty
    } catch (_) {}
  };

  useEffect(() => {
    getLoginSession();
  }, [user]);

  const changePassword = async (oldPassword, newPassword) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, oldPassword, newPassword);
    } catch (error) {
      setCognitoErrorMessage(error.message);
    }
  };

  const clearErrorMessage = () => {
    setPasswordNotValid('');
  };

  const updateProfile = (formData) => {
    try {
      clearErrorMessage();

      dispatch(updateUserReducer(formData));
      dispatch(updateProfileThunk(formData));
      dispatch(updateProfileReducer(formData));

      handleCloseDrawer();
      handleCloseModal();
    } catch (error) {
      setCognitoErrorMessage(error.message);
    }
  };

  const updateSettings = (formData) => {
    try {
      clearErrorMessage();
      const { newPassword, oldPassword } = formData;
      delete formData.newPassword;
      delete formData.oldPassword;

      if (oldPassword === newPassword) {
        setPasswordNotValid('Please select a new password');
        setCognitoErrorMessage('Please select a different password');
        return false;
      }

      changePassword(oldPassword, newPassword);
      dispatch(updateUserReducer(formData));
      dispatch(updateProfileReducer(formData));
      dispatch(updateProfileThunk(formData));

      handleCloseDrawer();
      handleCloseModal();
    } catch (error) {
      setCognitoErrorMessage(error.message);
    }
  };

  return {
    userID, ...data, passwordNotValid, cognitoErrorMessage, updateProfile, updateSettings,
  };
};

export default useUser;
