import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  JourneyCard,
  AddCard,
  DrawerModal,
  CenterModal,
  DeleteItemForm,
  JourneyForm,
  UploadPhotoForm,
} from '@travelpassero/khaos';
// Hooks
import { useWindowSize } from '@hooks/useWindowSize';
import useJourneys from '@hooks/useJourneys';
import useCardPhoto from '@hooks/useCardPhoto';
// Redux
import {
  openDrawer,
  openCenterModal,
  setDrawerLoadingState,
} from '@context/actions';
import { selectAppState } from '@context/global/appSlice';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import { selectPhotoState } from '@context/photo/photoSlice';
// Utils
import { dataLayerUtil } from '@utils/dataLayerUtil';
// Components
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import SidePageSpinner from '@components/SidePageSpinner';
// Styles
import useLayout from '@hooks/useLayout';
import styled from 'styled-components';
import { useJourney } from '@hooks/useJourney';
import CardPositioner from './CardPositioner';

const JourneyCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
`;

const JourneyCardContainer = ({
  journeys,
}) => {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const { globalStateReducer } = useSelector(selectAppState);
  const { refetch } = useSelector(selectJourneysState);
  const {
    carouselPhotos,
    photo,
    photoSelectionType,
  } = useSelector(selectPhotoState);
  const { handleCloseModal, handleCloseDrawer } = useLayout();
  const {
    getJourneys, leaveJourney, createJourney, updateJourneyByID,
  } = useJourneys();
  const [leavingJourneyID, setLeavingJourneyID] = useState(null);

  const {
    isCenterModalVisible,
    isDrawerVisible,
    drawerChildrenType,
    modalChildrenType,
    drawerSelectedID,
    drawerLoadingState,
  } = globalStateReducer;

  const getSelectedIdData = (id) => journeys.length && journeys.find((item) => item.pk === id);

  const selectedJourneyData = getSelectedIdData(drawerSelectedID);

  const {
    selectCarouselPhoto,
    selectPhoto,
    searchPhotos,
    postPhoto,
    resetPhotoSelection,
    photoStatus,
    getSamplePhotos,
  } = useCardPhoto(selectedJourneyData, 'journeyboard');

  const handleDrawerAction = (action, id) => {
    dispatch(openDrawer(action, id));
    getSamplePhotos();
  };

  const handleDeleteModalOpen = (id) => {
    setLeavingJourneyID(id);
    dispatch(openCenterModal('delete', id));
  };

  const handleOpenUploadModal = () => {
    dispatch(openCenterModal('upload'));
  };

  const handleCreateJourney = async (formData) => {
    dispatch(setDrawerLoadingState(true));
    const {
      title, location, link, locationAddress,
    } = formData;

    let { key } = photo;
    if (!key) {
      key = await postPhoto();
    }

    const createJourneyAttributes = {
      imgSrcKey: key,
      location,
      locationAddress,
      title,
      link,
      photographerName: photo.photographerName,
      photographerProfile: photo.photographerProfile,
    };

    const journeyID = createJourney(createJourneyAttributes);
    handleCloseDrawer();
    dataLayerUtil('Create Journey', createJourneyAttributes);
    dispatch(setDrawerLoadingState(false));
    history.push(`/journeys/${journeyID}/ideas`);
  };

  const handleOnClick = (id) => {
    history.push(`/journeys/${id}/ideas`);
  };

  let DrawerChildren;

  const handleDeleteItem = async () => {
    leaveJourney({ journeyID: leavingJourneyID });
    handleCloseModal();
  };

  const handleOnUpload = (selectedFile) => {
    selectPhoto(selectedFile);
    handleCloseModal();
  };

  const handleUpdateJourney = async (formData) => {
    dispatch(setDrawerLoadingState(true));
    const {
      title, location, link, locationAddress,
    } = formData;

    let { key } = photo;
    if (!key) { key = await postPhoto(); }

    const updateJourneyAttributes = {
      title,
      link,
      location,
      locationAddress,
      imgSrcKey: key,
      photographerName: photo.photographerName,
      photographerProfile: photo.photographerProfile,
    };
    handleCloseDrawer();
    updateJourneyByID({ journeyID: drawerSelectedID, ...updateJourneyAttributes });
    dispatch(setDrawerLoadingState(false));
  };

  switch (drawerChildrenType) {
    case 'edit':
    case 'add':
      DrawerChildren = (
        <JourneyForm
          isEdit={drawerChildrenType === 'edit'}
          data={selectedJourneyData || {}}
          onFinish={drawerChildrenType === 'edit' ? handleUpdateJourney : handleCreateJourney}
          imgSelectionArray={carouselPhotos}
          onSelectCarouselPhoto={selectCarouselPhoto}
          onSearchPhotos={searchPhotos}
          onOpenUploadModal={handleOpenUploadModal}
          photoStatus={photoStatus}
          onResetPhotoSelection={resetPhotoSelection}
          photoSelectionType={photoSelectionType}
          photo={photo}
        />
      );
      break;

    default:
      DrawerChildren = null;
  }

  const sortedJourneys = journeys?.sort((a, b) => new Date(b?.updatedAt) - new Date(a?.updatedAt)).map((item) => {
    const {
      title, imgSrcKey, crew, ...params
    } = item;
    return (
      <CardImageContainer
        key={item.pk}
        imgSrcKey={imgSrcKey}
        render={(imgSrcUrl) => (
          <JourneyCard
            onCardClick={() => handleOnClick(item.pk)}
            onEditClick={() => handleDrawerAction('edit', item.pk)}
            onDeleteClick={() => handleDeleteModalOpen(item.pk)}
            myCrewUsers={crew || []}
            imgSrc={imgSrcUrl}
            title={title}
            {...params}
          />
        )}
      />
    );
  });

  sortedJourneys.unshift(
    <AddCard
      key="empty-journey-card"
      type="journey"
      onClick={() => { handleDrawerAction('add', 0); }}
    />,
  );

  useEffect(() => {
    const abortController = new AbortController();

    if (refetch) {
      setLeavingJourneyID(null);
      getJourneys();
    }
    return () => abortController.abort();
  }, [refetch]);

  return (
    <JourneyCardWrapper>
      <CardPositioner
        cardMaxWidth={400}
        cardMinWidth={300}
        cardsPerPage={30}
        usePagination
        paginationTextColor="var(--white)"
        cards={sortedJourneys}
      />
      {DrawerChildren && (
      <DrawerModal
        handleOnClose={handleCloseDrawer}
        showModal={isDrawerVisible}
        children={drawerLoadingState ? <SidePageSpinner /> : DrawerChildren}
        maxWidth="600px"
      />
      )}

      {(modalChildrenType === 'delete' && !!leavingJourneyID) && (
      <CenterModal
        footer={null}
        handleOnClose={handleCloseModal}
        showModal={isCenterModalVisible}
        children={(
          <DeleteItemForm
            item="Journey"
            handleOnClose={handleCloseModal}
            handleDeleteItem={handleDeleteItem}
          />
          )}
        modalWidth={isMobile ? '100%' : '500px'}
      />
      )}
      {modalChildrenType === 'upload' && (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={(
            <UploadPhotoForm
              onClose={handleCloseModal}
              onUpload={handleOnUpload}
            />
          )}
          modalWidth={isMobile ? '100%' : '500px'}
        />
      )}
    </JourneyCardWrapper>
  );
};

export default JourneyCardContainer;
