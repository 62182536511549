import * as R from 'remeda';
import moment from 'moment';

export const formatSimple = (responses, crew) => responses
    && responses.map((item, index) => {
      const findUser = crew.find((user) => user.id === item.userID);

      const userInfo = {
        name: findUser && findUser.firstName,
        color: findUser && findUser.iconColor && `var(--${findUser.iconColor})`,
      };

      return {
        key: `${index + 1}`,
        userInfo,
        data: item.response,
      };
    });

export const formatBinary = (responses, getLabels) => {
  const totalResponseAmounts = responses
    && responses.reduce((acc, cur) => {
      let responseType;
      let updateLeftAmount;
      let updateRightAmount;
      if (cur && cur.response && cur.response.includes('0')) {
        responseType = 'left';
      }

      if (cur && cur.response && cur.response.includes('1')) {
        responseType = 'right';
      }

      if (responseType === 'left') {
        updateLeftAmount = acc.leftAmount ? acc.leftAmount + 1 : 1;
        updateRightAmount = acc.rightAmount ? acc.rightAmount : 0;
      }

      if (responseType === 'right') {
        updateLeftAmount = acc.rightAmount ? acc.rightAmount + 1 : 1;
        updateRightAmount = acc.leftAmount ? acc.leftAmount : 0;
      }

      return {
        leftAmount: updateLeftAmount,
        rightAmount: updateRightAmount,
      };
    }, {});

  const leftAmount = totalResponseAmounts && totalResponseAmounts.leftAmount;
  const rightAmount = totalResponseAmounts && totalResponseAmounts.rightAmount;
  const totalAmount = totalResponseAmounts && leftAmount + rightAmount;

  const leftPercent = leftAmount === 0 ? 0 : (totalAmount / leftAmount) * 100;
  const rightPercent = rightAmount === 0 ? 0 : (totalAmount / rightAmount) * 100;

  const { leftLabel, rightLabel } = getLabels;

  return {
    percentages: { leftPercent, rightPercent },
    labels: { leftLabel, rightLabel },
    amounts: { leftAmount, rightAmount },
  };
};

export const formatBinaryResultsData = (responses, crew) => {
  const responseItems1 = responses
    && responses.reduce((acc, cur) => {
      const findUser = crew.find((user) => user.id === cur.userID);

      if (cur.response.includes('0')) {
        return [...acc, findUser.firstName];
      }
      return acc;
    }, []);

  const responseItems2 = responses
    && responses.reduce((acc, cur) => {
      const findUser = crew.find((user) => user.id === cur.userID);

      if (cur.response.includes('1')) {
        return [...acc, findUser.firstName];
      }
      return acc;
    }, []);

  const formattedItem1 = responseItems1 && responseItems1.join(', ');
  const formattedItem2 = responseItems2 && responseItems2.join(', ');

  return {
    binaryData1: formattedItem1,
    binaryData2: formattedItem2,
  };
};

export const formatMultiple = ({ responses, choices, crew }) => {
  const responseItems = responses
    && responses.map((item, index) => {
      const findUser = crew.find((user) => user.id === item.userID);

      const userInfo = {
        name: findUser?.firstName,
        color: findUser?.iconColor,
      };

      const getDataFormatted = item.responseOptions
        && item.responseOptions.map((option) => {
          const findChoiceOption = choices.find(
            (choice) => choice.id === option,
          );

          return findChoiceOption && findChoiceOption.option;
        });

      const data = getDataFormatted && getDataFormatted.join(', ');

      return {
        key: `${index + 1}`,
        userInfo,
        data,
      };
    });

  return responseItems;
};

export const formatDates = ({ responses, crew }) => {
  const responseItems = responses
    && responses.map((item, index) => item.responseDateRange.map((date) => {
      const startDate = date && date.dateRange && date.dateRange[0];
      const endDate = date && date.dateRange && date.dateRange[1];

      if (!startDate || !endDate) {
        return null;
      }
      const getUser = crew && crew.find((mem) => mem.id === item.userID);

      return {
        startDate: new Date(startDate),
        endDate: new Date(endDate),
        key: `${index + 1}`,
        color: getUser && getUser.iconColor,
        name: getUser && getUser.firstName,
      };
    }));

  const calendarFlattenRange = responseItems && R.flatten(responseItems);

  const filterCalRange = calendarFlattenRange && calendarFlattenRange.filter((i) => i);
  const calendarDateRange = filterCalRange
    && filterCalRange.map((c) => ({
      key: c.key,
      endDate: c.endDate,
      startDate: c.startDate,
    }));

  const calendarColorMultiRange = filterCalRange && filterCalRange.map((i) => `var(--${i.color})`);
  const calendarSingleColorRange = filterCalRange && filterCalRange.map(() => 'var(--accent)');

  const resultsTableData = filterCalRange
    && filterCalRange.map((cal, index) => {
      const { startDate, endDate } = cal;

      const userInfo = {
        name: cal && cal.name,
        color: cal && cal.color && `var(--${cal.color})`,
      };

      const formattedStart = moment(startDate).format('MMM DD');
      const formattedEnd = moment(endDate).format('MMM DD');

      return {
        key: `${index + 1}`,
        userInfo,
        data: `${formattedStart} to ${formattedEnd}`,
      };
    });

  return {
    resultsTableData,
    calendarDateRange,
    calendarColorMultiRange,
    calendarSingleColorRange,
  };
};

export const formatTiles = ({ responses, choices, ideas }) => {
  const choiceIdeas = choices && choices.map((c) => c.ideaID);
  const slectedIdeas = ideas && ideas.filter((idea) => choiceIdeas.includes(idea.id));

  const responseItems = responses
    && responses.map((item) => item.responseIdeas.map((idea) => ({
      idea: idea.ideaID,
      status: idea.status,
    })));
  const responseFlattenRange = responseItems && R.flatten(responseItems);

  const filterResponses = responseFlattenRange
    && responseFlattenRange.reduce((acc, cur) => {
      const findCur = acc.find((item) => item.idea === cur.idea);

      const statusType = cur.status;

      if (!findCur) {
        return [
          ...acc,
          {
            idea: cur.idea,
            [statusType]: 1,
          },
        ];
      }

      return [
        ...acc,
        {
          idea: findCur.idea,
          [statusType]: findCur.statusType ? findCur.statusType + 1 : 1,
        },
      ];
    }, []);

  const combineFilterResponses = filterResponses
    && filterResponses.length > 0
    && filterResponses.reduce((acc, cur) => {
      const hasIdea = acc.find((it) => it.idea === cur.idea);

      if (hasIdea) {
        const findIndex = acc.findIndex((it) => it.idea === cur.idea);
        const copiedArry = acc.slice();

        copiedArry[findIndex] = { ...acc[findIndex], ...cur };

        return [...copiedArry];
      }
      return [...acc, cur];
    }, []);

  const totalIdeas = slectedIdeas
    && slectedIdeas.map((idea) => {
      const findResults = combineFilterResponses
        && combineFilterResponses.find((it) => it.idea === idea.id);

      return {
        ...idea,
        ...findResults,
      };
    });

  return totalIdeas;
};

export const formatTileResultCards = ({ responses, crew, ideas }) => (
  responses
    && responses.map((item) => {
      const { userID } = item;
      const findUser = crew.find((i) => i.id === userID);
      const {
        firstName, lastName, iconColor, isPendingFriend,
      } = findUser;

      const options = item.responseIdeas.map((idea) => {
        const getIdea = ideas.find((i) => i.id === idea.ideaID);

        return {
          title: getIdea && getIdea.title,
          status: idea.status,
        };
      });

      return {
        firstName,
        lastName: lastName?.charAt(0).toUpperCase(),
        isPendingFriend,
        iconColor,
        options,
      };
    })
);

export const formatUserInfo = (crew) => (
  crew
    && crew.map((mem) => ({
      name: mem.firstName,
      color: mem.iconColor,
    }))
);
