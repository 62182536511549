import { useEffect } from 'react';
import { Container, SpinnerContainer, UserFormContainer } from '@styles/pages/SIgnInPageStyle';
import Spinner from '@components/Spinner';
import { useSelector } from 'react-redux';
import { selectUserState } from '@context/user/userSlice';
import { useTracking } from '@hooks/useTracking';

const OnboardingLayout = ({ children, page }) => {
  const { setPage } = useTracking();
  const { loading } = useSelector(selectUserState);

  useEffect(() => {
    setPage(page);
  }, [page]);

  return (
    <Container>
      <UserFormContainer>
        {loading ? (
          <SpinnerContainer>
            <Spinner size={104} />
          </SpinnerContainer>
        ) : null}
        {children}
      </UserFormContainer>
    </Container>
  );
};

export default OnboardingLayout;
