import { IDEA_CATEGORY } from '@travelpassero/khaos';

export function isSimpleItineraryCard(ideaCategoryId) {
  return [
    IDEA_CATEGORY.FLIGHT,
    IDEA_CATEGORY.TRAIN,
    IDEA_CATEGORY.DRIVE,
    IDEA_CATEGORY.HOTEL,
    IDEA_CATEGORY.RENTAL,
    IDEA_CATEGORY.CAMP,
  ].includes(ideaCategoryId);
}

export function isPinnedItineraryIdea(ideaCategoryId) {
  return [
    IDEA_CATEGORY.HOTEL,
    IDEA_CATEGORY.RENTAL,
    IDEA_CATEGORY.CAMP,
  ].includes(ideaCategoryId);
}

export function isCheckInOutIdea(ideaCategoryId) {
  return [
    IDEA_CATEGORY.HOTEL,
    IDEA_CATEGORY.RENTAL,
    IDEA_CATEGORY.CAMP,
  ].includes(ideaCategoryId);
}

export function isDepartingArrivingIdea(ideaCategoryId) {
  return [
    IDEA_CATEGORY.FLIGHT,
    IDEA_CATEGORY.TRAIN,
    IDEA_CATEGORY.DRIVE,
  ].includes(ideaCategoryId);
}
