import React from 'react';
import useUser from '@hooks/useUser';
import useSurvey from '@hooks/useSurvey';
import { useJourney } from '@hooks/useJourney';
import SurveyResultsPage from './SurveyResultsPage';
import SurveyAnswerPage from './SurveyAnswerPage';

const SurveyResultsContainer = ({ journeyID, hasBringPageButton, splitScreenSide }) => {
  const { userID } = useUser();
  const { data } = useJourney(journeyID);
  const { getCurrentSurvey } = useSurvey(data);

  const selectedSurvey = getCurrentSurvey();

  let showResultsPage;

  if (selectedSurvey) {
    const { creator, invited, completed } = selectedSurvey;

    if (!invited.includes(userID) && creator !== userID) {
      showResultsPage = false;
    }

    if (creator === userID) {
      showResultsPage = true;
    }

    if (completed.includes(userID)) {
      showResultsPage = true;
    }
  }

  // show this page when invited and not completed
  if (!showResultsPage) {
    return (
      <SurveyAnswerPage journeyID={journeyID} splitScreenSide={splitScreenSide} />
    );
  }
  return (
    <SurveyResultsPage
      journeyID={journeyID}
      hasBringPageButton={hasBringPageButton}
      splitScreenSide={splitScreenSide}
    />
  );
};

export default SurveyResultsContainer;
