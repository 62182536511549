import React from 'react';
import styled from 'styled-components';

import { Button, Typography } from '@travelpassero/khaos';
import { Col, Row } from 'antd';
import SurveyGif from '../assets/survey.gif';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SurveyIntroPage = ({ onCreateSurveyClick }) => (
  <Container>
    <Row
      justify="center"
      style={{
        margin: '40px 16px',
      }}
    >
      <Col span={24}>
        <Typography
          style={{
            textAlign: 'center',
            font: 'Montserrat',
            letterSpacing: '5px',
          }}
          size=".875rem"
          type="secondaryHeading"
        >
          WELCOME TO SURVEYS!
        </Typography>
        <Typography
          size="2rem"
          style={{ textAlign: 'center', padding: '16px 0 50px' }}
          type="primarySubHeading"
        >
          Easily get feedback by creating a survey for your crew members.
        </Typography>
      </Col>
      <Col span={24} style={{ textAlign: 'center' }}>
        <img src={SurveyGif} alt="survey-helper" style={{ width: '100%', maxWidth: '800px' }} />
      </Col>
      <Col>
        <Button
          onClick={onCreateSurveyClick}
          style={{ marginTop: '56px' }}
          type="primary"
        >
          Create a Survey
        </Button>
      </Col>
    </Row>
  </Container>
);

export default SurveyIntroPage;
