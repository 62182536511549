import { createAsyncThunk } from '@reduxjs/toolkit';
import { Auth } from '@aws-amplify/auth';
import * as HTTP from '@utils/http';
import { profile, social } from '@utils/urls';

export const signIn = createAsyncThunk('signIn',
  async (formData) => {
    try {
      const { email, password, remembered } = formData;
      if (remembered) localStorage.setItem('remembered', 'true');

      const EMAIL = email.toLowerCase();
      await Auth.signIn(EMAIL, password);
      return await HTTP.get(profile);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getProfile = createAsyncThunk('getProfile',
  async () => {
    try {
      return await HTTP.get(profile);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const updateProfileThunk = createAsyncThunk('updateProfile',
  async (formData) => {
    try {
      return await HTTP.patch(profile, formData);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const socialAuth = createAsyncThunk('socialLogin',
  async (socialData) => {
    try {
      const socialResponse = await HTTP.post(social, socialData);
      const { network, socialId, authToken } = socialResponse;
      const key = {
        network,
        socialId,
        authToken,
      };
      if (socialResponse.authToken) {
        const cognitoUser = await Auth.signIn(socialResponse.email);

        if (cognitoUser.challengeName === 'CUSTOM_CHALLENGE') {
          await Auth.sendCustomChallengeAnswer(cognitoUser, JSON.stringify(key));

          return await HTTP.get(profile);
        }
      }
    } catch (error) {
      throw new Error(error.message);
    }
  });
