import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Button,
  PopConfirm,
  Search,
  CircleCheckbox,
  Empty,
  CenterModalTabs,
  Typography,
} from '@travelpassero/khaos';
import {
  Col, Row, Divider, Form,
} from 'antd';
import useLayout from '@hooks/useLayout';
import useFriend from '@hooks/useFriend';
import useJourneyCrew from '@hooks/useJourneyCrew';
import { useDispatch, useSelector } from 'react-redux';
import { openCenterModal } from '@context/actions';
import { selectAppState } from '@context/global/appSlice';
import { selectFriendState } from '@context/friend/friendSlice';
import Spinner from '@components/Spinner';
import useJourneys from '@hooks/useJourneys';
import FriendList from '../../pages/myFriends/FriendList';
import { filterUsers } from '../../utils/filter';
import AddFriendsContainer from './AddFriendsContainer';

const FriendsEditContainer = styled.div`
  padding: 16px;
  height: 100%;
  background: var(--page-background);
`;

const ManageCrewModal = ({ journeyID }) => {
  const dispatch = useDispatch();
  const { handleCloseModal } = useLayout();

  const { globalStateReducer } = useSelector(selectAppState);
  const { sentFriendRequestStatus } = useSelector(selectFriendState);
  const [form] = Form.useForm();
  const handleOpenModal = (type) => {
    dispatch(openCenterModal(type));
  };

  const {
    potentialCrew, changeFriendInCrew, allFriendsInCrew, noFriendsInCrew,
  } = useJourneyCrew(journeyID);
  const { addCrewUsersToJourney } = useJourneys();
  const { resetSentFriendRequestStatus } = useFriend();
  const [potentialCrewList, setPotentialCrewList] = useState([...potentialCrew]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [wasChanged, setWasChanged] = useState(false);

  const { isCenterModalVisible } = globalStateReducer;

  const getCheckedFriends = () => potentialCrew?.filter((friend) => !friend.isDisabled && friend.isChecked);

  useEffect(() => {
    setPotentialCrewList(filterUsers(searchQuery, potentialCrew));
    const enabledFriends = potentialCrew?.filter((friend) => !friend.isDisabled);
    const checkedFriends = getCheckedFriends();
    if (enabledFriends.length) {
      if (checkedFriends.length >= enabledFriends.length) {
        setIndeterminate(false);
        setCheckAll(true);
      } else {
        setIndeterminate(checkedFriends.length > 0);
        setCheckAll(false);
      }
    }
  }, [potentialCrew]);

  // when the add a new friend request modal completes its submission cycle
  useEffect(() => {
    if (sentFriendRequestStatus?.toLowerCase() === 'fulfilled') {
      setTimeout(() => {
        handleCloseModal();
        resetSentFriendRequestStatus();
      }, 3000);
    }
  }, [sentFriendRequestStatus]);

  const handleSearchFriends = (event) => {
    const query = event?.target?.value || event;

    setPotentialCrewList(filterUsers(query, potentialCrew));
    setSearchQuery(query);
  };

  const handleOnAddCrew = () => {
    const checkedFriends = getCheckedFriends();
    addCrewUsersToJourney({ journeyID, crewIDs: checkedFriends.map((checkedFriend) => checkedFriend.id) });
    setWasChanged(false);
    handleCloseModal();
  };

  const onCheckAllChange = (e) => {
    const enabledFriends = potentialCrew?.filter((friend) => !friend.isDisabled);

    if (enabledFriends?.length) {
      const isChecked = e.target.checked;

      if (isChecked) {
        allFriendsInCrew();
        setWasChanged(true);
      } else {
        noFriendsInCrew();
        setWasChanged(false);
      }

      setCheckAll(isChecked);
    }
  };

  const handleCheckboxClick = (id) => {
    changeFriendInCrew(id);
    setWasChanged(true);
  };

  let SubmitStatusContent = null;

  if (sentFriendRequestStatus?.toLowerCase() === 'pending') {
    SubmitStatusContent = (
      <Row justify="center" align="middle" style={{ padding: '70px 0' }}>
        <Col>
          <Spinner size={104} />
        </Col>
      </Row>
    );
  }

  if (sentFriendRequestStatus?.toLowerCase() === 'fulfilled') {
    SubmitStatusContent = (
      <Row justify="center" align="middle" style={{ padding: '70px 0' }}>
        <Col>
          <Typography size="1.25rem" type="primarySubHeading">
            Friend requests sent.
          </Typography>
        </Col>
      </Row>
    );
  }

  return (
    <CenterModalTabs
      onClose={handleCloseModal}
      showModal={isCenterModalVisible}
      title="Add Journey Crew"
      tabs={[{
        label: 'Add Friends',
        isHidden: !potentialCrew?.length,
        Content: SubmitStatusContent || (
          <Row>
            <Col span={24}>
              <FriendsEditContainer>
                <Row
                  wrap={false}
                  justify="space-between"
                  align="middle"
                  gutter={[24, 16]}
                  style={{ padding: '8px' }}
                >
                  <Col flex="auto">
                    <Search
                      placeholder="Search for a friend"
                      onChange={handleSearchFriends}
                      onSearch={handleSearchFriends}
                    />
                  </Col>
                  <Col>
                    <CircleCheckbox
                      type="primary"
                      indeterminate={indeterminate}
                      onClick={onCheckAllChange}
                      isChecked={checkAll}
                      title="Check all"
                      style={{ whiteSpace: 'nowrap' }}
                    />
                  </Col>
                </Row>
                <Divider style={{ margin: '10px 0 16px 0' }} />
                {potentialCrewList?.length ? (
                  <FriendList
                    list={potentialCrewList}
                    onCheckboxClick={handleCheckboxClick}
                    cardsPerPage={10}
                  />
                ) : (
                  <Empty
                    heading="You don’t have any friends yet"
                    subHeading={(
                      <>
                        Why don’t you go ahead and
                        {' '}
                        <Button
                          type="link"
                          onClick={() => handleOpenModal('add friends')}
                        >
                          send a friend request
                        </Button>
                      </>
                    )}
                  />
                )}
              </FriendsEditContainer>
            </Col>
          </Row>
        ),
        buttons: !SubmitStatusContent && [
          <Button onClick={handleCloseModal}>
            Cancel
          </Button>,
          <PopConfirm onConfirm={handleOnAddCrew}>
            <Button type="primary" ddisabled={!wasChanged}>
              Add to Crew
            </Button>
          </PopConfirm>,
        ],
      }, {
        label: 'Add New',
        Content: (
          SubmitStatusContent || <AddFriendsContainer journeyID={journeyID} onClose={handleCloseModal} form={form} />
        ),
        buttons: !SubmitStatusContent && [
          <Button onClick={handleCloseModal}>
            Cancel
          </Button>,
          <Button type="primary" onClick={form.submit}>
            Send Friend Requests
          </Button>,
        ],
      }]}
    />
  );
};

export default ManageCrewModal;
