import React, { useEffect, useState } from 'react';

import {
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
} from 'antd';

import styled from 'styled-components';
import { useWindowSize } from '@hooks/useWindowSize';
import { Button, Typography } from '@travelpassero/khaos';
import useFriend from '@hooks/useFriend';
import { useSelector } from 'react-redux';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import { selectFriendState } from '@context/friend/friendSlice';

const { Item } = Form;
const { Option } = Select;

const FriendContainer = styled.div`
    padding: 34px 24px 24px 24px;
`;

function FormRow({
  index, item = {}, journeyID, journeys, form,
}) {
  const { firstName, lastName, email } = item;
  const journeyOptions = [];
  if (journeys?.length) {
    journeys.forEach((journey) => {
      const { pk, data } = journey;
      journeyOptions.push(
        <Option key={pk} value={pk}>
          {data.title}
        </Option>,
      );
    });
  }

  useEffect(() => {
    // this is here because the select field is not populating when journey is selected on load
    form.setFieldsValue({ [`journeyID_${index}`]: journeyID });
  }, [journeyID]);

  return (
    <Row key={`${index}_friend--form-list`} justify="center" gutter={[16, 0]}>
      <Col xs={12}>
        <Item
          name={`firstName_${index}`}
          initialValue={firstName}
        >
          {/* input label is needed for hubspot */}
          <Input placeholder="First Name" label={`firstName_${index}`} />
        </Item>
      </Col>
      <Col xs={12}>
        <Item
          name={`lastName_${index}`}
          initialValue={lastName}
        >
          <Input placeholder="Last Name" label={`lastName_${index}`} />
        </Item>
      </Col>
      <Col xs={12}>
        <Item
          name={`email_${index}`}
          initialValue={email}
          rules={[{ type: 'email', required: true }]}
        >
          <Input placeholder="Email" label={`email_${index}`} />
        </Item>
      </Col>
      <Col xs={12}>
        <Item
          name={`journeyID_${index}`}
        >
          <Select
            listHeight={500}
            defaultValue={journeyID}
            value={journeyID}
            placeholder="Add to Journey (optional)"
            allowClear
            label={`journeyID_${index}`}
          >
            {journeyOptions}
          </Select>
        </Item>
      </Col>
    </Row>
  );
}

function AddFriendsContainer({ journeyID, form }) {
  const { isMobile } = useWindowSize();
  const { journeys } = useSelector(selectJourneysState);
  const { sentFriendRequestStatus } = useSelector(selectFriendState);
  const [friendForm, setFriendForm] = useState([
    { firstName: '', lastName: '', email: '' },
  ]);
  const { sendFormFriendRequests, friendErrorMessage } = useFriend();

  const handleOnMinusClick = () => {
    const MinusArrayItem = [...friendForm];

    if (MinusArrayItem.length <= 1) {
      return;
    }

    MinusArrayItem.length -= 1;
    setFriendForm(MinusArrayItem);
  };

  const handleOnPlusClick = () => {
    const AddedArrayItem = [
      ...friendForm,
      { firstName: '', lastName: '', email: '' },
    ];

    setFriendForm(AddedArrayItem);
  };

  const handleSendFriendRequests = async (formData) => {
    await sendFormFriendRequests(formData);
  };

  const validateMessages = {
    required: 'This field is required',
    types: {
      email: 'Provide a valid email address',
    },
  };

  return (
    <Form
      form={form}
      name="control-hooks"
      onFinish={handleSendFriendRequests}
      validateMessages={validateMessages}
    >
      <FriendContainer>
        {friendForm && friendForm.map((item, index) => (
          <div key={`${item.email}-${index}`}>
            <FormRow
              index={index}
              item={item}
              journeyID={journeyID}
              journeys={journeys}
              isMobile={isMobile}
              form={form}
            />
            {index < friendForm?.length - 1 && <Divider style={{ margin: '12px 0 32px 0' }} />}
          </div>
        ))}
        <Row justify="center" gutter={16} style={{ marginTop: '12px' }}>
          <Col>
            <Button onClick={handleOnMinusClick} type="circleMinus" />
          </Col>
          <Col>
            <Button onClick={handleOnPlusClick} type="circlePlus" />
          </Col>
        </Row>
      </FriendContainer>
      {friendErrorMessage && (
      <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Typography size="1rem" type="errorText">
            {friendErrorMessage}
          </Typography>
        </Col>
      </Row>
      )}
      {sentFriendRequestStatus?.toLowerCase() === 'rejected' && (
      <Row justify="center" align="middle" style={{ textAlign: 'center' }}>
        <Col span={24}>
          <Typography size="1rem" type="errorText">
            An error occured. Please try again.
          </Typography>
        </Col>
      </Row>
      )}
    </Form>
  );
}

export default AddFriendsContainer;
