import React from 'react';
import {
  Switch,
  Route,
  useParams,
  Redirect,
} from 'react-router-dom';

import Layout from '@components/Layout';
import { useWindowSize } from '@travelpassero/khaos';
import IdeaBoardPageContainer from './IdeaBoardPageContainer';
import SurveyPageContainer from './SurveyPageContainer';
import SurveyResultsContainer from './SurveyResultsContainer';
import ItineraryPageContainer from './ItineraryPageContainer';
import SplitScreenContainer from './splitscreen/SplitScreenContainer';

function JourneyRoutes() {
  const { id: journeyID } = useParams();
  const journeyPath = `/journeys/${journeyID}`;
  const { isMobile } = useWindowSize();

  return (
    <Layout backgroundColor="var(--page-background)" showJourneyMenu>
      <Switch>
        <Route path={`${journeyPath}/ideas-with-itinerary`}>
          {isMobile && <Redirect to={`${journeyPath}/ideas`} />}
          <SplitScreenContainer
            SplitScreenChildLeft={<IdeaBoardPageContainer journeyID={journeyID} splitScreenSide="left" />}
            SplitScreenChildRight={<ItineraryPageContainer journeyID={journeyID} splitScreenSide="right" />}
            closeSplitScreenPath={`${journeyPath}/ideas`}
          />
        </Route>
        <Route path={`${journeyPath}/ideas-with-surveys/:id`}>
          {isMobile && <Redirect to={`${journeyPath}/ideas`} />}
          <SplitScreenContainer
            SplitScreenChildLeft={<IdeaBoardPageContainer journeyID={journeyID} splitScreenSide="left" />}
            SplitScreenChildRight={<SurveyResultsContainer journeyID={journeyID} splitScreenSide="right" />}
            closeSplitScreenPath={`${journeyPath}/ideas`}
          />
        </Route>
        <Route path={`${journeyPath}/surveys-with-itinerary/:id`}>
          {isMobile && <Redirect to={`${journeyPath}/surveys`} />}
          <SplitScreenContainer
            SplitScreenChildLeft={<SurveyResultsContainer journeyID={journeyID} splitScreenSide="left" />}
            SplitScreenChildRight={<ItineraryPageContainer journeyID={journeyID} splitScreenSide="right" />}
            closeSplitScreenPath={`${journeyPath}/surveys`}
          />
        </Route>
        <Route path={`${journeyPath}/ideas-with-surveys`}>
          {isMobile && <Redirect to={`${journeyPath}/ideas`} />}
          <SplitScreenContainer
            SplitScreenChildLeft={<IdeaBoardPageContainer journeyID={journeyID} splitScreenSide="left" />}
            SplitScreenChildRight={<SurveyPageContainer journeyID={journeyID} splitScreenSide="right" />}
            closeSplitScreenPath={`${journeyPath}/ideas`}
          />
        </Route>
        <Route path={`${journeyPath}/ideas`}>
          <IdeaBoardPageContainer journeyID={journeyID} />
        </Route>
        <Route path={`${journeyPath}/surveys/:id`}>
          <SurveyResultsContainer journeyID={journeyID} />
        </Route>
        <Route path={`${journeyPath}/surveys`}>
          <SurveyPageContainer journeyID={journeyID} />
        </Route>
        <Route path={`${journeyPath}/itinerary`}>
          <ItineraryPageContainer journeyID={journeyID} />
        </Route>
      </Switch>
    </Layout>
  );
}

export default JourneyRoutes;
