import React, { useState, useEffect } from 'react';
import {
  Menu, Row, Col, Tooltip, Badge, Divider,
} from 'antd';
import styled from 'styled-components';
import { useLocation, Link, useParams } from 'react-router-dom';

import { CircleProfileIcon, Typography } from '@travelpassero/khaos';
import {
  DownloadOutlined,
  WindowsFilled,
  AndroidFilled,
  AppleFilled,
  SettingFilled,
  SearchOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { openCenterModal, openDrawer, setLeftNavCollapse } from '@context/actions';
import useNotification from '@hooks/useNotification';
import { selectUserState } from '@context/user/userSlice';
import { useJourney } from '@hooks/useJourney';
import { useAddToHomescreenPrompt } from '@hooks/useAddToHomescreenPrompt';
import useUser from '@hooks/useUser';
import { selectJourneyState } from '@context/journey/journeySlice';
import JourneysIcon from '../../assets/icon-journeys.svg';
import BulbIcon from '../../assets/icon-bulb.svg';
import SurveyIcon from '../../assets/icon-survey.svg';
import ItineraryIcon from '../../assets/icon-itinerary.svg';
import FriendsIcon from '../../assets/icon-friends.svg';
import ClockIcon from '../../assets/icon-clock.svg';
import ChromeIcon from '../../assets/icon-chrome.svg';

const { Item } = Menu;

const NavContainer = styled.div`
  .ant-divider-inner-text {
    width: 100%;
    max-width: fit-content;
  }
`;

const MenuContainer = styled(Menu)`
  background: ${(props) => (props.isSubMenu ? 'var(--component-background-light)' : 'none')} !important;
  padding: ${(props) => (props.isSubMenu && props.isCollapsed ? '13px 0' : '0')} !important;
    
  .ant-menu-item {
    margin: ${(props) => (props.isMobile ? '16px 12px' : '12px 10px')} !important;
    padding: 11px !important;
    height: 40px;
    width: auto !important;
    border-radius: 8px;
    overflow: visible;

    &.sub-menu {
      margin: ${(props) => (props.isMobile ? '8px 12px' : '12px 10px')} !important;
    }

    .ant-menu-item-icon {
      display: block;
      height: 100%;

      > .ant-row {
        height: 100%;
      }

      .ant-badge {
        display: block;
      }

      .menu-label {
        color: var(--primary) !important;
        font-weight: 600;
        display: block;
        margin-left: 10px;
      }
      
      .anticon, img, svg {
        display: block;
        margin-right: 0;

        path:not(.a) {
          fill: var(--icons-variant) !important;
        }
      }
    }
  }

  .ant-menu-item-selected {
    background: var(--accent) !important;

    .ant-menu-item-icon {
      .menu-label, .anticon, img, svg {
        color: var(--white) !important;

        path:not(.a) {
          fill: var(--white) !important;
        }
      }
    }

    &:after {
      display: none !important;
    }
  }

  .ant-badge {
    max-width: fit-content;
    .ant-badge-count {
      background: var(--accent-variant);
    }
  }
`;

// const handleChromeClick = () => {
//   window.open('https://chrome.google.com/webstore/detail/levantr/eldloofphpicjfjfbinpefldcnoonihc');
// };

const Navigation = ({ isMobile, isCollapsed = false, onMenuClick = () => {} }) => {
  const [promptToInstall] = useAddToHomescreenPrompt();
  const location = useLocation();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { notifications } = useNotification();

  const { journey } = useSelector(selectJourneyState);

  const { data } = journey || {};
  const { title } = data || {};

  const {
    iconColor, firstName, lastName, username,
  } = useUser();
  const [pathTypes, setPathTypes] = useState([]);
  const [showJourneyMenu, setShowJourneyMenu] = useState(false);

  const { pathname } = location;

  useEffect(() => {
    if (isMobile) dispatch(setLeftNavCollapse(false));
  }, [isMobile]);

  useEffect(() => {
    setShowJourneyMenu(false);

    if (pathname.includes('/journeys')) {
      if (pathname.includes('ideas') && pathname.includes('survey')) {
        setShowJourneyMenu(true);
        setPathTypes(['/journeys/ideas', '/journeys/surveys']);
      } else if (pathname.includes('ideas') && pathname.includes('itinerary')) {
        setShowJourneyMenu(true);
        setPathTypes(['/journeys/ideas', '/journeys/itinerary']);
      } else if (pathname.includes('itinerary') && pathname.includes('survey')) {
        setShowJourneyMenu(true);
        setPathTypes(['/journeys/itinerary', '/journeys/surveys']);
      } else if (pathname.includes('/ideas')) {
        setShowJourneyMenu(true);
        setPathTypes(['/journeys/ideas']);
      } else if (pathname.includes('/itinerary')) {
        setShowJourneyMenu(true);
        setPathTypes(['/journeys/itinerary']);
      } else if (pathname.includes('/surveys')) {
        setShowJourneyMenu(true);
        setPathTypes(['/journeys/surveys']);
      } else {
        setPathTypes(['/journeys']);
      }
    } else if (pathname.includes('/friends')) {
      setPathTypes(['/friends']);
    } else if (pathname.includes('/discover')) {
      setPathTypes(['/discover']);
    } else if (pathname.includes(`/${username}`)) {
      setPathTypes([`/${username}`]);
    } else {
      setPathTypes(['/journeys']);
    }
  }, [pathname]);

  const journeyPath = `/journeys/${id}`;

  const handleSettingsClick = () => {
    dispatch(openDrawer('settings'));
  };

  const handleNotificationsClick = () => {
    dispatch(openCenterModal('notifications'));
  };

  const userAgent = window.navigator.userAgent.toLowerCase();
  const isIos = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.platform);
  const isWindows = /(win32|win64|windows|wince)/i.test(window.navigator.platform);
  const isAndroid = /(android)/.test(userAgent);

  let PWAIcon;
  switch (true) {
    case isIos:
      PWAIcon = <AppleFilled style={{ fontSize: '2rem' }} />;
      break;

    case isAndroid:
      PWAIcon = <AndroidFilled style={{ fontSize: '2rem' }} />;
      break;

    case isWindows:
      PWAIcon = <WindowsFilled style={{ fontSize: '2rem' }} />;
      break;

    default:
      PWAIcon = <DownloadOutlined style={{ fontSize: '2rem' }} />;
      break;
  }

  const menuContent = [
    {
      items: [
        {
          label: 'My Journeys',
          path: '/',
          key: '/journeys',
          Icon: <JourneysIcon height="28" width="28" style={{ margin: '0 3px 0 2px' }} />,
        },
        {
          label: 'Discover',
          path: '/discover',
          key: '/discover',
          Icon: <SearchOutlined style={{ fontSize: '28px', margin: '0 3px 0 2px' }} />,
        },
      ],
    },
    {
      label: title || 'Journey',
      isHidden: !showJourneyMenu,
      isSubMenu: true,
      items: [
        {
          label: 'Idea Board',
          path: `${journeyPath}/ideas`,
          key: '/journeys/ideas',
          Icon: <BulbIcon height="28" width="28" style={{ margin: '0 3px 0 2px' }} />,
        },
        {
          label: 'Surveys',
          path: `${journeyPath}/surveys`,
          key: '/journeys/surveys',
          Icon: <SurveyIcon height="28" width="28" style={{ margin: '0 3px 0 2px' }} />,
        },
        {
          label: 'Itinerary',
          path: `${journeyPath}/itinerary`,
          key: '/journeys/itinerary',
          Icon: <ItineraryIcon height="28" width="28" style={{ margin: '0 3px 0 2px' }} />,
        },
      ],
    },
    {
      label: 'Manage',
      items: [
        {
          label: 'My Friends',
          path: '/friends',
          key: '/friends',
          Icon: <FriendsIcon height="28" width="28" style={{ margin: '0 3px 0 2px' }} />,
        },
        {
          label: 'My Profile',
          path: `/${username}`,
          key: `/${username}`,
          Icon: (
            <CircleProfileIcon
              firstName={firstName}
              lastName={lastName}
              iconColor={iconColor}
              style={{ transform: 'scale(.75)', margin: '0 -2px 0 -3px', borderWidth: '3px' }}
            />
          ),
        },
        {
          label: <div style={{ paddingLeft: '3px' }}>Notifications</div>,
          onClick: handleNotificationsClick,
          Icon: (
            <Badge
              count={notifications?.length}
              overflowCount={999}
              offset={[-2, 2]}
            >
              <ClockIcon height="28" width="28" style={{ margin: '0 0 0 2px' }} />
            </Badge>
          ),
        },
        {
          label: 'Settings',
          onClick: handleSettingsClick,
          Icon: <SettingFilled style={{ margin: '0 3px 0 2px', fontSize: '28px' }} />,
        },
      ],
    },
    {
      label: 'Tools',
      items: [
        {
          label: 'Install App',
          onClick: promptToInstall,
          Icon: PWAIcon,
        },
      ],
    },
  ];

  return menuContent.map((section, index) => {
    const aboveSection = menuContent[index - 1];
    let SectionDivider = null;

    const {
      label: sectionLabel, isSubMenu, isHidden, items,
    } = section;

    const menuProps = { isMobile, isSubMenu, isCollapsed };

    if (sectionLabel) {
      if (!isCollapsed) {
        SectionDivider = (
          <Typography
            type="greyText"
            size="1rem"
            style={{
              fontWeight: '600',
              textTransform: 'uppercase',
              textAlign: 'left',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
              display: 'block',
              padding: '8px 16px 0 16px',
            }}
          >
            {sectionLabel}
          </Typography>
        );
      } else if (!isSubMenu && (!aboveSection?.isSubMenu || aboveSection?.isHidden)) {
        SectionDivider = <Divider style={{ margin: '16px 0' }} />;
      } else if ((aboveSection?.isSubMenu || !aboveSection?.isHidden)) {
        SectionDivider = <div style={{ height: '20px' }} />;
      }
    }

    const Items = items?.map((item, index) => {
      let Body = null;

      const {
        label: itemLabel, Icon, key, onClick, path,
      } = item;

      if (isMobile) {
        Body = (
          <Row justify="center" align="middle" gutter={4} wrap={false}>
            <Col span={4}>
              {Icon}
            </Col>
            <Col span={16} style={{ textAlign: 'center' }}>
              <Typography className="menu-label" type="primaryText" size={isSubMenu ? '1.5rem' : '1.75rem'}>
                {itemLabel}
              </Typography>
            </Col>
            <Col span={4} />
          </Row>
        );
      } else {
        Body = (
          <Tooltip title={isCollapsed ? itemLabel : false} placement="right">
            <Row justify="start" align="middle" gutter={4} wrap={false}>
              <Col>
                {Icon}
              </Col>
              {!isCollapsed && (
              <Col>
                <Typography className="menu-label" type="primaryText" size="1.125rem">
                  {itemLabel}
                </Typography>
              </Col>
              )}
            </Row>
          </Tooltip>
        );
      }

      if (onClick) {
        return (
          <Item
            key={key || index}
            className={isSubMenu ? 'sub-menu' : ''}
            onClick={onClick}
            icon={<div>{Body}</div>}
          />
        );
      } if (path) {
        return (
          <Item
            key={key || index}
            className={isSubMenu ? 'sub-menu' : ''}
            icon={<Link to={path}>{Body}</Link>}
          />
        );
      }

      return null;
    });

    if (isHidden) {
      return null;
    }

    return (
      <NavContainer key={`${sectionLabel}${index}`}>
        {SectionDivider}
        <MenuContainer
          theme="dark"
          mode="inline"
          selectedKeys={pathTypes}
          onClick={onMenuClick}
          {...menuProps}
        >
          {Items}
        </MenuContainer>
      </NavContainer>
    );
  });
};

export default Navigation;
