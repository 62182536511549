export const dataLayerUtil = (type, attributes) => {
  window.dataLayer.push({
    event: 'event',
    eventProps: {
      category: type,
      attributes,
    },
  });
};

export default dataLayerUtil;
