import React from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import {
  Typography,
  AddFolderModal,
  CenterModal,
  Empty,
  TopBar,
  Button,
} from '@travelpassero/khaos';
import { useHistory } from 'react-router-dom';

import SurveyResultsCardContainer from '@components/containers/SurveyResultsCardContainer';
import ManageCrewModal from '@components/sharedComponentContainers/ManageCrewModal';
import ModalSpinner from '@components/ModalSpinner';
import {
  closeModal,
  openCenterModal,
  setModalLoadingState,
} from '@context/actions';

import { PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import CrewList from '@components/sharedComponentContainers/CrewList';
import useJourneyCrew from '@hooks/useJourneyCrew';
import addJourneyFolder from '@operations/addJourneyFolder';
import { selectAppState } from '@context/global/appSlice';
import { useJourney } from '@hooks/useJourney';
import { useTracking } from '@hooks/useTracking';
import { Col, Row } from 'antd';
import useSurvey from '@hooks/useSurvey';
import { useWindowSize } from '@hooks/useWindowSize';
import PageScrollContainer from './PageScrollContainer';

const CrewListContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SurveyResultsPage = ({
  journeyID, splitScreenSide,
}) => {
  const { setPageView, setPage } = useTracking();
  const { isMobile } = useWindowSize();
  const { data } = useJourney(journeyID);
  const { crewList, removeCrewMember } = useJourneyCrew(journeyID);
  const { getCurrentSurvey } = useSurvey(data);
  const dispatch = useDispatch();
  const {
    globalStateReducer: {
      isCenterModalVisible,
      modalChildrenType,
      modalLoadingState,
    },
  } = useSelector(selectAppState);
  const { title } = useJourney(journeyID);

  const selectedSurvey = getCurrentSurvey();

  const history = useHistory();

  setPageView('Survey results Page');
  setPage('surveyresults');

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleAddCrewMember = () => {
    dispatch(openCenterModal('crew'));
  };

  const handleManageCrewMembers = () => {
    dispatch(openCenterModal('manage-crew'));
  };

  const onIdeaBoardSplitScreenClick = () => {
    if (selectedSurvey) {
      history.push(`/journeys/${journeyID}/ideas-with-surveys/${selectedSurvey.id}`);
    }
  };

  const onItenerarySplitScreenClick = () => {
    if (selectedSurvey) {
      history.push(`/journeys/${journeyID}/surveys-with-itinerary/${selectedSurvey.id}`);
    }
  };

  const handleAddFolderFinish = async (formData) => {
    const { title } = formData;

    dispatch(setModalLoadingState(true));
    await addJourneyFolder(journeyID, title);
    dispatch(setModalLoadingState(false));

    // refetch();
    handleCloseModal();
  };

  const handleSurveysBackClick = () => {
    history.push(`/journeys/${journeyID}/surveys`);
  };

  let ModalChildren;
  let Modal;
  switch (modalChildrenType) {
    case 'folder':
      Modal = (
        <AddFolderModal
          onFinish={handleAddFolderFinish}
          onClose={handleCloseModal}
          showModal={isCenterModalVisible}
        />
      );
      break;

    case 'crew':
      Modal = (
        <ManageCrewModal journeyID={journeyID} />
      );
      break;

    case 'manage-crew':
      Modal = (
        <CenterModal
          title={`People in this Journey (${crewList.length})`}
          showModal={isCenterModalVisible}
          handleOnClose={handleCloseModal}
          children={(
            <Row justify="center" align="middle">
              <Col span={24}>
                <CrewListContainer>
                  <CrewList
                    list={[...crewList]}
                    onRemove={removeCrewMember}
                    removeConfirmText="Remove crew member?"
                  />
                </CrewListContainer>
              </Col>
            </Row>
                )}
          footerButtons={[
            <Button onClick={handleAddCrewMember}>
              <PlusOutlined />
              Add more Crew Members
            </Button>,
          ]}
        />
      );
      break;

    default:
      ModalChildren = null;
  }

  if (selectedSurvey) {
    return (
      <>
        <TopBar
          sectionTitle="Survey Results"
          tripTitle={title}
          onManageCrewMembers={handleManageCrewMembers}
          myCrewUsers={crewList || []}
          onAddCrewMember={handleAddCrewMember}
          splitScreenSide={splitScreenSide}
          splitScreenOptions={[
            {
              key: 'ideas',
              name: 'Idea Board',
              onClick: onIdeaBoardSplitScreenClick,
            },
            {
              key: 'itenerary',
              name: 'Itenerary',
              onClick: onItenerarySplitScreenClick,
            },
          ]}
        />
        <PageScrollContainer splitScreenSide={splitScreenSide}>
          <div style={{ paddingTop: '40px' }} />
          <Button
            type="default"
            style={{ marginLeft: '46px' }}
            onClick={handleSurveysBackClick}
          >
            <Typography type="primaryText">Back to all surveys</Typography>
            <RollbackOutlined />
          </Button>
          <div style={{ textAlign: 'center', margin: '24px 0' }}>
            <Typography size="1.8rem" type="primaryHeading">
              {selectedSurvey.title}
            </Typography>
          </div>

          <SurveyResultsCardContainer
            survey={selectedSurvey}
            journeyData={data}
          />
          {isCenterModalVisible && ModalChildren ? (
            <CenterModal
              footer={null}
              handleOnClose={handleCloseModal}
              showModal={isCenterModalVisible}
              children={modalLoadingState ? <ModalSpinner /> : ModalChildren}
              modalWidth={isMobile ? '100%' : '500px'}
            />
          ) : isCenterModalVisible && Modal ? Modal : null}
        </PageScrollContainer>
      </>
    );
  }

  return (
    <Row justify="center" gutter={[0, 32]} style={{ paddingTop: '90px' }}>
      <Col>
        <Empty
          heading="The selected survey was not found."
        />
      </Col>
    </Row>
  );
};

export default SurveyResultsPage;
