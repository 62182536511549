import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import { Pagination } from '@travelpassero/khaos';

const Container = styled.div`
  padding-left: ${(props) => props.paddingLeft}px !important;
  width: 100%;

  .pagination-data {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }

  .card-sizing {
    width: ${(props) => props.cardWidth}px !important;
  }

  .card-positioner-margin-center {
    margin-left: ${(props) => Math.floor(props.cardWidth / 2)}px !important;
  }
`;

function CardPositioner({
  cards,
  cardMaxWidth = 200,
  cardMinWidth = 0,
  rowWidthOffset = 0,
  cardsPerPage,
  usePagination = false,
  paginationTextColor,
  center = false, // aligns cards less than row width to the center of container
}) {
  const ref = useRef(null);
  const { globalStateReducer } = useSelector(selectAppState);
  const [cardWidth, setCardWidth] = useState(0);
  const [paddingLeft, setPaddingLeft] = useState(0);

  const { isLeftNavCollapsed } = globalStateReducer;

  useEffect(() => {
    const containerWidth = ref?.current?.offsetWidth;
    let newPaddingLeft = 0;

    // find out least number of cards that can fit in one row without exceeding the max card width
    const minCardsPerRow = Math.floor(containerWidth / cardMaxWidth) + 1;

    // calculate the width of each card
    let newCardWidth = Math.floor((containerWidth - rowWidthOffset) / minCardsPerRow);

    // when the number of cards shown is less than how many can fit in a row, then center the cards and set to max width
    let numberOfCards = cards.length;
    if (cardsPerPage && cardsPerPage < cards.length) numberOfCards = cardsPerPage;

    if (center && minCardsPerRow > numberOfCards) {
      newCardWidth = cardMaxWidth;
      newPaddingLeft = Math.floor(((containerWidth - rowWidthOffset) - (newCardWidth * numberOfCards)) / 2);

    // when the cards get too small, set the card to the max width and center the cards in the container with left padding
    } else if (newCardWidth < cardMinWidth && minCardsPerRow > 1) {
      newCardWidth = cardMaxWidth;
      newPaddingLeft = Math.floor(((containerWidth - rowWidthOffset) - (newCardWidth * (minCardsPerRow - 1))) / 2);
    }

    // only update changes
    if (newCardWidth !== cardWidth) {
      setCardWidth(newCardWidth);
    }

    setPaddingLeft(newPaddingLeft);
  }, [isLeftNavCollapsed, cards]);

  return (
    <Container ref={ref} cardWidth={cardWidth} paddingLeft={paddingLeft}>
      {usePagination ? (
        <Pagination
          itemsPerPage={cardsPerPage}
          data={cards}
          textColor={paginationTextColor}
        />
      ) : (
        <div style={{
          display: 'flex',
          flexWrap: 'wrap',
          width: '100%',
        }}
        >
          {cards}
        </div>
      )}
    </Container>
  );
}

export default CardPositioner;
