import useUser from '@hooks/useUser';
import Layout from '@components/Layout';
import ProfilePage from './ProfilePage';

const ProfilePageContainer = () => {
  const { userID } = useUser();

  if (userID) {
    return <Layout><ProfilePage /></Layout>;
  }

  return <ProfilePage />;
};

export default ProfilePageContainer;
