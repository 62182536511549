import styled from 'styled-components';
import { useWindowSize } from '@hooks/useWindowSize';
import LogoIcon from '@assets/passero_icon.svg';
import LogoWhite from '@assets/passero_logo_white.svg';
import Background from '@assets/login-background.jpg';
import { Typography } from '@travelpassero/khaos';
import { Col, Row } from 'antd';

const DesktopBackground = styled.div`
  background-image: url(${Background});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  width: 50%;
  scroll-snap-align: start;
  position: relative;
`;

const BrandOnboarding = () => {
  const { width } = useWindowSize();
  const isMobile = width < 900;

  return (
    <>
      {isMobile ? (
        <Row align="middle" justify="start">
          <Col>
            <LogoIcon width="54px" height="60px" style={{ alignSelf: 'flex-start' }} />
          </Col>
        </Row>
      ) : (
        <DesktopBackground>
          <div
            style={{
              position: 'absolute',
              top: '50%',
              left: '0',
              right: '0',
              transform: 'translate(0, -50%)',
              textAlign: 'center',
            }}
          >
            <LogoWhite
              width="50%"
              height="100%"
            />
            <div
              style={{
                position: 'absolute',
                top: '70%',
                left: '45%',
                right: '24.5%',
                textAlign: 'start',
              }}
            >
              <Typography type="whiteHeading" size={width < 1600 ? '1rem' : '1.5rem'}>
                Make every travel a story worth sharing
              </Typography>
            </div>
          </div>
        </DesktopBackground>
      )}
    </>
  );
};

export default BrandOnboarding;
