import { useEffect, useState } from 'react';

const useOnPageLoad = ({ status, onPageLoad, onCheckDataLoaded }) => {
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const isDataLoaded = onCheckDataLoaded?.() || false;

    if (status === 'idle' || (status === 'fulfilled' && !(isDataLoaded || isPageLoaded))) {
      onPageLoad?.();

      setIsPageLoaded(true);
    }
  }, [status, onPageLoad, isPageLoaded]);
};

export default useOnPageLoad;
