import { createSlice } from '@reduxjs/toolkit';
import {
  getFriendsThunk,
  getReceivedFriendRequestsThunk,
  getSentFriendRequestsThunk,
  getDeclinedSentFriendRequestsThunk,
  sendFriendRequestsThunk,
} from '@context/friend/friendThunk';

const initialState = {
  status: 'idle',
  friends: [],
  error: null,
  receivedFriendRequests: [],
  sentFriendRequests: [],
  declinedSentFriendRequests: [],
  sentFriendRequestStatus: null,
};

export const friendSlice = createSlice({
  name: 'friend',
  initialState,
  reducers: {
    addFriendsReducer(state, { payload }) {
      const { friends } = payload;
      const noExistingFriends = [];

      friends.forEach((friend) => {
        const existingFriend = [...state.friends].some(
          (f) => f.id === friend.id,
        );

        if (!existingFriend) {
          noExistingFriends.push(friend);
        }
      });

      state.friends = [...state.friends, ...noExistingFriends];
    },
    addSentFriendRequestsReducer(state, { payload }) {
      const { friendRequests } = payload;
      const noExistingFriendRequests = [];

      friendRequests.forEach((friendRequest) => {
        const existingFriendRequest = [...state.friends].some(
          (f) => f.id === friendRequest.id,
        );

        if (!existingFriendRequest) {
          noExistingFriendRequests.push({
            ...friendRequest,
            isFriendRequestSent: true,
          });
        }
      });

      state.sentFriendRequests = [...state.sentFriendRequests, ...noExistingFriendRequests];
    },
    removeFriendsReducer(state, { payload }) {
      const { friends } = payload;
      state.friends = [...state.friends].filter((currentFriend) => {
        if (friends.find((friend) => friend.id === currentFriend.id)) return false;
        return true;
      });
    },
    removeSentFriendRequestsReducer(state, { payload }) {
      const { pendingFriendId } = payload;
      state.sentFriendRequests = [...state.sentFriendRequests].filter((pendingFriend) => pendingFriend.id !== pendingFriendId);
    },
    removeReceivedFriendRequestsReducer(state, { payload }) {
      const { friendRequests } = payload;
      state.receivedFriendRequests = [...state.receivedFriendRequests].filter((currentFriendRequest) => {
        if (friendRequests.find((friendRequest) => friendRequest.id === currentFriendRequest.id)) return false;
        return true;
      });
    },
    resetSentFriendRequestStatusReducer(state) {
      state.sentFriendRequestStatus = null;
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getFriendsThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getFriendsThunk.fulfilled, (state, { payload }) => {
        state.friends = payload;
        state.status = 'fulfilled';
      })
      .addCase(getFriendsThunk.rejected, ((state) => {
        state.status = 'rejected';
      }));

    builder
      .addCase(getReceivedFriendRequestsThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getReceivedFriendRequestsThunk.fulfilled, (state, { payload }) => {
        state.receivedFriendRequests = payload;
        state.status = 'fulfilled';
      })
      .addCase(getReceivedFriendRequestsThunk.rejected, ((state) => {
        state.status = 'rejected';
      }));

    builder
      .addCase(getSentFriendRequestsThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getSentFriendRequestsThunk.fulfilled, (state, { payload }) => {
        state.sentFriendRequests = [...payload].map((request) => ({
          ...request,
          isFriendRequestSent: true,
        }));
        state.status = 'fulfilled';
      })
      .addCase(getSentFriendRequestsThunk.rejected, ((state) => {
        state.status = 'rejected';
      }));

    builder
      .addCase(getDeclinedSentFriendRequestsThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getDeclinedSentFriendRequestsThunk.fulfilled, (state, { payload }) => {
        state.declinedSentFriendRequests = payload;
        state.status = 'fulfilled';
      })
      .addCase(getDeclinedSentFriendRequestsThunk.rejected, ((state) => {
        state.status = 'rejected';
      }));

    builder
      .addCase(sendFriendRequestsThunk.pending, (state) => {
        state.sentFriendRequestStatus = 'pending';
      })
      .addCase(sendFriendRequestsThunk.fulfilled, (state) => {
        state.sentFriendRequestStatus = 'fulfilled';
      })
      .addCase(sendFriendRequestsThunk.rejected, ((state) => {
        state.sentFriendRequestStatus = 'rejected';
      }));
  }),
});

export const selectFriendState = (state) => state.friend;

export const {
  addFriendsReducer,
  addSentFriendRequestsReducer,
  removeFriendsReducer,
  removeSentFriendRequestsReducer,
  removeReceivedFriendRequestsReducer,
  resetSentFriendRequestStatusReducer,
} = friendSlice.actions;

export default friendSlice.reducer;
