import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  Empty,
  AddCard,
  Typography,
} from '@travelpassero/khaos';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import { openCenterModal } from '@context/actions';
import useFriend from '@hooks/useFriend';
import useJourneys from '@hooks/useJourneys';
import {
  Badge, Col, Row, Space, Tabs,
} from 'antd';
import PageScrollContainer from '@pages/PageScrollContainer';
import CardPositioner from '@components/containers/CardPositioner';
import FriendList from './FriendList';
import ReceivedFriendRequests from './ReceivedFriendRequests';

const { TabPane } = Tabs;

const FriendCardContainer = styled.div`
  width: 100%;

  .ant-tabs-nav {
    margin: 0;
    border-bottom: 1px solid;
    border-color: var(--borders);
    box-shadow: 0px 3px 6px rgba(0, 0, 0, .07);
  }
  .ant-tabs-nav-wrap {
    background: var(--white);
    padding: 0 24px;
  }
  .ant-tabs-ink-bar {
    background: var(--secondary);
  }

  .ant-badge .ant-badge-count {
    background: var(--accent-variant);
  }
`;

const FriendsContainer = ({
  setTabKey,
  isMobile,
  splitScreenSide,
  friendsList,
  receivedFriendRequestsList,
  sentFriendRequestsList,
}) => {
  const { journeys } = useSelector(selectJourneysState);
  const {
    friends,
    receivedFriendRequests,
    sentFriendRequests,
    removeSentFriendRequests,
    sendFriendRequestReminder,
    removeFriends,
  } = useFriend();

  const { addCrewUsersToJourney } = useJourneys();
  const dispatch = useDispatch();

  const findJourney = (journeyID) => journeys?.find((journey) => journey.pk === journeyID);

  const handleAddFriendToCrew = (friendId, journeyID) => {
    addCrewUsersToJourney({ journeyID, crewIDs: [friendId] });
  };

  const handleAddSentFriendRequestToCrew = (pendingFriendId, journeyID) => {
    const pendingFriend = sentFriendRequests?.find((pendingFriend) => pendingFriend.id === pendingFriendId);
    const { data } = findJourney(journeyID);

    const currentCrewMember = data?.crew?.find((crewMember) => crewMember.id === pendingFriendId);

    if (!currentCrewMember && pendingFriend) {
      addCrewUsersToJourney({ journeyID, crewIDs: [pendingFriendId] });
    }
  };

  const handleOnReminderClick = (pendingFriend) => {
    sendFriendRequestReminder(pendingFriend);
  };

  const handleOnRemoveClickSent = (personId) => {
    removeSentFriendRequests(personId);
  };

  const handleOnRemoveFriendClick = (friendId) => {
    removeFriends([friends.find((friend) => friend.id === friendId)]);
  };

  const handleOpenModal = (type) => {
    dispatch(openCenterModal(type));
  };

  return (
    <Row>
      <Col span={24}>
        <FriendCardContainer>
          <Tabs defaultActiveKey="friends" onChange={setTabKey} destroyInactiveTabPane>
            <TabPane
              tab={(
                <Typography
                  type="primarySubHeading"
                  size="1rem"
                  style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '240px',
                  }}
                >
                  All Friends
                </Typography>
                  )}
              key="friends"
            >
              <PageScrollContainer splitScreenSide={splitScreenSide}>
                <div style={{ padding: '16px 16px 56px 16px' }}>
                  {friends?.length
                    ? (
                      <FriendList
                        list={friendsList}
                        canDrag={!isMobile}
                        onOpenAdd={() => handleOpenModal('add friends')}
                        onAddToCrew={handleAddFriendToCrew}
                        onRemoveClick={handleOnRemoveFriendClick}
                        removeConfirmText="Remove friend?"
                        cardsPerPage={30}
                        rowWidthOffset={2}
                      />
                    ) : (
                      <>
                        <CardPositioner
                          cardMaxWidth={550}
                          cards={[(
                            <AddCard
                              key="empty-friend-card"
                              type="friend"
                              onClick={() => handleOpenModal('add friends')}
                              style={{ verticalAlign: 'bottom' }}
                            />
                          )]}
                          center
                        />
                        <Empty
                          heading="You don’t have any friends yet"
                          subHeading='Send a friend request by clicking the "Add Friend" button above.'
                          style={{ paddingTop: '46px' }}
                        />
                      </>
                    )}
                </div>
              </PageScrollContainer>
            </TabPane>
            {receivedFriendRequests?.length && (
              <TabPane
                tab={(
                  <Space>
                    <Typography
                      type="primarySubHeading"
                      size="1rem"
                      style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        maxWidth: '240px',
                      }}
                    >
                      Received Requests
                    </Typography>
                    <Badge count={receivedFriendRequests?.length} overflowCount={999} />
                  </Space>
                  )}
                key="received requests"
              >
                <PageScrollContainer splitScreenSide={splitScreenSide}>
                  <div style={{ padding: '16px' }}>
                    <ReceivedFriendRequests
                      receivedFriendRequestsList={receivedFriendRequestsList}
                    />
                  </div>
                </PageScrollContainer>
              </TabPane>
            )}
            {sentFriendRequests?.length && (
              <TabPane
                tab={(
                  <Typography
                    type="primarySubHeading"
                    size="1rem"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      maxWidth: '240px',
                    }}
                  >
                    Sent Requests
                  </Typography>
                  )}
                key="sent requests"
              >
                <PageScrollContainer splitScreenSide={splitScreenSide}>
                  <div style={{ padding: '16px' }}>
                    <FriendList
                      list={sentFriendRequestsList}
                      canDrag={!isMobile}
                      onAddToCrew={handleAddSentFriendRequestToCrew}
                      onRemoveClick={handleOnRemoveClickSent}
                      removeConfirmText="Cancel friend request?"
                      onReminderClick={handleOnReminderClick}
                      cardsPerPage={30}
                      rowWidthOffset={2}
                    />
                  </div>
                </PageScrollContainer>
              </TabPane>
            )}
          </Tabs>
        </FriendCardContainer>
      </Col>
    </Row>
  );
};

export default FriendsContainer;
