import { Auth } from '@aws-amplify/auth';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { ResetPasswordForm } from '@travelpassero/khaos';
import { setOnboarding, setUserLoader } from '@context/user/userSlice';
import OnboardingLayout from '@components/layout/OnboardingLayout';
import BrandOnboarding from '@components/layout/BrandOnboarding';

const ResetPasswordPage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redirectTo] = useState(queryParams.get('redirectTo'));

  const handleResetPassword = async (formData) => {
    const { email } = formData;
    const EMAIL = email.toLowerCase();

    try {
      dispatch(setUserLoader(true));
      await Auth.forgotPassword(EMAIL);
      dispatch(setOnboarding({ email: EMAIL }));
      history.push(redirectTo ? `/forgot-password/confirm?redirectTo=${redirectTo}` : '/forgot-password/confirm');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  const handleOnSignInClick = () => {
    history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
  };

  const handleVerifySignUpClick = () => {
    history.push(redirectTo ? `/register/verify?redirectTo=${redirectTo}` : '/register/verify');
  };

  return (
    <OnboardingLayout page="resetpassword">
      <BrandOnboarding />
      <ResetPasswordForm
        onFinish={handleResetPassword}
        onVerifySignUpClick={handleVerifySignUpClick}
        onBackSignInClick={handleOnSignInClick}
        errorMessage={errorMessage}
      />
    </OnboardingLayout>
  );
};

export default ResetPasswordPage;
