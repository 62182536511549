import React from 'react';
import ItineraryPage from './ItineraryPage';

const ItineraryPageContainer = ({ journeyID, splitScreenSide }) => (
  <ItineraryPage
    journeyID={journeyID}
    splitScreenSide={splitScreenSide}
  />
);

export default ItineraryPageContainer;
