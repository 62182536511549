import React from 'react';
import {
  Empty,
} from '@travelpassero/khaos';
import useFriend from '@hooks/useFriend';
import FriendList from '../../pages/myFriends/FriendList';

const CrewList = ({
  list, onOpenAdd, onRemove, removeConfirmText, cardsPerPage = 10, dropLocation, style,
}) => {
  const { sendFriendRequests, sendFriendRequestReminder } = useFriend();

  const handleOnReminderClick = (pendingFriend) => {
    sendFriendRequestReminder(pendingFriend);
  };

  return list?.length ? (
    <FriendList
      list={list}
      onOpenAdd={onOpenAdd}
      addType="crew"
      onAddFriendClick={(friend) => sendFriendRequests([friend])}
      onRemoveClick={onRemove}
      removeConfirmText={removeConfirmText}
      onReminderClick={handleOnReminderClick}
      cardsPerPage={cardsPerPage}
      dropLocation={dropLocation}
      style={style}
      rowWidthOffset={4}
    />
  ) : (
    <Empty
      heading="No crew for this journey"
      subHeading="Add crew members by selecting from your list of friends"
    />
  );
};

export default CrewList;
