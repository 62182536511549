/* eslint-disable max-len */
export default [
  {
    location: 'Rotorua',
    itineraryTime: '18:00',
    imgSrcKey: 'ideaboard/stargaze.jpg',
    itineraryDate: '2021-03-03T00:00:00+08:00',
    link: 'https://www.klook.com/activity/2219-skyline-gondola-rotorua/?aff_adid=6359&aff_af_wid=825&aid=825&utm_medium=affiliate-alwayson&utm_source=non-network&utm_campaign=825&utm_term=&utm_content=',
    description: 'Spectacular views of Rotorua city on this gondola and out towards the geothermal areas and lakes from Mount Ngongotaha. Get the chance to spot amazing constellations in the clear New Zealand sky at nighttime.',
    price: '30',
    duration: 'Evening tour',
    title: 'Skyline Gondola and Luge Ticket in Rotorua',
    id: '1',
    ideaCategoryId: '300',
  },
  {
    location: '44 Main Rd, Fox Glacier',
    itineraryTime: '08:50',
    imgSrcKey: 'ideaboard/ice.jpg',
    itineraryDate: '2021-03-08T00:00:00+08:00',
    link: 'https://www.getyourguide.com/fox-glacier-l45011/fox-glacier-4-hour-hike-and-scenic-helicopter-experience-t257732/',
    description: "Enjoy this unique Heli-Hike experience combining the excitement of walking on Fox Glacier with the thrill of a helicopter flight. You'll land in a remote and beautiful part of the glacier where nature has created spectacular ice caves and arches.",
    price: '375',
    duration: 'Half day tour - Morning',
    title: 'Fox Glacier Heli Hike',
    id: '2',
    ideaCategoryId: '302',
  },
  {
    location: 'Rotorua',
    itineraryTime: '09:00',
    createdAt: '2022-09-12T22:09:22.742Z',
    imgSrcKey: 'ideaboard/performance.jpg',
    itineraryDate: '2021-03-03T00:00:00+08:00',
    link: 'https://www.klook.com/activity/2173-whakarewarewa-village-tour-rotorua/?aff_adid=6361&aff_af_wid=825&aid=825&utm_medium=affiliate-alwayson&utm_source=non-network&utm_campaign=825&utm_term=&utm_content=',
    description: 'The only living geothermal Maori village in New Zealand.\nMarvel at the geothermal wonders of the Pohutu geyser, steam vents and mud pools, and learn how they are used in daily life. ',
    price: '35',
    duration: 'Full day tour',
    title: 'Whakarewarewa Village Tour',
    id: '3',
    ideaCategoryId: '300',
  },
  {
    location: 'Rotorua',
    itineraryTime: '13:30',
    imgSrcKey: 'ideaboard/boat.jpg',
    itineraryDate: '2021-03-04T00:00:00+08:00',
    itineraryNote: 'Make sure to pack your swimsuit and need to bring your passport with you',
    link: 'https://www.klook.com/activity/2203-lakeland-queen-cruise-rotorua/?aff_adid=6363&aff_af_wid=825&aid=825&utm_medium=affiliate-alwayson&utm_source=non-network&utm_campaign=825&utm_term=&utm_content=',
    description: "Enjoy your afternoon tea cruising on the scenic Lake Rotorua. The best seat and views of Lake Rotorua and surrounding geothermal areas. Relax and unwind and learn about the passing landmarks from your Captain's commentary",
    price: '25',
    duration: 'Half day tour - Afternoon',
    title: 'Lakeland Queen Cruise',
    id: '4',
    ideaCategoryId: '300',
  },
];
