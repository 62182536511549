export const awsConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL,
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USER_POOL,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
    mandatorySignIn: true,
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_PHOTO_BUCKET,
      region: process.env.REACT_APP_AWS_REGION,
    },
  },
  API: {
    endpoints: [
      {
        name: 'Hydra',
        endpoint: process.env.REACT_APP_HYDRA_ENDPOINT,
      },
    ],
  },
};
