import React, { useState, useEffect } from 'react';
import {
  Button, PopConfirm, CircleCheckbox, Empty,
} from '@travelpassero/khaos';
import { Col, Row, Divider } from 'antd';
import useFriend from '@hooks/useFriend';
import FriendList from './FriendList';

const ReceivedFriendRequests = ({ receivedFriendRequestsList }) => {
  const { acceptFriendRequests, declineFriendRequests } = useFriend();
  const [receivedRequests, setReceivedRequests] = useState([...receivedFriendRequestsList]);
  const [checkedRequests, setCheckedRequests] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const getCheckedRequests = () => receivedRequests.filter((request) => request.isChecked);

  const trackCheckedRequests = () => {
    const checkedRequests = getCheckedRequests();

    setCheckedRequests(checkedRequests);

    if (checkedRequests.length >= receivedRequests.length) {
      setIndeterminate(false);
      setCheckAll(true);
    } else {
      setIndeterminate(checkedRequests.length > 0);
      setCheckAll(false);
    }
  };

  useEffect(() => {
    trackCheckedRequests();
  }, [receivedRequests]);

  useEffect(() => {
    setReceivedRequests([...receivedFriendRequestsList]);
  }, [receivedFriendRequestsList]);

  const handleSelectNone = (e) => {
    setReceivedRequests(receivedRequests.map((request) => {
      const checkedRequest = { ...request };
      checkedRequest.isChecked = false;
      return checkedRequest;
    }));
  };

  const handleSelectAll = (e) => {
    setReceivedRequests(receivedRequests.map((request) => {
      const checkedRequest = { ...request };
      checkedRequest.isChecked = true;
      return checkedRequest;
    }));
  };

  const onCheckAllChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) handleSelectAll();
    else handleSelectNone();

    setCheckAll(e.target.checked);
  };

  const handleOnCheckboxClickReceived = (id) => {
    setReceivedRequests(receivedRequests.map((request) => {
      const checkedRequest = { ...request };
      if (checkedRequest.id === id) checkedRequest.isChecked = !checkedRequest.isChecked;
      return checkedRequest;
    }));
  };

  const handleOnAcceptClick = () => {
    acceptFriendRequests(getCheckedRequests());
  };

  const handleOnDeclineClick = () => {
    declineFriendRequests(getCheckedRequests());
  };

  return (
    <>
      {receivedRequests?.length
        ? (
          <>
            <Row justify="space-between" align="middle">
              <Col>
                <Row justify="start" align="middle" gutter={[16, 16]}>
                  <Col>
                    <CircleCheckbox
                      type="primary"
                      indeterminate={indeterminate}
                      onClick={onCheckAllChange}
                      isChecked={checkAll}
                      title="Check all"
                    />
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row justify="end" align="middle" gutter={[16, 16]}>
                  <Col>
                    <PopConfirm placement="left" onConfirm={handleOnDeclineClick}>
                      <Button disabled={!checkedRequests?.length}>
                        Decline
                      </Button>
                    </PopConfirm>
                  </Col>
                  <Col>
                    <Button type="primary" onClick={handleOnAcceptClick} disabled={!checkedRequests?.length}>
                      Accept
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Divider style={{ marginTop: '8px' }} />
            <FriendList
              list={receivedRequests}
              onCheckboxClick={handleOnCheckboxClickReceived}
              cardsPerPage={26}
            />
          </>
        ) : <Empty heading="No requests found" />}
    </>
  );
};

export default ReceivedFriendRequests;
