export const filterUsers = (query, list) => {
  if (query?.length) {
    query = query.toLowerCase();

    return list?.filter((user) => {
      const { firstName, lastName } = user;
      return firstName?.toLowerCase().includes(query) || lastName?.toLowerCase().includes(query);
    }) || [];
  }

  return [...list];
};

export const filterJourneys = (query, list) => {
  if (query?.length) {
    query = query.toLowerCase();

    return list?.filter((journey) => {
      const { title } = journey.data;
      return title?.toLowerCase().includes(query) || title?.toLowerCase().includes(query);
    }) || [];
  }

  return [...list];
};
