import React, { useState } from 'react';
import {
  Button as AntButton, Col, Row,
} from 'antd';
import styled from 'styled-components';

import { useWindowSize } from '@hooks/useWindowSize';
import { CloseOutlined, MenuOutlined } from '@ant-design/icons';

import LogoIcon from '../assets/passero_icon.svg';
import MobileNavContainer from './MobileNavContainer';

const Container = styled.div`
  height: 67px;
  border-bottom: 1px solid var(--borders);
  z-index: 10;
  background-color: var(--white);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, .07);
  display: block;
`;

const MenuButton = styled(AntButton)`
  background: none !important;
  border: none !important;
  box-shadow: none !important;
  height: 50px;

  &:after {
    border: none !important;
    box-shadow: none !important;
  }
`;

const TopNav = () => {
  const { isMobile } = useWindowSize();
  const [isNavVisible, setIsNavVisible] = useState(false);

  const toggleCollapsed = () => {
    setIsNavVisible(!isNavVisible);
  };

  return (
    <Container>
      <Row justify="center" align="middle" style={{ height: '100%' }}>
        <Col span={4}>
          <MenuButton onClick={toggleCollapsed}>
            {isNavVisible
              ? <CloseOutlined style={{ marginLeft: '8px', color: 'var(--primary)', fontSize: '32px' }} />
              : <MenuOutlined style={{ marginLeft: '8px', color: 'var(--primary)', fontSize: '32px' }} />}
          </MenuButton>
        </Col>
        <Col span={16} style={{ textAlign: 'center' }}>
          <LogoIcon height="40px" width="40px" style={{ marginTop: '8px' }} />
        </Col>
        <Col span={4} />
      </Row>
      {isMobile && <MobileNavContainer isVisible={isNavVisible} onMenuClick={toggleCollapsed} />}
      <Row justify="space-between" align="middle" style={{ margin: '0 24px' }}>
        <Col>
          {isMobile && (
            <div style={{ display: 'flex', alignItems: 'center' }} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default TopNav;
