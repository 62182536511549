import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from '@utils/http';
import { survey } from '@utils/urls';

export const getJourneysThunk = createAsyncThunk('getJourneys',
  async () => {
    try {
      const payload = await HTTP.get('/journeys');
      const { meta: { pages } } = payload;
      for (let i = 2; i <= pages; i++) {
        const { journeys, creators } = await HTTP.get(`/journeys?page=${i}`);
        payload.creators = payload.creators.concat(creators);
        payload.journeys = payload.journeys.concat(journeys);
      }
      return payload;
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getSampleJourneysThunk = createAsyncThunk('getSampleJourneys',
  async ({ region, categories }) => {
    try {
      return await HTTP.getPublic('/discover/journeys', { region, categories });
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const createJourneyThunk = createAsyncThunk('createJourney',
  async (request) => {
    try {
      return await HTTP.post('/journeys', request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const duplicateJourneyThunk = createAsyncThunk('duplicateJourney',
  async ({ journeyID, ...request }) => {
    try {
      const url = `/journeys/${journeyID}/duplicate-journey`;
      return await HTTP.post(url, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteJourneyThunk = createAsyncThunk('deleteJourney',
  async (request) => {
    try {
      const url = `/journeys/${request.journeyID}`;
      return await HTTP.del(url);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const addCrewMembersToJourneyThunk = createAsyncThunk('addCrewMembersToJourney',
  async (request) => {
    try {
      const { journeyID, crewMemberIds } = request;
      const url = `/journeys/${journeyID}/crew`;
      return await HTTP.post(url, { crewMemberIds });
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const removeCrewUserFromJourneyThunk = createAsyncThunk('removeCrewMemberFromJourney',
  async (request) => {
    try {
      const { journeyID, crewID } = request;
      const url = `/journeys/${journeyID}/crew/${crewID}`;
      return await HTTP.del(url);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const addSurveyEmailThunk = createAsyncThunk('addSurvey',
  async (request) => {
    try {
      return await HTTP.post(survey, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });
