import { createSlice } from '@reduxjs/toolkit';
import { getSamplePhotosThunk, searchPhotosThunk } from './photoThunk';

const initialState = {
  searchPhotoStatus: 'idle',
  samplePhotoStatus: 'idle',
  categoryKey: '',
  photoSelectionLabel: '',
  photo: {},
  error: null,
  carouselPhotos: [],
  photoSelectionType: 'sample',
};

const mapCarouselPhotos = (carouselPhotos, selectedKey) => carouselPhotos.map((carouselPhoto) => (
  { ...carouselPhoto, selected: carouselPhoto.Key === selectedKey }
));

const sortCarouselPhotos = (carouselPhotos) => carouselPhotos.sort((a, b) => (
  (a.selected === b.selected) ? 0 : a.selected ? -1 : 1));

export const photoSlice = createSlice({
  name: 'photo',
  initialState,
  reducers: {
    setCategoryKeyReducer(state, { payload }) {
      state.categoryKey = payload;
    },
    setPhotoSelectionLabelReducer(state, { payload }) {
      state.photoSelectionLabel = payload;
    },
    setCarouselPhotosReducer(state, { payload }) {
      state.carouselPhotos = sortCarouselPhotos(mapCarouselPhotos(state.carouselPhotos, payload?.selectedKey));
      state.samplePhotoStatus = 'idle';
      state.searchPhotoStatus = 'idle';
      state.photo = {
        key: payload?.selectedKey,
        showPreview: false,
      };
      state.error = null;
      state.photoSelectionType = 'sample';
    },
    selectCarouselPhotoReducer(state, { payload }) {
      state.carouselPhotos = sortCarouselPhotos(mapCarouselPhotos(state.carouselPhotos, payload.Key));
      state.samplePhotoStatus = 'idle';
      state.searchPhotoStatus = 'idle';
      state.photo = {
        key: payload.Key,
        showPreview: false,
        src: payload.src,
        file: null,
        trackingLink: null,
        photographerName: null,
        photographerProfile: null,
      };
      state.photoSelectionType = 'sample';
    },
    selectPhotoReducer(state, { payload }) {
      state.samplePhotoStatus = 'idle';
      state.searchPhotoStatus = 'idle';
      state.photo = {
        key: payload.Key,
        showPreview: true,
        file: payload.value,
        src: payload.src,
        trackingLink: payload.trackingLink || null,
        photographerName: payload.photographer?.name || null,
        photographerProfile: payload.photographer?.profile || null,
      };
      state.photoSelectionType = payload.photoSelectionType;
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getSamplePhotosThunk.pending, (state) => {
        state.error = null;
        state.carouselPhotos = [];
        state.samplePhotoStatus = 'pending';
      })
      .addCase(getSamplePhotosThunk.fulfilled, (state, { payload }) => {
        state.carouselPhotos = sortCarouselPhotos(mapCarouselPhotos(payload));
        state.samplePhotoStatus = 'fulfilled';
        state.searchPhotoStatus = 'idle';
        state.error = null;
        state.photoSelectionType = 'sample';
      })
      .addCase(getSamplePhotosThunk.rejected, ((state, { payload }) => {
        state.error = payload;
        state.samplePhotoStatus = 'rejected';
      }))
      .addCase(searchPhotosThunk.pending, (state) => {
        state.searchPhotoStatus = 'pending';
        state.error = null;
        state.photoSelectionType = 'search';
      })
      .addCase(searchPhotosThunk.fulfilled, (state, { payload }) => {
        state.carouselPhotos = payload;
        state.searchPhotoStatus = 'idle';
        state.error = null;
        state.photo = {
          ...state.photo,
          showPreview: false,
        };
      })
      .addCase(searchPhotosThunk.rejected, ((state, { payload }) => {
        state.carouselPhotos = [];
        state.searchPhotoStatus = 'rejected';
        state.error = payload;
      }));
  }),
});

export const selectPhotoState = (state) => state.photo;

export const {
  setCategoryKeyReducer,
  setPhotoSelectionLabelReducer,
  setCarouselPhotosReducer,
  selectCarouselPhotoReducer,
  selectPhotoReducer,
} = photoSlice.actions;

export default photoSlice.reducer;
