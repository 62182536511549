import { Storage } from 'aws-amplify';
import * as URL from '@utils/urls';

export const get = async (key) => {
  key = key?.replace('public/', '');
  return `${URL.photoStorage}public/${key}`;
};

export const put = async (key, file) => {
  try {
    const data = await Storage.put(key, file, { contentType: file.type });
    return data.key;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const remove = async (key) => {
  try {
    const data = await Storage.remove(key);
    return data.payload;
  } catch (error) {
    throw new Error(error.message);
  }
};
