import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  addFriendsReducer,
  addSentFriendRequestsReducer,
  removeFriendsReducer,
  selectFriendState,
  removeSentFriendRequestsReducer,
  removeReceivedFriendRequestsReducer,
  resetSentFriendRequestStatusReducer,
} from '@context/friend/friendSlice';
import {
  sendFriendRequestsThunk,
  acceptFriendRequestsThunk,
  deleteFriendsThunk,
  getFriendsThunk,
  getReceivedFriendRequestsThunk,
  getSentFriendRequestsThunk,
  getDeclinedSentFriendRequestsThunk,
  cancelSentFriendRequestsThunk,
  declineFriendRequestsThunk,
  sendFriendRequestReminderThunk,
} from '@context/friend/friendThunk';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import useUser from '@hooks/useUser';
import getRandomColor from '@utils/getRandomColor';
import * as HTTP from '@utils/http';
import { FRIEND_REQUEST_RECEIVED, FRIEND_REQUEST_REMINDER } from '@utils/notificationTypes';
import useNotification from './useNotification';
import useJourneys from './useJourneys';

const useFriend = () => {
  const [friendErrorMessage, setFriendErrorMessage] = useState('');
  const [potentialFriends, setPotentialFriends] = useState([]);
  const {
    friends, receivedFriendRequests, sentFriendRequests, declinedSentFriendRequests,
  } = useSelector(selectFriendState);
  const dispatch = useDispatch();
  const { email } = useUser();
  const { journeys } = useSelector(selectJourneysState);

  const { updateJourneyByID } = useJourneys();
  const { notifications, removeNotifications } = useNotification();

  useEffect(() => {
    const potentialFriendsList = [];

    journeys.forEach((journey) => {
      journey.data?.crew?.forEach((crewMember) => {
        const { id } = crewMember;
        let isPotentialFriend = true;

        // is existing friend
        [...friends || []].forEach((friend) => {
          if (friend.id === id) isPotentialFriend = false;
        });

        // is existing sent friend request
        [...sentFriendRequests || []].forEach((sentFriendRequest) => {
          if (sentFriendRequest.id === id) isPotentialFriend = false;
        });

        // is existing received friend request
        [...receivedFriendRequests || []].forEach((receivedFriendRequest) => {
          if (receivedFriendRequest.id === id) isPotentialFriend = false;
        });

        // is me
        if (crewMember.email === email) isPotentialFriend = false;

        // is existing potential friend
        [...potentialFriendsList || []].forEach((potentialFriend) => {
          if (potentialFriend.id === id) isPotentialFriend = false;
        });

        if (isPotentialFriend) {
          potentialFriendsList.push({
            ...crewMember,
            isNotFriend: true,
          });
        }
      });
    });

    setPotentialFriends(potentialFriendsList);
  }, [journeys, friends, receivedFriendRequests, sentFriendRequests]);

  const selectJourney = (journeyID) => journeys.find((journeyItem) => journeyItem.pk === journeyID);

  const sendFriendRequestReminder = (pendingFriend) => {
    dispatch(sendFriendRequestReminderThunk({ pendingFriendId: pendingFriend.id }));
  };

  const isFriendRequestError = (friendEmail) => {
    let errorMessage = '';

    const friendEmailFormatted = friendEmail.toLowerCase();

    // is existing friend
    [...friends || []].forEach((friend) => {
      if (friend.email?.toLowerCase() === friendEmailFormatted) {
        errorMessage = `Cannot send friend request to ${friend.firstName} ${friend.lastName}. They are already your friend.`;
      }
    });

    // is existing received friend request
    [...receivedFriendRequests || []].forEach((receivedFriendRequest) => {
      if (receivedFriendRequest.email?.toLowerCase() === friendEmailFormatted) {
        errorMessage = `Cannot send friend request to ${receivedFriendRequest.firstName} ${receivedFriendRequest.lastName}. 
          They already sent you a request.`;
      }
    });

    // is previously sent friend request declined
    [...declinedSentFriendRequests || []].forEach((declinedFriendRequest) => {
      if (declinedFriendRequest.email?.toLowerCase() === friendEmailFormatted) {
        errorMessage = `Cannot send friend request to ${declinedFriendRequest.firstName} ${declinedFriendRequest.lastName}. 
          You previously sent them a request.`;
      }
    });

    // is me
    if (email?.toLowerCase() === friendEmailFormatted) {
      errorMessage = 'Contains your email. You cannot add yourself.';
    }

    setFriendErrorMessage(errorMessage);
    return errorMessage.length > 0;
  };

  const sendFormFriendRequests = async (formData) => {
    const friendArrayUnformatted = Object.keys(formData).map((item) => item);
    let isError = false;

    const JourneysNewCrew = {};

    const friendRequestPromises = friendArrayUnformatted.map(async (_, index) => {
      let firstName = formData[`firstName_${index}`];
      let lastName = formData[`lastName_${index}`];

      firstName = firstName?.charAt(0).toUpperCase() + firstName?.slice(1);
      lastName = lastName?.charAt(0).toUpperCase() + lastName?.slice(1);

      const friendEmail = formData[`email_${index}`]?.toLowerCase();

      if (!firstName || !friendEmail) return false;

      const journeyID = formData[`journeyID_${index}`];

      const formattedData = {
        id: uuidv4(),
        firstName,
        lastName,
        email: friendEmail,
        journeyID,
        iconColor: getRandomColor(),
      };

      isError = isFriendRequestError(friendEmail);
      if (isError) return false;

      // send reminder instead, if there is an existing sent friend request
      const existingSentFriendRequest = [...sentFriendRequests || []]
        .find((pendingFriend) => pendingFriend.email?.toLowerCase() === friendEmail);

      if (existingSentFriendRequest) {
        sendFriendRequestReminder(existingSentFriendRequest);
        return false;
      }

      const validatedFriends = await HTTP.post('/friends/validate', { friends: [formattedData] });

      if (journeyID) {
        // track which journeys the friend requests were also added to
        if (!JourneysNewCrew[journeyID]) JourneysNewCrew[journeyID] = [];
        JourneysNewCrew[journeyID].push(...validatedFriends);
      }

      return validatedFriends.pop();
    });

    if (!isError) {
      const friendRequestArrayFormated = await Promise.all(friendRequestPromises);
      const friendRequests = friendRequestArrayFormated?.filter((friendRequest) => friendRequest);

      // add friend requests to selected journeys
      Object.keys(JourneysNewCrew).forEach((journeyID) => {
        const { data: { crew: journeyCrew } } = selectJourney(journeyID);
        updateJourneyByID({ journeyID, crew: [...journeyCrew, ...JourneysNewCrew[journeyID]] });
      });

      // create friend requests
      dispatch(addSentFriendRequestsReducer({ friendRequests }));
      dispatch(sendFriendRequestsThunk({ friendRequests }));
    }
  };

  const sendFriendRequests = async (pendingFriends) => {
    let isError = false;
    pendingFriends.forEach((pendingFriend) => {
      if (isFriendRequestError(pendingFriend.email)) isError = true;
    });

    if (!isError) {
      const validatedPendingFriends = await HTTP.post('/friends/validate', { friends: pendingFriends });

      dispatch(addSentFriendRequestsReducer({ friendRequests: validatedPendingFriends }));
      dispatch(sendFriendRequestsThunk({ friendRequests: validatedPendingFriends }));
    }
  };

  const acceptFriendRequests = async (friendRequests) => {
    const newFriends = friendRequests?.filter((friend) => friend.firstName && friend.email);

    const validatedFriends = await HTTP.post('/friends/validate', { friends: newFriends });

    dispatch(addFriendsReducer({ friends: validatedFriends }));
    dispatch(removeReceivedFriendRequestsReducer({ friendRequests }));
    dispatch(acceptFriendRequestsThunk({ friendRequests }));
  };

  const removeFriends = (friends) => {
    dispatch(removeFriendsReducer({ friends }));
    dispatch(deleteFriendsThunk({ friends }));
  };

  const getFriends = () => {
    dispatch(getFriendsThunk());
  };

  const getReceivedFriendRequests = () => {
    dispatch(getReceivedFriendRequestsThunk());
  };

  const getSentFriendRequests = () => {
    dispatch(getSentFriendRequestsThunk());
  };

  const getDeclinedSentFriendRequests = () => {
    dispatch(getDeclinedSentFriendRequestsThunk());
  };

  const removeSentFriendRequests = (pendingFriendId) => {
    dispatch(removeSentFriendRequestsReducer({ pendingFriendId }));
    dispatch(cancelSentFriendRequestsThunk({ pendingFriendId }));
  };

  const declineFriendRequests = (friendRequests) => {
    dispatch(removeReceivedFriendRequestsReducer({ friendRequests }));
    dispatch(declineFriendRequestsThunk({ friendRequests }));

    removeNotifications(notifications?.filter((notification) => {
      const { type } = notification;
      const isType = type === FRIEND_REQUEST_RECEIVED || type === FRIEND_REQUEST_REMINDER;
      return isType && friendRequests.find((request) => request.id === notification.friendId);
    }));
  };

  const resetSentFriendRequestStatus = () => {
    dispatch(resetSentFriendRequestStatusReducer());
  };

  return {
    friends,
    potentialFriends,
    receivedFriendRequests,
    sentFriendRequests,
    friendErrorMessage,
    sendFormFriendRequests,
    sendFriendRequests,
    acceptFriendRequests,
    removeFriends,
    getFriends,
    getReceivedFriendRequests,
    getSentFriendRequests,
    getDeclinedSentFriendRequests,
    removeSentFriendRequests,
    declineFriendRequests,
    sendFriendRequestReminder,
    resetSentFriendRequestStatus,
  };
};

export default useFriend;
