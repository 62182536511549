import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import { selectUserState, setOnboardingSuccessMessage, setUserLoader } from '@context/user/userSlice';
import { useState } from 'react';
import { ConfirmSignUpForm } from '@travelpassero/khaos';
import OnboardingLayout from '@components/layout/OnboardingLayout';
import BrandOnboarding from '@components/layout/BrandOnboarding';
import { useAfterLogin } from '@hooks/useAfterLogin';

const ConfirmSignUpPage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const { onboarding } = useSelector(selectUserState);
  const { redirectTo } = useAfterLogin();
  const dispatch = useDispatch();
  const history = useHistory();

  const handleOnSignInClick = () => {
    history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
  };

  const handleResendCodeClick = async (email) => {
    try {
      dispatch(setUserLoader(true));
      await Auth.resendSignUp(email);
      setErrorMessage('Code resent successfully!');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  const handleSignupConfirmFinish = async (formData) => {
    const { email, code } = formData;

    const EMAIL = email.toLowerCase();
    try {
      dispatch(setUserLoader(true));
      await Auth.confirmSignUp(EMAIL, code);
      dispatch(setOnboardingSuccessMessage({ successMessage: 'Account successfully created' }));
      history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  return (
    <OnboardingLayout page="confirmsignup">
      <BrandOnboarding />
      <ConfirmSignUpForm
        email={onboarding.email}
        onResendCodeClick={() => handleResendCodeClick(onboarding.email)}
        onFinish={handleSignupConfirmFinish}
        onBackSignInClick={handleOnSignInClick}
        errorMessage={errorMessage}
      />
    </OnboardingLayout>

  );
};

export default ConfirmSignUpPage;
