import React from 'react';
import { useDrag } from 'react-dnd';

import { IdeaCard } from '@travelpassero/khaos';

import { ItemTypes } from '@utils/dragTypes';

function IdeaCardDnDContainer({
  onEditClick,
  onDeleteClick,
  creator,
  imgSrc,
  handleOnFolderChange,
  OnIdeaItineraryAdd,
  onIdeaAddToSurvey,
  id,
  ...item
}) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IDEACARD,
    item: { id },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && dropResult.type === 'folder') {
        handleOnFolderChange(id, dropResult.name);
      }
      if (item && dropResult && dropResult.type === 'itinerary') {
        OnIdeaItineraryAdd(id, dropResult.name);
      }
      if (item && dropResult && dropResult.type === 'survey') {
        const { questionNumber } = dropResult;

        onIdeaAddToSurvey(id, questionNumber);
      }
      return null;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <div ref={drag}>
      <IdeaCard
        {...item}
        ref={drag}
        onEditClick={onEditClick}
        onDeleteClick={onDeleteClick}
        creator={creator}
        imgSrc={imgSrc}
      />
    </div>
  );
}

export default IdeaCardDnDContainer;
