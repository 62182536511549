import { useState, useEffect } from 'react';
import * as Storage from '@utils/storage';

const CardImageContainer = ({ imgSrcKey, render }) => {
  const [imgUrl, setImgUrl] = useState();

  imgSrcKey = imgSrcKey && imgSrcKey.replace('public/', '');
  useEffect(() => {
    const abortController = new AbortController();

    let response = '';

    async function s3Fetch() {
      if (imgSrcKey !== '') {
        response = Storage.get(imgSrcKey);
        const data = await response;
        setImgUrl(data);
      }
    }
    s3Fetch();

    return function cleanUp() {
      abortController.abort();
    };
  }, [imgSrcKey]);

  return render(imgUrl);
};

export default CardImageContainer;
