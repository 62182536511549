import { createSlice } from '@reduxjs/toolkit';
import { getPublicJourneyThunk, getPublicUserJourneysThunk, getPublicProfileThunk } from '@context/public/publicThunk';

const initialState = {
  profile: {},
  publicJourneyStatus: 'idle',
  publicProfileStatus: 'idle',
  publicUserJourneysStatus: 'idle',
  publicJourney: null,
  error: null,
};

export const publicSlice = createSlice({
  name: 'public',
  initialState,
  reducers: {
    updateProfileReducer(state, { payload }) {
      state.profile = { ...state.profile, ...payload };
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getPublicJourneyThunk.pending, (state) => {
        state.publicJourney = null;
        state.publicJourneyStatus = 'pending';
      })
      .addCase(getPublicJourneyThunk.fulfilled, (state, { payload }) => {
        state.publicJourney = payload;
        state.publicJourneyStatus = 'fulfilled';
      })
      .addCase(getPublicJourneyThunk.rejected, ((state) => {
        state.publicJourney = null;
        state.publicJourneyStatus = 'rejected';
      }));
    builder
      .addCase(getPublicProfileThunk.pending, (state) => {
        state.publicProfileStatus = 'pending';
      })
      .addCase(getPublicProfileThunk.fulfilled, (state, { payload }) => {
        state.profile = payload;
        state.publicProfileStatus = 'fulfilled';
      })
      .addCase(getPublicProfileThunk.rejected, ((state) => {
        state.publicProfileStatus = 'rejected';
      }));
    builder
      .addCase(getPublicUserJourneysThunk.pending, (state) => {
        state.publicUserJourneysStatus = 'pending';
      })
      .addCase(getPublicUserJourneysThunk.fulfilled, (state, { payload }) => {
        state.publicUserJourneys = payload;
        state.publicUserJourneysStatus = 'fulfilled';
      })
      .addCase(getPublicUserJourneysThunk.rejected, ((state) => {
        state.publicUserJourneysStatus = 'rejected';
      }));
  }),
});

export const selectPublicState = (state) => state.public;

export const {
  updateProfileReducer,
} = publicSlice.actions;

export default publicSlice.reducer;
