import { selectJourneyState } from '@context/journey/journeySlice';
import { useSelector } from 'react-redux';

const useIdea = (ideaID) => {
  const { journey } = useSelector(selectJourneyState);

  const { data } = journey || {};
  const { ideas } = data || {};

  const getSelectedIdeaData = (id) => ideas && ideas.find((item) => item.id === id);
  const selectedIdea = getSelectedIdeaData(ideaID);
  return { selectedIdea };
};

export default useIdea;
