import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from '@utils/http';

export const getJourneyThunk = createAsyncThunk('getJourney',
  async ({ journeyID }) => {
    try {
      return await HTTP.get(`/journeys/${journeyID}`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const updateJourneyThunk = createAsyncThunk('updateJourney',
  async ({
    id, loader, modalLoader, ...request
  }) => {
    try {
      const url = `/journeys/${id}`;
      return await HTTP.patch(url, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const createFolderThunk = createAsyncThunk('createFolder',
  async (request) => {
    try {
      const {
        journeyID, title, id, parentFolderKey,
      } = request;
      const url = `/journeys/${journeyID}/folders`;
      return await HTTP.post(url, { title, id, parentFolderKey });
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const updateFolderThunk = createAsyncThunk('updateFolder',
  async (request) => {
    try {
      const {
        folderID, journeyID, title, parentFolderKey,
      } = request;

      const url = `/journeys/${journeyID}/folders/${folderID}`;
      return await HTTP.patch(url, { title, parentFolderKey });
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteFolderThunk = createAsyncThunk('deleteFolder',
  async (request) => {
    try {
      const { folderID, journeyID } = request;
      const url = `/journeys/${journeyID}/folders/${folderID}`;
      return await HTTP.del(url);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const createIdeaThunk = createAsyncThunk('createIdea',
  async (request) => {
    try {
      const { journeyID, idea } = request;
      const url = `/journeys/${journeyID}/ideas`;
      return await HTTP.post(url, idea);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const updateIdeaThunk = createAsyncThunk('updateIdea',
  async (request) => {
    try {
      const { journeyID, ideaID, idea } = request;
      const url = `/journeys/${journeyID}/ideas/${ideaID}`;
      return await HTTP.patch(url, idea);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const updateIdeasThunk = createAsyncThunk('updateIdeas',
  async (request) => {
    const { journeyID, ideas } = request;
    try {
      const url = `/journeys/${journeyID}/ideas`;
      return await HTTP.patch(url, ideas);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteIdeaThunk = createAsyncThunk('deleteIdea',
  async (request) => {
    try {
      const { journeyID, ideaID } = request;
      const url = `/journeys/${journeyID}/ideas/${ideaID}`;
      return await HTTP.del(url);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteIdeasThunk = createAsyncThunk('deleteIdeas',
  async (request) => {
    try {
      const { journeyID, ideas } = request;
      const url = `/journeys/${journeyID}/ideas`;
      return await HTTP.del(url, ideas);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const updateQuickAddItineraryThunk = createAsyncThunk('updateQuickAddItinerary',
  async ({
    id, quickItemId, ...request
  }) => {
    try {
      const url = `/journeys/${id}/quickItem/${quickItemId}`;
      return await HTTP.patch(url, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });
