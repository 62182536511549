import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from '@utils/http';
import { notifications } from '@utils/urls';

export const getNotificationsThunk = createAsyncThunk('getNotifications',
  async () => {
    try {
      return await HTTP.get(notifications);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteNotificationsThunk = createAsyncThunk('deleteNotifications',
  async (request) => {
    try {
      return await HTTP.del(`${notifications}`, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });
