import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserState } from '@context/user/userSlice';
import { useHistory, useParams } from 'react-router-dom';
import { getProfile } from '@context/user/userThunk';
import { Auth } from 'aws-amplify';

const withAuth = (Component) => ({ children }) => {
  const history = useHistory();
  const { redirectTo } = useParams();
  const dispatch = useDispatch();
  const { user } = useSelector(selectUserState);

  const getLoginSession = async () => {
    try {
      await Auth.currentAuthenticatedUser();
      if (!user) dispatch(getProfile());
    } catch (error) {
      history.push(redirectTo || '/login');
    }
  };

  useEffect(() => {
    getLoginSession();
  }, [user]);

  return user && <Component>{children}</Component>;
};
export default withAuth;
