import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Empty, Typography } from '@travelpassero/khaos';
import { Col, Row } from 'antd';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTracking } from '@hooks/useTracking';
import PageSpinner from '@components/PageSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { getSampleJourneysThunk } from '@context/journeys/journeysThunk';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import PublicJourneyCards from '@components/containers/PublicJourneyCards';
import PageScrollContainer from '@pages/PageScrollContainer';

import discoverCategories from '@utils/discoverCategories';
import useOnPageLoad from '@hooks/useOnPageLoad';

const CenterText = styled.div`
text-align: center;
padding: 32px 0 16px`;

const JourneyRow = styled(Row)`
  display:flex; 
  flex-direction:column;
  gap: 32px;
  max-width:1150px;
  width:100%;
  margin: 0 auto;
  padding: 0 16px;

  @media(min-width:768px){
    padding:0 30px;
  }
`;

const DiscoverCategoryPage = () => {
  const { category: categoryParam } = useParams();
  const dispatch = useDispatch();

  const { setPageView, setPage } = useTracking();
  setPageView(`Discover Category ${categoryParam}`);
  setPage(`Discover Category ${categoryParam}`);

  const categorySection = discoverCategories.find((category) => category.path === categoryParam);

  const { title, categories } = categorySection || {};

  const discoverTitle = title?.charAt(0).toUpperCase() + title?.slice(1);

  const {
    sampleJourneysStatus, sampleJourneys,
  } = useSelector(selectJourneysState);

  const onPageLoad = () => {
    const categoriesQuery = categories?.join(',');

    if (categoriesQuery) {
      dispatch(getSampleJourneysThunk({ categories: categoriesQuery }));
    }
  };

  const onCheckDataLoaded = () => !!sampleJourneys?.length;

  useOnPageLoad({ status: sampleJourneysStatus, onPageLoad, onCheckDataLoaded });

  const isSampleJourneys = sampleJourneys?.length > 0;

  if (sampleJourneysStatus === 'pending') {
    return <PageSpinner />;
  }

  if (sampleJourneysStatus === 'rejected') {
    return (
      <PageScrollContainer>
        <Empty
          heading="Nothing could be discovered right now"
          subHeading="Please try again later"
        />
      </PageScrollContainer>
    );
  }

  if (categoryParam && sampleJourneysStatus === 'fulfilled') {
    return (
      <PageScrollContainer>
        <CenterText>
          <Typography
            size="1.5rem"
            type="secondaryText"
            style={{
              textTransform: 'uppercase',
              fontWeight: 500,
              lineHeight: '39px',
            }}
          >
            {`${discoverTitle} Itineraries`}
          </Typography>
        </CenterText>
        <JourneyRow>
          <Col>
            {isSampleJourneys ? <PublicJourneyCards publicUserJourneys={sampleJourneys} /> : (
              <Empty
                heading="Nothing could be discovered right now"
                subHeading="Please try again later"
              />
            )}
          </Col>
        </JourneyRow>
      </PageScrollContainer>
    );
  }
};

export default DiscoverCategoryPage;
