import React from 'react';
import styled from 'styled-components';
import Spinner from './Spinner';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  position: absolute;
`;

const SidePageSpinner = () => (
  <SpinnerContainer>
    <Spinner size={104} />
  </SpinnerContainer>
);

export default SidePageSpinner;
