import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const UserFormContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: auto !important;
  align-items: stretch;
  padding: 0;
  display: flex;
  background-color: white;

  @media (max-width: 900px) {
    padding: 26px;
    flex-direction: column;
    flex: 1 1 auto;
    justify-content: flex-start;
  }
  
    @media (min-width: 700px) and (max-width: 900px) {
      padding-left: 70px;
      padding-right: 70px;
    }
`;

export const SpinnerContainer = styled.div`
  height: inherit;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
`;
