import { createSlice } from '@reduxjs/toolkit';
import { getProfile, signIn, socialAuth } from '@context/user/userThunk';
import { defineState } from '@context/persist';

const defaultState = {
  user: null,
  loading: false,
  error: null,
  onboarding: { },
};

const initialState = defineState(defaultState)('user');

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserLoader(state, { payload }) {
      state.loading = payload;
    },
    setOnboarding(state, { payload }) {
      state.onboarding = { ...state.onboarding, ...payload };
    },
    setOnboardingSuccessMessage(state, { payload }) {
      state.onboarding = { ...state.onboarding, ...payload };
    },
    updateUserReducer(state, { payload }) {
      state.user.data = { ...state.user.data, ...payload };
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
        sessionStorage.setItem('userID', payload.pk);
      })
      .addCase(signIn.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error;
      })
      .addCase(getProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(getProfile.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
        sessionStorage.setItem('userID', payload.pk);
      })
      .addCase(getProfile.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error;
      });

    builder
      .addCase(socialAuth.pending, (state) => {
        state.loading = true;
      })
      .addCase(socialAuth.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.user = payload;
        sessionStorage.setItem('userID', payload.pk);
      })
      .addCase(socialAuth.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error;
      });
  }),
});

export const selectUserState = (state) => state.user;

export const {
  setUserLoader, setOnboarding, setOnboardingSuccessMessage, updateUserReducer,
} = userSlice.actions;

export default userSlice.reducer;
