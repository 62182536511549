import React from 'react';
import { useDrag } from 'react-dnd';
import { CardFolder } from '@travelpassero/khaos';
import DropContainer from '@components/containers/DropContainer';
import { ItemTypes } from '@utils/dragTypes';
import ChildFolderCard from '@components/sharedComponentContainers/ChildFolderCard';
import IdeaFolderCard from '@components/sharedComponentContainers/IdeaFolderCard';
import IdeaCardFolderDnDContainer from './IdeaCardFolderDnDContainer';
import ChildFolderDnDContainer from './ChildFolderDnDContainer';

function FolderDnDContainer({
  creator,
  title,
  cards = [],
  childFolders = [],
  folderID,
  canDrag,
  onIdeaToFolderChange,
  onFolderToFolderChange,
  onIdeaItineraryAdd,
  onIdeaAddToSurvey,
  onCenterModalAction,
  onFolderMenuClick,
  ...args
}) {
  if (canDrag) {
    const renderedCards = cards.map((card) => (
      <IdeaCardFolderDnDContainer
        {...card}
        key={card.id}
        handleOnFolderChange={onIdeaToFolderChange}
        OnIdeaItineraryAdd={onIdeaItineraryAdd}
        onIdeaAddToSurvey={onIdeaAddToSurvey}
        onCardClick={(e) => e.stopPropagation()}
      />
    ));
    const renderedChildFolders = childFolders.map((childFolder) => (
      <ChildFolderDnDContainer
        {...childFolder}
        key={childFolder.id}
        handleOnFolderChange={onFolderToFolderChange}
      />
    ));
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.IDEACARD,
      item: { id: folderID },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult && dropResult.type === 'folder') {
          onFolderToFolderChange(folderID, dropResult.name);
        }
        return null;
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    if (isDragging) {
      return <div ref={drag} />;
    }

    return (
      <div ref={drag}>
        <DropContainer folderID={folderID} type="folder">
          <CardFolder
            key={folderID}
            cards={[...renderedChildFolders, ...renderedCards]}
            title={title}
            onEditClick={() => onCenterModalAction('edit-folder', folderID)}
            onDeleteClick={() => onCenterModalAction('delete-folder', folderID)}
            onFolderClick={() => onFolderMenuClick(folderID)}
            {...args}
          />
        </DropContainer>
      </div>
    );
  }

  const renderedCards = cards.map((card) => {
    const { imgSrc, title } = card;

    return (
      <IdeaFolderCard title={title} imgSrc={imgSrc} isMobile />
    );
  });

  const renderedChildFolders = childFolders.map((childFolder) => {
    const { title, innerCards } = childFolder;

    return (
      <ChildFolderCard title={title} innerCards={innerCards} isMobile />
    );
  });

  return (
    <CardFolder
      cards={[...renderedChildFolders, ...renderedCards]}
      title={title}
      onEditClick={() => onCenterModalAction('edit-folder', folderID)}
      onDeleteClick={() => onCenterModalAction('delete-folder', folderID)}
      onFolderClick={() => onFolderMenuClick(folderID)}
    />
  );
}

export default FolderDnDContainer;
