import { configureStore } from '@reduxjs/toolkit';
import userSlice from '@context/user/userSlice';
import journeySlice from '@context/journey/journeySlice';
import journeysSlice from '@context/journeys/journeysSlice';
import friendSlice from '@context/friend/friendSlice';
import notificationSlice from '@context/notification/notificationSlice';
import { persistStore } from '@context/persist';
import storageSlice from '@context/storage/storageSlice';
import photoSlice from '@context/photo/photoSlice';
import globalStateReducer from './reducer';
import publicSlice from './public/publicSlice';

const store = configureStore({
  reducer: {
    app: globalStateReducer,
    user: userSlice,
    journey: journeySlice,
    journeys: journeysSlice,
    friend: friendSlice,
    notification: notificationSlice,
    public: publicSlice,
    storage: storageSlice,
    photo: photoSlice,
  },
});

persistStore(store, { whitelist: ['user'] });

export default store;
