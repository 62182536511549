export default [
  {
    categories: ['adventure'],
    title: 'Adventurous',
    path: 'adventure',
  },
  {
    categories: ['nature', 'outdoor'],
    title: 'Nature Escapes',
    path: 'nature',
  },
  {
    categories: ['romantic'],
    title: 'Romantic + Honeymoon',
    path: 'romantic',
  },
  {
    categories: ['cultural', 'foodies'],
    title: 'Cultural Immersion',
    path: 'cultural',
  },
  {
    categories: ['relaxing'],
    title: 'R&R and Wellness',
    path: 'relaxing',
  },
  {
    categories: ['weekend', 'weekend getaway'],
    title: 'Weekend Getaway',
    path: 'weekend',
  },
];
