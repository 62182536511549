// eslint-disable-next-line import/no-extraneous-dependencies
import { combineReducers } from 'redux';
import {
  OPEN_CENTER_MODAL,
  OPEN_DRAWER,
  CLOSE_MODAL,
  CLOSE_DRAWER,
  HANDLE_CHANGE,
  ADD_USER_DATA,
  ADD_IDEA_TO_SURVEY,
  REMOVE_IDEA_FROM_SURVEY,
  REMOVE_ALL_FROM_SURVEY,
  SET_MODAL_LOADING_STATE,
  SET_DRAWER_LOADING_STATE,
  SET_LEFT_NAV_COLLAPSED,
} from './actions';

import { globalInitialState } from './globalInitialState';

const subreducer = (state, action) => ({
  ...state,
  ...action,
});

const closeAllModals = () => ({
  isCenterModalVisible: false,
  modalChildrenType: '',
  modalSelectedID: '',
});

const closeAllDrawers = () => ({
  isDrawerVisible: false,
  drawerChildrenType: '',
  drawerSelectedID: '',
});

export const globalStateReducer = (state = globalInitialState, action) => {
  switch (action.type) {
    case OPEN_CENTER_MODAL:
      const selectedID = action.payload.modalSelectedID;
      const modalType = action.payload.type;

      return {
        ...state,
        isCenterModalVisible: true,
        modalChildrenType: modalType,
        modalSelectedID: selectedID,
      };

    case OPEN_DRAWER:
      const { type } = action.payload;
      const selectedDrawerID = action.payload.drawerSelectedID;

      return {
        ...state,
        isDrawerVisible: true,
        drawerChildrenType: type,
        drawerSelectedID: selectedDrawerID,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        ...closeAllModals(),
      };

    case CLOSE_DRAWER:
      return {
        ...state,
        ...closeAllDrawers(),
      };

    case ADD_USER_DATA:
      const { userInfo } = action.payload;

      return {
        ...state,
        userInfo: subreducer(state.userInfo, userInfo),
      };

    case HANDLE_CHANGE:
      const { value } = action.payload;
      const { name } = action.payload;

      return {
        ...state,
        addInput: subreducer(state.addInput, {
          [`${name}Selected`]: value,
        }),
      };

    case ADD_IDEA_TO_SURVEY:
      const surveyID = action.payload.surveyIdeaID;
      const { questionNumber } = action.payload;

      const surveyActionIdeas = action.payload.ideas
        ? action.payload.ideas
        : [];
      const oldState = state.surveySelectedIdeas
        ? state.surveySelectedIdeas
        : [];

      const getQuestionStateIndex = oldState
        && oldState.findIndex((i) => i.questionNumber === questionNumber);

      const surveyStateIdeasObj = getQuestionStateIndex !== -1 ? oldState[getQuestionStateIndex] : null;

      const surveyIdeas = surveyActionIdeas;
      const surveyStateIdeas = surveyStateIdeasObj && surveyStateIdeasObj.ideas;

      const formatSurveyIdeas = surveyIdeas && surveyIdeas.map((i) => i.id);
      const formatStateIdeas = surveyStateIdeas || [];

      const ideas = [
        ...new Set([...formatSurveyIdeas, ...formatStateIdeas, surveyID]),
      ].filter((i) => i);

      const questionFormat = {
        questionNumber,
        ideas,
      };

      const newState = state.surveySelectedIdeas.slice();
      const newStateIndex = newState.findIndex(
        (i) => i.questionNumber === questionNumber,
      );
      if (newStateIndex !== -1) {
        newState[newStateIndex] = questionFormat;
      } else {
        return {
          ...state,
          surveySelectedIdeas: [...state.surveySelectedIdeas, questionFormat],
        };
      }

      return {
        ...state,
        surveySelectedIdeas: newState,
      };

    case REMOVE_IDEA_FROM_SURVEY:
      const { ideaID } = action.payload;
      const surveyQuestionNumber = action.payload.questionNumber;
      const { surveySelectedIdeas } = state;
      const questionIndex = surveySelectedIdeas
        && state.surveySelectedIdeas.findIndex(
          (i) => i.questionNumber === surveyQuestionNumber,
        );

      const newQuestionArray = surveySelectedIdeas.slice();

      const newQuestionFormat = {
        questionNumber: surveyQuestionNumber,
        ideas: newQuestionArray[questionIndex].ideas.filter(
          (i) => i !== ideaID,
        ),
      };
      newQuestionArray[questionIndex] = newQuestionFormat;

      return {
        ...state,
        surveySelectedIdeas: newQuestionArray,
      };

    case REMOVE_ALL_FROM_SURVEY:
      return {
        ...state,
        surveySelectedIdeas: [],
      };

    case SET_MODAL_LOADING_STATE:
      const { modalLoadingState } = action.payload;

      return {
        ...state,
        modalLoadingState,
      };

    case SET_DRAWER_LOADING_STATE:
      const { drawerLoadingState } = action.payload;

      return {
        ...state,
        drawerLoadingState,
      };

    case SET_LEFT_NAV_COLLAPSED:
      const { isLeftNavCollapsed } = action.payload;

      return {
        ...state,
        isLeftNavCollapsed,
      };

    default:
      return {
        ...state,
      };
  }
};

export default combineReducers({
  globalStateReducer,
});
