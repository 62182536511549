export const globalInitialState = {
  isCenterModalVisible: false,
  isDrawerVisible: false,
  drawerChildrenType: '',
  modalSelectedID: '',
  drawerSelectedID: '',
  surveySelectedIdeas: [],
  modalLoadingState: false,
  drawerLoadingState: false,
  isLeftNavCollapsed: false,
  userInfo: {
    firstName: '',
    lastName: '',
    iconColor: '',
    email: '',
    userID: '',
    phoneNumber: '',
    isEmailSubscribed: '',
  },
};
