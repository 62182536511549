import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from '@utils/http';
import { friends, friendRequests } from '@utils/urls';

export const getFriendsThunk = createAsyncThunk('getFriends',
  async () => {
    try {
      return await HTTP.get(friends);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteFriendsThunk = createAsyncThunk('deleteFriends',
  async (request) => {
    try {
      return await HTTP.del(`${friends}`, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getSentFriendRequestsThunk = createAsyncThunk('getSentFriendRequests',
  async () => {
    try {
      return await HTTP.get(`${friendRequests}/sent`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getDeclinedSentFriendRequestsThunk = createAsyncThunk('getDeclinedSentFriendRequests',
  async () => {
    try {
      return await HTTP.get(`${friendRequests}/sent/declined`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const sendFriendRequestsThunk = createAsyncThunk('sendFriendRequests',
  async (request) => {
    try {
      return await HTTP.post(`${friendRequests}/sent`, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const cancelSentFriendRequestsThunk = createAsyncThunk('cancelSentFriendRequests',
  async (request) => {
    try {
      const { pendingFriendId } = request;
      return await HTTP.post(`${friendRequests}/sent/cancel/${pendingFriendId}`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getReceivedFriendRequestsThunk = createAsyncThunk('getReceivedFriendRequests',
  async () => {
    try {
      return await HTTP.get(`${friendRequests}/received`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const acceptFriendRequestsThunk = createAsyncThunk('acceptFriendRequests',
  async (request) => {
    try {
      return await HTTP.post(`${friendRequests}/received/accept`, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const declineFriendRequestsThunk = createAsyncThunk('declineFriendRequests',
  async (request) => {
    try {
      return await HTTP.post(`${friendRequests}/received/decline`, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const sendFriendRequestReminderThunk = createAsyncThunk('sendFriendRequestReminder',
  async (request) => {
    try {
      const { pendingFriendId } = request;
      return await HTTP.post(`${friendRequests}/reminder/${pendingFriendId}`, request);
    } catch (error) {
      throw new Error(error.message);
    }
  });
