export default [
  {
    region: 'asia',
    imgSrcKey: 'ideaboard-activities/sights3.jpg',
    path: 'asia',
  },
  {
    region: 'americas',
    imgSrcKey: 'ideaboard-activities/beach1.jpg',
    path: 'americas',
  },
  {
    region: 'europe',
    imgSrcKey: 'ideaboard-activities/beach2.jpg',
    path: 'europe',
  },
  {
    region: 'africa',
    imgSrcKey: 'ideaboard-activities/hike1.jpg',
    path: 'africa',
  },
  {
    region: 'oceania',
    imgSrcKey: 'ideaboard-activities/hike2.jpg',
    path: 'oceania',
  },
];
