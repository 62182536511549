import { API } from 'aws-amplify';

const apiName = 'Hydra';
const apiEndpoint = process.env.REACT_APP_HYDRA_ENDPOINT;

const buildQueryString = (params) => {
  const queryString = Object.keys(params)
    .filter((key) => params[key] !== undefined)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
    .join('&');

  return queryString ? `?${queryString}` : '';
};

export const get = async (path, queryStringParameters = {}) => {
  try {
    const data = await API.get(apiName, path, { headers: {}, queryStringParameters });
    return data.payload;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getPublic = async (path, queryStringParameters = {}) => {
  try {
    const queryString = buildQueryString(queryStringParameters);

    const response = await fetch(`${apiEndpoint}${path}${queryString}`);
    const { payload } = await response.json();

    return payload;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const post = async (path, body) => {
  try {
    const data = await API.post(apiName, path, { body });
    return data.payload;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const patch = async (path, body) => {
  try {
    const data = await API.patch(apiName, path, { body });
    return data.payload;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const del = async (path, body) => {
  try {
    const data = await API.del(apiName, path, { body });
    return data.payload;
  } catch (error) {
    throw new Error(error.message);
  }
};
