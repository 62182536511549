import React, { useState } from 'react';
import styled from 'styled-components';

import {
  Card as AntCard, Col, Form, Input, Row, Select,
} from 'antd';

import {
  MultipleChoiceQuestion,
  BinaryChoiceQuestion,
  DateRangeQuestion,
} from '@travelpassero/khaos';

import TileChoiceQuestion from './TileChoiceQuestion';

const { Option } = Select;

const CardContainer = styled(AntCard)`
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--white);

  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid var(--grey);

  .ant-card-body {
    padding: 36px 36px 8px 36px;
    width: 100%;
  }
`;

const FormInputItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    input {
      height: 40px;
    }
  }
`;

const FormSelectInputItem = styled.div`
  .ant-select .ant-select-selector {
    height: 40px;
    display: flex;
    align-items: center;

    .ant-select-item-option-content {
      height: 40px;

      input {
        height: 40px;
      }
    }
  }
`;

function SurveyQuestionCard({ questionNumber, ideas, style }) {
  const [formType, setFormType] = useState('simple');

  const onSelectChange = (values) => {
    setFormType(values);
  };

  let Content;
  switch (formType) {
    case 'simple':
      Content = null;
      break;
    case 'checkbox':
      Content = <BinaryChoiceQuestion questionNumber={questionNumber} />;

      break;
    case 'multiple':
      Content = <MultipleChoiceQuestion questionNumber={questionNumber} />;
      break;

    case 'date':
      Content = <DateRangeQuestion questionNumber={questionNumber} />;
      break;

    case 'chooseTiles':
      Content = (
        <TileChoiceQuestion ideas={ideas} questionNumber={questionNumber} />
      );
      break;

    default:
      Content = null;
  }

  return (
    <div className="card-sizing" style={{ padding: '0 16px', margin: '16px 0' }}>
      <CardContainer style={style}>
        <Row justify="space-between" gutter={[16, 16]}>
          <Col flex={1}>
            <FormInputItem
              name={`question-${questionNumber}_title`}
              help="Title is required"
              rules={[{ required: true }]}
            >
              <Input placeholder="Add your Question" />
            </FormInputItem>
          </Col>
          <Col>
            <FormSelectInputItem>
              <Select
                onChange={onSelectChange}
                placeholder="Question Type"
                allowClear
              >
                <Option value="simple">Text Box</Option>
                <Option value="checkbox">Binary Question</Option>
                <Option value="multiple">Checkbox</Option>
                <Option value="date">Date Range</Option>
                <Option value="chooseTiles">Choose Tiles</Option>
              </Select>
            </FormSelectInputItem>
          </Col>
        </Row>
        {Content || null}
      </CardContainer>
    </div>
  );
}

export default SurveyQuestionCard;
