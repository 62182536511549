import useUser from '@hooks/useUser';
import Layout from '@components/Layout';
import DiscoverRegionPage from './DiscoverRegionPage';

const DiscoverRegionPageContainer = () => {
  const { userID } = useUser();

  if (userID) {
    return <Layout><DiscoverRegionPage /></Layout>;
  }

  return <DiscoverRegionPage />;
};

export default DiscoverRegionPageContainer;
