import React, { useEffect, useState } from 'react';
import moment from 'moment';
import {
  Button, CircleCheckbox, CircleProfileIcon, PopConfirm, Typography, Empty, CenterModal,
} from '@travelpassero/khaos';
import {
  FRIEND_REQUEST_ACCEPTED,
  FRIEND_REQUEST_RECEIVED,
  FRIEND_REQUEST_REMINDER,
  JOURNEY_INVITE_RECEIVED,
} from '@utils/notificationTypes';
import { Col, List, Row } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import useFriend from '@hooks/useFriend';
import useNotification from '@hooks/useNotification';
import { useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import useLayout from '@hooks/useLayout';

const { Item } = List;

const LinkWrapper = styled.span`
  a {
    padding: 0;
    color: inherit;
    font-weight: bold;
    line-height: inherit;
    height: inherit;
    border: none;
    text-decoration: underline;

    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      color: var(--secondary) !important;
    }
  }
`;

const Avatar = ({ user }) => {
  const {
    firstName, lastName, iconColor, isPendingFriend,
  } = user;

  return (
    <CircleProfileIcon
      firstName={firstName}
      lastName={lastName}
      isPendingFriend={isPendingFriend}
      iconColor={iconColor}
    />
  );
};

const Decline = ({ onDecline }) => (
  <PopConfirm title="Decline friend request?" placement="right" onConfirm={onDecline}>
    <Button>
      Decline
    </Button>
  </PopConfirm>
);

const Confirm = ({ onConfirm }) => (
  <PopConfirm title="Accept friend request?" placement="right" onConfirm={onConfirm}>
    <div style={{ paddingLeft: '12px' }}>
      <Button type="primary">
        Accept
      </Button>
    </div>
  </PopConfirm>
);

const NotificationBody = ({
  notification, onCheckboxClick, onAcceptFriendRequest, onDeclineFriendRequest,
}) => {
  const {
    notificationId, type, journeyId, friend, journey, isChecked, createdAt,
  } = notification;

  let body = null;
  if (type === JOURNEY_INVITE_RECEIVED && journey) {
    body = {
      description: (
        <>
          <span style={{ fontWeight: '600' }}>
            {friend.firstName}
          </span>
          {' has invited you to join '}
          <LinkWrapper>
            <Link to={`/journeys/${journeyId}/ideas`}>{`${journey.title} journey`}</Link>
          </LinkWrapper>
          .
        </>
      ),
    };
  } else if (type === FRIEND_REQUEST_RECEIVED) {
    body = {
      description: (
        <>
          <span style={{ fontWeight: '600' }}>
            {friend.firstName}
          </span>
          {' has sent you a friend request.'}
        </>
      ),
      onConfirm: () => onAcceptFriendRequest(notification),
      onDecline: () => onDeclineFriendRequest(notification),
    };
  } else if (type === FRIEND_REQUEST_ACCEPTED) {
    body = {
      description: (
        <>
          <span style={{ fontWeight: '600' }}>
            {friend.firstName}
          </span>
          {' has accepted your friend request.'}
        </>
      ),
    };
  } else if (type === FRIEND_REQUEST_REMINDER) {
    body = {
      description: (
        <>
          <span style={{ fontWeight: '600' }}>
            {friend.firstName}
          </span>
          {' is waiting for you to accept their friend request.'}
        </>
      ),
      onConfirm: () => onAcceptFriendRequest(notification),
      onDecline: () => onDeclineFriendRequest(notification),
    };
  }

  const dateDiff = moment().diff(moment(createdAt));
  let dateDisplay;
  let dateCompare = parseInt(moment.duration(dateDiff).asMinutes());

  if (dateCompare < 1) dateDisplay = 'Just now';
  else if (dateCompare < 60) dateDisplay = `${dateCompare} minute${dateCompare < 2 ? '' : 's'} ago`;
  else {
    dateCompare = parseInt(moment.duration(dateDiff).asHours());

    if (dateCompare < 24) dateDisplay = `${dateCompare} hour${dateCompare < 2 ? '' : 's'} ago`;
    else {
      dateCompare = parseInt(moment.duration(dateDiff).asDays());
      if (dateCompare < 30) dateDisplay = `${dateCompare} day${dateCompare < 2 ? '' : 's'} ago`;
      else dateDisplay = `On ${moment(createdAt).format('MMMM Do, YYYY')}`;
    }
  }

  return (
    <Item>
      <Item.Meta
        avatar={<Avatar user={friend} />}
        description={(
          <Row justify="space-between">
            <Col flex="1 1 200px">
              <Row>
                <Col span={24}>
                  <Typography type="primaryText">
                    {body.description}
                  </Typography>
                </Col>
                <Col span={24}>
                  <Typography type="greyText">
                    {dateDisplay}
                  </Typography>
                </Col>
              </Row>
            </Col>
            <Col flex={body.onDecline && body.onConfirm ? '1 1 200px' : 'auto'}>
              <Row justify="end" wrap={false} style={{ marginTop: '8px' }}>
                {!body.onDecline && !body.onConfirm && (
                <CircleCheckbox
                  onClick={() => onCheckboxClick(notificationId)}
                  isChecked={isChecked}
                />
                )}
                {body.onDecline && <Decline onDecline={body.onDecline} />}
                {body.onConfirm && <Confirm onConfirm={body.onConfirm} />}
              </Row>
            </Col>
          </Row>
          )}
      />
    </Item>
  );
};

const NotificationsModal = () => {
  const { handleCloseModal } = useLayout();
  const { acceptFriendRequests, declineFriendRequests, receivedFriendRequests } = useFriend();
  const { removeNotifications, notifications } = useNotification();
  const { globalStateReducer } = useSelector(selectAppState);

  const {
    isCenterModalVisible,
  } = globalStateReducer;

  const [notificationList, setNotificationList] = useState(notifications);
  const [isMarked, setIsMarked] = useState(false);
  const [wasChanged, setWasChanged] = useState(false);

  const sortByMostRecent = (notifications) => [...notifications].sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1));

  useEffect(() => {
    if (notifications.length) {
      setNotificationList(sortByMostRecent(notifications));
    }
  }, [notifications]);

  const onMarkAllChange = () => {
    setNotificationList(notificationList.map((notification) => {
      const checkedNotification = { ...notification };
      checkedNotification.isChecked = !isMarked;
      return checkedNotification;
    }));

    setIsMarked(!isMarked);
    setWasChanged(true);
  };

  const handleCheckboxClick = (id) => {
    setNotificationList(notificationList.map((notification) => {
      const checkedNotification = { ...notification };
      if (checkedNotification.notificationId === id) checkedNotification.isChecked = !checkedNotification.isChecked;
      return checkedNotification;
    }));
    setWasChanged(true);
  };

  const handleOnAcceptFriendRequest = (notification) => {
    const { friendId } = notification;
    acceptFriendRequests(receivedFriendRequests?.filter((friendRequest) => friendRequest.id === friendId));
    removeNotifications([notification]);
  };

  const handleOnDeclineFriendRequest = (notification) => {
    const { friendId } = notification;
    declineFriendRequests(receivedFriendRequests?.filter((friendRequest) => friendRequest.id === friendId));
    removeNotifications([notification]);
  };

  const handleOnRead = () => {
    removeNotifications(notificationList?.filter((notification) => notification.isChecked));
    handleCloseModal();
  };

  if (notifications.length < 1) {
    return (
      <CenterModal
        handleOnClose={handleCloseModal}
        showModal={isCenterModalVisible}
        title="Notifications"
        children={(
          <Empty
            heading="You currently do not have any notifications."
          />
      )}
        footerButtons={[
          <Button onClick={handleCloseModal}>
            Close
          </Button>,
        ]}
      />
    );
  }

  return (
    <CenterModal
      handleOnClose={handleCloseModal}
      showModal={isCenterModalVisible}
      title="Notifications"
      children={(
        <List>
          {notificationList.map((notification) => (
            <NotificationBody
              key={notification.notificationId}
              notification={notification}
              onCheckboxClick={handleCheckboxClick}
              onAcceptFriendRequest={handleOnAcceptFriendRequest}
              onDeclineFriendRequest={handleOnDeclineFriendRequest}
            />
          ))}
        </List>
      )}
      footerButtons={[
        <Button onClick={onMarkAllChange}>
          Mark
          {' '}
          {isMarked ? 'None' : 'All'}
        </Button>,
        <PopConfirm onConfirm={handleOnRead}>
          <Button type="primary" disabled={!wasChanged}>
            Read
          </Button>
        </PopConfirm>,
      ]}
    />
  );
};

export default NotificationsModal;
