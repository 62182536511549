import React, { useState } from 'react';
import {
  Col,
  Row,
} from 'antd';
import {
  PopConfirm, Switch,
} from '@travelpassero/khaos';
import { selectPublicState } from '@context/public/publicSlice';
import { useSelector } from 'react-redux';
import useUser from '@hooks/useUser';

function PublicToggleSection({ isEditable }) {
  const {
    updateProfile,
  } = useUser();

  const {
    profile,
  } = useSelector(selectPublicState);

  const {
    isProfilePublic: isProfilePublicDefault,
  } = profile || {};

  const [isPublicChange, setIsPublicChange] = useState(false);
  const [isProfilePublic, setIsProfilePublic] = useState(isProfilePublicDefault);

  const handleOnPublicChangeConfirm = () => {
    updateProfile({
      isProfilePublic,
    });

    setIsPublicChange(!isPublicChange);
  };

  const handleOnPublicChangeCancel = () => {
    setIsPublicChange(!isPublicChange);

    setTimeout(() => {
      setIsProfilePublic(!isProfilePublic);
    }, 100);
  };

  const handleOnPublicChange = (value) => {
    setIsProfilePublic(value);
    setIsPublicChange(!isPublicChange);
  };

  if (!isEditable) return null;

  return (
    <Row align="middle" gutter={[8, 0]} style={{ padding: '0 46px 8px 0' }}>
      <Col>
        <PopConfirm
          onConfirm={handleOnPublicChangeConfirm}
          onCancel={handleOnPublicChangeCancel}
          visible={isPublicChange}
          title={`Make your profile ${isProfilePublic ? 'public' : 'private'}?`}
        >
          <Switch
            label="Public Profile"
            value={isProfilePublic}
            onChange={handleOnPublicChange}
          />

        </PopConfirm>
      </Col>
    </Row>
  );
}

export default PublicToggleSection;
