import { selectAppState } from '@context/global/appSlice';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import {
  CenterModal,
  DrawerModal,
  UploadPhotoForm,
} from '@travelpassero/khaos';
import ProfileForm from '@components/form/ProfileForm';
import useLayout from '@hooks/useLayout';
import SidePageSpinner from '@components/SidePageSpinner';
import { openCenterModal } from '@context/actions';
import { selectPhotoReducer } from '@context/photo/photoSlice';
import { useWindowSize } from '@hooks/useWindowSize';
import ProfileBackgroundForm from '@components/form/ProfileBackgroundForm';

const JourneyCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const EditProfile = () => {
  const { globalStateReducer } = useSelector(selectAppState);
  const { isMobile } = useWindowSize();
  const { handleCloseModal, handleCloseDrawer } = useLayout();
  const dispatch = useDispatch();

  let widthAspect = 1;
  let heightAspect = 1;

  let DrawerChildren;
  const {
    isCenterModalVisible,
    isDrawerVisible,
    drawerChildrenType,
    modalChildrenType,
    drawerLoadingState,
  } = globalStateReducer;

  const handleOpenUploadModal = () => {
    dispatch(openCenterModal('upload'));
  };

  const selectPhoto = (selectedPhoto) => {
    dispatch(selectPhotoReducer(selectedPhoto));
  };

  const handleOnUpload = async (selectedFile) => {
    selectPhoto(selectedFile);
    handleCloseModal();
  };

  const handleOnClose = () => {
    dispatch(selectPhotoReducer({}));
    handleCloseDrawer();
  };

  switch (drawerChildrenType) {
    case 'edit-profile':
      widthAspect = 100;
      heightAspect = 100;
      DrawerChildren = (
        <ProfileForm
          onOpenUploadModal={handleOpenUploadModal}
        />
      );
      break;
    case 'edit-banner':
      widthAspect = 400;
      heightAspect = 100;
      DrawerChildren = (
        <ProfileBackgroundForm
          onOpenUploadModal={handleOpenUploadModal}
        />
      );
      break;

    default:
      DrawerChildren = null;
  }
  return (
    <JourneyCardWrapper>
      {DrawerChildren && (
      <DrawerModal
        handleOnClose={handleOnClose}
        showModal={isDrawerVisible}
        children={drawerLoadingState ? <SidePageSpinner /> : DrawerChildren}
        maxWidth="600px"
      />
      )}
      {modalChildrenType === 'upload' && (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={(
            <UploadPhotoForm
              onClose={handleCloseModal}
              onUpload={handleOnUpload}
              widthAspect={widthAspect}
              heightAspect={heightAspect}
            />
          )}
          modalWidth={isMobile ? '100%' : '500px'}
        />
      )}
    </JourneyCardWrapper>
  );
};

export default EditProfile;
