import React from 'react';
import moment from 'moment';
import { DATE_FORMAT } from '@travelpassero/khaos';
import {
  Document, Page, Text, View, Font, Image,
} from '@react-pdf/renderer';
import fontPrimary from '../fonts/epilogue/Epilogue-VariableFont_wght.ttf';
import getDatesBetween from './getDatesBetween';
import logo from '../assets/passero_logo_white.svg';

export const GetPDF = ({ data, ideas }) => {
  if (!data || !ideas) {
    return (
      <Document>
        <Page wrap>
          <Text>No data to show</Text>
        </Page>
      </Document>
    );
  }

  Font.register({
    family: 'font-primary',
    src: fontPrimary,
  });

  const styles = {
    body: {
      paddingBottom: 65,
    },
    innerBody: {
      paddingHorizontal: 35,
    },
    section: {
      backgroundColor: 'black',
      height: 75,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
      flexWrap: 'nowrap',
      alignItems: 'stretch',
    },
    table: {
      fontSize: 10,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignContent: 'stretch',
      flexWrap: 'nowrap',
      alignItems: 'stretch',
      borderWidth: 1,
      borderColor: 'black',
      borderStyle: 'solid',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      alignContent: 'stretch',
      flexWrap: 'nowrap',
      alignItems: 'stretch',
    },
    cell: {
      flexGrow: 1,
      flexShrink: 1,
      flexBasis: 'auto',
      alignSelf: 'stretch',
    },
  };

  const { dateRangeStart, dateRangeEnd } = data;
  const itineraryQuickAddItems = data?.itineraryQuickAddItems;

  const datesBetweenDates = data && getDatesBetween(dateRangeStart, dateRangeEnd);

  const Itinerary = datesBetweenDates?.map((day) => {
    const cardIdeas = ideas
      && ideas.filter((i) => {
        const ideaDay = moment(i.itineraryDate).format(DATE_FORMAT);

        return ideaDay === day;
      });

    const quickAddItems = itineraryQuickAddItems?.filter((item) => {
      const itemDay = moment(item.itineraryDate).format(DATE_FORMAT);

      return itemDay === day;
    }) || [];

    const sortByTime = (a, b) => parseInt(a.itineraryTime) - parseInt(b.itineraryTime);

    const QuickAddItemsFormatted = quickAddItems?.map((item) => ({ ...item, isQuickAdd: true }));

    const combineTotalItems = [...(cardIdeas || []), ...(QuickAddItemsFormatted || [])];

    const totalItems = combineTotalItems.sort(sortByTime);

    const formatDay = moment(day).format('dddd - MMMM Do YYYY');

    const DayItems = totalItems
      && totalItems.length > 0
      && totalItems.map((item) => {
        const {
          title,
          duration,
          description,
          price,
          itineraryNote,
          itineraryTime,
          location,
        } = item;

        const formatPrice = price ? `$${price}` : undefined;

        return (
          <View style={styles.table}>
            {/* Item Headers */}
            <View style={styles.row}>
              <View
                style={{
                  backgroundColor: '#C3EBE3',
                  borderWidth: 1,
                  borderColor: 'black',
                  borderStyle: 'solid',
                  width: '20%',
                  paddingVertical: 6,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    textAlign: 'right',
                    fontWeight: 'bold',
                    paddingRight: 12,
                  }}
                >
                  {itineraryTime || '-'}
                </Text>
              </View>
              <View
                style={{
                  backgroundColor: '#FFF0F3',
                  borderWidth: 1,
                  borderColor: 'black',
                  borderStyle: 'solid',
                  paddingLeft: 20,
                  paddingVertical: 6,
                  flex: 1,
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    fontWeight: 'bold',
                  }}
                >
                  {title}
                </Text>
              </View>
            </View>

            {/* item descriptions */}
            <View style={styles.row}>
              <View
                style={{
                  paddingVertical: 6,
                  borderRightColor: 'black',
                  borderRightStyle: 'solid',
                  borderRightWidth: 1,
                  width: '20%',
                  paddingRight: 12,
                  textAlign: 'right',
                }}
              >
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    fontWeight: 'bold',
                  }}
                >
                  Duration:
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    fontWeight: 'bold',
                  }}
                >
                  About:
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    marginBottom: 10,
                    fontWeight: 'bold',
                  }}
                >
                  Note:
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    fontWeight: 'bold',
                  }}
                >
                  Price:
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    fontWeight: 'bold',
                  }}
                >
                  Location:
                </Text>
              </View>

              <View style={{ flex: 1, paddingVertical: 6 }}>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    paddingLeft: 20,
                  }}
                >
                  {duration || '-'}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    paddingLeft: 20,
                    maxLines: 1,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {description || '-'}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    marginBottom: 10,
                    paddingLeft: 20,
                    maxLines: 1,
                    textOverflow: 'ellipsis',
                  }}
                >
                  {itineraryNote || '-'}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    paddingLeft: 20,
                  }}
                >
                  {formatPrice || '-'}
                </Text>
                <Text
                  style={{
                    fontSize: 12,
                    fontFamily: 'font-primary',
                    display: 'block',
                    paddingLeft: 20,
                  }}
                >
                  {location || '-'}
                </Text>
              </View>
            </View>
          </View>
        );
      });

    return (
      <View>
        <View
          style={{
            backgroundColor: 'black',
            paddingVertical: 12,
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4,
            marginTop: 26,
          }}
        >
          <Text style={{ fontSize: 16, textAlign: 'center', color: 'white' }}>
            {formatDay}
          </Text>
        </View>
        <View
          style={{
            height: 10,
            borderWidth: 1,
            borderColor: 'black',
            borderStyle: 'solid',
          }}
        />
        {DayItems}
      </View>
    );
  });

  return (
    <Document>
      <Page style={styles.body}>
        <View style={styles.section}>
          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignContent: 'stretch',
              flexWrap: 'nowrap',
              alignItems: 'stretch',
              marginHorizontal: 35,
            }}
          >
            <View>
              <Image
                style={{ height: 70, width: 125, paddingTop: 10 }}
                src={logo}
              />
            </View>
            <View style={{ display: 'flex', alignItems: 'center' }}>
              <Text style={{ color: 'white', paddingTop: 30 }}>Itinerary</Text>
            </View>
          </View>
        </View>

        <View style={styles.innerBody}>{Itinerary}</View>
      </Page>
    </Document>
  );
};

export default GetPDF;
