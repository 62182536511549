import React from 'react';
import Spinner from './Spinner';

const PageSpinner = () => (
  <div
    style={{
      height: '100vh',
      width: '100vw',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'var(--page-background)',
    }}
  >
    <Spinner size={104} />
  </div>
);

export default PageSpinner;
