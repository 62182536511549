import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { CalendarOutlined, EyeInvisibleOutlined, PlusOutlined } from '@ant-design/icons';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  TopBar,
  CenterModal,
  ItineraryAddDatesModal,
  Button,
  SelectSurveyResult,
  AddFolderModal,
  Calendar,
  DATE_FORMAT,
  ShareItineraryModal,
} from '@travelpassero/khaos';

import {
  Col, Row, Tooltip,
} from 'antd';
import ItineraryCardContainer from '@components/containers/ItineraryCardContainer';
import ManageCrewModal from '@components/sharedComponentContainers/ManageCrewModal';
import ModalSpinner from '@components/ModalSpinner';
import addJourneyFolder from '@operations/addJourneyFolder';

import {
  closeModal,
  openCenterModal,
  setModalLoadingState,
  closeDrawer,
} from '@context/actions';
import { selectAppState } from '@context/global/appSlice';
import { selectJourneysState } from '@context/journeys/journeysSlice';

import { dataLayerUtil } from '@utils/dataLayerUtil';
import PDFView from '@utils/GetPDF';

import CrewList from '@components/sharedComponentContainers/CrewList';
import useJourneyCrew from '@hooks/useJourneyCrew';
import { useJourney } from '@hooks/useJourney';
import { useWindowSize } from '@hooks/useWindowSize';
import { useTracking } from '@hooks/useTracking';
import { useHistory } from 'react-router-dom';
import ItineraryIntroPage from './ItineraryIntroPage';
import PageScrollContainer from './PageScrollContainer';

const CrewListContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const ItineraryDateButton = ({ itinerary = {}, onClick }) => {
  const { dateRangeStart, dateRangeEnd } = itinerary;
  const formatTitle = (date) => {
    if (!date) return '';
    return `${moment(date).format('MMM Do')}`;
  };

  const formatTitleYear = (date) => {
    if (!date) return '';
    return `${moment(date).format('YYYY')}`;
  };

  return (
    <Col>
      <Tooltip title="Edit Date">
        <div>
          <Button type="default" onClick={onClick}>
            {formatTitle(dateRangeStart)}
            {' '}
            -
            {' '}
            {formatTitle(dateRangeEnd)}
            {' '}
            {formatTitleYear(dateRangeEnd)}
          </Button>
        </div>
      </Tooltip>
    </Col>
  );
};

const ItineraryPage = ({
  journeyID,
  splitScreenSide,
}) => {
  const { setPageView, setPage } = useTracking();
  const { isMobile, width } = useWindowSize();
  const dispatch = useDispatch();
  const history = useHistory();
  const { globalStateReducer } = useSelector(selectAppState);
  const { modalLoading } = useSelector(selectJourneysState);
  const useJourneyData = useJourney(journeyID);
  const {
    ideas, title, folders, itinerary, surveys, updateJourney,
  } = useJourneyData || {};
  const { crewList, removeCrewMember } = useJourneyCrew(journeyID);
  const [isCalendarVisible, setIsCalendarVisible] = useState(true);
  const [selectedDay, setSelectedDay] = useState();
  const [selectedDayRangeDisplay, setSelectedDayRangeDisplay] = useState([]);

  setPageView('Itinerary');
  setPage('itinerary');

  const {
    isCenterModalVisible,
    modalChildrenType,
  } = globalStateReducer;

  const handleAddCrewMember = () => {
    dispatch(openCenterModal('crew'));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const handleOpenCenterModal = (type) => {
    dispatch(openCenterModal(type));
  };

  const handleManageCrewMembers = () => {
    dispatch(openCenterModal('manage-crew'));
  };

  const onIdeaBoardSplitScreenClick = () => {
    history.push(`/journeys/${journeyID}/ideas-with-itinerary`);
  };

  const onSurveyResultsSplitScreenClick = (formData) => {
    const { surveyID } = formData;

    handleCloseModal();
    handleCloseDrawer();
    history.push(`/journeys/${journeyID}/surveys-with-itinerary/${surveyID}`);
  };

  const handleAddFolderFinish = async (formData) => {
    const { title } = formData;

    dispatch(setModalLoadingState(true));
    await addJourneyFolder(journeyID, title);
    dispatch(setModalLoadingState(false));

    // refetch();
    handleCloseModal();
  };

  const handleUpdateDateRanges = async (formData) => {
    const { startingDate, endingDate } = formData;
    const dateRangeStart = moment(startingDate).format(DATE_FORMAT);
    const dateRangeEnd = moment(endingDate).format(DATE_FORMAT);
    const newItinerary = {
      ...itinerary, dateRangeStart, dateRangeEnd, itineraryQuickAddItems: [],
    };

    updateJourney({ itinerary: newItinerary, loader: false });

    const attributes = {
      dateRangeStart: startingDate,
      dateRangeEnd: endingDate,
    };

    dataLayerUtil('Updated Itinerary Dates', attributes);
    handleCloseModal();
  };

  const handleOnIsPublicChange = (value) => {
    const newItinerary = {
      ...itinerary,
      isPublic: value,
    };

    updateJourney({ itinerary: newItinerary, loader: false });
  };

  let ModalChildren;
  let Modal = null;
  switch (modalChildrenType) {
    case 'crew':
      Modal = (
        <ManageCrewModal journeyID={journeyID} />
      );
      break;

    case 'edit-dates':
      const { dateRangeStart, dateRangeEnd } = itinerary || {};
      Modal = (
        <ItineraryAddDatesModal
          onClose={handleCloseModal}
          showModal={isCenterModalVisible}
          onFinish={handleUpdateDateRanges}
          startingDate={dateRangeStart}
          endingDate={dateRangeEnd}
        />
      );
      break;

    case 'select-survey-results':
      ModalChildren = (
        <SelectSurveyResult
          surveys={surveys}
          onClose={handleCloseModal}
          onFinish={onSurveyResultsSplitScreenClick}
        />
      );
      break;

    case 'folder':
      Modal = (
        <AddFolderModal
          onFinish={handleAddFolderFinish}
          onClose={handleCloseModal}
          showModal={isCenterModalVisible}
        />
      );
      break;

    case 'manage-crew':
      Modal = (
        <CenterModal
          title={`People in this Journey (${crewList.length})`}
          showModal={isCenterModalVisible}
          handleOnClose={handleCloseModal}
          children={(
            <Row justify="center" align="middle">
              <Col span={24}>
                <CrewListContainer>
                  <CrewList
                    list={[...crewList]}
                    onRemove={removeCrewMember}
                    removeConfirmText="Remove crew member?"
                  />
                </CrewListContainer>
              </Col>
            </Row>
            )}
          footerButtons={[
            <Button onClick={handleAddCrewMember}>
              <PlusOutlined />
              Add more Crew Members
            </Button>,
          ]}
        />
      );
      break;

    case 'share-itinerary':
      const publicItineraryUrl = `${window.location.origin}/public/itinerary/${journeyID}`;
      Modal = (
        <ShareItineraryModal
          onClose={handleCloseModal}
          showModal={isCenterModalVisible}
          downloadButtonText={(
            <PDFDownloadLink
              document={<PDFView ideas={ideas} data={itinerary} key={journeyID} />}
              fileName={`${title}`}
              style={{
                color: 'var(--default)',
              }}
            >
              {({ loading }) => (loading ? 'Loading...' : 'Download it')}
            </PDFDownloadLink>
          )}
          onIsPublicChange={handleOnIsPublicChange}
          isPublic={itinerary.isPublic}
          iframe={`<iframe width="1000" height="1000" src="${publicItineraryUrl}" title="${title}" frameborder="0"></iframe>`}
          url={publicItineraryUrl}
        />
      );
      break;

    default:
      ModalChildren = null;
  }

  const hasData = itinerary?.dateRangeStart && itinerary?.dateRangeEnd;
  const isSplitScreen = !!splitScreenSide;
  const isNarrow = isSplitScreen || width < 1200;

  const handleCalendarDateChange = (data) => {
    const { selection: { endDate } } = data;
    const formattedDate = moment(endDate).utc().format(DATE_FORMAT);
    setSelectedDay(formattedDate);
  };

  const handleOnDayFocus = (dayRange) => {
    setSelectedDayRangeDisplay(dayRange);
  };

  const calendarDateRange = [];

  const formatCalendarDate = (dateString) => {
    // needs to be UTC, otherwise users in distant timezones will show the wrong date
    const utcDateString = new Date(dateString).toUTCString();
    const day = new Date(utcDateString).getUTCDate();
    return new Date(utcDateString).setDate(day);
  };

  if (itinerary?.dateRangeStart && itinerary?.dateRangeEnd) {
    calendarDateRange.push({
      startDate: formatCalendarDate(itinerary?.dateRangeStart),
      endDate: formatCalendarDate(itinerary?.dateRangeEnd),
      key: 'selection',
    });
  }

  if (calendarDateRange?.length) {
    calendarDateRange.push({
      startDate: formatCalendarDate(selectedDayRangeDisplay[0]),
      endDate: formatCalendarDate(selectedDayRangeDisplay[1]),
      key: 'selection',
    });
  }
  return (
    <>
      <TopBar
        sectionTitle="Itinerary"
        onManageCrewMembers={handleManageCrewMembers}
        tripTitle={title}
        myCrewUsers={crewList || []}
        onAddCrewMember={handleAddCrewMember}
        onShareClick={hasData ? () => handleOpenCenterModal('share-itinerary') : null}
        splitScreenSide={splitScreenSide}
        splitScreenOptions={[
          {
            key: 'ideas',
            name: 'Idea Board',
            onClick: onIdeaBoardSplitScreenClick,
          },
          {
            key: 'survey-results',
            name: 'Survey Results',
            onClick: () => handleOpenCenterModal('select-survey-results'),
          },
        ]}
      />
      <PageScrollContainer splitScreenSide={splitScreenSide}>
        <Row wrap={isNarrow} style={{ margin: '16px' }}>
          {!!itinerary?.dateRangeStart && !!itinerary?.dateRangeEnd && (
            isNarrow ? (
              <Col span={24}>
                <Row justify="center" style={{ margin: '28px 0 8px 0' }}>
                  <ItineraryDateButton
                    itinerary={itinerary}
                    onClick={() => handleOpenCenterModal('edit-dates')}
                  />
                </Row>
              </Col>
            ) : (
              <Col flex={`0 0 ${isCalendarVisible ? '400px' : '50px'}`} style={{ paddingRight: '40px' }}>
                <div style={{ position: 'sticky', top: '0' }}>
                  <Row justify="center" gutter={[16, 24]} style={{ padding: '16px 20px 8px 0', marginTop: '-16px' }}>
                    <Col>
                      <Tooltip title={isCalendarVisible ? 'Hide Calendar' : 'Show Calendar'}>
                        <div>
                          <Button type="default" onClick={() => setIsCalendarVisible(!isCalendarVisible)}>
                            {isCalendarVisible
                              ? <EyeInvisibleOutlined style={{ fontSize: '1.5rem', lineHeight: '0' }} />
                              : <CalendarOutlined style={{ fontSize: '1.5rem', lineHeight: '0' }} />}
                          </Button>
                        </div>
                      </Tooltip>
                    </Col>
                    {isCalendarVisible && (
                    <ItineraryDateButton
                      itinerary={itinerary}
                      onClick={() => handleOpenCenterModal('edit-dates')}
                    />
                    )}
                    {isCalendarVisible && (
                    <Col span={24}>
                      <Calendar
                        calendarDateRange={calendarDateRange}
                        calendarColorRange={['var(--accent)', 'var(--accent-variant)']}
                        onChange={handleCalendarDateChange}
                      />
                    </Col>
                    )}
                  </Row>
                </div>
              </Col>
            ))}
          <Col flex={1} style={{ width: 'calc(60% - 48px)' }}>
            {hasData ? (
              <div style={{ position: 'relative' }}>
                <div style={{ margin: isNarrow ? '20px 0px 88px 44px' : '20px 0 88px 0' }}>
                  <ItineraryCardContainer
                    useJourneyData={useJourneyData}
                    folders={folders}
                    selectedDay={selectedDay}
                    onDayFocus={handleOnDayFocus}
                    isSplitScreen={isSplitScreen}
                  />
                </div>
              </div>
            ) : (
              <ItineraryIntroPage
                onFinish={handleUpdateDateRanges}
              />
            )}
          </Col>
        </Row>
      </PageScrollContainer>

      {isCenterModalVisible && ModalChildren ? (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={modalLoading ? <ModalSpinner /> : ModalChildren}
          modalWidth={isMobile ? '100%' : '700px'}
        />
      ) : isCenterModalVisible && Modal ? Modal : null}
    </>
  );
};

export default ItineraryPage;
