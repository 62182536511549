import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { selectJourneysState } from '@context/journeys/journeysSlice';
import JourneyCardContainer from '@components/containers/JourneyCardContainer';
import Layout from '@components/Layout';
import useUser from '@hooks/useUser';
import { useTracking } from '@hooks/useTracking';
import { Typography } from '@travelpassero/khaos';
import PageScrollContainer from './PageScrollContainer';

const JourneyPageContainer = () => {
  const { setPage } = useTracking();
  const { journeys, status } = useSelector(selectJourneysState);

  const userData = useUser();
  const { email } = userData;

  useEffect(() => {
    if (status === 'idle') {
      window.analytics.identify(email, {
        ...userData,
      });
    }
  }, [status]);

  setPage('journeys');

  return (
    <Layout backgroundColor="var(--accent)">
      <PageScrollContainer footerTextType="whiteText">
        <Typography
          type="whiteHeading"
          style={{
            color: 'var(--white)',
            textAlign: 'center',
            paddingTop: '46px',
          }}
        >
          My Journeys
        </Typography>
        <JourneyCardContainer
          journeys={journeys?.map((journey) => ({ pk: journey.pk, ...journey.data, updatedAt: journey.updatedAt })) || []}
        />
      </PageScrollContainer>
    </Layout>
  );
};

export default JourneyPageContainer;
