import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getJourneysThunk,
  duplicateJourneyThunk,
  deleteJourneyThunk,
  createJourneyThunk,
  removeCrewUserFromJourneyThunk,
  addCrewMembersToJourneyThunk,
} from '@context/journeys/journeysThunk';
import { updateJourneyThunk } from '@context/journey/journeyThunk';
import {
  createJourneyReducer,
  leaveJourneyReducer,
  selectJourneysState,
  updateJourneyByIndexReducer,
} from '@context/journeys/journeysSlice';
import useUser from '@hooks/useUser';
import { selectFriendState } from '@context/friend/friendSlice';

const useJourneys = () => {
  const dispatch = useDispatch();
  const userData = useUser();
  const {
    email, iconColor, firstName, lastName, userID,
  } = userData || {};
  const { journeys } = useSelector(selectJourneysState);
  const { friends, sentFriendRequests } = useSelector(selectFriendState);

  const getJourneyIndex = (journeyID) => journeys.findIndex((journey) => journey.pk === journeyID);

  const getJourneys = (params) => {
    const { loader } = params || {};
    dispatch(getJourneysThunk({ refetch: false, loader, modalLoader: false }));
  };

  const createJourney = (journeyAttributes) => {
    const uid = uuidv4();

    journeyAttributes.ideas = [];
    journeyAttributes.folders = [];
    journeyAttributes.surveys = [];
    journeyAttributes.crew = [
      {
        id: userID, email, iconColor, firstName, lastName,
      },
    ];
    journeyAttributes.creator = userID;

    const localJourney = {
      pk: uid,
      data: { ...journeyAttributes },
    };

    dispatch(createJourneyThunk({ pk: uid, ...journeyAttributes }));
    dispatch(createJourneyReducer(localJourney));
    return uid;
  };

  const updateJourneyByID = ({ journeyID, ...params }) => {
    const journey = { id: journeyID, ...params };
    const journeyIndex = getJourneyIndex(journeyID);
    dispatch(updateJourneyByIndexReducer({ journeyIndex, params }));
    dispatch(updateJourneyThunk(journey));
  };

  const duplicateJourney = ({ journeyID }) => {
    const uid = uuidv4();

    dispatch(duplicateJourneyThunk({ journeyID, pk: uid }));
  };

  const leaveJourney = ({ journeyID }) => {
    dispatch(leaveJourneyReducer({ journeyID }));
    const journey = journeys.find((journey) => journey.pk === journeyID);

    if (journey) {
      const { data: { crew } } = journey;

      // only delete a journey and photos if only the current user is in that journey
      if (crew?.length < 2) {
        dispatch(deleteJourneyThunk({ journeyID }));
      // only remove the user from the journey. other crew members should still see it on their boards.
      } else {
        const newCrew = [...crew.filter((crewMember) => crewMember.id !== userID)];
        dispatch(updateJourneyThunk({ id: journeyID, crew: newCrew }));
        dispatch(removeCrewUserFromJourneyThunk({ journeyID, crewID: userID }));
      }
    }
  };

  const addCrewUsersToJourney = ({ journeyID, crewIDs }) => {
    const journeyIndex = getJourneyIndex(journeyID);
    const { data: { crew } } = journeys[journeyIndex];

    const users = [];

    crewIDs.forEach((crewID) => {
      const currentCrewMember = crew.find((crewMember) => crewMember.id === crewID);

      if (!currentCrewMember) {
        let user = friends.find((friend) => friend.id === crewID);
        if (!user) user = sentFriendRequests.find((pendingFriend) => pendingFriend.id === crewID);
        users.push(user);
      }
    });

    updateJourneyByID({ journeyID, crew: [...crew, ...users] });
    dispatch(addCrewMembersToJourneyThunk({ journeyID, crewMemberIds: crewIDs }));
  };

  return {
    getJourneys,
    createJourney,
    updateJourneyByID,
    duplicateJourney,
    leaveJourney,
    addCrewUsersToJourney,
  };
};

export default useJourneys;
