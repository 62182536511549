import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Tooltip,
} from 'antd';
import { Button, Typography } from '@travelpassero/khaos';

function NameSection({ firstName, lastName }) {
  const [isShareUrlCopied, setIsShareUrlCopied] = useState(false);

  const handleClipboardCopy = (value) => {
    setIsShareUrlCopied(true);
    navigator.clipboard.writeText(value || '');
  };

  useEffect(() => {
    if (isShareUrlCopied) {
      setTimeout(() => {
        setIsShareUrlCopied(false);
      }, 5_000);
    }
  }, [isShareUrlCopied]);

  return (
    <Row wrap={false} align="middle" gutter={[8, 0]} style={{ marginRight: '60px' }}>
      <Col flex="0 1">
        <Typography
          type="secondarySubHeading"
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {`${firstName} ${lastName}`}
          </div>
        </Typography>
      </Col>
      <Col flex="none" style={{ height: '40px', alignItems: 'center', display: 'flex' }}>
        {isShareUrlCopied ? (
          <Typography type="defaultText" size=".875rem">Copied to Clipboard!</Typography>
        ) : (
          <Tooltip title="Share">
            <div>
              <Button
                onClick={() => handleClipboardCopy(window.location.href)}
                type="greyShare"
              />
            </div>
          </Tooltip>
        )}
      </Col>
    </Row>
  );
}

export default NameSection;
