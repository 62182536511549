import React from 'react';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

function Spinner({ size, color }) {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: size, color: color || 'var(--secondary)' }}
      spin
    />
  );
  return <Spin indicator={antIcon} />;
}

export default Spinner;
