import { createSlice } from '@reduxjs/toolkit';
import {
  getJourneysThunk, getSampleJourneysThunk, duplicateJourneyThunk,
} from '@context/journeys/journeysThunk';

const initialState = {
  status: 'idle',
  journeys: [],
  loading: true,
  error: null,
  refetch: false,
  creators: [],
  modalLoading: false,
  duplicateJourneyStatus: null,
  sampleJourneys: [],
  sampleJourneysStatus: 'idle',
};

export const journeysSlice = createSlice({
  name: 'journeys',
  initialState,
  reducers: {
    createJourneyReducer(state, { payload }) {
      const journeys = [...state.journeys];
      journeys.unshift(payload);
      state.journeys = journeys;
    },
    updateJourneyByIndexReducer(state, { payload }) {
      const { journeyIndex, params } = payload;
      state.journeys[journeyIndex].data = { ...state.journeys[journeyIndex].data, ...params };
    },
    leaveJourneyReducer(state, { payload }) {
      const { journeyID } = payload;
      state.journeys = state.journeys.filter((journey) => journey.pk !== journeyID);
    },
    resetDuplicateJourneyStatusReducer(state) {
      state.sentFriendRequestStatus = null;
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getJourneysThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getJourneysThunk.fulfilled, (state, { payload }) => {
        state.journeys = payload.journeys;
        state.creators = payload.creators;
        state.crews = payload.crews;
        state.status = 'fulfilled';
      })
      .addCase(getJourneysThunk.rejected, ((state) => {
        state.status = 'rejected';
      }))
      .addCase(duplicateJourneyThunk.pending, (state) => {
        state.duplicateJourneyStatus = 'pending';
      })
      .addCase(duplicateJourneyThunk.fulfilled, (state) => {
        state.duplicateJourneyStatus = 'fulfilled';
      })
      .addCase(duplicateJourneyThunk.rejected, ((state) => {
        state.duplicateJourneyStatus = 'rejected';
      }))
      .addCase(getSampleJourneysThunk.pending, (state) => {
        state.sampleJourneysStatus = 'pending';
      })
      .addCase(getSampleJourneysThunk.fulfilled, (state, { payload }) => {
        state.sampleJourneys = payload.journeys;
        state.sampleJourneysStatus = 'fulfilled';
      })
      .addCase(getSampleJourneysThunk.rejected, ((state) => {
        state.sampleJourneysStatus = 'rejected';
      }))
      .addMatcher((action) => action.type.endsWith('Journeys/rejected'),
        (state, { error }) => {
          state.error = error;
        });
  }),
});

export const selectJourneysState = (state) => state.journeys;

export const {
  createJourneyReducer,
  updateJourneyByIndexReducer,
  leaveJourneyReducer,
  resetDuplicateJourneyStatusReducer,
} = journeysSlice.actions;

export default journeysSlice.reducer;
