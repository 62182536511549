import React from 'react';
import {
  DrawerModal, SettingsForm,
} from '@travelpassero/khaos';
import { Layout as AntLayout, Row, Col } from 'antd';
import { useWindowSize } from '@hooks/useWindowSize';
import { useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import useUser from '@hooks/useUser';
import { selectUserState } from '@context/user/userSlice';
import useLayout from '@hooks/useLayout';
import NotificationsModal from './NotificationsModal';
import TopNav from './TopNav';
import LeftNavContainer from './LeftNavContainer';

const Layout = ({
  children, backgroundColor,
}) => {
  const { isMobile } = useWindowSize();
  const { updateSettings, passwordNotValid, cognitoErrorMessage } = useUser();
  const { handleCloseDrawer } = useLayout();
  const { globalStateReducer } = useSelector(selectAppState);
  const { user } = useSelector(selectUserState);

  const {
    drawerChildrenType,
    isDrawerVisible,
    isCenterModalVisible,
    modalChildrenType,
  } = globalStateReducer;

  const handleUpdateSettings = async (formData) => {
    updateSettings(formData);
  };

  const handleSignOut = async () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.reload();
  };

  let Modal;
  switch (modalChildrenType) {
    case 'notifications':
      Modal = (
        <NotificationsModal />
      );
      break;

    default:
      Modal = null;
  }

  let DrawerChildren;
  switch (drawerChildrenType) {
    case 'settings':
      DrawerChildren = (
        <SettingsForm
          onFinish={(val) => handleUpdateSettings(val)}
          userInfo={user?.data}
          needsVerification={false}
          passwordNotValid={passwordNotValid}
          cognitoErrorMessage={cognitoErrorMessage}
          onSignOut={handleSignOut}
        />
      );
      break;

    default:
      DrawerChildren = null;
  }

  return (
    <AntLayout
      style={{
        minHeight: '100%',
        position: 'relative',
        backgroundColor,
        width: '100%',
      }}
    >
      <Row wrap={false}>
        {!isMobile && (
        <Col>
          <LeftNavContainer />
        </Col>
        )}
        <Col flex="auto">
          {isMobile && <TopNav />}
          {children}
        </Col>
      </Row>

      {isCenterModalVisible && Modal}
      {isDrawerVisible && DrawerChildren ? (
        <DrawerModal
          handleOnClose={handleCloseDrawer}
          showModal={isDrawerVisible}
          children={DrawerChildren}
          maxWidth="600px"
        />
      ) : null}

    </AntLayout>
  );
};

export default Layout;
