import {
  Form, Row, Col,
} from 'antd';
import styled from 'styled-components';
import {
  UploadOutlined,
} from '@ant-design/icons';
import {
  Typography,
  Button,
} from '@travelpassero/khaos';
import { v4 as uuidv4 } from 'uuid';
import useUser from '@hooks/useUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectPhotoState, selectPhotoReducer } from '@context/photo/photoSlice';
import { deleteFileThunk, uploadFileThunk } from '@context/storage/storageThunk';
import { selectPublicState } from '@context/public/publicSlice';

const FormInputItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    input, .ant-input-search-button {
      height: 40px;
    }
  }
 .ant-form-item{
    margin-bottom: 20px !important;
  }

  .ant-picker {
    width: 100%;
  }
`;

const ButtonContainerStyled = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 450px;
`;

const BackgroundPreviewImg = styled.img`
  width: 400px;
  height: 100%;
  border-radius: 16px;
  object-fit: cover;
  margin-top: 10px;
`;

function ProfileBackgroundForm({
  onOpenUploadModal,
}) {
  const { updateProfile } = useUser();
  const { profile } = useSelector(selectPublicState);
  const { profileBackgroundData } = profile || {};
  const { imgSrcKey } = profileBackgroundData || {};
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { photo } = useSelector(selectPhotoState);

  const postPhoto = async (folder) => {
    const { file } = photo;

    if (!file) return;

    return await dispatch(uploadFileThunk({
      key: `${folder}/${uuidv4()}.jpg`,
      file,
    })).unwrap();
  };

  const deleteOldPhoto = (key) => {
    dispatch(deleteFileThunk({
      key,
    }));
  };

  const handleOnFinish = async (formData) => {
    let { key } = photo;
    if (!key) { key = await postPhoto('profile/background'); }

    const formattedData = {
      ...formData,
    };

    if (key) {
      formattedData.profileBackgroundData = {
        imgSrcKey: key,
      };
    }

    updateProfile(formattedData);
    deleteOldPhoto(imgSrcKey);
    dispatch(selectPhotoReducer({}));
  };

  return (
    <Row justify="center" align="middle" style={{ height: '80%' }}>
      <Col>
        <Row justify="center" style={{ padding: '0 24px' }}>
          <Col span={24} style={{ width: '100%', padding: '68px 0 24px 0', textAlign: 'center' }}>
            <Typography
              size="1.5rem"
              type="primaryHeading"
            >
              Edit your Profile Background
            </Typography>
          </Col>
          <Col style={{ width: '100%' }}>
            <FormContainer>
              <Form form={form} name="control-hooks" onFinish={handleOnFinish}>

                <FormInputItem style={{ flex: 1 }}>
                  <Row justify="center" align="middle">
                    <Col>
                      <Button style={{ marginTop: 10 }} onClick={onOpenUploadModal}>
                        <UploadOutlined />
                        Upload an image
                      </Button>
                    </Col>
                  </Row>
                  <Row justify="center" align="middle">
                    <Col>
                      {!!photo.src && <BackgroundPreviewImg src={photo.src} alt="" />}
                    </Col>
                  </Row>
                </FormInputItem>
                <ButtonContainerStyled
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                </ButtonContainerStyled>
              </Form>
            </FormContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ProfileBackgroundForm;
