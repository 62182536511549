import React, { useState } from 'react';
import styled from 'styled-components';
import * as R from 'remeda';
import { useHistory } from 'react-router-dom';
import { PlusOutlined, RollbackOutlined } from '@ant-design/icons';
import { Col, Form, Row } from 'antd';
import {
  TopBar,
  AnswerSurveySimple,
  AnswerSurveyTiles,
  AnswerSurveyBinary,
  AnswerSurveyMultiple,
  AnswerSurveyDates,
  Button,
  CenterModal,
  CalendarExpanded,
  Typography,
  Empty,
} from '@travelpassero/khaos';

import PageSpinner from '@components/PageSpinner';
import MultiImageContainer from '@components/renderContainers/MultiImageContainer';
import ManageCrewModal from '@components/sharedComponentContainers/ManageCrewModal';
import CrewList from '@components/sharedComponentContainers/CrewList';
import useJourneyCrew from '@hooks/useJourneyCrew';

import {
  closeModal,
  openCenterModal,
} from '@context/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import { useJourney } from '@hooks/useJourney';

import useUser from '@hooks/useUser';
import useSurvey from '@hooks/useSurvey';
import { useWindowSize } from '@hooks/useWindowSize';
import PageScrollContainer from './PageScrollContainer';

const CrewListContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`;

export const FlexCardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardContainer = styled.div`
  width: 100%;
  padding: 18px 0;
  display: flex;
  justify-content: center;
`;

const SurveyAnswerPage = ({ journeyID, splitScreenSide }) => {
  const history = useHistory();
  const { isMobile } = useWindowSize();
  const {
    data, ideas, title, surveys, updateJourney,
  } = useJourney(journeyID);
  const { crewList, removeCrewMember } = useJourneyCrew(journeyID);
  const { getCurrentSurvey } = useSurvey(data);
  const dispatch = useDispatch();
  const { globalStateReducer } = useSelector(selectAppState);
  const { userID } = useUser();

  const handleManageCrewMembers = () => {
    dispatch(openCenterModal('manage-crew'));
  };

  const selectedSurvey = getCurrentSurvey();

  const {
    modalChildrenType,
    isCenterModalVisible,
    modalSelectedID,
    modalLoadingState,
  } = globalStateReducer;

  if (selectedSurvey) {
    const { questions, completed } = selectedSurvey;

    const [form] = Form.useForm();

    const getTileQuestions = questions.filter((item) => item.type === 'tiles');

    const choiceIDs = getTileQuestions?.map((question) => {
      const { choices } = question;
      return choices.map((i) => i.ideaID);
    }).flat();

    const choiceIdeas = ideas.filter((idea) => choiceIDs?.includes(idea.id));

    const dataLength = choiceIdeas.length;

    const [ideaStatus, setIdeaSatus] = useState({});
    const [ideaStatusLength] = useState(dataLength);

    const [ifError, setIfError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const handleAnswerSurveySubmit = async (formData) => {
      const surveysUpdated = surveys?.map((survey) => {
        if (survey.id === formData.id) {
          return {
            ...survey,
            ...formData,
          };
        }
        return survey;
      }) || [];
      updateJourney({ surveys: surveysUpdated });
    };

    const handleCloseModal = () => {
      dispatch(closeModal());
    };

    const handleSurveysBackClick = () => {
      history.push(`/journeys/${journeyID}/surveys`);
    };

    const handleOnCalendarExpandClick = (questionID) => {
      dispatch(openCenterModal('calendar', questionID));
    };

    const handleAddCrewMember = () => {
      dispatch(openCenterModal('crew'));
    };

    const handleOnDislikeClick = (id) => {
      setIdeaSatus((prevState) => ({
        ...prevState,
        [id]: 'dislike',
      }));
    };

    const handleOnLikeClick = (id) => {
      setIdeaSatus((prevState) => ({
        ...prevState,
        [id]: 'like',
      }));
    };

    const onFinish = async (formData) => {
      setIfError(false);
      setErrorMessage('');

      const IdeaLength = Object.keys(ideaStatus);

      if (ideaStatusLength !== IdeaLength.length) {
        setIfError(true);
        setErrorMessage('Please vote on all tiles.');
        return;
      }

      const questionTypes = R.groupBy(Object.keys(formData), (x) => {
        const sortKey = x.match(new RegExp('question-(.*)_'));
        return sortKey && sortKey[1];
      });

      const updatedQuestions = [...questions].map((item, index) => {
        let responseType;
        let response;

        switch (item.type) {
          case 'simple':
            responseType = 'response';
            response = formData[questionTypes[index + 1][0]];
            break;

          case 'tiles':
            responseType = 'responseIdeas';
            const questionsIDs = item.choices.map((choice) => choice.ideaID);
            const filterStatuses = Object.keys(ideaStatus).filter((item) => questionsIDs.includes(item));

            response = filterStatuses.map((idea) => ({
              ideaID: idea,
              status: ideaStatus[idea],
            }));

            break;

          case 'date':
            responseType = 'responseDateRange';
            const dateRanges = questionTypes[index + 1];

            const responseMessage = dateRanges
            && dateRanges.map((item) => ({
              dateRange: formData[item],
            }));

            response = responseMessage;

            break;

          case 'checkbox':
            responseType = 'response';
            response = formData[questionTypes[index + 1][0]];
            break;

          case 'multiple':
            const multipleAnswers = questionTypes[index + 1];

            const filteredAnswers = multipleAnswers
            && multipleAnswers.filter((id) => formData[id]);

            const responseIDs = filteredAnswers
            && filteredAnswers.map((answer) => {
              const split = answer.split('_multiple-');

              return split[1];
            });

            responseType = 'responseOptions';
            response = responseIDs;
            break;
          default:
        }

        const questionResponse = {
          userID,
          [responseType]: response,
        };

        const previousResponses = item.responses
          ? [...item.responses, questionResponse]
          : [questionResponse];

        return {
          ...item,
          responses: previousResponses,
        };
      });

      const updatedCompletedUser = completed
        ? [...completed, userID]
        : [userID];

      const updatedSurvey = {
        ...selectedSurvey,
        questions: updatedQuestions,
        completed: updatedCompletedUser,
      };

      await handleAnswerSurveySubmit(updatedSurvey);
    };

    const getSelectedQuestionData = (id) => questions && questions.find((q) => q.id === id);

    let ModalChildren;
    let Modal;
    let modalWidth = '500px';
    const selectedQuestionData = getSelectedQuestionData(modalSelectedID);

    switch (modalChildrenType) {
      case 'calendar':
        const { choices } = selectedQuestionData;

        const calendarDateRange = choices
        && choices.map((item) => {
          const startDate = item && item.dateRange && new Date(item.dateRange[0]);
          const endDate = item && item.dateRange && new Date(item.dateRange[1]);

          return {
            startDate,
            endDate,
          };
        });

        ModalChildren = (
          <CalendarExpanded
            calendarDateRange={calendarDateRange}
            calendarColorRange={['var(--accent)']}
          />
        );
        modalWidth = '1150px';
        break;

      case 'manage-crew':
        Modal = (
          <CenterModal
            title={`People in this Journey (${crewList.length})`}
            showModal={isCenterModalVisible}
            handleOnClose={handleCloseModal}
            children={(
              <Row justify="center" align="middle">
                <Col span={24}>
                  <CrewListContainer>
                    <CrewList
                      list={[...crewList]}
                      onRemove={removeCrewMember}
                      removeConfirmText="Remove crew member?"
                    />
                  </CrewListContainer>
                </Col>
              </Row>
                )}
            footerButtons={[
              <Button onClick={handleAddCrewMember}>
                <PlusOutlined />
                Add more Crew Members
              </Button>,
            ]}
          />
        );
        break;

      case 'crew':
        Modal = (
          <ManageCrewModal journeyID={journeyID} />
        );
        break;

      default:
        ModalChildren = null;
    }

    return (
      <>
        <TopBar
          sectionTitle="Answer Survey"
          tripTitle={title}
          onManageCrewMembers={handleManageCrewMembers}
          myCrewUsers={crewList || []}
          onAddCrewMember={handleAddCrewMember}
          splitScreenSide={splitScreenSide}
        />
        <PageScrollContainer splitScreenSide={splitScreenSide}>
          <div style={{ paddingTop: '40px' }} />
          <Button
            type="default"
            style={{ marginLeft: '46px' }}
            onClick={handleSurveysBackClick}
          >
            <Typography type="primaryText">Back to all surveys</Typography>
            <RollbackOutlined />
          </Button>
          <div style={{ textAlign: 'center', marginBottom: '24px' }}>
            <Typography size="1.8rem" type="primaryHeading">
              {selectedSurvey.title}
            </Typography>
          </div>

          <Form form={form} name="answer-survey" onFinish={onFinish}>
            <FlexCardContainer>
              {questions.map((item, index) => {
                const {
                  title, type, choices, id,
                } = item;

                let Component;

                switch (type) {
                  case 'simple':
                    Component = (
                      <AnswerSurveySimple
                        questionNumber={index + 1}
                        title={title}
                        style={{ backgroundColor: 'white' }}
                      />
                    );
                    break;

                  case 'checkbox': // <-- these are actually radio buttons
                    Component = (
                      <AnswerSurveyBinary
                        key={`${title}_${index}`}
                        questionNumber={index + 1}
                        title={title}
                        choices={choices}
                        style={{ backgroundColor: 'white' }}
                      />
                    );
                    break;

                  case 'multiple':
                    Component = (
                      <AnswerSurveyMultiple
                        questionNumber={index + 1}
                        title={title}
                        choices={choices}
                        style={{ backgroundColor: 'white' }}
                      />
                    );
                    break;

                  case 'date':
                    const calendarDateRange = choices
                    && choices.map((item) => {
                      const startDate = item && item.dateRange && new Date(item.dateRange[0]);
                      const endDate = item && item.dateRange && new Date(item.dateRange[1]);

                      return {
                        startDate,
                        endDate,
                      };
                    });

                    const colorRange = [...Array(calendarDateRange.length)].map(
                      () => 'var(--accent)',
                    );

                    Component = (
                      <AnswerSurveyDates
                        questionNumber={index + 1}
                        title={title}
                        onCalendarExpandClick={() => handleOnCalendarExpandClick(id)}
                        calendarDateRange={calendarDateRange}
                        calendarColorRange={colorRange}
                        style={{ backgroundColor: 'white' }}
                      />
                    );
                    break;

                  case 'tiles':
                    const choiceIDs = choices.map((i) => i.ideaID);
                    const choiceIdeas = ideas.filter((idea) => choiceIDs.includes(idea.id));

                    const dataLength = choiceIdeas.length;

                    Component = (
                      <MultiImageContainer
                        dataWithKeys={choiceIdeas}
                        dataChanged={dataLength}
                        render={({ dataWithImgUrls }) => (
                          <AnswerSurveyTiles
                            questionNumber={index + 1}
                            title={title}
                            ideas={dataWithImgUrls}
                            handleOnDislikeClick={handleOnDislikeClick}
                            handleOnLikeClick={handleOnLikeClick}
                            style={{ backgroundColor: 'white' }}
                          />
                        )}
                      />
                    );
                    break;

                  default:
                    Component = null;
                }

                if (modalLoadingState) {
                  return <PageSpinner />;
                }

                return (
                  <CardContainer key={`${title}_${id}`}>
                    {Component}
                  </CardContainer>
                );
              })}
            </FlexCardContainer>

            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '32px',
              }}
            >
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
            {ifError ? (
              <Typography
                size="1rem"
                style={{
                  display: 'block',
                  textAlign: 'center',
                  paddingTop: '12px',
                }}
                type="errorText"
              >
                {errorMessage}
              </Typography>
            ) : null}
          </Form>

          {isCenterModalVisible && ModalChildren ? (
            <CenterModal
              footer={null}
              handleOnClose={handleCloseModal}
              showModal={isCenterModalVisible}
              children={ModalChildren}
              modalWidth={isMobile ? '100%' : modalWidth}
            />
          ) : isCenterModalVisible && Modal ? Modal : null}
        </PageScrollContainer>
      </>
    );
  }

  return (
    <Row justify="center" gutter={[0, 32]} style={{ paddingTop: '90px' }}>
      <Col>
        <Empty
          heading="The selected survey was not found."
        />
      </Col>
    </Row>
  );
};

export default SurveyAnswerPage;
