import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Tooltip } from 'antd';
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import { Button } from '@travelpassero/khaos';
import bannerPlaceholderImage from '../../assets/banner-placeholder.jpg';

const CardContainer = styled(Card)`
padding: 16px 0 0 0;


&.ant-card {
  background:none;
  border:0;
}
&& .ant-card-body {
filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
position:relative;
border-radius:12px;
overflow:hidden;
  padding: 0;
}`;
const ImageContainer = styled.img`
width:100%;
min-height:130px;
max-height: 300px;
@media(max-width:767px){
  min-height:250px;
  object-fit:cover;
}
`;

const ImgOverlay = styled.div`
    position:absolute;
    width:100%;
    height:65px;
    top:0;
    left:0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.58) 100%);
    z-index:1;
`;

const EditButtonContainer = styled.div`
  position: absolute;
  top: 14px;
  right: 14px;
  z-index: 2;
  `;

const CardBanner = ({
  imgSrc, className, onEdit, isEditable,
}) => {
  const [isPhotoError, setIsPhotoError] = useState(false);

  const handlePhotoError = () => {
    setIsPhotoError(true);
  };

  useEffect(() => {
    setIsPhotoError(false);
  }, [imgSrc]);

  return (
    <CardContainer>
      {onEdit && <ImgOverlay />}
      <CardImageContainer
        imgSrcKey={imgSrc}
        render={(imgSrcUrl) => (isPhotoError ? (
          <ImageContainer className={className} src={bannerPlaceholderImage} alt="banner-placeholder" />
        ) : (
          <ImageContainer className={className} src={imgSrcUrl} alt="" onError={handlePhotoError} />
        ))}
      />
      {isEditable && (
        <EditButtonContainer>
          <Tooltip title="Edit">
            <div>
              <Button
                onClick={onEdit}
                type="greyEdit"
              />
            </div>
          </Tooltip>
        </EditButtonContainer>
      )}
    </CardContainer>
  );
};
export default CardBanner;
