import { useEffect, useState } from 'react';
import { DATE_FORMAT } from '@travelpassero/khaos';
import getDatesBetween from '@utils/getDatesBetween';
import { isCheckInOutIdea, isDepartingArrivingIdea, isPinnedItineraryIdea } from '@utils/ideaCategory';
import moment from 'moment';
import { sortIdeasByTime } from '@utils/sort';

export const useItinerary = (journeyData = {}) => {
  const { itinerary, ideas } = journeyData || {};

  const { dateRangeStart, dateRangeEnd, itineraryQuickAddItems } = itinerary || {};

  const [pinnedIdeas, setPinnedIdeas] = useState([]);
  const [allIdeas, setAllIdeas] = useState([]);

  useEffect(() => {
    setAllIdeas([...(ideas || []), ...(itineraryQuickAddItems || [])]);
  }, [ideas, itineraryQuickAddItems]);

  useEffect(() => {
    // update ideas that are pinned to top of itinerary day
    const ideasToPin = [];

    allIdeas.forEach((idea) => {
      const { ideaCategoryId } = idea;
      if (isPinnedItineraryIdea(ideaCategoryId)) {
        ideasToPin.push(idea);
      }
    });
    setPinnedIdeas(ideasToPin);
  }, [allIdeas]);

  const dateRanges = getDatesBetween(dateRangeStart, dateRangeEnd);

  const getIdeasThisDay = (day) => {
    const ideasThisDay = [...allIdeas].filter((idea) => {
      const ideaDay = moment(idea.itineraryDate, DATE_FORMAT).format(DATE_FORMAT);
      return ideaDay === day;
    }).map((idea) => {
      const { ideaCategoryId } = idea;
      const isCheckIn = isCheckInOutIdea(ideaCategoryId);

      const isDeparting = isDepartingArrivingIdea(ideaCategoryId);

      return {
        ...idea,
        title: idea.title && `${isCheckIn ? 'Check In:' : isDeparting ? 'Departing:' : ''} ${idea.title}`,
      };
    });

    // for items that have a startDate, we assume that they also have an endDate.
    // so we add a duplicate row to the itinerary view, to display the end date.
    allIdeas.forEach((idea) => {
      if (idea.endDate && moment(idea.endDate).format(DATE_FORMAT) === day) {
        const { ideaCategoryId } = idea;
        const isCheckOut = isCheckInOutIdea(ideaCategoryId);

        const isArriving = isDepartingArrivingIdea(ideaCategoryId);

        ideasThisDay.push({
          ...idea,
          title: idea.title && `${isCheckOut ? 'Check Out:' : isArriving ? 'Arriving:' : ''} ${idea.title}`,
          itineraryTime: idea.endTime,
          isEndIdea: true,
        });
      }
    });

    return ideasThisDay.sort(sortIdeasByTime);
  };

  return {
    pinnedIdeas,
    dateRangeStart,
    dateRangeEnd,
    dateRanges,
    getIdeasThisDay,
  };
};
