import React, {
  Fragment,
} from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import CardBanner from '@components/containers/CardBanner';
import {
  Empty, Typography, useWindowSize,
} from '@travelpassero/khaos';
import { Card, Col, Row } from 'antd';

import { useTracking } from '@hooks/useTracking';
import PageSpinner from '@components/PageSpinner';
import Carousel from '@components/containers/Carousel';
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import { useDispatch, useSelector } from 'react-redux';
import { getSampleJourneysThunk } from '@context/journeys/journeysThunk';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import { LeftCircleFilled, RightCircleFilled } from '@ant-design/icons';
import PublicJourneyCard from '@components/containers/PublicJourneyCard';
import PageScrollContainer from '@pages/PageScrollContainer';
import discoverRegions from '@utils/discoverRegions';
import discoverCategories from '@utils/discoverCategories';
import useOnPageLoad from '@hooks/useOnPageLoad';

const BannerContainer = styled.div`
  position: relative;
  max-width:1285px;
  width:100%;
  margin:0 auto;
  padding:0 16px
 `;
const BannerText = styled.div`
position: absolute;
top: 40px;
left: 70px;
@media(min-width:320px){
  .banner-text{
    max-width: 150px !important;
    font-size: .875rem !important;
  }
  .banner-text-top{
    font-size: .775rem !important;
    line-height: 2 !important;
  }
}
@media(min-width:992px){
  .banner-text-top{
    font-size: .875rem !important;
    line-height: 4 !important;
  }
  .banner-text{
    font-size: 2rem !important;
  }
}
`;

const CenterText = styled.div`
  text-align: left;
  padding-left: ${({ isMobile }) => (isMobile ? '0' : '40px')};
  padding-right: ${({ isMobile }) => (isMobile ? '0' : '30px')};
  padding-top: 48px;
`;

const JourneyRow = styled(Row)`
  display:flex; 
  flex-direction:column;
  gap: 32px;
  max-width:1350px;
  width:100%;
  margin: 0 auto;
  padding: 0 16px;

  @media(min-width:768px){
    padding:0 30px;
  }
`;

const CarouselContainer = styled.div`
.slick-track {
  display: flex;
  flex-wrap: nowrap;
  gap: 16px;
  padding: 16px 0;
}
`;

const RightArrow = styled(RightCircleFilled)`
  svg {
    width: 28px;
    height: 28px;
    fill: var(--black);
    opacity: 0.7;
  }
`;
const LeftArrow = styled(LeftCircleFilled)`
  svg {
    width: 28px;
    height: 28px;
    fill: var(--black);
    opacity: 0.7;
  }
`;

const CardInnerHeader = styled.a`
color: var(--white);
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 21px;
letter-spacing: 0.1px;
display:block;

&:hover {
  color: var(--white);
}
`;
const RegionCard = styled(Card)`
  position:relative;
  display:block;
  height:220px;
  overflow:hidden;
  border-radius:10px;

  .ant-card-cover {
    object-fit: cover;
    object-position: center;
    width:100%;
    height:100%;
    min-height: 220px;
  }

  .ant-card-body { 
    padding: 0px;
  }
`;
const ImgOverlay = styled.div`
    position:absolute;
    width:100%;
    height:100%;
    top:0;
    left:0;
    background: linear-gradient(transparent 0%, rgba(0, 0, 0, .35) 70%);
    z-index:1;
`;

const DiscoverPage = () => {
  const dispatch = useDispatch();
  const { isMobile } = useWindowSize();

  const { setPageView, setPage } = useTracking();
  setPageView('Discover');
  setPage('Discover');

  const {
    sampleJourneysStatus, sampleJourneys,
  } = useSelector(selectJourneysState);

  const onPageLoad = () => dispatch(getSampleJourneysThunk({}));
  const onCheckDataLoaded = () => !!sampleJourneys?.length;

  useOnPageLoad({ status: sampleJourneysStatus, onPageLoad, onCheckDataLoaded });

  const carouselSettings = {
    arrows: true,
    draggable: true,
    nextArrow: <RightArrow />,
    prevArrow: <LeftArrow />,
    infinite: true,
  };

  if (sampleJourneysStatus === 'pending') {
    return <PageSpinner />;
  }

  if (sampleJourneysStatus === 'rejected') {
    return (
      <PageScrollContainer>
        <Empty
          heading="Nothing could be discovered right now"
          subHeading="Please try again later"
        />
      </PageScrollContainer>
    );
  }

  return (
    <PageScrollContainer>
      <BannerContainer>
        <CardBanner imgSrc="discover/banner/banner-main.jpg" radius="16px" />
        <BannerText>
          <Typography
            className="banner-text-top"
            size=".975rem"
            type="whiteText"
            style={{ display: 'block', lineHeight: 4 }}
          >
            Jumpstart your planning
          </Typography>
          <Typography
            className="banner-text"
            size="2rem"
            type="whiteText"
            style={{
              lineHeight: 1,
            }}
          >
            Downloadable travel itineraries
            <span style={{ display: 'block', lineHeight: 1 }}>you can customize</span>
          </Typography>
        </BannerText>
      </BannerContainer>

      <JourneyRow>
        <Col>
          <CenterText isMobile={isMobile}>
            <Typography
              type="secondaryText"
              size="1.5rem"
              style={{
                fontWeight: 500,
                lineHeight: '39px',
              }}
            >
              Itineraries by Geography
            </Typography>
          </CenterText>
          <CarouselContainer style={{ padding: '0 25px' }}>
            <Carousel {...carouselSettings}>
              {discoverRegions
                .map((regionSection, index) => {
                  const { region, imgSrcKey, path } = regionSection;

                  const title = region.charAt(0).toUpperCase() + region.slice(1);
                  return (
                    <RegionCard hoverable bordered={false} key={`${title}-${index}`}>
                      <CardImageContainer
                        imgSrcKey={imgSrcKey}
                        render={(imgSrcUrl) => (
                          <CardInnerHeader href={`/discover/region/${path}`}>
                            <ImgOverlay />
                            <div
                              style={{
                                zIndex: 1,
                                position: 'absolute',
                                display: 'flex',
                                alignItems: 'end',
                                paddingBottom: '24px',
                                justifyContent: 'center',
                                height: '100%',
                                width: '100%',
                              }}
                            >
                              <Typography
                                type="whiteText"
                                size="1.875rem"
                                style={{
                                  textTransform: 'uppercase',
                                  fontWeight: 700,
                                }}
                              >
                                {title}
                              </Typography>
                            </div>
                            <img className="ant-card-cover" src={imgSrcUrl} alt={title} />
                          </CardInnerHeader>
                        )}
                      />
                    </RegionCard>
                  );
                })}
            </Carousel>
          </CarouselContainer>

          {discoverCategories.map((categoriesSection, index) => {
            const { categories, title, path } = categoriesSection;

            const categoryJourneys = sampleJourneys.filter((sampleJourney) => {
              const { data } = sampleJourney || {};
              const { category } = data || {};

              return category?.some((c) => categories.includes(c.toLowerCase()));
            });

            if (!categoryJourneys.length) return null;

            return (
              <Fragment key={`${title}-${index}`}>
                <CenterText isMobile={isMobile}>
                  <Row
                    justify={isMobile ? 'center' : 'space-between'}
                    align="middle"
                    gutter={16}
                    wrap={isMobile}
                  >
                    <Col
                      flex="auto"
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <Typography
                        type="secondaryText"
                        size="1.5rem"
                      >
                        {title}
                      </Typography>
                    </Col>
                    <Col flex="none">
                      <Link to={`/discover/category/${path}`}>
                        <Typography
                          type="accentText"
                          style={{
                            textDecoration: 'underline',
                          }}
                        >
                          View all
                        </Typography>
                      </Link>
                    </Col>
                  </Row>
                </CenterText>
                <CarouselContainer style={{ padding: '0 25px' }}>
                  <Carousel {...carouselSettings}>
                    {categoryJourneys
                      .map((categoryJourney) => {
                        const { pk } = categoryJourney;
                        return (
                          <PublicJourneyCard key={pk} publicJourney={categoryJourney} />
                        );
                      })}
                  </Carousel>
                </CarouselContainer>
              </Fragment>
            );
          })}
        </Col>
      </JourneyRow>
    </PageScrollContainer>
  );
};

export default DiscoverPage;
