import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button, Typography, Search,
} from '@travelpassero/khaos';
import {
  Col, Row, Menu, Tooltip, Dropdown, Popover,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';

const TopBarContainer = styled.div`
  background-color: var(--white);
  border-bottom: ${(props) => (props.isMobile ? 'none' : '1px solid')};
  border-color: var(--borders) !important;
  position: inherit;
  padding: 16px 24px 16px 24px;
  z-index: 1;
`;

const MenuItem = styled(Menu.Item)`
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
  font-size: 1rem;
  padding: 12px 16px;
`;

function JourneyCrewTopBar({
  onSearch, onSort, isMobile, viewType, showOptions = true,
}) {
  const [searchPlaceholder, setSearchPlaceholder] = useState('');
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);

  useEffect(() => {
    if (viewType === 'crew') {
      setSearchPlaceholder('Search for a crew member');
    } else {
      setSearchPlaceholder('Search for a journey');
    }
  }, [viewType]);

  const handleShowOptionsMenu = () => {
    setShowOptionsMenu(!showOptionsMenu);
  };

  const handleOnSearch = (event) => {
    const query = event?.target?.value || event;

    onSearch(query);
  };

  const handleOnSort = (event) => {
    const { key } = event;

    onSort(key);
  };

  const myFriendsMenu = (
    <Menu onClick={handleOnSort}>
      <MenuItem key="ascending">Ascending</MenuItem>
      <MenuItem key="descending">Descending</MenuItem>
    </Menu>
  );

  return (
    <TopBarContainer className="journey-crew-top-bar" isMobile={isMobile}>
      <Row justify="space-between" align="middle" gutter={[8]} wrap={false} style={{ height: '100%' }}>
        <Col>
          <Typography
            size={isMobile ? '1rem' : '1.25rem'}
            type="primaryHeading"
          >
            Journey Crew
          </Typography>
        </Col>
        <Col>
          <Row align="middle" gutter={[16]}>
            {showOptions && (
            <Col>
              <Popover
                trigger="click"
                placement="bottomRight"
                onVisibleChange={handleShowOptionsMenu}
                visible={showOptionsMenu}
                content={(
                  <Row
                    justify="start"
                    align="middle"
                    gutter={[16, 16]}
                    style={{ marginBottom: '-8px', maxWidth: '300px' }}
                  >
                    <Col span={24}>
                      <Search
                        placeholder={searchPlaceholder}
                        onSearch={handleOnSearch}
                        onChange={handleOnSearch}
                      />
                    </Col>
                    <Col span={24}>
                      <Dropdown overlay={myFriendsMenu}>
                        <div>
                          <Button
                            style={{
                              color: 'var(--primary)',
                              fontSize: '1em',
                              height: '40px',
                            }}
                          >
                            <Typography
                              type="primary"
                              size=".875rem"
                              style={{ marginRight: '42px' }}
                            >
                              Sort A-Z
                            </Typography>
                            <DownOutlined />
                          </Button>
                        </div>
                      </Dropdown>
                    </Col>
                  </Row>
                  )}
              >
                <Tooltip title="Options" placement="left">
                  <div>
                    <Button type="ellipsis" />
                  </div>
                </Tooltip>
              </Popover>
            </Col>
            )}
          </Row>
        </Col>
      </Row>
    </TopBarContainer>
  );
}

export default JourneyCrewTopBar;
