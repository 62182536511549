import React from 'react';
import styled from 'styled-components';
import { Col, Row } from 'antd';
import { Button } from '@travelpassero/khaos';

import useLayout from '@hooks/useLayout';

const Container = styled.div`
  border-left: 1px solid var(--borders);
  background-color: var(--white);
  box-shadow: inset 0px 3px 6px rgba(0, 0, 0, .07);
`;

const SplitScreenContainer = ({
  SplitScreenChildLeft, SplitScreenChildRight, closeSplitScreenPath,
}) => {
  const { handleCloseSplitScreen } = useLayout();

  return (
    <Row wrap={false}>
      <Col span={12}>
        {SplitScreenChildLeft}
      </Col>
      <Col span={12}>
        <Container>
          {SplitScreenChildRight}
          {closeSplitScreenPath && (
          <Button
            onClick={() => handleCloseSplitScreen(closeSplitScreenPath)}
            style={{ position: 'absolute', top: '24px', right: '24px' }}
            type="circleClose"
          />
          )}
        </Container>
      </Col>
    </Row>
  );
};

export default SplitScreenContainer;
