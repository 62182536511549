import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  getJourneyThunk,
  createIdeaThunk,
  deleteFolderThunk,
  deleteIdeaThunk,
  deleteIdeasThunk,
  updateFolderThunk,
  updateIdeaThunk,
  updateIdeasThunk,
  createFolderThunk,
  updateJourneyThunk,
  updateQuickAddItineraryThunk,
} from '@context/journey/journeyThunk';
import { dataLayerUtil } from '@utils/dataLayerUtil';
import useUser from '@hooks/useUser';
import {
  createFolderReducer,
  createIdeaReducer,
  selectJourneyState,
  updateFolderReducer,
  updateIdeaReducer,
  updateJourneyReducer,
  updateQuickAddItineraryReducer,
} from '@context/journey/journeySlice';
import { useEffect, useState } from 'react';

export const useJourney = (journeyID) => {
  const dispatch = useDispatch();
  const userData = useUser();
  const { userID } = userData || {};

  const { journey, journeyStatus } = useSelector(selectJourneyState);

  const { data, pk } = journey || {};
  const {
    ideas, crew, title, itinerary, folders, surveys,
  } = data || {};

  const [retryCount, setRetryCount] = useState(0);

  const getJourney = () => {
    dispatch(getJourneyThunk({ journeyID }));
  };

  useEffect(() => {
    if (journeyID) {
      if ((journeyStatus === 'idle') || (journeyID !== pk && journeyStatus === 'fulfilled')) {
        getJourney();
      } else if ((journeyStatus === 'rejected' && retryCount < 10)) {
        setTimeout(() => {
          getJourney();
          setRetryCount(retryCount + 1);
        }, 2000);
      }
    }
  }, [journeyID, pk, journeyStatus, retryCount]);

  const updateJourney = ({ ...params }) => {
    const journey = { id: journeyID, ...params };

    dispatch(updateJourneyReducer({ params }));
    dispatch(updateJourneyThunk(journey));
  };

  const createIdea = ({
    formData, photoData,
  }) => {
    const idea = {
      ...formData,
      ...photoData,
      creator: userID,
      createdDate: new Date().toISOString(),
      id: uuidv4(),
    };

    dispatch(createIdeaReducer({ idea }));
    dispatch(createIdeaThunk({ journeyID, idea }));

    dataLayerUtil('Created idea', idea);
  };

  const editIdea = ({
    drawerSelectedID, photoData, formData,
  }) => {
    const idea = {
      journeyID,
      ideaID: drawerSelectedID,
      idea: {
        ...formData,
        ...photoData,
      },
    };

    const updatedIdeas = ideas?.map((idea) => {
      if (idea.id === drawerSelectedID) {
        return { ...idea, ...formData, ...photoData };
      }
      return idea;
    }) || [];

    dispatch(updateIdeaReducer({ ideas: updatedIdeas }));
    dispatch(updateIdeaThunk(idea));

    dataLayerUtil('Edit idea', idea);
  };

  const updateIdea = ({
    ideaID, ...params
  }) => {
    const updatedIdeas = ideas?.map((idea) => {
      if (idea.id === ideaID) return { ...idea, ...params };
      return idea;
    }) || [];

    dispatch(updateIdeaReducer({ ideas: updatedIdeas }));
    dispatch(updateIdeaThunk({
      journeyID, ideaID, loader: false, modalLoader: false, idea: params,
    }));
  };

  const updateIdeas = ({
    ideaIDs, ...params
  }) => {
    const updatedIdeas = ideas?.map((idea) => {
      if (ideaIDs.includes(idea.id)) return { ...idea, ...params };
      return idea;
    }) || [];

    dispatch(updateIdeaReducer({ ideas: updatedIdeas }));

    ideaIDs.forEach((ideaID) => {
      dispatch(updateIdeaThunk({
        journeyID, ideaID, loader: false, modalLoader: false, idea: params,
      }));
    });
  };

  const deleteIdea = ({ ideaID }) => {
    const idea = {
      journeyID,
      ideaID,
    };

    const updatedIdeas = ideas?.filter((idea) => idea.id !== ideaID);

    dispatch(updateIdeaReducer({ ideas: updatedIdeas }));
    dispatch(deleteIdeaThunk(idea));

    dataLayerUtil('Delete idea', { ideaID });
  };

  const createFolder = ({ formData, parentFolderKey }) => {
    const { title } = formData;
    const updatedFolder = {
      title,
      id: uuidv4(),
      parentFolderKey,
    };

    dispatch(createFolderReducer({ folder: updatedFolder }));
    dispatch(createFolderThunk({ journeyID, ...updatedFolder, loader: false }));
  };

  const updateFolder = ({
    formData, modalSelectedID,
  }) => {
    const { title, parentFolderKey } = formData;

    const updatedFolder = {
      folderID: modalSelectedID,
      journeyID,
      title,
      parentFolderKey,
    };

    const updatedFolders = folders?.map((folder) => {
      if (folder.id === modalSelectedID) return { ...folder, ...updatedFolder };
      return folder;
    });

    dispatch(updateFolderReducer({ folders: updatedFolders }));
    dispatch(updateFolderThunk(updatedFolder));

    dataLayerUtil('Edit folder', { folderAttributes: updatedFolder });
  };

  const deleteFolder = ({ folderID }) => {
    const folderAttributes = {
      folderID,
      journeyID,
    };
    const removedFolder = folders.find((folder) => folder.id === folderID);

    // reassign removed children to parent folder
    const remainingFolders = folders?.filter((folder) => folder.id !== folderID).map((folder) => {
      if (folder.parentFolderKey === folderID) {
        const parentFolderKey = removedFolder.parentFolderKey || '';
        return { ...folder, parentFolderKey };
      }

      return folder;
    });

    const updatedIdeas = ideas?.map((idea) => {
      if (idea.folderKey === folderID) {
        const folderKey = removedFolder.parentFolderKey || '';
        return { ...idea, folderKey };
      }

      return idea;
    }) || [];

    dispatch(updateFolderReducer({ folders: remainingFolders }));
    dispatch(updateIdeaReducer({ ideas: updatedIdeas }));

    dispatch(deleteFolderThunk(folderAttributes));
    dispatch(updateIdeasThunk({ journeyID, ideas: updatedIdeas }));
    updateJourney({ folders: remainingFolders, ideas: updatedIdeas });

    dataLayerUtil('Delete folder', folderAttributes);
  };

  const deleteFolderAndChildren = ({ folderID }) => {
    const removedFolders = [folders.find((folder) => folder.id === folderID)];

    function filterRemovedFolders(folderKey) {
      folders.forEach((folder) => {
        if (folder.parentFolderKey === folderKey) {
          filterRemovedFolders(folder.id);
          removedFolders.push(folder);
        }
      });
    }
    filterRemovedFolders(folderID);

    // filters out all folders that are offspring of selected folder
    const remainingFolders = folders?.filter((folder) => !removedFolders?.find((removedFolder) => removedFolder.id === folder.id));

    // filters out all ideas that are offspring of selected folder
    const remainingIdeas = [];
    const removedIdeas = [];

    ideas.forEach((idea) => {
      if (!removedFolders.find((removedFolder) => removedFolder?.id === idea.folderKey)) {
        remainingIdeas.push(idea);
      } else removedIdeas.push(idea);
    });

    dispatch(deleteIdeasThunk({ journeyID, ideas: removedIdeas }));
    updateJourney({ folders: remainingFolders, ideas: remainingIdeas });
  };

  const updateQuickAddItinerary = ({
    itineraryQuickAddID, ...params
  }) => {
    const itineraryQuickAddItems = itinerary?.itineraryQuickAddItems.map((quickAddItem) => {
      if (quickAddItem.id === itineraryQuickAddID) {
        return { ...quickAddItem, ...params };
      }
      return quickAddItem;
    }) || [];

    dispatch(updateQuickAddItineraryReducer({ itineraryQuickAddItems }));
    dispatch(updateQuickAddItineraryThunk({
      id: journeyID, quickItemId: itineraryQuickAddID, ...params,
    }));
  };

  return {
    journeyStatus,
    data,
    ideas,
    crew,
    title,
    itinerary,
    folders,
    surveys,
    getJourney,
    updateJourney,
    createIdea,
    editIdea,
    updateIdea,
    updateIdeas,
    deleteIdea,
    createFolder,
    updateFolder,
    deleteFolder,
    deleteFolderAndChildren,
    updateQuickAddItinerary,
  };
};
