import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const Container = styled.div`
  .quill {
    display: flex;
    flex-direction: column;
    height: 100%;
    font-size: 10pt;
    font-family: Epilogue, sans-serif;
  }

  .ql-container {
    min-height: 200px;
    height: 100%;
  }
  `;

const RichTextEditor = ({ value = '', onChange }) => {
  const [text, setText] = useState(value);

  const handleTextChange = (content, _, __, editor) => {
    if (editor?.getLength() <= 8000) {
      const hrefWithoutHttp = /href="((?!http:\/\/)(?!https:\/\/)[^"]*)"/g;
      const formattedContent = content.replace(hrefWithoutHttp, 'href="https://');
      setText(formattedContent);
      onChange(formattedContent);
    }
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'link'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: ['var(--black)', 'var(--text-grey)', 'var(--primary)', 'var(--accent)', 'var(--green)', 'var(--error'] }, 'clean'],
    ],
  };
  const formats = ['header', 'font', 'bold', 'italic', 'underline', 'link', 'list', 'color', 'clear'];
  const theme = 'snow';

  return (
    <Container>
      <ReactQuill
        className="ql-clear"
        value={text}
        onChange={handleTextChange}
        modules={modules}
        formats={formats}
        theme={theme}
        style={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '250px',
          height: '100%',
          fontSize: '10pt',
          fontFamily: 'Epilogue, sans-serif',
        }}
      />
    </Container>
  );
};

export default RichTextEditor;
