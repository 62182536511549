const checkWindowAvailable = () => typeof window !== 'undefined';

const filterWhitelist = (state, whitelist) => {
  const localState = {};

  if (whitelist.length) {
    whitelist.forEach((key) => {
      localState[key] = state[key];
    });
  }

  return localState;
};

const definePersistSystem = () => {
  if (localStorage.getItem('remembered') === 'true') return 'localStorage';
  return 'sessionStorage';
};

const getState = () => {
  const persistSystem = definePersistSystem();
  const storeString = checkWindowAvailable() && window[persistSystem].getItem('store');
  return JSON.parse(storeString) || {};
};

export const defineState = (defaultState) => (reducer) => {
  if (Object.prototype.hasOwnProperty.call(getState(), reducer)) {
    const localReducer = getState()[reducer];
    return localReducer || defaultState;
  }
  return defaultState;
};

const setStorage = (store, config) => {
  if (checkWindowAvailable()) {
    const persistSystem = definePersistSystem();
    const { whitelist } = config;
    const localState = filterWhitelist(store.getState(), whitelist);
    const storeString = JSON.stringify(localState);
    window[persistSystem].setItem('store', storeString);
  }
};

export const persistStore = (
  store,
  config,
) => store.subscribe(() => setStorage(store, config));
