import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Storage from '@utils/storage';

export const uploadFileThunk = createAsyncThunk('upload',
  async ({ key, file }) => {
    try {
      return await Storage.put(key, file);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const deleteFileThunk = createAsyncThunk('delete',
  async ({ key }) => {
    try {
      return await Storage.remove(key);
    } catch (error) {
      throw new Error(error.message);
    }
  });
