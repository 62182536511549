import React from 'react';
import styled from 'styled-components';
import Navigation from './sharedComponentContainers/Navigation';

const Container = styled.div`
  background-color: var(--white);
  transition: width .2s;
  width: 100%;
  height: calc(100vh - 67px);
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 67px;
  left: 0;
  z-index: 999;
`;

const MobileNavContainer = ({ isVisible, onMenuClick }) => (
  <>
    {isVisible ? (
      <Container>
        <Navigation isMobile onMenuClick={onMenuClick} />
      </Container>
    ) : null}
  </>
);

export default MobileNavContainer;
