import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from '@utils/dragTypes';
import { useWindowSize } from '@hooks/useWindowSize';

function DropContainer({
  children, type, folderID, isEmpty, style,
}) {
  const { isMobile } = useWindowSize();

  if (!isMobile) {
    const [{ canDrop, isOver }, drop] = useDrop({
      accept: ItemTypes.IDEACARD,
      drop: () => ({ name: folderID, type }),
      canDrop: (item) => item?.folderId !== folderID,
      collect: (monitor) => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }, [folderID]);

    const isActive = canDrop && isOver;
    let isHovering;
    let isDropping;

    if (isActive) {
      isDropping = true;
      isHovering = false;
    } else if (canDrop) {
      isHovering = true;
      isDropping = false;
    }

    return (
      <div
        className="card-sizing"
        ref={drop}
        style={{ display: isEmpty ? 'block' : 'inline-flex', verticalAlign: 'bottom', ...style }}
      >
        {React.cloneElement(children, { isHovering, isDropping })}
      </div>
    );
  }

  return (
    <>
      {React.cloneElement(children)}
    </>
  );
}
export default DropContainer;
