import React from 'react';
import styled from 'styled-components';
import { Typography } from '@travelpassero/khaos';
import { Card, Row, Col } from 'antd';

const FolderTab = styled.div`
  height: 8px;
  width: 100%;

  > * {
    width: 32%;
    height: 100%;
    background-color: var(--component-background-darker);
    border-radius: 5px 5px 0 0;
  }
`;

const InnerCard = styled(Card)`
  width: 100%;
  height: ${(props) => (props.isMobile ? '114px' : '68px')};
  background: var(--component-background-darker);
  color: var(--white);
  box-shadow: 0px 3px 6px #00000012;
  border-radius: 0 5px 5px 5px;
  border: none;
  opacity: 1;
  padding: 4px 4px 4px 8px;

  .ant-card-body {
    height: 100%;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-end;
    flex-direction: column;
  }
`;

const ChildFolderContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  padding: 8px 4px 2px 4px;
  align-items: center;
`;

const FolderItemsCount = styled.div`
  height: ${(props) => (props.isMobile ? '45px' : '30px')};
  width: ${(props) => (props.isMobile ? '56px' : '38px')};
  border: 1px solid var(--white);
  border-radius: 5px;
  display: flex;
  margin: ${(props) => (props.isMobile ? '16px' : '0')};
  justify-content: center;
  text-align: center;
  align-items: center;
`;

function ChildFolderCard({
  title,
  innerCards = [],
  dragRef,
  isMobile = false,
}) {
  return (
    <div ref={dragRef} className="folder-inner-card-sizing" style={{ margin: '4px 0', padding: '0 4px' }}>
      <FolderTab><div /></FolderTab>
      <InnerCard isMobile={isMobile}>
        <ChildFolderContent>
          {innerCards.length > 0 ? (
            <FolderItemsCount isMobile={isMobile}>
              <Typography
                type="whiteText"
                size={isMobile ? '1.125rem' : '.875rem'}
                style={{
                  fontWeight: 'bold',
                }}
              >
                {innerCards.length < 1000 ? `+${innerCards.length}` : '999+'}
              </Typography>
            </FolderItemsCount>
          ) : <div style={{ height: '30px' }} />}
        </ChildFolderContent>
        <Row justify="start" style={{ width: '100%' }}>
          <Col>
            <div style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
            >
              <Typography
                type="whiteText"
                size=".75rem"
                style={{
                  fontWeight: 'bold',
                }}
              >
                {title}
              </Typography>
            </div>
          </Col>
        </Row>
      </InnerCard>
    </div>
  );
}

export default ChildFolderCard;
