export const carouselPhotos = '/images/';
export const users = '/users';
export const profile = '/profile';
export const journeys = '/journeys';
export const friends = '/friends';
export const friendRequests = '/friends/requests';
export const survey = '/survey';
export const photoStorage = 'https://dxxd0kddfyd18.cloudfront.net/';
export const photoSearch = '/search/images';
export const photoTracking = '/track/image';
export const social = '/social';
export const notifications = '/notifications';
