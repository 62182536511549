/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      iconColor
      isEmailSubscribed
      isConfirmed
      isReferred
      referredBy
      lastLogin
      journeys
      crew
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      iconColor
      isEmailSubscribed
      isConfirmed
      isReferred
      referredBy
      lastLogin
      journeys
      crew
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      firstName
      lastName
      email
      phoneNumber
      iconColor
      isEmailSubscribed
      isConfirmed
      isReferred
      referredBy
      lastLogin
      journeys
      crew
      createdAt
      updatedAt
    }
  }
`;
export const createJourney = /* GraphQL */ `
  mutation CreateJourney(
    $input: CreateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    createJourney(input: $input, condition: $condition) {
      id
      title
      startingDate
      endingDate
      createdDate
      lastEditDate
      hasUnknownDate
      isSampleJourney
      sampleJourneyType
      link
      creator
      location
      imgSrcKey
      ideas {
        id
        ideaWeight
        title
        imgSrcKey
        location
        description
        price
        duration
        link
        creator
        folderKey
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        itineraryDate
        itineraryTime
        itineraryNote
        itineraryByLengthDay
      }
      journeyCrew
      surveys {
        id
        title
        creator
        invited
        completed
        createdDate
        questions {
          id
          title
          type
        }
      }
      itinerary {
        id
        type
        dateRangeStart
        dateRangeEnd
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        ItineraryByLength {
          length
        }
        itineraryQuickAddItems {
          id
          title
          type
          itineraryDate
          itineraryTime
          itineraryNote
          itineraryByLengthDay
        }
      }
      folders {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateJourney = /* GraphQL */ `
  mutation UpdateJourney(
    $input: UpdateJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    updateJourney(input: $input, condition: $condition) {
      id
      title
      startingDate
      endingDate
      createdDate
      lastEditDate
      hasUnknownDate
      isSampleJourney
      sampleJourneyType
      link
      creator
      location
      imgSrcKey
      ideas {
        id
        ideaWeight
        title
        imgSrcKey
        location
        description
        price
        duration
        link
        creator
        folderKey
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        itineraryDate
        itineraryTime
        itineraryNote
        itineraryByLengthDay
      }
      journeyCrew
      surveys {
        id
        title
        creator
        invited
        completed
        createdDate
        questions {
          id
          title
          type
        }
      }
      itinerary {
        id
        type
        dateRangeStart
        dateRangeEnd
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        ItineraryByLength {
          length
        }
        itineraryQuickAddItems {
          id
          title
          type
          itineraryDate
          itineraryTime
          itineraryNote
          itineraryByLengthDay
        }
      }
      folders {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteJourney = /* GraphQL */ `
  mutation DeleteJourney(
    $input: DeleteJourneyInput!
    $condition: ModelJourneyConditionInput
  ) {
    deleteJourney(input: $input, condition: $condition) {
      id
      title
      startingDate
      endingDate
      createdDate
      lastEditDate
      hasUnknownDate
      isSampleJourney
      sampleJourneyType
      link
      creator
      location
      imgSrcKey
      ideas {
        id
        ideaWeight
        title
        imgSrcKey
        location
        description
        price
        duration
        link
        creator
        folderKey
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        itineraryDate
        itineraryTime
        itineraryNote
        itineraryByLengthDay
      }
      journeyCrew
      surveys {
        id
        title
        creator
        invited
        completed
        createdDate
        questions {
          id
          title
          type
        }
      }
      itinerary {
        id
        type
        dateRangeStart
        dateRangeEnd
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        ItineraryByLength {
          length
        }
        itineraryQuickAddItems {
          id
          title
          type
          itineraryDate
          itineraryTime
          itineraryNote
          itineraryByLengthDay
        }
      }
      folders {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
`;
