import styled from 'styled-components';

export const OnboardingFormContainer = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  @media (max-width: 900px) {
    width: 100%;
    margin-top: 30px;
    padding: 0;
  }
  @media (min-width: 900px) and (max-width: 1200px) {
    padding: 0 54px;
  }
`;
