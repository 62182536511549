import React from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import {
  TopBar,
  CenterModal,
  Button,
} from '@travelpassero/khaos';

import { PlusOutlined } from '@ant-design/icons';
import SurveyCardContainer from '@components/containers/SurveyCardContainer';
import ManageCrewModal from '@components/sharedComponentContainers/ManageCrewModal';

import { openCenterModal, closeModal } from '@context/actions';
import { selectAppState } from '@context/global/appSlice';

import { Col, Row } from 'antd';
import { useTracking } from '@hooks/useTracking';
import MultiImageContainer from '@components/renderContainers/MultiImageContainer';
import { useHistory, useLocation } from 'react-router-dom';
import { useWindowSize } from '@hooks/useWindowSize';
import CrewList from '@components/sharedComponentContainers/CrewList';
import useJourneyCrew from '@hooks/useJourneyCrew';
import { useJourney } from '@hooks/useJourney';
import SurveyCreatePage from './SurveyCreatePage';
import PageScrollContainer from './PageScrollContainer';
import SurveyIntroPage from './SurveyIntroPage';

const CrewListContainer = styled.div`
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
`;

const SurveyPage = ({
  journeyID,
  splitScreenSide,
}) => {
  const history = useHistory();
  const { search } = useLocation();
  const { setPageView, setPage } = useTracking();
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const { globalStateReducer } = useSelector(selectAppState);
  const useJourneyData = useJourney(journeyID);
  const {
    title, crew, ideas, surveys,
  } = useJourneyData;
  const { crewList, removeCrewMember } = useJourneyCrew(journeyID);
  setPageView('Survey Page');
  setPage('survey');

  const {
    modalChildrenType,
    isCenterModalVisible,
  } = globalStateReducer;

  const handleAddCrewMember = () => {
    dispatch(openCenterModal('crew'));
  };

  const handleManageCrewMembers = () => {
    dispatch(openCenterModal('manage-crew'));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleOnCreateSurveyClick = () => {
    if (isMobile) history.push(`/journeys/${journeyID}/surveys?create-survey=true&no-intro=true`);
    else history.push(`/journeys/${journeyID}/ideas-with-surveys?create-survey=true&no-intro=true`);
  };

  let Modal;
  switch (modalChildrenType) {
    case 'crew':
      Modal = (
        <ManageCrewModal journeyID={journeyID} />
      );
      break;

    case 'manage-crew':
      Modal = (
        <CenterModal
          title={`People in this Journey (${crewList.length})`}
          showModal={isCenterModalVisible}
          handleOnClose={handleCloseModal}
          children={(
            <Row justify="center" align="middle">
              <Col span={24}>
                <CrewListContainer>
                  <CrewList
                    list={[...crewList]}
                    onRemove={removeCrewMember}
                    removeConfirmText="Remove crew member?"
                  />
                </CrewListContainer>
              </Col>
            </Row>
              )}
          footerButtons={[
            <Button onClick={handleAddCrewMember}>
              <PlusOutlined />
              Add more Crew Members
            </Button>,
          ]}
        />
      );
      break;

    default:
      Modal = null;
  }

  if ((!surveys || !surveys.length) && !new URLSearchParams(search).get('no-intro')) {
    return (
      <PageScrollContainer splitScreenSide={splitScreenSide}>
        <SurveyIntroPage onCreateSurveyClick={handleOnCreateSurveyClick} />
      </PageScrollContainer>
    );
  }

  if (new URLSearchParams(search).get('create-survey')) {
    return (
      <PageScrollContainer splitScreenSide={splitScreenSide}>
        <MultiImageContainer
          dataWithKeys={ideas || []}
          render={({ dataWithImgUrls }) => (
            <SurveyCreatePage
              ideas={dataWithImgUrls}
              crew={crew || []}
              journeyID={journeyID}
              style={{ textAlign: 'center' }}
            />
          )}
        />
      </PageScrollContainer>
    );
  }

  return (
    <>
      <TopBar
        sectionTitle="Surveys"
        tripTitle={title}
        onManageCrewMembers={handleManageCrewMembers}
        myCrewUsers={crewList || []}
        onAddCrewMember={handleAddCrewMember}
        splitScreenSide={splitScreenSide}
      />
      <PageScrollContainer splitScreenSide={splitScreenSide}>
        <SurveyCardContainer
          onCreateSurveyClick={handleOnCreateSurveyClick}
          journeyID={journeyID}
          useJourneyData={useJourneyData}
          style={{
            backgroundColor: 'var(--page-background)',
          }}
        />
        {isCenterModalVisible && Modal}
      </PageScrollContainer>
    </>
  );
};

export default SurveyPage;
