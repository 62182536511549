import React from 'react';
import styled from 'styled-components';
import { Typography } from '@travelpassero/khaos';

const RichTextTypography = styled.div`
    word-wrap: break-word;
    text-overflow: ellipsis;

    h1, h2, p, ol, ul {
      margin: 0;
      padding: 0;
      font-family: font-primary;
    }

    h1 {
      font-size: 2rem;
    }

    h2 {
      font-size: 1.25rem;
    }

    p {
      font-size: .875rem;
    }

    ol, ul {
      padding-left: 16px;
    }
  `;

function ProfileDescriptionSection({ profileDescription, isEditable }) {
  const hasHTMLContent = (htmlString = '') => {
    const strippedString = htmlString.replace(/<\/?[^>]+(>|$)/g, '');
    return strippedString.trim().length > 0;
  };

  const hasDescription = hasHTMLContent(profileDescription);

  if (hasDescription) {
    return (
      <RichTextTypography dangerouslySetInnerHTML={{ __html: profileDescription }} />
    );
  }

  if (isEditable) {
    return (
      <Typography type="greyText">
        Add in a description about yourself, your travel history, and anything else you want
        to share with fellow Passero travelers!
      </Typography>
    );
  }

  return null;
}

export default ProfileDescriptionSection;
