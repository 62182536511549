import React, { useEffect, useState } from 'react';
import { ArrowDownOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
// eslint-disable-next-line import/no-unresolved
import { useDrop } from 'react-dnd';
import { TinyIdeaCard, Typography } from '@travelpassero/khaos';
import styled from 'styled-components';

import { ItemTypes } from '@utils/dragTypes';
import { addSelectedIdeaToSurvey, removeAllFromSurvey, removeSelectedIdeaToSurvey } from '@context/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';

const TileContainer = styled.div`
  position: relative;
  max-width: 600px;
  min-height: 205px;
  border: 1.5px dashed var(--accent);
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  overflow: auto;
  z-index: 1;
`;

const DragMessageContainer = styled.div`
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

const TripDropdown = styled(Form.Item)`
  margin-bottom: 12px;

  .ant-form-item-control-input-content {
    display: flex;
    align-items: center;
  }
  .ant-select .ant-select-selector {
    height: 40px;
    display: flex;
    align-items: center;
  }
`;

const DragContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
`;

const HoverBackGround = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  opacity: 0.8;
  z-index: 4;
  border: 2px dashed white;
`;

const DropBackGround = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--icons-color-variant);
  height: 100%;
  width: 100%;
  opacity: 0.8;
  z-index: 4;
  border: 2px dashed white;
`;

const ArrowContent = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  margin-right: 25px;
  z-index: 4;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const { Option } = Select;

const TileChoiceQuestion = ({
  ideas, questionNumber,
}) => {
  const dispatch = useDispatch();
  const { globalStateReducer } = useSelector(selectAppState);
  const {
    surveySelectedIdeas,
  } = globalStateReducer;

  const findIndex = surveySelectedIdeas.findIndex(
    (i) => i.questionNumber === questionNumber,
  );

  const questionSelected = surveySelectedIdeas[findIndex] || null;
  const questionSelectedObj = questionSelected && questionSelected.ideas;
  const questionSelectedIdeas = questionSelectedObj || [];

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: ItemTypes.IDEACARD,
    drop: () => ({ name: 'survey', type: 'survey', questionNumber }),
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const [ideaOptions, setIdeaOptions] = useState(ideas);
  const [selectedIdeas, setSelectedIdeas] = useState([]);

  const handleOnDeleteClick = (id) => {
    dispatch(removeSelectedIdeaToSurvey({ ideaID: id, questionNumber }));
  };

  const onIdeaChange = (formData) => {
    const formDataKeys = formData && formData.map((i) => i.key);
    const addedSelectedKeys = [...formDataKeys, ...questionSelectedIdeas];

    const selectedIdeas = addedSelectedKeys
      && ideas.filter((item) => addedSelectedKeys.includes(item.id));

    if (formDataKeys.length === 0) {
      setIdeaOptions(ideas);
      setSelectedIdeas([]);
      dispatch(removeAllFromSurvey());
      return;
    }

    const selectedIdeaIDs = selectedIdeas && selectedIdeas.map((i) => i.id);
    setIdeaOptions(() => ideas?.filter(
      (idea) => !selectedIdeaIDs.includes(idea.id),
    ));

    dispatch(
      addSelectedIdeaToSurvey({
        surveySelectedIdeas: selectedIdeas,
        questionNumber,
      }),
    );
  };

  useEffect(() => {
    const getSelectedIdeasFilter = ideas?.filter((item) => questionSelectedIdeas.includes(item.id));

    setSelectedIdeas(getSelectedIdeasFilter);
  }, [questionSelectedIdeas.length]); // eslint-disable-line

  const isActive = canDrop && isOver;

  let isHovering;
  let isDropping;
  if (isActive) {
    isDropping = true;
    isHovering = false;
  } else if (canDrop) {
    isHovering = true;
    isDropping = false;
  }

  return (
    <div style={{ padding: '26px 0' }}>
      <Typography
        type="primaryText"
        style={{ display: 'block', paddingBottom: '12px' }}
      >
        Add idea tiles for crew members to pick from: (drag or dropdown)
      </Typography>
      <TripDropdown name={`question-${questionNumber}_chooseTiles`}>
        <Select
          style={{ height: '40px' }}
          mode="multiple"
          labelInValue
          placeholder="Select an idea for this question"
          onChange={onIdeaChange}
        >
          {ideaOptions
            && ideaOptions.map((option) => {
              const { id, title } = option;

              return (
                <Option key={id} value={id} label={title}>
                  {title}
                </Option>
              );
            })}
        </Select>
      </TripDropdown>
      <div style={{ position: 'relative' }}>
        <TileContainer ref={drop}>
          {isHovering ? (
            <DragContainer>
              <HoverBackGround />
              <ArrowContent>
                <ArrowDownOutlined
                  style={{ color: 'white', fontSize: '2em' }}
                />
                <Typography type="secondaryHeading" size="1.125rem">
                  Drop Here
                </Typography>
              </ArrowContent>
            </DragContainer>
          ) : null}

          {isDropping ? (
            <DragContainer>
              <DropBackGround />
              <ArrowContent>
                <ArrowDownOutlined
                  style={{ color: 'white', fontSize: '2em' }}
                />
                <div style={{ height: '36px' }} />
                <Typography type="secondaryHeading" size="1.125rem">
                  Drop Here
                </Typography>
              </ArrowContent>
            </DragContainer>
          ) : null}

          {selectedIdeas
            && selectedIdeas.map((idea) => {
              const {
                title, duration, imgSrc, price, id,
              } = idea;
              return (
                <TinyIdeaCard
                  key={id}
                  title={title}
                  duration={duration}
                  imgSrc={imgSrc}
                  price={price}
                  onDeleteClick={() => handleOnDeleteClick(id)}
                />
              );
            })}
        </TileContainer>
        <DragMessageContainer>
          {!selectedIdeas.length && 'Drag and Drop Here'}
        </DragMessageContainer>
      </div>
    </div>
  );
};

export default TileChoiceQuestion;
