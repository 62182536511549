import React from 'react';
import { useSelector } from 'react-redux';
import {
  Col,
  Row,
} from 'antd';
import {
  GlobalOutlined,
  InstagramOutlined,
  YoutubeFilled,
} from '@ant-design/icons';
import { Typography } from '@travelpassero/khaos';
import { selectPublicState } from '@context/public/publicSlice';
import PinterestIcon from '../../assets/pinterest.svg';
import TiktokIcon from '../../assets/tiktok.svg';

function SocialLinksSection({ isEditable }) {
  const {
    profile,
  } = useSelector(selectPublicState);

  const {
    websiteUrl, instagramUrl, youtubeUrl, pinterestUrl, tiktokUrl,
  } = profile || {};

  const isSocialLinks = !!websiteUrl || !!instagramUrl || !!youtubeUrl || !!pinterestUrl || !!tiktokUrl;

  const formatUrl = (url = '') => {
    if (!url.startsWith('http')) return `https://${url}`;
    return url;
  };

  return (
    <Row wrap={false} gutter={[8, 8]} align="middle">
      {isSocialLinks ? (
        <>
          {!!tiktokUrl && (
          <Col>
            <a href={formatUrl(tiktokUrl)} target="_blank" aria-label="Tiktok" rel="noreferrer">
              <TiktokIcon
                height="20"
                width="100%"
                fill="var(--icons)"
              />
            </a>
          </Col>
          )}
          {!!instagramUrl && (
          <Col>
            <a href={formatUrl(instagramUrl)} target="_blank" aria-label="Instagram" rel="noreferrer">
              <InstagramOutlined
                style={{ color: 'var(--icons)', fontSize: '20px' }}
              />
            </a>
          </Col>
          )}
          {!!pinterestUrl && (
          <Col>
            <a href={formatUrl(pinterestUrl)} target="_blank" aria-label="Pinterest" rel="noreferrer">
              <PinterestIcon
                height="20"
                width="100%"
                fill="var(--icons)"
              />
            </a>
          </Col>
          )}
          {!!youtubeUrl && (
          <Col>
            <a href={formatUrl(youtubeUrl)} target="_blank" aria-label="YouTube" rel="noreferrer">
              <YoutubeFilled
                style={{ color: 'var(--icons)', fontSize: '20px' }}
              />
            </a>
          </Col>
          )}
          {!!websiteUrl && (
          <Col>
            <a href={formatUrl(websiteUrl)} target="_blank" aria-label="WebSite" rel="noreferrer">
              <GlobalOutlined
                style={{ color: 'var(--icons)', fontSize: '20px' }}
              />
            </a>
          </Col>
          )}
        </>
      ) : isEditable && (
      <Typography type="accentText" style={{ padding: '4px' }}>
        Add your social links
      </Typography>
      )}
    </Row>
  );
}

export default SocialLinksSection;
