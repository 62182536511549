import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Footer } from '@travelpassero/khaos';
import { Col, Row } from 'antd';
import { useWindowSize } from '@hooks/useWindowSize';

const Container = styled.div`
  height: ${(props) => props.height}px;
  overflow-y: auto;
`;

const PageScrollContainer = ({
  children, style, splitScreenSide, footerTextType,
}) => {
  const containerRef = useRef();
  const { height } = useWindowSize();
  const [childOffsetTop, setChildOffsetTop] = useState(0);
  const [parentOffsetTop, setParentOffsetTop] = useState(0);
  const [maxHeight, setMaxHeight] = useState(0);

  useEffect(() => {
    setChildOffsetTop(containerRef?.current?.offsetTop || 0);
    setParentOffsetTop(containerRef?.current?.offsetParent?.offsetTop || 0);
    setMaxHeight(height < 490 ? document.body.scrollHeight : height);
  });

  return (
    <Container
      ref={containerRef}
      height={maxHeight - (childOffsetTop + parentOffsetTop)}
      style={style}
    >
      <Row style={{ height: '100%' }}>
        <Col span={24}>
          <Row style={{ height: '100%' }}>
            <Col flex={1} span={24}>
              {children}
            </Col>
            <Col span={24} style={{ marginTop: 'auto' }}>
              <Row justify={splitScreenSide ? splitScreenSide === 'left' ? 'start' : 'end' : 'space-between'}>
                <Col span={24}>
                  <Footer splitScreenSide={splitScreenSide} textType={footerTextType} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default PageScrollContainer;
