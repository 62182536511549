import React from 'react';
import ManageCrewModal from '../../components/sharedComponentContainers/ManageCrewModal';
import AddFriendsModal from './AddFriendsModal';

const FriendCenterModal = ({
  type, onClose, journeyID,
}) => {
  switch (type) {
    case 'add friends':
      return (
        <AddFriendsModal
          onClose={onClose}
          journeyID={journeyID}
        />
      );
    case 'add crew':
      return (
        <ManageCrewModal journeyID={journeyID} />
      );
    default:
      return null;
  }
};

export default FriendCenterModal;
