export const simpleColumns = [
  {
    title: 'UserInfo',
    dataIndex: 'userInfo',
    render: (userInfo) => {
      const { name, color } = userInfo;

      return (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <p
            style={{
              borderRadius: '100%',
              backgroundColor: color,
              width: '8px',
              height: '8px',
              margin: '0 12px 0px 0px',
            }}
          />
          {name}
        </span>
      );
    },
  },
  {
    title: 'Data',
    dataIndex: 'data',
  },
];
