import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectFriendState } from '@context/friend/friendSlice';
import { removeCrewUserFromJourneyThunk } from '@context/journeys/journeysThunk';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import useUser from './useUser';
import useJourneys from './useJourneys';

const useJourneyCrew = (journeyID) => {
  const dispatch = useDispatch();
  const { userID } = useUser();
  const { updateJourneyByID } = useJourneys();
  const { journeys } = useSelector(selectJourneysState);
  const { data } = journeys.find(({ pk }) => pk === journeyID) || {};

  const { crew } = data || {};
  const { friends, receivedFriendRequests, sentFriendRequests } = useSelector(selectFriendState);

  const [potentialCrew, setPotentialCrew] = useState([...friends, ...sentFriendRequests]);
  const [crewList, setCrewList] = useState(null);

  const trackCheckedFriends = (updatedFriends) => {
    setPotentialCrew(updatedFriends);
    // sync the crew list with the friends list
    let updatedCrew = [...crewList];

    updatedFriends.forEach((friend) => {
      if (!friend.isDisabled) {
        if (friend.isChecked && !crewList.find((crewMember) => crewMember.id === friend.id)) {
          updatedCrew.push(friend);
        } else if (!friend.isChecked) {
          updatedCrew = updatedCrew.filter((crewMember) => crewMember.id !== friend.id);
        }
      }
    });

    setCrewList(updatedCrew);
  };

  useEffect(() => {
    if (journeyID) {
      setPotentialCrew([...friends, ...sentFriendRequests]);
      const crewOthers = crew?.filter((item) => item.id !== userID) || [];
      // mark friends
      const markedFriends = [...friends, ...sentFriendRequests].map((friend) => {
        const markedFriend = { ...friend };

        // who are a part of this crew
        const crewFriend = crewOthers.find((crewMember) => crewMember.id === markedFriend.id);
        if (crewFriend) markedFriend.isDisabled = true;

        // who are pending a friend request
        const pendingFriend = [...receivedFriendRequests, ...sentFriendRequests].find((person) => person.id === markedFriend.id);
        if (pendingFriend) markedFriend.isPendingFriend = true;

        return markedFriend;
      });

      setPotentialCrew([...markedFriends]);

      // mark crew
      const crewWithFriends = crewOthers.map((crewMember) => {
        const markedCrewMember = { ...crewMember };
        const friendRequests = [...receivedFriendRequests, ...sentFriendRequests];

        // who are also friends
        const crewFriend = potentialCrew.find((friend) => friend.id === markedCrewMember.id);
        if (!crewFriend) markedCrewMember.isNotFriend = true;

        // who are pending a friend request
        const pendingFriend = friendRequests.find((person) => person.id === markedCrewMember.id);
        if (pendingFriend) markedCrewMember.isPendingFriend = true;

        // who was sent a friend request
        const friendRequestSent = [...sentFriendRequests].find((person) => person.id === markedCrewMember.id);
        if (friendRequestSent) markedCrewMember.isFriendRequestSent = true;

        return markedCrewMember;
      });

      setCrewList([...crewWithFriends]);
    }
  }, [journeyID, crew, friends, receivedFriendRequests, sentFriendRequests]);

  const noFriendsInCrew = () => {
    trackCheckedFriends(potentialCrew.map((friend) => {
      const checkedFriend = { ...friend };
      if (!checkedFriend.isDisabled) checkedFriend.isChecked = false;
      return checkedFriend;
    }));
  };

  const allFriendsInCrew = () => {
    trackCheckedFriends(potentialCrew.map((friend) => {
      const checkedFriend = { ...friend };
      if (!checkedFriend.isDisabled) checkedFriend.isChecked = true;
      return checkedFriend;
    }));
  };

  const changeFriendInCrew = (id) => {
    trackCheckedFriends(potentialCrew.map((friend) => {
      const checkedFriend = { ...friend };
      if (!checkedFriend.isDisabled && checkedFriend.id === id) checkedFriend.isChecked = !checkedFriend.isChecked;
      return checkedFriend;
    }));
  };

  const removeCrewMember = (id) => {
    trackCheckedFriends(potentialCrew.map((friend) => {
      const checkedFriend = { ...friend };
      if (checkedFriend.id === id) {
        checkedFriend.isDisabled = false;
        checkedFriend.isChecked = false;
      }
      return checkedFriend;
    }));

    const newCrew = [...crewList.filter((crewMember) => crewMember.id !== id)];
    setCrewList(newCrew);
    updateJourneyByID({ journeyID, crew: newCrew });
    dispatch(removeCrewUserFromJourneyThunk({ journeyID, crewID: id }));
  };

  return {
    potentialCrew, crewList, removeCrewMember, changeFriendInCrew, allFriendsInCrew, noFriendsInCrew,
  };
};

export default useJourneyCrew;
