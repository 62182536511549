import { selectJourneysState } from '@context/journeys/journeysSlice';
import { useSelector } from 'react-redux';

export const useCreator = (creatorID) => {
  const { creators } = useSelector(selectJourneysState);

  const [creator] = creators?.filter((creatorItem) => creatorItem.id === creatorID) || [null];

  if (!creator) return {};

  return creator;
};
