import React, { useCallback } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import { PlusOutlined, ArrowDownOutlined, PushpinFilled } from '@ant-design/icons';
// eslint-disable-next-line import/no-unresolved
import { useDrop } from 'react-dnd';
import {
  Typography, Button, HorizontalDateIcon, ItineraryCardRow, SimpleItineraryCardRow, TIME_FORMAT,
} from '@travelpassero/khaos';

import { ItemTypes } from '@utils/dragTypes';

import CardImageContainer from '@components/renderContainers/CardImageContainer';
import { Col, Row } from 'antd';

import { isSimpleItineraryCard } from '@utils/ideaCategory';
import debounce from 'lodash.debounce';
import ItineraryIdeaCardDnDContainer from './ItineraryIdeaCardDnDContainer';
import SimpleItineraryCardDnDContainer from './SimpleItineraryCardDnDContainer';

const DayCardContainer = styled.div`
  position: absolute;
  left: -46px;
  display: flex;
  align-items: center;
  z-index: 3;
`;

const Container = styled.div`
  padding: 16px;
  border: 1px solid var(--borders);
  border-radius: 4px;
  position: relative;
  background-color: var(--white);
  margin-bottom: 24px;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 7%);
`;

const PinnedIdea = styled.div`
  height: 40px;
  border: 1px solid var(--borders);
  border-radius: 4px;
  margin-bottom: 8px;
  position: relative;

  .pinned-idea-icon {
    height: 30px;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 8px;
  }
`;

const DragContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: -16px;
`;

const HoverBackGround = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--primary);
  height: 100%;
  width: 100%;
  opacity: 0.8;
  z-index: 4;
  margin: -2px;
  border: 2px dashed white;
`;

const DropBackGround = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: var(--icons-color-variant);
  height: 100%;
  width: 100%;
  opacity: 0.8;
  z-index: 4;
  margin: -2px;
  border: 2px dashed white;
`;

const ArrowContent = styled.div`
  display: flex;
  position: relative;
  height: 100%;
  z-index: 4;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

function ItineraryCardSection({
  date,
  ideas = [],
  isSplitScreen,
  isMobile,
  onDrawerAction,
  onOpenChange,
  onNoteChange,
  onDragCard,
  pinnedIdeas = [],
}) {
  const isNarrow = isSplitScreen || isMobile;

  let isHovering;
  let isDropping;
  let dropRef;

  if (!isMobile) {
    const [{ canDrop, isOver }, drop] = useDrop({
      accept: [ItemTypes.IDEACARD, ItemTypes.ITINERARYCARD],
      canDrop: (item) => !ideas?.filter((idea) => idea.id === item.id).length,
      drop: () => ({ name: date, type: 'itinerary' }),
      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    });
    dropRef = drop;
    const isActive = canDrop && isOver;

    if (isActive) {
      isDropping = true;
      isHovering = false;
    } else if (canDrop) {
      isHovering = true;
      isDropping = false;
    }
  }

  const getDayOfWeek = moment(date).format('ddd');
  const getDateMonth = moment(date).format('MMM');
  const getDateNumber = moment(date).format('D');

  const filterPinnedIdeas = (idea) => date === idea.itineraryDate
  || (date >= idea.itineraryDate && date <= idea.endDate);

  const handleNoteChangeCallBack = useCallback(debounce(async (e, id) => {
    const { value } = e.target || {};

    await onNoteChange(value, id);
  }, 1000), []);

  return (
    <Container isHovering={isHovering} isDropping={isDropping} ref={dropRef}>
      {!isMobile && isHovering ? (
        <DragContainer>
          <HoverBackGround />
          <ArrowContent>
            <ArrowDownOutlined style={{ color: 'white', fontSize: '2em' }} />
            <Typography type="secondaryHeading" size="1.125rem">
              Drop Here
            </Typography>
          </ArrowContent>
        </DragContainer>
      ) : null}

      {!isMobile && isDropping ? (
        <DragContainer>
          <DropBackGround />
          <ArrowContent>
            <ArrowDownOutlined style={{ color: 'white', fontSize: '2em' }} />
            <div style={{ height: '36px' }} />
            <Typography type="secondaryHeading" size="1.125rem">
              Drop Here
            </Typography>
          </ArrowContent>
        </DragContainer>
      ) : null}

      <div>
        <DayCardContainer>
          <HorizontalDateIcon
            dateDayOfWeek={getDayOfWeek}
            dateMonth={getDateMonth}
            dateNumber={getDateNumber}
          />
        </DayCardContainer>
      </div>
      <Row>
        {pinnedIdeas?.filter(filterPinnedIdeas).map((idea) => {
          const { id, title } = idea;
          return (
            <Col key={id} span={24}>
              <PinnedIdea>
                <div className="pinned-idea-icon">
                  <PushpinFilled
                    style={{
                      fontSize: '1.25rem',
                      color: 'var(--accent)',
                      padding: '4px',
                    }}
                  />
                </div>
                <Row
                  justify="center"
                  align="middle"
                  wrap={false}
                  style={{ margin: isNarrow ? '0 48px 0 76px' : '0 48px 0 96px', height: '100%' }}
                >
                  <Col>
                    <Typography
                      type="primarySubHeading"
                      style={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      {title}
                    </Typography>
                  </Col>
                </Row>
              </PinnedIdea>
            </Col>
          );
        })}
      </Row>
      {ideas?.length > 0 && ideas?.map((idea, index) => {
        const {
          id,
          imgSrcKey,
          ideaCategoryId,
          isEndIdea,
          itineraryDate,
        } = idea;
        // display idea as a simple card if specified or only contains a sample image
        if (isSimpleItineraryCard(ideaCategoryId) || !imgSrcKey || imgSrcKey?.includes('/ideaboard')) {
          if (isMobile) {
            return (
              <SimpleItineraryCardRow
                key={`${id}_${index}`}
                idea={idea}
                onOpenNoteClick={() => onDrawerAction('update-note', id)}
                onEditClick={() => onDrawerAction('edit-idea', id, itineraryDate)}
                onOpenChange={() => onOpenChange(id, isEndIdea)}
                isNarrow
              />
            );
          }
          return (
            <SimpleItineraryCardDnDContainer
              key={`${id}_${index}`}
              idea={idea}
              isSplitScreen={isSplitScreen}
              onOpenNoteClick={() => onDrawerAction('update-note', id)}
              onNoteChange={(val) => handleNoteChangeCallBack(val, id)}
              onEditClick={() => onDrawerAction('edit-idea', id, itineraryDate)}
              onOpenChange={() => onOpenChange(id, isEndIdea)}
              onDragCard={onDragCard}
            />
          );
        }

        return (
          <CardImageContainer
            key={`${index}_itinerary--card-row`}
            imgSrcKey={imgSrcKey}
            render={(imgSrcUrl) => {
              if (isMobile) {
                return (
                  <ItineraryCardRow
                    imgSrc={imgSrcUrl}
                    idea={idea}
                    onEditClick={() => onDrawerAction('edit-idea', id)}
                    onOpenNoteClick={() => onDrawerAction('update-note', id)}
                    onOpenChange={() => onOpenChange(id)}
                    isNarrow
                  />
                );
              }

              return (
                <ItineraryIdeaCardDnDContainer
                  imgSrc={imgSrcUrl}
                  idea={idea}
                  onEditClick={() => onDrawerAction('edit-idea', id)}
                  onOpenNoteClick={() => onDrawerAction('update-note', id)}
                  onNoteChange={(val) => handleNoteChangeCallBack(val, id)}
                  isSplitScreen={isSplitScreen}
                  onOpenChange={() => onOpenChange(id)}
                  onDragCard={onDragCard}
                />
              );
            }}
          />
        );
      })}

      <Row justify="center" style={{ paddingTop: '24px', marginLeft: isNarrow ? '20px' : '50px' }}>
        <Col>
          <Button
            type="default"
            onClick={() => onDrawerAction('new-idea', undefined, date)}
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <PlusOutlined style={{ display: 'flex', fontSize: '1.25rem', color: 'var(--accent)' }} />
            Add
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default ItineraryCardSection;
