import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import CardBanner from '@components/containers/CardBanner';
import { Empty, Typography } from '@travelpassero/khaos';
import { Col, Row } from 'antd';

import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTracking } from '@hooks/useTracking';
import PageSpinner from '@components/PageSpinner';
import { useDispatch, useSelector } from 'react-redux';
import { getSampleJourneysThunk } from '@context/journeys/journeysThunk';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import PublicJourneyCards from '@components/containers/PublicJourneyCards';
import PageScrollContainer from '@pages/PageScrollContainer';
import useOnPageLoad from '@hooks/useOnPageLoad';

const BannerContainer = styled.div`
  max-width:1285px;
  width:100%;
  margin:0 auto;
  padding:0 16px
`;
const CenterText = styled.div`
text-align: center;
padding: 32px 0 16px`;

const JourneyRow = styled(Row)`
  display:flex; 
  flex-direction:column;
  gap: 32px;
  max-width:1150px;
  width:100%;
  margin: 0 auto;
  padding: 0 16px;

  @media(min-width:768px){
    padding:0 30px;
  }
`;

const DiscoverRegionPage = () => {
  const history = useHistory();
  const { region: regionParam } = useParams();
  const dispatch = useDispatch();

  const { setPageView, setPage } = useTracking();
  setPageView(`Discover Region ${regionParam}`);
  setPage(`Discover Region ${regionParam}`);

  const discoverType = regionParam?.toLowerCase();

  const discoverTitle = discoverType.charAt(0).toUpperCase() + discoverType.slice(1);

  const bannerImgSrc = 'discover/banner/banner-'.concat(discoverType, '.jpg');

  const {
    sampleJourneysStatus, sampleJourneys,
  } = useSelector(selectJourneysState);

  const onPageLoad = () => dispatch(getSampleJourneysThunk({ region: regionParam }));
  const onCheckDataLoaded = () => !!sampleJourneys?.length;

  useOnPageLoad({ status: sampleJourneysStatus, onPageLoad, onCheckDataLoaded });

  useEffect(() => {
    if (regionParam?.toLowerCase() !== 'all'
    && sampleJourneysStatus === 'fulfilled'
    && sampleJourneys.length < 1
    ) {
      history.push('/discover');
      dispatch(getSampleJourneysThunk({}));
    }
  }, [regionParam, sampleJourneysStatus, sampleJourneys]);

  if (sampleJourneysStatus === 'pending') {
    return <PageSpinner />;
  }

  if (regionParam?.toLowerCase() === 'all' && sampleJourneysStatus === 'rejected') {
    return (
      <PageScrollContainer>
        <Empty
          heading="Nothing could be discovered right now"
          subHeading="Please try again later"
        />
      </PageScrollContainer>
    );
  }

  if (regionParam && sampleJourneysStatus === 'fulfilled') {
    return (
      <PageScrollContainer>
        <BannerContainer>
          <CardBanner
            imgSrc={bannerImgSrc}
          />
        </BannerContainer>
        <CenterText>
          <Typography
            size="1.5rem"
            type="secondaryText"
            style={{
              textTransform: 'uppercase',
              fontWeight: 500,
              lineHeight: '39px',
            }}
          >
            {`${discoverTitle} Itineraries`}
          </Typography>
        </CenterText>
        <JourneyRow>
          <Col>
            <PublicJourneyCards publicUserJourneys={sampleJourneys} />
          </Col>
        </JourneyRow>
      </PageScrollContainer>
    );
  }
};

export default DiscoverRegionPage;
