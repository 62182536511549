import React, { useState } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import { Col, Row, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@travelpassero/khaos';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { setLeftNavCollapse } from '@context/actions';
import Navigation from './sharedComponentContainers/Navigation';
import Logo from '../assets/passero_logo.svg';
import LogoIcon from '../assets/passero_icon.svg';

const Container = styled.div`
  background-color: var(--white);
  border-right: 1px solid var(--borders) !important;
  transition: width .2s;
  width: ${(props) => (props.isCollapsed ? '75px' : '215px')};
  height: 100%;
  min-height: 100vh;
  overflow: hidden;
`;

const LeftNavContainer = () => {
  const dispatch = useDispatch();
  const { globalStateReducer } = useSelector(selectAppState);
  const { isLeftNavCollapsed } = globalStateReducer;
  const [isCollapsed, setIsCollapsed] = useState(isLeftNavCollapsed);

  const handleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setTimeout(() => {
      dispatch(setLeftNavCollapse(!isCollapsed));
    }, 250);
  };

  return (
    <Container isCollapsed={isCollapsed}>
      <Row align="middle" style={{ height: '100%', flexFlow: 'column wrap' }}>
        <Col style={{
          width: '100%', height: '48px', textAlign: 'center', marginTop: '23px',
        }}
        >
          <Link to="/">
            {isCollapsed ? <LogoIcon height="42" width="100%" />
              : <Logo height="42" width="100%" />}
          </Link>
        </Col>
        <Col flex={16} style={{ width: '100%', paddingTop: '8px' }}>
          <Navigation isCollapsed={isCollapsed} />
        </Col>
        <Col flex={3} style={{ width: '100%' }}>
          <Row justify="start" align="middle">
            <Col style={{ paddingLeft: '22px' }}>
              <Tooltip title={isCollapsed ? 'Expand' : false} placement="right">
                <div>
                  <Button type="link" onClick={handleCollapse}>
                    <Row justify="center" align="middle" gutter={[13]} wrap={false}>
                      <Col>
                        <Typography
                          type="primaryText"
                          size="1.75rem"
                        >
                          {isCollapsed ? <RightCircleOutlined /> : <LeftCircleOutlined />}
                        </Typography>
                      </Col>
                      {!isCollapsed && (
                      <Col>
                        <Typography
                          type="primaryText"
                          size="1.125rem"
                          style={{
                            textDecoration: 'none',
                            fontWeight: '600',
                          }}
                        >
                          Collapse
                        </Typography>
                      </Col>
                      )}
                    </Row>
                  </Button>
                </div>
              </Tooltip>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default LeftNavContainer;
