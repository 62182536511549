import { Auth } from '@aws-amplify/auth';
import { ResetPasswordConfirmForm } from '@travelpassero/khaos';
import OnboardingLayout from '@components/layout/OnboardingLayout';
import {
  selectUserState, setOnboardingSuccessMessage, setUserLoader,
} from '@context/user/userSlice';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import BrandOnboarding from '@components/layout/BrandOnboarding';
import { OnboardingFormContainer } from '@styles/containers/OnboardingFormContainerStyle';

const ResetPasswordConfirmPage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redirectTo] = useState(queryParams.get('redirectTo'));
  const dispatch = useDispatch();

  const { onboarding } = useSelector(selectUserState);

  const handleVerifySignUpConfirmFinish = async (formData) => {
    const { email, code, password } = formData;
    const EMAIL = email.toLowerCase();

    try {
      dispatch(setUserLoader(true));
      await Auth.forgotPasswordSubmit(EMAIL, code, password);
      dispatch(setOnboardingSuccessMessage({ successMessage: 'Password successfully changed' }));
      history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  const handleOnSignInClick = () => {
    history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
  };

  const handleResendCodeClick = async (email) => {
    try {
      dispatch(setUserLoader(true));
      await Auth.resendSignUp(email);
      setErrorMessage('Code resent successfully!');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  return (
    <OnboardingLayout page="resetpasswordconfirm">
      <BrandOnboarding />
      <OnboardingFormContainer>
        <ResetPasswordConfirmForm
          onFinish={handleVerifySignUpConfirmFinish}
          onBackSignInClick={handleOnSignInClick}
          onResendCodeClick={() => handleResendCodeClick(onboarding.email)}
          errorMessage={errorMessage}
          email={onboarding.email}
        />
      </OnboardingFormContainer>
    </OnboardingLayout>
  );
};

export default ResetPasswordConfirmPage;
