import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { uploadFileThunk } from '@context/storage/storageThunk';
import { getSamplePhotosThunk, searchPhotosThunk, sendPhotoTrackingThunk } from '@context/photo/photoThunk';
import {
  selectPhotoState,
  setCategoryKeyReducer,
  setCarouselPhotosReducer,
  setPhotoSelectionLabelReducer,
  selectCarouselPhotoReducer,
  selectPhotoReducer,
} from '@context/photo/photoSlice';
import * as URL from '@utils/urls';
import { ideaCategories } from '@travelpassero/khaos';

const useCardPhoto = (selectedData, cardType) => {
  const dispatch = useDispatch();
  const {
    carouselPhotos,
    photoSelectionType,
    photo,
    samplePhotoStatus,
    searchPhotoStatus,
    categoryKey,
    photoSelectionLabel,
  } = useSelector(selectPhotoState);

  const [photoStatus, setPhotoStatus] = useState('idle');

  const getPhotosCategoryKey = (ideaCategoryId) => {
    let key;
    ideaCategories.forEach((category) => {
      if ([...category.types].find((type) => type.id === ideaCategoryId)) {
        key = category.photoKey?.toLowerCase();
      }
    });
    const newKey = key ? `${cardType}-${key}` : cardType;

    dispatch(setCategoryKeyReducer(newKey));
    return newKey;
  };

  const setPhotoSelectionLabel = (ideaCategoryId) => {
    let label;
    ideaCategories.forEach((category) => {
      category.types.forEach((type) => {
        if (ideaCategoryId === type.id) {
          label = type.label?.toLowerCase();
        }
      });
    });
    dispatch(setPhotoSelectionLabelReducer(label));
  };

  const getSamplePhotos = (ideaCategoryId) => {
    dispatch(getSamplePhotosThunk({ pathName: getPhotosCategoryKey(ideaCategoryId) }));
    setPhotoSelectionLabel(ideaCategoryId);
  };

  const setCarouselPhotos = () => {
    let selectedKey = carouselPhotos[Math.floor(Math.random() * carouselPhotos.length)]?.Key;

    const photosWithLabel = [...carouselPhotos].filter((photo) => photo.Key.includes(photoSelectionLabel?.replace('/', '_')));
    if (photosWithLabel.length) {
      selectedKey = photosWithLabel[Math.floor(Math.random() * photosWithLabel.length)]?.Key;
    }

    dispatch(setCarouselPhotosReducer({ selectedKey }));
  };

  useEffect(() => {
    if (selectedData && samplePhotoStatus === 'idle') {
      getSamplePhotos(selectedData?.ideaCategoryId);
    }
  }, [selectedData]);

  const setPhotoSelection = () => {
    const {
      imgSrcKey, photographerName, photographerProfile, ideaCategoryId,
    } = selectedData;
    if (imgSrcKey?.includes(cardType)) {
      if (imgSrcKey?.includes(getPhotosCategoryKey(ideaCategoryId))) {
        // check to see if the selected photo is a carousel photo
        const selectedCarouselPhoto = carouselPhotos.find((carouselPhoto) => carouselPhoto.Key === imgSrcKey);
        if (selectedCarouselPhoto) {
          dispatch(selectCarouselPhotoReducer({ Key: imgSrcKey }));
        } else setCarouselPhotos();
      } else setCarouselPhotos();

    // select the custom photo
    } else {
      const customPhoto = {
        Key: imgSrcKey,
        src: `${URL.photoStorage}public/${imgSrcKey}`,
        photographer: {
          name: photographerName,
          profile: photographerProfile,
        },
      };

      dispatch(selectPhotoReducer(customPhoto));
    }
  };

  useEffect(() => {
    if (carouselPhotos.length && samplePhotoStatus === 'fulfilled') {
      if (selectedData) setPhotoSelection();
      else setCarouselPhotos();
    }
  }, [samplePhotoStatus]);

  useEffect(() => {
    let status = 'idle';
    const statuses = [samplePhotoStatus, searchPhotoStatus];
    if (statuses.find((status) => status === 'rejected')) status = 'rejected';
    if (statuses.find((status) => status === 'pending')) status = 'pending';
    if (statuses.find((status) => status === 'fulfilled')) status = 'fulfilled';

    setPhotoStatus(status);
  }, [samplePhotoStatus, searchPhotoStatus]);

  const selectPhoto = (selectedPhoto) => {
    dispatch(selectPhotoReducer({ ...selectedPhoto, photoSelectionType: 'upload' }));
  };

  const selectCarouselPhoto = (selectedPhoto) => {
    if (photoSelectionType.toLowerCase() === 'sample') {
      dispatch(selectCarouselPhotoReducer(selectedPhoto));
    } else {
      dispatch(selectPhotoReducer({ ...selectedPhoto, photoSelectionType }));
    }
  };

  const searchPhotos = (query) => {
    dispatch(searchPhotosThunk({ query }));
  };

  const resetPhotoSelection = () => {
    dispatch(getSamplePhotosThunk({ pathName: categoryKey }));
  };

  const postPhoto = async () => {
    let { file } = photo;

    if (!file) {
      const fetchedPhoto = await fetch(photo.src);
      file = await fetchedPhoto.blob();
    }

    let folder = '';
    if (cardType === 'ideaboard') folder = 'idea-uploads';
    else if (cardType === 'journeyboard') folder = 'journey-uploads';

    // unsplash api usage requires a tracking link to be sent upon photo selection
    if (photo.trackingLink) {
      dispatch(sendPhotoTrackingThunk({ trackingLink: photo.trackingLink }));
    }

    return await dispatch(uploadFileThunk({
      key: `${folder}/${uuidv4()}.jpg`,
      file,
    })).unwrap();
  };

  return {
    getSamplePhotos,
    selectCarouselPhoto,
    selectPhoto,
    searchPhotos,
    photoStatus,
    resetPhotoSelection,
    postPhoto,
  };
};

export default useCardPhoto;
