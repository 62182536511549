import { createSlice } from '@reduxjs/toolkit';
import {
  getJourneyThunk,
} from '@context/journey/journeyThunk';

const initialState = {
  journeyStatus: 'idle',
  journey: null,
  loading: true,
  error: null,
};

export const journeySlice = createSlice({
  name: 'journey',
  initialState,
  reducers: {
    updateJourneyReducer(state, { payload }) {
      const { params } = payload;
      state.journey.data = { ...state.journey.data, ...params };
    },
    updateFolderReducer(state, { payload }) {
      const { folders } = payload;
      state.journey.data.folders = folders;
    },
    createIdeaReducer(state, { payload }) {
      const { idea } = payload;
      state.journey.data.ideas = [...state.journey.data.ideas || [], { ...idea }];
    },
    updateIdeaReducer(state, { payload }) {
      const { ideas } = payload;
      state.journey.data.ideas = ideas;
    },
    createFolderReducer(state, { payload }) {
      const { folder } = payload;
      state.journey.data.folders = [...state.journey.data.folders || [], { ...folder }];
    },
    updateQuickAddItineraryReducer(state, { payload }) {
      const { itineraryQuickAddItems } = payload;
      state.journey.data.itinerary.itineraryQuickAddItems = itineraryQuickAddItems || [];
    },
    resetDuplicateJourneyStatusReducer(state) {
      state.sentFriendRequestStatus = null;
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getJourneyThunk.pending, (state) => {
        state.journey = null;
        state.journeyStatus = 'pending';
      })
      .addCase(getJourneyThunk.fulfilled, (state, { payload }) => {
        state.journey = payload;
        state.journeyStatus = 'fulfilled';
      })
      .addCase(getJourneyThunk.rejected, ((state) => {
        state.journey = null;
        state.journeyStatus = 'rejected';
      }))
      .addMatcher((action) => action.type.endsWith('Folder/fulfilled'),
        (state, { payload }) => {
          state.journey.data.folders = payload.folders;
        })
      .addMatcher((action) => action.type.endsWith('Journey/rejected'),
        (state, { error }) => {
          state.error = error;
        });
  }),
});

export const selectJourneyState = (state) => state.journey;

export const {
  createJourneyReducer, updateJourneyReducer, leaveJourneyReducer,
  createIdeaReducer, updateIdeaReducer,
  updateFolderReducer, createFolderReducer, updateQuickAddItineraryReducer, resetDuplicateJourneyStatusReducer,
} = journeySlice.actions;

export default journeySlice.reducer;
