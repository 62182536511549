import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';

import Layout from '@components/Layout';

import { selectAppState } from '@context/global/appSlice';
import useLayout from '@hooks/useLayout';
import useFriend from '@hooks/useFriend';
import { useWindowSize } from '@hooks/useWindowSize';
import { useTracking } from '@hooks/useTracking';
import SplitScreenContainer from '@pages/splitscreen/SplitScreenContainer';
import { Col, Row, Button } from 'antd';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import { openCenterModal } from '@context/actions';
import { sortUsers, sortJourneys } from '@utils/sort';
import { filterUsers, filterJourneys } from '@utils/filter';
import useJourneyCrew from '@hooks/useJourneyCrew';
import FriendCenterModal from './FriendCenterModal';
import JourneyCrewContainer from './JourneyCrewContainer';
import FriendsContainer from './FriendsContainer';
import FriendsTopBar from './FriendsTopBar';
import JourneyCrewTopBar from './JourneyCrewTopBar';

const MobileViewButton = styled(Button)`
  height: 100% !important;
  width: 100%;
  background-color: var(--white);
  border: none;
  border-radius: 0 !important;
  padding: 0 !important;
  box-shadow: none;

  &:active, &:hover, &:focus {
    color: initial !important;
  }

  &.inactive {
    > * {
      background-color: var(--page-background) !important;
      border-bottom: 1px solid var(--borders) !important;
    }

    .journey-crew-top-bar {
      box-shadow: none !important;
    }

    .ant-typography {
      color: var(--text-grey) !important;
    }
  }

  > * {
    width: 100%;
    height: inherit;
  }

  .journey-crew-top-bar {
    border-left: 1px solid;
  }

  &::after {
    box-shadow: none !important;
  }
`;

const MyFriendsPage = () => {
  const { isMobile } = useWindowSize();
  const { setPageView, setPage } = useTracking();
  const { globalStateReducer } = useSelector(selectAppState);
  const { handleCloseModal } = useLayout();
  const dispatch = useDispatch();
  const { journeys } = useSelector(selectJourneysState);
  const {
    friends,
    receivedFriendRequests,
    sentFriendRequests,
    removeFriend,
  } = useFriend();

  const [isFriendMobileView, setIsFriendMobileView] = useState(true);
  const [selectedJourneyId, setSelectedJourneyId] = useState(null);
  const [tabKey, setTabKey] = useState('');
  const [viewType, setViewType] = useState('journeys');

  const [journeySearchQuery, setJourneySearchQuery] = useState('');
  const [crewSearchQuery, setCrewSearchQuery] = useState('');

  const [friendsList, setFriendsList] = useState([...friends]);
  const [receivedFriendRequestsList, setReceivedFriendRequestsList] = useState([...receivedFriendRequests]);
  const [sentFriendRequestsList, setSentFriendRequestsList] = useState([...sentFriendRequests]);

  const [journeysList, setJourneysList] = useState([...journeys]);
  const [crewListDisplay, setCrewListDisplay] = useState(null);

  const { crewList } = useJourneyCrew(selectedJourneyId);

  setPageView('My friends page');
  setPage('myfriends');

  setPageView('My friends page');
  setPage('myfriends');

  const {
    modalChildrenType,
    modalSelectedID,
  } = globalStateReducer;

  useEffect(() => {
    if (!selectedJourneyId) handleCloseModal();
  }, [selectedJourneyId]);

  useEffect(() => {
    setFriendsList([...friends]);
  }, [friends]);

  useEffect(() => {
    setSentFriendRequestsList([...sentFriendRequests]);
  }, [sentFriendRequests]);

  useEffect(() => {
    setReceivedFriendRequestsList([...receivedFriendRequests]);
  }, [receivedFriendRequests]);

  useEffect(() => {
    if (crewList) { setCrewListDisplay(filterUsers(crewSearchQuery, crewList)); }
  }, [crewList]);

  useEffect(() => {
    setJourneysList(filterJourneys(journeySearchQuery, journeys));
  }, [journeys]);

  const handleChangeMobileView = (state) => {
    setIsFriendMobileView(state);
  };

  const handleSortUsers = (order) => {
    if (tabKey === 'sent requests') {
      setSentFriendRequestsList(sortUsers(order, sentFriendRequestsList));
    } else if (tabKey === 'received requests') {
      setReceivedFriendRequestsList(sortUsers(order, receivedFriendRequestsList));
    } else {
      setFriendsList(sortUsers(order, friendsList));
    }
  };

  const handleSearchUsers = (query) => {
    if (tabKey === 'sent requests') {
      setSentFriendRequestsList(filterUsers(query, sentFriendRequests));
    } else if (tabKey === 'received requests') {
      setReceivedFriendRequestsList(filterUsers(query, receivedFriendRequests));
    } else {
      setFriendsList(filterUsers(query, friends));
    }
  };

  const handleSearchJourneyCrew = (query) => {
    if (viewType === 'crew') {
      setCrewSearchQuery(query);
      setCrewListDisplay(filterUsers(query, crewList));
    } else {
      setJourneySearchQuery(query);
      setJourneysList(filterJourneys(query, journeys));
    }
  };

  const handleSortJourneyCrew = (order) => {
    if (viewType === 'crew') {
      setCrewListDisplay(sortUsers(order, crewList));
    } else {
      setJourneysList(sortJourneys(order, journeys));
    }
  };

  const handleViewJourneyCrew = (id) => {
    setViewType('crew');
    setSelectedJourneyId(id);
  };

  const handleOnViewAllJourneys = () => {
    setViewType('journeys');
    setSelectedJourneyId(null);
  };

  const handleRemoveFriend = () => {
    removeFriend(modalSelectedID);
    handleCloseModal();
  };

  const handleOpenModal = (type) => {
    dispatch(openCenterModal(type));
  };

  return (
    <Layout>
      {isMobile ? (
        <>
          <Row>
            <Col span={12}>
              <MobileViewButton
                className={isFriendMobileView ? '' : 'inactive'}
                onClick={() => handleChangeMobileView(true)}
              >
                <FriendsTopBar
                  onSearch={handleSearchUsers}
                  onSort={handleSortUsers}
                  onOpenModal={handleOpenModal}
                  isMobile
                  viewType={tabKey}
                  showOptions={isFriendMobileView}
                />
              </MobileViewButton>
            </Col>
            <Col span={12}>
              <MobileViewButton
                className={isFriendMobileView ? 'inactive' : ''}
                onClick={() => handleChangeMobileView(false)}
              >
                <JourneyCrewTopBar
                  onSearch={handleSearchUsers}
                  onSort={handleSortUsers}
                  onOpenModal={handleOpenModal}
                  isMobile
                  viewType={viewType}
                  isFriendMobileView={isFriendMobileView}
                  showOptions={!isFriendMobileView}
                />
              </MobileViewButton>
            </Col>
          </Row>
          {isFriendMobileView ? (
            <FriendsContainer
              isMobile
              onOpenModal={handleOpenModal}
              setTabKey={setTabKey}
              friendsList={friendsList}
              receivedFriendRequestsList={receivedFriendRequestsList}
              sentFriendRequestsList={sentFriendRequestsList}
            />
          ) : (
            <JourneyCrewContainer
              journeyID={selectedJourneyId}
              isMobile={false}
              splitScreenSide="right"
              viewType={viewType}
              journeys={journeysList}
              crewListDisplay={crewListDisplay}
              onViewJourneyCrew={handleViewJourneyCrew}
              onViewAllJourneys={handleOnViewAllJourneys}
            />
          )}
        </>
      ) : (
        <SplitScreenContainer
          showCloseButton={false}
          SplitScreenChildLeft={(
            <>
              <FriendsTopBar
                isMobile={false}
                onSearch={handleSearchUsers}
                onSort={handleSortUsers}
                onOpenModal={handleOpenModal}
                viewType={tabKey}
              />
              <FriendsContainer
                isMobile={false}
                splitScreenSide="left"
                onOpenModal={handleOpenModal}
                setTabKey={setTabKey}
                friendsList={friendsList}
                receivedFriendRequestsList={receivedFriendRequestsList}
                sentFriendRequestsList={sentFriendRequestsList}
              />
            </>
        )}
          SplitScreenChildRight={(
            <>
              <JourneyCrewTopBar
                onSearch={handleSearchJourneyCrew}
                onSort={handleSortJourneyCrew}
                onOpenModal={handleOpenModal}
                isMobile={false}
                viewType={viewType}
              />
              <JourneyCrewContainer
                journeyID={selectedJourneyId}
                isMobile={false}
                splitScreenSide="right"
                viewType={viewType}
                journeys={journeysList}
                crewListDisplay={crewListDisplay}
                onViewJourneyCrew={handleViewJourneyCrew}
                onViewAllJourneys={handleOnViewAllJourneys}
              />
            </>
        )}
        />
      )}
      <FriendCenterModal
        type={modalChildrenType}
        onClose={handleCloseModal}
        onDeleteItem={handleRemoveFriend}
        journeyID={selectedJourneyId}
      />
    </Layout>
  );
};

export default MyFriendsPage;
