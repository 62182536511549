import React from 'react';
import { Carousel as AntCarousel } from 'antd';

const Carousel = ({
  children,
  dots = false,
  slidesToScroll = 4,
  slidesToShow = 4,
  centerMode = false,
  centerPadding = '5%',
  infinite,
  ...props
}) => (
  <AntCarousel
    dots={dots}
    slidesToScroll={slidesToScroll}
    slidesToShow={slidesToShow}
    centerMode={centerMode}
    centerPadding={centerPadding}
    draggable
    infinite={infinite}
    {...props}
    responsive={[
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          centerMode: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerMode: false,
          infinite: false,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          infinite: false,
        },
      },
    ]}
  >
    {children}
  </AntCarousel>
);

export default Carousel;
