import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import getDatesBetween from '@utils/getDatesBetween';
import { Typography, Button } from '@travelpassero/khaos';
import { useTracking } from '@hooks/useTracking';
import { useParams, Link } from 'react-router-dom';
import PublicItinerary from '@components/containers/PublicItinerary';
import PageSpinner from '@components/PageSpinner';
import { selectPublicState } from '@context/public/publicSlice';
import { getPublicJourneyThunk } from '@context/public/publicThunk';
import { Col, Row } from 'antd';
import background from '@assets/login-background.jpg';
import LogoIcon from '@assets/passero_icon.svg';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import useUser from '@hooks/useUser';
import useJourneys from '@hooks/useJourneys';
import useOnPageLoad from '@hooks/useOnPageLoad';

const PasseroImgContainer = styled.div`
  margin-top: 10%;
  text-align: center;
  position: relative;
  background: var(--white);
  z-index: 0;
  margin: 0 0 40px 0;
  height: 200px;
  overflow: hidden;

  .passero-background-image {
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 0;
  }
`;

const PasseroImgOverlay = styled.div`
  position: absolute;
  height: fit-content;
  width: fit-content;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 9;
  padding: 8px 12px;
`;

const PublicItineraryPage = () => {
  const { id } = useParams();
  const { setPageView, setPage } = useTracking();
  const dispatch = useDispatch();

  let dateRanges;

  setPageView('Public Itinerary');
  setPage('public itinerary');
  const { userID } = useUser();

  const { status: journeyStatus, journeys } = useSelector(selectJourneysState);
  const { publicJourneyStatus, publicJourney } = useSelector(selectPublicState);
  const { getJourneys } = useJourneys();

  const onPageLoadPublicJourneys = () => dispatch(getPublicJourneyThunk({ journeyID: id }));
  const onCheckPublicJourneysDataLoaded = () => !!publicJourney;

  useOnPageLoad({ status: publicJourneyStatus, onPageLoad: onPageLoadPublicJourneys, onCheckDataLoaded: onCheckPublicJourneysDataLoaded });

  useEffect(() => {
    if (userID && journeyStatus === 'idle') {
      getJourneys();
    }
  }, [userID, journeyStatus]);

  if (publicJourneyStatus === 'pending') {
    return <PageSpinner />;
  }

  if (publicJourneyStatus === 'fulfilled') {
    const { dateRangeStart, dateRangeEnd } = publicJourney || {};
    dateRanges = getDatesBetween(dateRangeStart, dateRangeEnd);

    const canDownloadItinerary = !journeys?.find((j) => j.pk === id);

    if (!!publicJourney?.data && !!dateRanges) {
      return (
        <PublicItinerary publicJourney={publicJourney} canDownloadItinerary={canDownloadItinerary} />
      );
    }

    return (
      <Row justify="center" align="middle">
        <Col span="24">
          <PasseroImgContainer>
            <Row justify="center" align="middle">
              <img className="passero-background-image" src={background} alt="passero" />
              <PasseroImgOverlay>
                <Row align="middle" wrap={false} gutter={24}>
                  <Col>
                    <LogoIcon
                      width="4rem"
                      height="auto"
                    />
                  </Col>
                  <Col>
                    <Button
                      type="default"
                      children={<Link to="/">See my Journeys</Link>}
                    />
                  </Col>
                </Row>
              </PasseroImgOverlay>
            </Row>
          </PasseroImgContainer>
        </Col>
        <Col>
          <Typography
            type="primaryHeading"
          >
            Itinerary not found.
          </Typography>
        </Col>
      </Row>
    );
  }

  return <div />;
};

export default PublicItineraryPage;
