import { createSlice } from '@reduxjs/toolkit';
import { getNotificationsThunk } from '@context/notification/notificationThunk';

const initialState = {
  status: 'idle',
  notifications: [],
  error: null,
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    removeNotificationsReducer(state, { payload }) {
      const { notifications } = payload;
      state.notifications = [...state.notifications].filter((currentNotification) => {
        if (notifications.find((notification) => notification.notificationId === currentNotification.notificationId)) return false;
        return true;
      });
    },
  },
  extraReducers: ((builder) => {
    builder
      .addCase(getNotificationsThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(getNotificationsThunk.fulfilled, (state, { payload }) => {
        state.notifications = payload;
        state.status = 'fulfilled';
      })
      .addCase(getNotificationsThunk.rejected, ((state) => {
        state.status = 'rejected';
      }));
  }),
});

export const selectNotificationState = (state) => state.notification;

export const {
  removeNotificationsReducer,
} = notificationSlice.actions;

export default notificationSlice.reducer;
