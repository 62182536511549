import { useDispatch } from 'react-redux';
import { closeDrawer, closeModal, closeSplitscreen } from '@context/actions';
import { useHistory } from 'react-router-dom';

const useLayout = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const handleCloseSplitScreen = (newPath) => {
    history.push(newPath);
  };

  return {
    handleCloseModal,
    handleCloseDrawer,
    handleCloseSplitScreen,
  };
};

export default useLayout;
