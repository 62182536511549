import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { Typography } from '@travelpassero/khaos';
import { Divider, Select } from 'antd';
import { selectJourneysState } from '@context/journeys/journeysSlice';
import { useJourney } from '@hooks/useJourney';

const { Option } = Select;

const StyledSelect = styled(Select)`
  height: 36px !important;
  width: 280px;

  .ant-select-selector {
    height: 36px !important;
    display: flex;
    align-items: center;

    .ant-select-selection-placeholder {
      align-items: center;
      display: flex;
      font-family: 'font-primary', sans-serif;
    }

    .ant-select-selection-item {
      align-items: center;
      font-family: 'font-primary', sans-serif;
      color: var(--primary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 220px;
    }
  }
`;

const JourneySelect = ({ journeyID, onSelectJourney, onViewAllJourneys }) => {
  const { journeys } = useSelector(selectJourneysState);
  const { title } = useJourney(journeyID);

  const handleMenuClick = (value) => {
    if (value === 'all' && onViewAllJourneys) onViewAllJourneys();
    else onSelectJourney(value);
  };

  const journeyOptions = [];

  if (onViewAllJourneys) {
    journeyOptions.push((
      <Option
        key="all"
        value="all"
      >
        <Typography
          size="1.25rem"
          type="primaryText"
        >
          All Journeys
        </Typography>
      </Option>
    ),
    (
      <Option key="divider1" value="" disabled style={{ cursor: 'default' }}>
        <Divider style={{ margin: '8px 0 0 0' }} />
      </Option>
    ));
  }

  if (journeys?.length) {
    journeys.forEach((item) => {
      const { pk, data } = item;
      journeyOptions.push(
        <Option key={pk} value={pk}>
          <Typography
            size="1.25rem"
            type="primaryText"
          >
            {data.title}
          </Typography>
        </Option>,
      );
    });
  } else journeyOptions.push(<Option key="none">No Journeys listed</Option>);

  return (
    <StyledSelect
      listHeight={500}
      value={(
        <Typography
          size="1.25rem"
          type="primaryText"
        >
          {title}
        </Typography>
        )}
      onChange={handleMenuClick}
    >
      {journeyOptions}
    </StyledSelect>
  );
};

export default JourneySelect;
