import React from 'react';

import { Typography, ItineraryAddDatesForm, DATE_FORMAT } from '@travelpassero/khaos';
import moment from 'moment';

const ItineraryIntroPage = ({ onFinish }) => (
  <div style={{ marginTop: '36px' }}>
    <div>
      <Typography type="primaryHeading" size="2rem" style={{ textAlign: 'center' }}>
        <span style={{ color: 'var(--secondary)' }}>
          Lets get your trip planned!
        </span>
        <br />
        By first adding your selected dates.
      </Typography>
      <Typography
        type="primaryText"
        size="1rem"
        style={{ textAlign: 'center', width: '100%', display: 'block' }}
      >
        (You can update the dates anytime)
      </Typography>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '42px',
        }}
      >
        <ItineraryAddDatesForm
          onFinish={onFinish}
          dateMinLimit={moment().format(DATE_FORMAT)}
        />
      </div>
    </div>
  </div>
);

export default ItineraryIntroPage;
