import React from 'react';
import styled from 'styled-components';

import {
  ResultsTable,
  SurveyResultCard,
  ResultsPreferred,
  Calendar,
  CenterModal,
  CalendarExpanded,
  SurveyCardFooter,
  DrawerModal,
  SurveyRespondedResultsContainer,
  Typography,
  SurveyRespondedResultsCard,
} from '@travelpassero/khaos';
import {
  openCenterModal,
  closeModal,
  openDrawer,
  closeDrawer,
} from '@context/actions';

import { useDispatch, useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import { useJourney } from '@hooks/useJourney';
import { simpleColumns } from '../configuration/resultTableColumns';

import SurveyTileResponseCardContainer from './SurveyTileResponseCardContainer';

import {
  formatSimple,
  formatBinary,
  formatBinaryResultsData,
  formatMultiple,
  formatDates,
  formatTiles,
  formatTileResultCards,
  formatUserInfo,
} from '../configuration/formatResultsData';
import CardPositioner from './CardPositioner';

const Container = styled.div`
  padding: 30px;
  position: relative;
  margin-bottom: 72px;
`;

function SurveyResultsCardContainer({
  survey,
  journeyData = {},
}) {
  const dispatch = useDispatch();
  const {
    globalStateReducer: {
      isCenterModalVisible,
      isDrawerVisible,
      modalChildrenType,
      drawerChildrenType,
      modalSelectedID,
      drawerSelectedID,
    },
  } = useSelector(selectAppState);
  const { crew, ideas } = journeyData;
  const { questions } = survey;

  const handleOnCalendarExpand = (id) => {
    dispatch(openCenterModal('calendar', id));
  };

  const handleBinaryResponseClick = (id) => {
    dispatch(openDrawer('binary-sidebar', id));
  };

  const handleCalendarResponseClick = (id) => {
    dispatch(openDrawer('calendar-sidebar', id));
  };

  const handleTilesResponseClick = (id) => {
    dispatch(openDrawer('tiles-sidebar', id));
  };

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };

  const getSelectedQuestionData = (id) => questions?.find((q) => q.id === id);

  const selectedDrawerData = getSelectedQuestionData(drawerSelectedID);

  let DrawerChildren;
  switch (drawerChildrenType) {
    case 'binary-sidebar':
      const { choices, responses: binaryResponses } = selectedDrawerData;
      const leftLabel = choices[0] && choices[0].option;
      const rightLabel = choices[1] && choices[1].option;

      const { binaryData1, binaryData2 } = formatBinaryResultsData(
        binaryResponses,
        crew,
      );

      const leftChoice = {
        name: leftLabel,
        color: 'var(--secondary)',
      };

      const rightChoice = {
        name: rightLabel,
        color: 'var(--accent)',
      };

      const data = [
        {
          key: 'selection1',
          userInfo: leftChoice,
          data: binaryData1,
        },
        {
          key: 'selection2',
          userInfo: rightChoice,
          data: binaryData2,
        },
      ];

      const showData = data
        && (binaryData1 || binaryData2)
        && (binaryData1 !== '' || binaryData2 !== '');

      DrawerChildren = (
        <SurveyRespondedResultsContainer>
          {showData ? (
            <ResultsTable columns={simpleColumns} data={data} />
          ) : (
            <Typography
              size="1.25rem"
              type="primarySubHeading"
              style={{ textAlign: 'center', padding: '26px 0' }}
            >
              No one has responded yet
            </Typography>
          )}
        </SurveyRespondedResultsContainer>
      );
      break;
    case 'calendar-sidebar':
      const { responses: calresponses } = selectedDrawerData;

      const { resultsTableData } = formatDates({
        responses: calresponses,
        crew,
      });

      DrawerChildren = (
        <SurveyRespondedResultsContainer>
          {resultsTableData ? (
            <ResultsTable columns={simpleColumns} data={resultsTableData} />
          ) : (
            <Typography
              size="1.25rem"
              type="primarySubHeading"
              style={{ textAlign: 'center', padding: '26px 0' }}
            >
              No one has responded yet
            </Typography>
          )}
        </SurveyRespondedResultsContainer>
      );
      break;

    case 'tiles-sidebar':
      const { responses: tileresponses } = selectedDrawerData;

      const ideaCards = formatTileResultCards({
        responses: tileresponses,
        crew,
        ideas,
      });

      DrawerChildren = (
        <SurveyRespondedResultsContainer>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {ideaCards
              && ideaCards.map((card) => {
                const {
                  firstName, lastName, isPendingFriend, options, iconColor,
                } = card;

                return (
                  <SurveyRespondedResultsCard
                    options={options}
                    firstName={firstName}
                    lastName={lastName}
                    isPendingFriend={isPendingFriend}
                    iconColor={iconColor}
                    style={{ margin: '12px' }}
                  />
                );
              })}
          </div>
          {!ideaCards ? (
            <Typography type="primarySubHeading">No one has responded yet</Typography>
          ) : null}
        </SurveyRespondedResultsContainer>
      );
      break;

    default:
      DrawerChildren = null;
  }

  const selectedQuestionData = getSelectedQuestionData(modalSelectedID);

  let ModalChildren;
  switch (modalChildrenType) {
    case 'calendar':
      const { responses, choices } = selectedQuestionData;

      const { calendarDateRange, calendarColorMultiRange } = formatDates({
        responses,
        choices,
        crew,
      });

      const userInfo = formatUserInfo(crew);

      ModalChildren = (
        <>
          <CalendarExpanded
            calendarDateRange={
              calendarDateRange || undefined
            }
            calendarColorRange={calendarColorMultiRange}
          />
          <SurveyCardFooter
            isLargeFormat="false"
            isCalendar
            isCalendarModal
            isMultiColored
            userInfo={userInfo}
            onQuestionResponseClick={() => handleCalendarResponseClick(modalSelectedID)}
          />
        </>
      );
      break;

    default:
      ModalChildren = null;
  }

  const questionCards = [];

  if (questions) {
    questionCards.push(questions.map((item, index) => {
      const {
        title, responses, type, id, choices,
      } = item;

      let responseButton;
      let isLargeFormat;
      let isCalendar;
      let Component;
      let responseFunctionCall;
      switch (type) {
        case 'simple':
          Component = responses ? (
            <ResultsTable
              columns={simpleColumns}
              data={formatSimple(responses, crew)}
            />
          ) : (
            <Typography
              size="1.25rem"
              type="primarySubHeading"
              style={{ textAlign: 'center', padding: '26px 0' }}
            >
              No one has responded yet
            </Typography>
          );
          responseButton = false;
          isLargeFormat = 'false';
          break;

        case 'checkbox':
          const getLabels = {
            leftLabel: choices[0] && choices[0].option,
            rightLabel: choices[1] && choices[1].option,
          };
          const getBinary = formatBinary(responses, getLabels);

          Component = (
            <ResultsPreferred {...getBinary} style={{ height: '100%' }} />
          );

          responseFunctionCall = () => handleBinaryResponseClick(id);
          responseButton = true;
          isLargeFormat = 'false';
          break;

        case 'multiple':
          const getMultiple = formatMultiple({ responses, choices, crew });

          Component = getMultiple ? (
            <ResultsTable columns={simpleColumns} data={getMultiple} />
          ) : (
            <Typography
              size="1.25rem"
              type="primarySubHeading"
              style={{ textAlign: 'center', padding: '26px 0' }}
            >
              No one has responded yet
            </Typography>
          );
          responseButton = false;
          isLargeFormat = 'false';
          break;

        case 'date':
          const {
            calendarDateRange,
            calendarSingleColorRange,
          } = formatDates({ responses, choices, crew });

          Component = calendarDateRange ? (
            <Calendar
              calendarDateRange={calendarDateRange}
              calendarColorRange={calendarSingleColorRange}
              onCalendarExpandClick={() => handleOnCalendarExpand(id)}
            />
          ) : (
            <Typography
              size="1.25rem"
              type="primarySubHeading"
              style={{ textAlign: 'center', padding: '26px 0' }}
            >
              No one has responded yet
            </Typography>
          );

          responseFunctionCall = () => handleCalendarResponseClick(id);
          isCalendar = true;
          responseButton = true;
          isLargeFormat = 'false';
          break;

        case 'tiles':
          const ideaData = formatTiles({ responses, choices, ideas });

          Component = (
            <SurveyTileResponseCardContainer crew={crew} data={ideaData} />
          );

          responseFunctionCall = () => handleTilesResponseClick(id);
          responseButton = true;
          isLargeFormat = 'true';
          break;

        default:
          Component = null;
      }

      return (
        <SurveyResultCard
          key={`${index}_survey--results-card`}
          questionNumber={index + 1}
          questionTitle={title}
          responseButton={responseButton}
          isLargeFormat={isLargeFormat}
          isCalendar={isCalendar}
          onQuestionResponseClick={responseFunctionCall}
        >
          {Component}
        </SurveyResultCard>
      );
    }));
  }

  return (
    <Container>
      <CardPositioner
        cardMaxWidth={750}
        cardMinWidth={500}
        cards={questionCards}
      />
      {ModalChildren ? (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={ModalChildren}
          modalWidth="1150px"
        />
      ) : null}

      {DrawerChildren ? (
        <DrawerModal
          handleOnClose={handleCloseDrawer}
          showModal={isDrawerVisible}
          children={DrawerChildren}
          maxWidth="600px"
        />
      ) : null}
    </Container>
  );
}

export default SurveyResultsCardContainer;
