import { selectUserState } from '@context/user/userSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

export const useAfterLogin = () => {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redirectTo] = useState(queryParams.get('redirectTo'));
  const { user } = useSelector(selectUserState);

  useEffect(() => {
    if (user) history.push(redirectTo || '/');
  }, [user, redirectTo]);

  return { redirectTo };
};
