import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import {
  AddCard,
  DrawerModal,
  IdeaForm,
  CenterModal,
  DeleteItemForm,
  EditFolderModal,
  IdeaCard,
  DropCard,
  UploadPhotoForm,
  Typography,
  DATE_FORMAT,
} from '@travelpassero/khaos';
import {
  openDrawer,
  openCenterModal,
  addSelectedIdeaToSurvey,
  setModalLoadingState,
  closeDrawer,
  setDrawerLoadingState,
} from '@context/actions';

import useCardPhoto from '@hooks/useCardPhoto';
import { useJourney } from '@hooks/useJourney';
import useIdea from '@hooks/useIdea';

import GetUserTableItemContainer from '@components/renderContainers/GetUserTableItemContainer';
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import IdeaCardDnDContainer from '@components/containers/IdeaCardDnDContainer';
import FolderDnDContainer from '@components/containers/FolderDnDContainer';

import { selectAppState } from '@context/global/appSlice';
import useLayout from '@hooks/useLayout';
import useUser from '@hooks/useUser';
import { useWindowSize } from '@hooks/useWindowSize';
import DropContainer from '@components/containers/DropContainer';
import { selectPhotoState } from '@context/photo/photoSlice';
import SidePageSpinner from '@components/SidePageSpinner';
import { Col, Row } from 'antd';
import defaultIdeas from '@utils/defaultIdeas';
import { photoStorage } from '@utils/urls';
import PageSpinner from '@components/PageSpinner';
import CardPositioner from './CardPositioner';

const HiddenCardOverlay = styled.div`
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background: var(--page-background);
  opacity: .5;
`;

const NoIdeaContainer = styled.div`
  height: 116px;
  width: 100%;
  padding: 16px 0;
  overflowY: visable;

  .ant-typography {
    margin-left: 16px;
  } 

  .line-arrow {
    margin: 16px 0 0 -3px;
    border-top: 3px solid;
    border-left: 3px solid;
    border-color: var(--secondary);
    border-top-left-radius: 20px;
    min-height: 136px;
    height: 100%;
    min-width: 28px;
    position: relative;

    .arrow {
      border-right: 3px solid;
      border-bottom: 3px solid;
      border-color: var(--secondary);
      display: inline-block;
      padding: 8px;
      position: absolute;
      bottom: 0;
      left: -11px;
      transform: rotate(45deg);
    }
  }

  @media(max-width: 535px) {
    .line-arrow {
      position: absolute;
      margin-left: -120px;
      border-bottom: 3px solid;
      border-color: var(--secondary);
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      min-height: 162px;

      .arrow {
        transform: rotate(-45deg);
        bottom: -11px;
        left: 6px;
      }
    }

    .ant-typography {
      margin-left: -16px;
    } 
  }
`;

function IdeaCardContainer({
  useJourneyData = {},
  selectedFolderKey,
  handleFolderMenuClick,
  isSplitScreen,
}) {
  const { isMobile } = useWindowSize();
  const dispatch = useDispatch();
  const {
    globalStateReducer: {
      isCenterModalVisible,
      isDrawerVisible,
      drawerChildrenType,
      modalChildrenType,
      drawerSelectedID,
      modalSelectedID,
      drawerLoadingState,
    },
  } = useSelector(selectAppState);
  const {
    carouselPhotos,
    photo,
    photoSelectionType,
  } = useSelector(selectPhotoState);
  const { handleCloseModal, handleCloseDrawer } = useLayout();
  const {
    ideas,
    folders,
    title,
    itinerary,
    createIdea,
    editIdea,
    updateJourney,
    updateIdea,
    updateFolder,
    deleteFolder,
    deleteIdea,
    deleteFolderAndChildren,
  } = useJourneyData;
  const { userID } = useUser();
  const { selectedIdea } = useIdea(drawerSelectedID);
  const {
    getSamplePhotos,
    selectCarouselPhoto,
    selectPhoto,
    searchPhotos,
    postPhoto,
    resetPhotoSelection,
    photoStatus,
  } = useCardPhoto(selectedIdea, 'ideaboard');

  const { dateRangeStart, dateRangeEnd } = itinerary || {};

  const [foldersList, setFoldersList] = useState([]);
  const [childFolders, setChildFolders] = useState([]);
  const [ideasList, setIdeasList] = useState([]);
  const [isNoIdeas, setIsNoIdeas] = useState(false);

  const handleOnCategoryTypeChange = (ideaCategoryId) => {
    getSamplePhotos(ideaCategoryId);
  };

  const handleOnIdeaCreate = async (formData) => {
    dispatch(setDrawerLoadingState(true));
    let { key } = photo;
    if (!key) { key = await postPhoto(); }

    createIdea({
      formData,
      photoData: {
        imgSrcKey: key,
        photographerName: photo.photographerName,
        photographerProfile: photo.photographerProfile,
      },
      userID,
    });
    handleCloseDrawer();
    dispatch(setDrawerLoadingState(false));
  };

  const handleOnIdeaEdit = async (formData) => {
    dispatch(setDrawerLoadingState(true));
    let { key } = photo;
    if (!key) { key = await postPhoto(); }

    dispatch(closeDrawer());
    editIdea({
      drawerSelectedID,
      photoData: {
        imgSrcKey: key,
        photographerName: photo.photographerName,
        photographerProfile: photo.photographerProfile,
      },
      formData,
    });
    dispatch(setDrawerLoadingState(false));
  };

  const handleOnIdeaDelete = async (ideaID) => {
    deleteIdea({ ideaID });
  };

  const handleFolderAndChildrenDelete = async () => {
    deleteFolderAndChildren({ folderID: modalSelectedID });
  };

  const handleOnFolderEdit = async (formData) => {
    updateFolder({ formData, modalSelectedID });
  };

  const handleFolderDelete = async () => {
    deleteFolder({ folderID: modalSelectedID });
  };

  const handleOnIdeaToFolderChange = async (ideaID, folderID = '') => {
    updateIdea({
      ideaID, selectedIdea, folderKey: folderID,
    });
  };

  const handleOnFolderToFolderChange = async (folderId, parentFolderKey = '') => {
    const updatedFolders = folders.map((folder) => {
      if (folder.id === folderId) return { ...folder, parentFolderKey };
      return folder;
    });
    updateJourney({ folders: updatedFolders });
  };

  const handleOnIdeaItineraryAdd = async (ideaID, date) => {
    updateIdea({
      ideaID, itineraryDate: date ? moment(date).format(DATE_FORMAT) : undefined,
    });
  };

  const handleOnIdeaAddToSurvey = (id, questionNumber) => {
    dispatch(addSelectedIdeaToSurvey({ surveyIdeaID: id, questionNumber }));
  };

  const handleDrawerAction = (action, id, isFeatured) => {
    if (isFeatured) dispatch(openDrawer('featured', id));
    else dispatch(openDrawer(action, id));
  };

  const handleCenterModalAction = (action, id) => {
    dispatch(openCenterModal(action, id));
  };

  const handleDeleteItem = async () => {
    dispatch(setModalLoadingState(true));
    await handleOnIdeaDelete(modalSelectedID);
    dispatch(setModalLoadingState(false));

    handleCloseModal();
  };

  const handleOnUpload = (selectedFile) => {
    selectPhoto(selectedFile);
    handleCloseModal();
  };

  const getSelectedFolderData = (id) => folders && folders.find((item) => item.id === id);

  let DrawerChildren;
  switch (drawerChildrenType) {
    case 'add':
    case 'edit':
      DrawerChildren = (
        <IdeaForm
          tripTitle={title}
          onFormFinish={drawerChildrenType === 'edit' ? handleOnIdeaEdit : handleOnIdeaCreate}
          folders={folders}
          itineraryStartDate={dateRangeStart}
          itineraryEndDate={dateRangeEnd}
          data={selectedIdea}
          selectedFolderKey={selectedFolderKey}
          imgSelectionArray={carouselPhotos}
          onSelectCarouselPhoto={selectCarouselPhoto}
          onSearchPhotos={searchPhotos}
          onOpenUploadModal={() => handleCenterModalAction('upload')}
          photoStatus={photoStatus}
          onResetPhotoSelection={resetPhotoSelection}
          photoSelectionType={photoSelectionType}
          photo={photo}
          onAddFolderClick={() => handleCenterModalAction('folder')}
          onCategoryTypeChange={handleOnCategoryTypeChange}
          onlySelectCategories
        />
      );
      break;

    default:
      DrawerChildren = null;
  }

  let ModalChildren;
  let Modal;
  const selectedFolderData = getSelectedFolderData(modalSelectedID);

  switch (modalChildrenType) {
    case 'edit-folder':
      Modal = (
        <EditFolderModal
          showModal={isCenterModalVisible}
          onClose={handleCloseModal}
          onFinish={handleOnFolderEdit}
          title={selectedFolderData?.title}
          id={selectedFolderData?.id}
          folders={[...folders]}
          selectedFolderKey={selectedFolderKey}
        />
      );
      break;

    case 'delete-idea':
      ModalChildren = (
        <DeleteItemForm
          item="Idea"
          handleOnClose={handleCloseModal}
          handleDeleteItem={handleDeleteItem}
        />
      );
      break;

    case 'delete-folder':
      ModalChildren = (
        <DeleteItemForm
          item="folder"
          handleOnClose={handleCloseModal}
          handleDeleteItem={handleFolderDelete}
          twoOptions
          content="ideas"
          handleDeleteContent={handleFolderAndChildrenDelete}
        />
      );
      break;

    case 'upload':
      ModalChildren = (
        <UploadPhotoForm
          onClose={handleCloseModal}
          onUpload={handleOnUpload}
        />
      );
      break;

    default:
      ModalChildren = null;
  }

  useEffect(() => {
    setIsNoIdeas(false);
    const folderKeys = folders?.map((key) => key.id);

    setFoldersList(folderKeys && ideas
      ? ideas.filter((idea) => folderKeys.includes(idea.folderKey))
      : []);

    let ideaData = folderKeys && ideas
      ? ideas.filter((idea) => !folderKeys.includes(idea.folderKey))
      : ideas;

    let childFolders = folders?.filter((folder) => !folderKeys.includes(folder.parentFolderKey));

    if (selectedFolderKey) {
      ideaData = ideas?.filter((idea) => idea.folderKey === selectedFolderKey);
      childFolders = folders?.filter((folder) => folder.parentFolderKey === selectedFolderKey);
    }

    if (!isSplitScreen && !ideaData?.length && !childFolders?.length) {
      ideaData = defaultIdeas;
      setIsNoIdeas(true);
    }

    setIdeasList(ideaData);
    setChildFolders(childFolders);
  }, [ideas, folders, selectedFolderKey]);

  useEffect(() => {
    const abortController = new AbortController();
    return () => abortController.abort();
  }, []);

  const ideaCards = [(
    <AddCard
      key="empty__card"
      onClick={() => handleDrawerAction('add', null)}
      type="idea"
    />
  )];

  if (childFolders) {
    ideaCards.push(...childFolders.map((folder, index) => {
      const { title, id: folderID } = folder;

      const cardsInFolder = foldersList?.filter((item) => item.folderKey === folderID);
      const foldersInFolder = folders?.filter((item) => item.parentFolderKey === folderID);

      return (
        <FolderDnDContainer
          key={`${index}__card-folder`}
          cards={cardsInFolder && cardsInFolder.map((cardInFolder) => {
            const img = `public/${cardInFolder.imgSrcKey?.replace('public/', '')}`;
            const imgSrc = `${photoStorage}${img}`;
            return { ...cardInFolder, imgSrc };
          })}
          childFolders={foldersInFolder && foldersInFolder.map((folderInFolder) => {
            const innerCards = ideas ? ideas.filter((idea) => idea.folderKey === folderInFolder.id) : [];
            return { ...folderInFolder, innerCards };
          })}
          title={title}
          folderID={folderID}
          canDrag={!isMobile}
          onCenterModalAction={handleCenterModalAction}
          onFolderMenuClick={handleFolderMenuClick}
          onIdeaToFolderChange={handleOnIdeaToFolderChange}
          onFolderToFolderChange={handleOnFolderToFolderChange}
          onIdeaItineraryAdd={handleOnIdeaItineraryAdd}
          onIdeaAddToSurvey={handleOnIdeaAddToSurvey}
        />
      );
    }));
  }

  if (ideasList) {
    ideaCards.push(...ideasList.map((item, index) => {
      const {
        id, imgSrcKey, creator, ideaType,
      } = item;

      const isFeatured = ideaType === 'deal';

      return (
        <div style={{ position: 'relative', display: 'inline-flex' }} key={`${index}_idea--card`}>
          {isNoIdeas && <HiddenCardOverlay />}
          <GetUserTableItemContainer
            userID={creator}
            render={({ firstName, lastName }) => {
              const creatorName = (firstName || lastName) ? `${firstName || ''} ${lastName?.charAt(0).toUpperCase()}` : null;
              return (
                <CardImageContainer
                  imgSrcKey={imgSrcKey}
                  render={(imgSrcUrl) => {
                    if (isMobile) {
                      return (
                        <IdeaCard
                          {...item}
                          onEditClick={() => handleDrawerAction('edit', id, isFeatured)}
                          onDeleteClick={() => handleCenterModalAction('delete-idea', id)}
                          creator={creatorName}
                          imgSrc={imgSrcUrl}
                        />
                      );
                    }
                    return (
                      <IdeaCardDnDContainer
                        {...item}
                        id={id}
                        handleOnFolderChange={handleOnIdeaToFolderChange}
                        OnIdeaItineraryAdd={handleOnIdeaItineraryAdd}
                        onEditClick={() => handleDrawerAction('edit', id, isFeatured)}
                        onDeleteClick={() => handleCenterModalAction('delete-idea', id)}
                        onIdeaAddToSurvey={handleOnIdeaAddToSurvey}
                        creator={creatorName}
                        imgSrc={imgSrcUrl}
                      />
                    );
                  }}
                />
              );
            }}
          />
        </div>
      );
    }));
  }

  if (!isMobile) {
    ideaCards.push((
      <DropContainer type="idea" folderID={selectedFolderKey} key="drop__card">
        <DropCard style={{
          width: '100%', height: '325px', margin: 16, position: 'relative',
        }}
        />
      </DropContainer>
    ));
  }

  if (isNoIdeas) {
    ideaCards.unshift(
      <NoIdeaContainer key="no_idea--card">
        <Row wrap={false} style={{ height: '100%' }}>
          <Col span={8}>
            <div className="card-positioner-margin-center">
              <div className="line-arrow"><div className="arrow" /></div>
            </div>
          </Col>
          <Col flex={1}>
            <Typography size={isMobile ? '1.5rem' : '1.75rem'} type="primarySubHeading">
              Start adding ideas for your journey
            </Typography>
          </Col>
        </Row>
      </NoIdeaContainer>,
    );
  }

  return (
    <Row justify="start" style={{ padding: '16px' }}>
      <Col flex={1}>
        <CardPositioner
          cardMaxWidth={isMobile ? 400 : 300}
          cardMinWidth={250}
          rowWidthOffset={2}
          cards={ideaCards}
        />
        {DrawerChildren ? (
          <DrawerModal
            handleOnClose={handleCloseDrawer}
            showModal={isDrawerVisible}
            children={drawerLoadingState ? <SidePageSpinner /> : DrawerChildren}
            maxWidth="600px"
          />
        ) : null}

        {ModalChildren ? (
          <CenterModal
            footer={null}
            handleOnClose={handleCloseModal}
            showModal={isCenterModalVisible}
            children={ModalChildren}
            modalWidth={isMobile ? '100%' : '500px'}
          />
        ) : Modal || null}
      </Col>
    </Row>
  );
}

export default IdeaCardContainer;
