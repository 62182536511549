import React from 'react';
import Spinner from './Spinner';

const ModalSpinner = () => (
  <div
    style={{
      height: '500px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Spinner size={104} />
  </div>
);

export default ModalSpinner;
