import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import {
  JourneyCard, DropCard, Button, Empty, AddCard,
} from '@travelpassero/khaos';
import {
  Col, Row, Divider,
} from 'antd';
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import DropContainer from '@components/containers/DropContainer';
import { openCenterModal } from '@context/actions';
import { Link } from 'react-router-dom';
import PageScrollContainer from '@pages/PageScrollContainer';
import CrewList from '@components/sharedComponentContainers/CrewList';
import useJourneyCrew from '@hooks/useJourneyCrew';
import CardPositioner from '@components/containers/CardPositioner';
import JourneySelect from './JourneySelect';

const JourneysContainer = styled.div`
  .pagination-data {
    padding: 12px 12px 50px 12px;
  }
`;

const CrewContainer = styled.div`
  padding: 8px 16px 0 18px;
`;

const LinkWrapper = styled.span`
  a {
    padding: 0;
    color: inherit;
    font-weight: bold;
    line-height: inherit;
    height: inherit;
    border: none;
    text-decoration: underline;

    span {
      text-decoration: underline;
    }

    &:hover,
    &:focus {
      color: var(--secondary) !important;
    }
  }
`;

const EmptyDndContainer = ({ isHovering, isDropping }) => {
  if (isHovering || isDropping) {
    return (
      <DropCard
        isHovering={isHovering}
        isDropping={isDropping}
        style={{
          position: 'relative',
          height: '68px',
          margin: '8px',
        }}
      />
    );
  }

  return <div />;
};

const JourneyCrewBody = ({
  viewType, journeys = [], journeyID, onViewJourneyCrew, onViewAllJourneys, crew,
}) => {
  const { crewList, removeCrewMember } = useJourneyCrew(journeyID);
  const dispatch = useDispatch();

  const handleOpenModal = (type) => {
    dispatch(openCenterModal(type));
  };

  switch (viewType) {
    case 'journeys':
      if (journeys.length) {
        return (
          <JourneysContainer>
            <CardPositioner
              cardMaxWidth={420}
              cardMinWidth={250}
              rowWidthOffset={25}
              usePagination
              cardsPerPage={12}
              cards={journeys.map((item) => {
                const {
                  pk,
                  data: {
                    crew, imgSrcKey, ...params
                  },
                } = item;

                return (
                  <CardImageContainer
                    key={pk}
                    imgSrcKey={imgSrcKey}
                    render={(imgSrcUrl) => (
                      <DropContainer type="journey" folderID={pk}>
                        <JourneyCard
                          onCardClick={() => onViewJourneyCrew(pk)}
                          myCrewUsers={crew || []}
                          imgSrc={imgSrcUrl}
                          {...params}
                        />
                      </DropContainer>
                    )}
                  />
                );
              })}
            />
          </JourneysContainer>
        );
      }
      return (
        <Empty
          heading="You don’t have any journeys yet"
          subHeading={(
            <>
              Why don’t you go ahead and
              {' '}
              <LinkWrapper>
                <Link to="/">create your first journey</Link>
              </LinkWrapper>
            </>
          )}
          style={{ paddingTop: '58px' }}
        />
      );

    case 'crew':
      return (
        <>
          <Row>
            <Col span={24}>
              <Divider style={{ paddingTop: '12px' }}>
                <JourneySelect
                  journeyID={journeyID}
                  onSelectJourney={onViewJourneyCrew}
                  onViewAllJourneys={onViewAllJourneys}
                />
              </Divider>
            </Col>
          </Row>
          <CrewContainer>
            {crew?.length ? (
              <CrewList
                list={crewList}
                onOpenAdd={() => handleOpenModal('add crew')}
                onRemove={removeCrewMember}
                removeConfirmText="Remove crew member?"
                cardsPerPage={12}
                dropLocation={journeyID}
              />
            ) : (
              <>
                <CardPositioner
                  cardMaxWidth={450}
                  rowWidthOffset={2}
                  center
                  cards={[(
                    <AddCard
                      key="empty-crew-card"
                      type="crew"
                      onClick={() => handleOpenModal('add crew')}
                    />
                  ),
                  (
                    <DropContainer type="add-crew" folderID={journeyID} isEmpty key="epmty-drop-card">
                      <EmptyDndContainer />
                    </DropContainer>
                  )]}
                />
                <Empty
                  heading="You don’t have any crew yet"
                  subHeading={(
                    <>
                      Why don’t you go ahead and
                      {' '}
                      <Button
                        type="link"
                        onClick={() => handleOpenModal('add friends')}
                      >
                        send a friend request
                      </Button>
                      {' '}
                      or click the &quot;Add Crew&quot; button above.
                    </>
                  )}
                  style={{ paddingTop: '58px' }}
                />
              </>
            )}
          </CrewContainer>
        </>
      );

    default: <div />;
  }
};

function JourneyCrewContainer({
  journeyID,
  splitScreenSide,
  viewType,
  journeys,
  crewListDisplay,
  onViewJourneyCrew,
  onViewAllJourneys,
}) {
  return (
    <Row style={{ backgroundColor: 'var(--white)' }}>
      <Col span={24}>
        <PageScrollContainer splitScreenSide={splitScreenSide}>
          <JourneyCrewBody
            viewType={viewType}
            journeys={journeys}
            journeyID={journeyID}
            crew={crewListDisplay}
            onViewAllJourneys={onViewAllJourneys}
            onViewJourneyCrew={onViewJourneyCrew}
          />
        </PageScrollContainer>
      </Col>
    </Row>
  );
}

export default JourneyCrewContainer;
