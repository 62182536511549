import { VerifySignUpForm } from '@travelpassero/khaos';
import OnboardingLayout from '@components/layout/OnboardingLayout';
import { Auth } from '@aws-amplify/auth';
import { setOnboarding, setUserLoader } from '@context/user/userSlice';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import BrandOnboarding from '@components/layout/BrandOnboarding';

const VerifySignUpPage = () => {
  const [errorMessage, setErrorMessage] = useState();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [redirectTo] = useState(queryParams.get('redirectTo'));

  const handleVerifySignUpConfrmFinish = async (formData) => {
    const { email } = formData;

    const EMAIL = email.toLowerCase();

    try {
      dispatch(setUserLoader(true));
      await Auth.resendSignUp(EMAIL);
      dispatch(setOnboarding({ email: EMAIL }));
      setErrorMessage('Code resent successfully!');
      history.push(redirectTo ? `/register/confirm?redirectTo=${redirectTo}` : '/register/confirm');
    } catch (error) {
      setErrorMessage(error.message);
    } finally {
      dispatch(setUserLoader(false));
    }
  };

  const handleOnSignInClick = () => {
    history.push(redirectTo ? `/login?redirectTo=${redirectTo}` : '/login');
  };

  return (
    <OnboardingLayout page="verifysignup">
      <BrandOnboarding />
      <VerifySignUpForm
        onFinish={handleVerifySignUpConfrmFinish}
        onBackSignInClick={handleOnSignInClick}
        errorMessage={errorMessage}
      />
    </OnboardingLayout>
  );
};

export default VerifySignUpPage;
