import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '@utils/dragTypes';
import ChildFolderCard from '@components/sharedComponentContainers/ChildFolderCard';

function ChildFolderDnDContainer({
  handleOnFolderChange,
  id,
  title,
  innerCards,
  parentFolderKey,
}) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IDEACARD,
    item: { id, folderId: parentFolderKey },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && (dropResult.type === 'folder' || dropResult.type === 'idea')) {
        handleOnFolderChange(id, dropResult.name);
      }
      return null;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <ChildFolderCard title={title} innerCards={innerCards} dragRef={drag} />
  );
}

export default ChildFolderDnDContainer;
