import moment from 'moment';
import { DATE_FORMAT } from '@travelpassero/khaos';

export function getDatesBetweenDates(startDate, endDate) {
  const dateArray = [];
  let currentDate = moment(startDate);
  const stopDate = moment(endDate);

  while (currentDate <= stopDate) {
    dateArray.push(moment(currentDate).format(DATE_FORMAT));
    currentDate = moment(currentDate).add(1, 'days');
  }
  return dateArray;
}

export default getDatesBetweenDates;
