import { Button, Typography } from '@travelpassero/khaos';
import styled from 'styled-components';
import { socialAuth } from '@context/user/userThunk';
import { useDispatch } from 'react-redux';
import { Divider } from 'antd';
import { useGoogleLogin } from '@react-oauth/google';
import GoogleIcon from '../../assets/google.svg';
import '../../styles/sections/socialNetworks.css';

const DividerContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 16px 0;
  @media (min-width: 1200px) {
    width: 68%;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100px;
  @media (min-width: 700px) {
    width: 70%;
    flex-direction: row;
  } 
  @media (min-width: 900px ) and (max-width: 1200px) {
    flex-direction: column;
  }
  
  @media (min-width: 1200px) {
    justify-content: center;
  }
`;
const GoogleButton = styled(Button)`
  justify-content: center;
  width: 100%;
  font-family: 'font-primary', sans-serif;
  @media (min-width: 700px) {
    flex: 0.5;
  }

  @media (min-width: 1200px) {
    min-width: 200px;
    margin-right: 48px;
  }
  img {
    width: 20px;
  }
  span {
    margin-left: 16px;
  }
`;

const SocialNetworkContainer = ({ type }) => {
  const dispatch = useDispatch();

  const googleButtonText = type === 'signup' ? 'Sign up with Google' : 'Sign in with Google';

  const login = useGoogleLogin({
    onSuccess: async (credentialResponse) => {
      const response = await fetch(
        'https://www.googleapis.com/oauth2/v3/userinfo',
        {
          headers: {
            Authorization: `Bearer ${credentialResponse?.access_token}`,
          },
        },
      );

      const {
        sub: socialId,
        email,
        given_name: firstName,
        family_name: lastName,
      } = await response.json();

      const socialData = {
        socialId,
        email,
        firstName,
        lastName,
        network: 'google',
      };

      dispatch(socialAuth(socialData));
    },
  });

  return (
    <>
      <DividerContainer>
        <Divider>
          <Typography type="greyText">
            OR
          </Typography>
        </Divider>
      </DividerContainer>
      <ButtonsContainer>
        <GoogleButton
          onClick={login}
        >
          <GoogleIcon height="24" width="24" />
          <span>{googleButtonText}</span>
        </GoogleButton>
      </ButtonsContainer>
    </>
  );
};

export default SocialNetworkContainer;
