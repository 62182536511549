import React from 'react';
import styled from 'styled-components';
import { connect, useDispatch, useSelector } from 'react-redux';
import { SurveyResultsIdeaCard, ViewTile, DrawerModal } from '@travelpassero/khaos';
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import { openDrawer, closeDrawer } from '@context/actions';
import { selectAppState } from '@context/global/appSlice';
import CardPositioner from './CardPositioner';

const Container = styled.div`
  padding: 30px 0;
  background: var(--white);
`;

function SurveyTileResponseCardContainer({
  data, crew,
}) {
  const dispatch = useDispatch();
  const { globalStateReducer } = useSelector(selectAppState);
  const { isDrawerVisible, drawerChildrenType, drawerSelectedID } = globalStateReducer;

  const handleOnCardClick = (id) => {
    dispatch(openDrawer('view', id));
  };

  const handleCloseDrawer = () => {
    dispatch(closeDrawer());
  };
  const getSelectedIdeaData = (id) => data.find((item) => item.id === id);

  let DrawerChildren;
  const selectedIdeaData = getSelectedIdeaData(drawerSelectedID);

  switch (drawerChildrenType) {
    case 'view':
      DrawerChildren = (
        <CardImageContainer
          imgSrcKey={selectedIdeaData.imgSrcKey}
          render={(imgSrcUrl) => <ViewTile {...selectedIdeaData} imgSrc={imgSrcUrl} />}
        />
      );

      break;

    default:
      DrawerChildren = null;
  }

  const surveyResultCards = [];

  if (data) {
    surveyResultCards.push(data.map((item, index) => {
      const {
        id, dislike, like, imgSrcKey, creator,
      } = item;

      const findCreator = crew.find((it) => it.id === creator);
      const creatorName = findCreator ? `${
        findCreator?.firstName
      } ${findCreator?.lastName
            && findCreator.lastName.charAt(0).toUpperCase()}` : 'You';

      return (
        <CardImageContainer
          key={`${index}_survey--tile-card`}
          imgSrcKey={imgSrcKey}
          render={(imgSrcUrl) => (
            <SurveyResultsIdeaCard
              onCardClick={() => handleOnCardClick(id)}
              likedAmount={like}
              dislikedAmount={dislike}
              imgSrc={imgSrcUrl}
              {...item}
              creator={creatorName}
            />
          )}
        />
      );
    }));
  }

  return (
    <Container>
      <CardPositioner
        cardMaxWidth={350}
        cardMinWidth={250}
        cards={surveyResultCards}
      />
      {DrawerChildren ? (
        <DrawerModal
          handleOnClose={handleCloseDrawer}
          showModal={isDrawerVisible}
          children={DrawerChildren}
          maxWidth="600px"
        />
      ) : null}
    </Container>
  );
}

function mapStateToProps(state) {
  const { globalStateReducer } = state;

  return { state: globalStateReducer };
}

export default connect(mapStateToProps)(SurveyTileResponseCardContainer);
