import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { SignInForm } from '@travelpassero/khaos';

import { signIn } from '@context/user/userThunk';
import { selectUserState } from '@context/user/userSlice';

import OnboardingLayout from '@components/layout/OnboardingLayout';
import BrandOnboarding from '@components/layout/BrandOnboarding';
import SocialNetworkContainer from '@pages/onboarding/SocialNetworkContainer';
import { OnboardingFormContainer } from '@styles/containers/OnboardingFormContainerStyle';
import { useAfterLogin } from '@hooks/useAfterLogin';

const SignInPage = () => {
  const dispatch = useDispatch();
  const { redirectTo } = useAfterLogin();
  const history = useHistory();
  const { error, onboarding } = useSelector(selectUserState);

  const handleSignIn = async (formData) => {
    dispatch(signIn(formData));
  };

  const redirectToPage = (route) => {
    history.push(redirectTo ? `${route}?redirectTo=${redirectTo}` : route);
  };

  return (
    <OnboardingLayout page="signin">
      <BrandOnboarding />
      <OnboardingFormContainer>
        <SignInForm
          onFinish={handleSignIn}
          onForgotPasswordClick={() => redirectToPage('/forgot-password')}
          onVerifySignUpClick={() => redirectToPage('/register/verify')}
          onSignUpClick={() => redirectToPage('/register')}
          errorMessage={error?.message || ''}
          successMessage={onboarding?.successMessage}
        />
        <SocialNetworkContainer type="signin" />
      </OnboardingFormContainer>
    </OnboardingLayout>
  );
};

export default SignInPage;
