import { useDispatch, useSelector } from 'react-redux';
import { removeNotificationsReducer, selectNotificationState } from '@context/notification/notificationSlice';
import { deleteNotificationsThunk } from '@context/notification/notificationThunk';

const useNotification = () => {
  const { notifications } = useSelector(selectNotificationState);
  const dispatch = useDispatch();

  const removeNotifications = (notifications) => {
    dispatch(removeNotificationsReducer({ notifications }));
    dispatch(deleteNotificationsThunk({ notifications }));
  };

  return {
    notifications,
    removeNotifications,
  };
};

export default useNotification;
