import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { hotjar } from 'react-hotjar';
import TagManager from 'react-gtm-module';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// eslint-disable-next-line import/no-unresolved
import { DndProvider } from 'react-dnd';
// eslint-disable-next-line import/no-unresolved
import { HTML5Backend } from 'react-dnd-html5-backend';
// Redux
import store from '@context/store';
// Components
import JourneyPageContainer from '@pages/JourneyPageContainer';
import ProfilePageContainer from '@pages/myProfile/ProfilePageContainer';
import JourneyRoutes from '@pages/JourneyRoutes';
import MyFriendsPage from '@pages/myFriends/MyFriendsPage';
import SignInPage from '@pages/onboarding/SignInPage';
import SignUpPage from '@pages/onboarding/SignUpPage';
import PublicItineraryPage from '@pages/PublicItineraryPage';
import DiscoverPageContainer from '@pages/discover/DiscoverPageContainer';
import DiscoverRegionPageContainer from '@pages/discover/DiscoverRegionPageContainer';
import DiscoverCategoryPageContainer from '@pages/discover/DiscoverCategoryPageContainer';
import ConfirmSignUpPage from '@pages/onboarding/ConfirmSignUpPage';
import ResetPasswordPage from '@pages/onboarding/ResetPasswordPage';
import VerifySignUpPage from '@pages/onboarding/VerifySignUp';
import ResetPasswordConfirmPage from '@pages/onboarding/ResetPasswordConfirmPage';
import { useWindowSize } from '@hooks/useWindowSize';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Authenticator from './Authenticator';
import ErrorBoundary from './ErrorBoundary';
// Styles
import './App.css';
import 'antd/dist/antd.min.css';

const googleClientID = '799965743891-8ubbcg95vcp3cnf0qcnmvn8tripgnett.apps.googleusercontent.com';

const DNDProvider = ({ children }) => {
  const { isMobile } = useWindowSize();

  if (!isMobile) {
    return (
      <DndProvider backend={HTML5Backend}>
        { children }
      </DndProvider>
    );
  }

  return children;
};

function App() {
  useEffect(() => {
    hotjar.initialize(2235522, 6);
  }, []);

  const tagManagerArgs = {
    gtmId: 'GTM-MNHVPLT',
  };

  TagManager.initialize(tagManagerArgs);

  return (
    <ErrorBoundary>
      <GoogleOAuthProvider clientId={googleClientID}>
        <Provider store={store}>
          <DNDProvider>
            <Router>
              <Switch>
                <Route exact path="/login">
                  <SignInPage />
                </Route>
                <Route exact path="/register">
                  <SignUpPage />
                </Route>
                <Route exact path="/register/confirm">
                  <ConfirmSignUpPage />
                </Route>
                <Route exact path="/register/verify">
                  <VerifySignUpPage />
                </Route>
                <Route exact path="/forgot-password">
                  <ResetPasswordPage />
                </Route>
                <Route exact path="/forgot-password/confirm">
                  <ResetPasswordConfirmPage />
                </Route>
                <Route exact path="/public/itinerary/:id">
                  <PublicItineraryPage />
                </Route>
                <Route exact path="/discover">
                  <Authenticator>
                    <DiscoverPageContainer />
                  </Authenticator>
                </Route>
                <Route exact path="/discover/region/:region">
                  <Authenticator>
                    <DiscoverRegionPageContainer />
                  </Authenticator>
                </Route>
                <Route exact path="/discover/category/:category">
                  <Authenticator>
                    <DiscoverCategoryPageContainer />
                  </Authenticator>
                </Route>
                <Route exact path="/">
                  <Authenticator>
                    <JourneyPageContainer />
                  </Authenticator>
                </Route>
                <Route exact path="/journeys/:id/*">
                  <Authenticator>
                    <JourneyRoutes />
                  </Authenticator>
                </Route>
                <Route exact path="/friends">
                  <Authenticator>
                    <MyFriendsPage />
                  </Authenticator>
                </Route>
                <Route exact path="/:username">
                  <ProfilePageContainer />
                </Route>
              </Switch>
            </Router>
          </DNDProvider>
        </Provider>
      </GoogleOAuthProvider>
    </ErrorBoundary>
  );
}

export default App;
