import { photoStorage } from '@utils/urls';

const MultiImageContainer = ({ dataWithKeys, render }) => {
  const dataWithImgUrls = [];

  if (dataWithKeys) {
    dataWithKeys.forEach((item) => {
      const img = `public/${item.imgSrcKey?.replace('public/', '')}`;
      const imgSrc = `${photoStorage}${img}`;
      dataWithImgUrls.push({ ...item, imgSrc });
    });
  }

  return render({ dataWithImgUrls });
};

export default MultiImageContainer;
