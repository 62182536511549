import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Col,
  Row,
} from 'antd';
import { CircleProfileIcon, Typography } from '@travelpassero/khaos';
import CardImageContainer from '@components/renderContainers/CardImageContainer';

import { useWindowSize } from '@hooks/useWindowSize';

const ImageContainer = styled.img`
  border-radius:50%;
  width: ${(props) => props.size};
  height: ${(props) => props.size};
  object-fit: cover;
  `;

function ProfilePictureSection({
  firstName, lastName, profilePortraitImgSrcKey, publicUserJourneys,
}) {
  const { isMobile } = useWindowSize();
  const [isPortraitPhotoError, setIsPortraitPhotoError] = useState(false);

  const handlePortraitPhotoError = () => {
    setIsPortraitPhotoError(true);
  };

  useEffect(() => {
    setIsPortraitPhotoError(false);
  }, [profilePortraitImgSrcKey]);

  return (
    <Row
      gutter={[8, 8]}
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxWidth: isMobile ? '108px' : 'none',
        textAlign: 'center',
        paddingTop: '8px',
      }}
    >
      <Col>
        <Row justify="center">
          <Col>
            <CardImageContainer
              imgSrcKey={profilePortraitImgSrcKey}
              render={(imgSrcUrl) => (isPortraitPhotoError ? (
                <CircleProfileIcon
                  firstName={firstName}
                  lastName={lastName}
                  style={{
                    height: isMobile ? '60px' : '100px',
                    width: isMobile ? '60px' : '100px',
                    borderWidth: isMobile ? '4px' : '8px',
                  }}
                />
              ) : (
                <ImageContainer src={imgSrcUrl} size={isMobile ? '100px' : '144px'} alt="" onError={handlePortraitPhotoError} />
              ))}
            />
          </Col>
        </Row>
      </Col>
      <Col>
        <Typography type="secondaryText" size=".875rem">{`${publicUserJourneys.length} journeys on Passero`}</Typography>
      </Col>
    </Row>
  );
}

export default ProfilePictureSection;
