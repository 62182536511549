import React from 'react';
import styled from 'styled-components';
import { useWindowSize } from '@hooks/useWindowSize';
import { DropCard, AddCard } from '@travelpassero/khaos';
import DropContainer from '@components/containers/DropContainer';
import CardPositioner from '@components/containers/CardPositioner';
import FriendCardDnDContainer from './FriendCardDnDContainer';

const Container = styled.div`
  padding-bottom: 40px;
  width: 100%;

  .pagination-container {
    position: relative;
  }

  .pagination-data {
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
  }

  .pagination-buttons {
    bottom: -24px;
  }
`;

const FriendList = ({
  list = [],
  onReminderClick,
  onAddFriendClick,
  onCheckboxClick,
  onRemoveClick,
  removeConfirmText,
  onAddToCrew,
  onOpenAdd,
  addType = 'friend',
  canDrag,
  cardsPerPage,
  dropLocation,
  style,
  rowWidthOffset,
}) => {
  const { isMobile } = useWindowSize();

  const friendCards = [(
    onOpenAdd && (
    <AddCard
      key="empty-friend-card"
      type={addType}
      onClick={onOpenAdd}
    />
    )
  ),
  ...list.map((item, index) => (
    <FriendCardDnDContainer
      onReminderClick={onReminderClick}
      onAddFriendClick={onAddFriendClick}
      onRemoveClick={onRemoveClick}
      removeConfirmText={removeConfirmText}
      onCheckboxClick={onCheckboxClick}
      onAddToCrew={onAddToCrew}
      key={`${item.id}-${index}`}
      item={item}
      canDrag={!isMobile && canDrag}
    />
  ))];

  if (dropLocation) {
    friendCards.push((
      <DropContainer
        type="add-crew"
        folderID={dropLocation}
        style={{ padding: '8px' }}
        key="drop-friend-card"
      >
        <DropCard
          style={{
            position: 'relative',
            width: '100%',
            height: '68px',
          }}
        />
      </DropContainer>
    ));
  }

  return (
    <Container style={style}>
      <CardPositioner
        cardMaxWidth={550}
        rowWidthOffset={rowWidthOffset}
        cardsPerPage={cardsPerPage}
        usePagination
        cards={friendCards}
        center
      />
    </Container>
  );
};

export default FriendList;
