/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      iconColor
      isEmailSubscribed
      isConfirmed
      isReferred
      referredBy
      lastLogin
      journeys
      crew
      createdAt
      updatedAt
    }
  }
`;
export const getUserByEmail = /* GraphQL */ `
  query GetUserByEmail($email: String) {
    getUserByEmail(email: $email) {
      id
      firstName
      lastName
      email
      phoneNumber
      iconColor
      isEmailSubscribed
      isConfirmed
      isReferred
      referredBy
      lastLogin
      journeys
      crew
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        firstName
        lastName
        email
        phoneNumber
        iconColor
        isEmailSubscribed
        isConfirmed
        isReferred
        referredBy
        lastLogin
        journeys
        crew
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getJourney = /* GraphQL */ `
  query GetJourney($id: ID!) {
    getJourney(id: $id) {
      id
      title
      startingDate
      endingDate
      createdDate
      lastEditDate
      hasUnknownDate
      isSampleJourney
      sampleJourneyType
      link
      creator
      location
      imgSrcKey
      ideas {
        id
        ideaWeight
        title
        imgSrcKey
        location
        description
        price
        duration
        link
        creator
        folderKey
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        itineraryDate
        itineraryTime
        itineraryNote
        itineraryByLengthDay
      }
      journeyCrew
      surveys {
        id
        title
        creator
        invited
        completed
        createdDate
        questions {
          id
          title
          type
          choices {
            dateRange
            id
            ideaID
            option
          }
          responses {
            userID
            response
            responseOptions
            responseDateRange {
              dateRange
            }
            responseIdeas {
              ideaID
              status
            }
          }
        }
      }
      itinerary {
        id
        type
        dateRangeStart
        dateRangeEnd
        createdDate
        editActivityLog {
          lastEditDate
          lastEditBy
          changeLog
        }
        ItineraryByLength {
          length
        }
        itineraryQuickAddItems {
          id
          title
          type
          itineraryDate
          itineraryTime
          itineraryNote
          itineraryByLengthDay
        }
      }
      folders {
        id
        title
      }
      createdAt
      updatedAt
    }
  }
`;
export const listJourneys = /* GraphQL */ `
  query ListJourneys(
    $filter: ModelJourneyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listJourneys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        startingDate
        endingDate
        createdDate
        lastEditDate
        hasUnknownDate
        isSampleJourney
        sampleJourneyType
        link
        creator
        location
        imgSrcKey
        ideas {
          id
          ideaWeight
          title
          imgSrcKey
          location
          description
          price
          duration
          link
          creator
          folderKey
          createdDate
          itineraryDate
          itineraryTime
          itineraryNote
          itineraryByLengthDay
        }
        journeyCrew
        surveys {
          id
          title
          creator
          invited
          completed
          createdDate
        }
        itinerary {
          id
          type
          dateRangeStart
          dateRangeEnd
          createdDate
        }
        folders {
          id
          title
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const batchGetUsers = /* GraphQL */ `
  query BatchGetUsers($ids: [ID]) {
    batchGetUsers(ids: $ids) {
      id
      firstName
      lastName
      email
      phoneNumber
      iconColor
      isEmailSubscribed
      isConfirmed
      isReferred
      referredBy
      lastLogin
      journeys
      crew
      createdAt
      updatedAt
    }
  }
`;
export const sendCrewEmail = /* GraphQL */ `
  query SendCrewEmail($emailAddresses: [String], $firstName: String) {
    sendCrewEmail(emailAddresses: $emailAddresses, firstName: $firstName) {
      response
    }
  }
`;
export const sendSurveyCrewEmail = /* GraphQL */ `
  query SendSurveyCrewEmail(
    $emailAddresses: [String]
    $firstName: String
    $surveyLink: String
  ) {
    sendSurveyCrewEmail(
      emailAddresses: $emailAddresses
      firstName: $firstName
      surveyLink: $surveyLink
    ) {
      response
    }
  }
`;
