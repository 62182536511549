// Modals
export const OPEN_DRAWER = 'OPEN_DRAWER';
export const OPEN_CENTER_MODAL = 'OPEN_CENTER_MODAL';

export const CLOSE_MODAL = 'CLOSE_MODAL';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const HANDLE_CHANGE = 'HANDLE_CHANGE';

export const ADD_USER_DATA = 'ADD_USER_DATA';

export const ADD_IDEA_TO_SURVEY = 'ADD_IDEA_TO_SURVEY';
export const REMOVE_IDEA_FROM_SURVEY = 'REMOVE_IDEA_FROM_SURVEY';
export const REMOVE_ALL_FROM_SURVEY = 'REMOVE_ALL_FROM_SURVEY';

export const SET_MODAL_LOADING_STATE = 'SET_MODAL_LOADING_STATE';
export const SET_DRAWER_LOADING_STATE = 'SET_DRAWER_LOADING_STATE';

export const SET_LEFT_NAV_COLLAPSED = 'SET_LEFT_NAV_COLLAPSED';

export const openDrawer = (type, id) => ({
  type: OPEN_DRAWER,
  payload: {
    type,
    drawerSelectedID: id,
  },
});

export const openCenterModal = (type, id) => ({
  type: OPEN_CENTER_MODAL,
  payload: {
    type,
    modalSelectedID: id,
  },
});

export const closeModal = () => ({
  type: CLOSE_MODAL,
});

export const closeDrawer = () => ({
  type: CLOSE_DRAWER,
});

export const addSelectedIdeaToSurvey = ({
  surveySelectedIdeas,
  surveyIdeaID,
  questionNumber,
}) => ({
  type: ADD_IDEA_TO_SURVEY,
  payload: {
    ideas: surveySelectedIdeas,
    surveyIdeaID,
    questionNumber,
  },
});

export const removeSelectedIdeaToSurvey = ({ ideaID, questionNumber }) => ({
  type: REMOVE_IDEA_FROM_SURVEY,
  payload: {
    ideaID,
    questionNumber,
  },
});

export const removeAllFromSurvey = () => ({
  type: REMOVE_ALL_FROM_SURVEY,
});

export const setModalLoadingState = (modalLoadingState) => ({
  type: SET_MODAL_LOADING_STATE,
  payload: {
    modalLoadingState,
  },
});

export const setDrawerLoadingState = (drawerLoadingState) => ({
  type: SET_DRAWER_LOADING_STATE,
  payload: {
    drawerLoadingState,
  },
});

export const setLeftNavCollapse = (isLeftNavCollapsed) => ({
  type: SET_LEFT_NAV_COLLAPSED,
  payload: {
    isLeftNavCollapsed,
  },
});
