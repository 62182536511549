import { createSlice } from '@reduxjs/toolkit';
import { uploadFileThunk } from '@context/storage/storageThunk';

const initialState = {
  status: 'idle',
  key: undefined,
  error: null,
};

export const storageSlice = createSlice({
  name: 'storage',
  initialState,
  extraReducers: ((builder) => {
    builder
      .addCase(uploadFileThunk.pending, (state) => {
        state.status = 'pending';
      })
      .addCase(uploadFileThunk.fulfilled, (state, { payload }) => {
        state.key = payload;
        state.status = 'fulfilled';
      })
      .addCase(uploadFileThunk.rejected, ((state) => {
        state.status = 'rejected';
      }));
  }),
});

export const selectStorageState = (state) => state.storage;

export default storageSlice.reducer;
