const getRandomColor = () => {
  const randomNumber = Math.floor(Math.random() * Math.floor(5) + 1);

  switch (randomNumber) {
    case 1:
      return 'blue';

    case 2:
      return 'green';

    case 3:
      return 'pink';

    case 4:
      return 'grey';

    case 5:
      return 'yellow';

    default:
      return 'blue';
  }
};

export default getRandomColor;
