import moment from 'moment';

export const sortUsers = (order, list) => {
  let sortedList = list;

  if (order === 'ascending') {
    sortedList = [...list].sort((a, b) => {
      const nameA = a.firstName?.toUpperCase();
      const nameB = b.firstName?.toUpperCase();
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  } if (order === 'descending') {
    sortedList = [...list].sort((a, b) => {
      const nameA = a.firstName?.toUpperCase();
      const nameB = b.firstName?.toUpperCase();
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });
  }

  return [...sortedList];
};

export const sortJourneys = (order, list) => {
  let sortedList = list;

  if (order === 'ascending') {
    sortedList = [...list].sort((a, b) => {
      const titleA = a.data?.title?.toUpperCase();
      const titleB = b.data?.title?.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });
  } if (order === 'descending') {
    sortedList = [...list].sort((a, b) => {
      const titleA = a.data?.title?.toUpperCase();
      const titleB = b.data?.title?.toUpperCase();
      if (titleA > titleB) {
        return -1;
      }
      if (titleA < titleB) {
        return 1;
      }
      return 0;
    });
  }

  return [...sortedList];
};

export const sortIdeasByTime = (a, b) => {
  const { itineraryTime: itineraryTimeA, endTime: endTimeA, isEndIdea: isEndIdeaA } = a;
  const { itineraryTime: itineraryTimeB, endTime: endTimeB, isEndIdea: isEndIdeaB } = b;

  let timeA = isEndIdeaA ? endTimeA : itineraryTimeA;
  let timeB = isEndIdeaB ? endTimeB : itineraryTimeB;

  if (timeA?.includes('T')) timeA = timeA?.split('T')[1];
  if (timeB?.includes('T')) timeB = timeB?.split('T')[1];

  // if time is undefined, put at the end
  if (!timeA && !timeB) {
    return 0;
  }
  if (!timeA) {
    return 1;
  }
  if (!timeB) {
    return -1;
  }

  // in chronological order
  return moment.duration(timeA).asMinutes() - moment.duration(timeB).asMinutes();
};
