import React from 'react';
import { Col, Row } from 'antd';
import styled from 'styled-components';
import {
  timeFormatted, Typography, getIdeaCategory, Button,
} from '@travelpassero/khaos';
import CardImageContainer from '@components/renderContainers/CardImageContainer';
import { isSimpleItineraryCard } from '@utils/ideaCategory';
import { useItinerary } from '@hooks/useItinerary';
import {
  CheckCircleOutlined, DownloadOutlined, PushpinFilled,
} from '@ant-design/icons';
import background from '@assets/login-background.jpg';
import LogoIcon from '@assets/passero_icon.svg';
import moment from 'moment';
import useJourneys from '@hooks/useJourneys';

import { useSelector } from 'react-redux';
import { resetDuplicateJourneyStatusReducer, selectJourneysState } from '@context/journeys/journeysSlice';
import Spinner from '@components/Spinner';
import useUser from '@hooks/useUser';
import { useHistory, Link } from 'react-router-dom';
import { useWindowSize } from '../../hooks/useWindowSize';

const Container = styled.div`
    position: relative;
    background: var(--white);
    z-index: 0;
    margin: 0 0 40px 0;

  .journey-image {
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    z-index: 0;
  }

  .passero-background-image {
    width: 100%;
    position: absolute;
    margin: auto;
    top: 0;
    z-index: 0;
  }
`;

const JourneyImgOverlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: ${(props) => (props.isFeatured ? '12px' : '0')};
  z-index: 9;
  background-image: linear-gradient(rgba(0,0,0,0),rgba(0,0,0,.6));
  padding: 8px 12px;
`;

const PasseroImgOverlay = styled.div`
  position: absolute;
  height: fit-content;
  width: fit-content;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  margin: auto;
  z-index: 9;
  padding: 8px 12px;
`;

const VerticalLine = styled.div`
  border-left: 1px solid var(--accent);
  position: absolute;
  top: ${(props) => (props.isFirst ? '-36' : '8')}px;
  left: 34px;
  bottom: ${(props) => (props.isLast ? props.isSimpleIdea ? '20px' : '50%' : '-16px')};
  z-index: 1;
`;

const DayContainer = styled.div`
  background: var(--accent);
  border-radius: 4px;
  padding: 8px 0 6px 0;
  margin: ${(props) => (props.isEmpty ? '32px 0 16px 0' : '32px 0')};
  width: 68px;
  text-align: center;
  box-shadow: 0 10px 20px #0000000d;
`;

const TimeContainer = styled.div`
    z-index: 2;
    height: fit-content;
    width: 68px;
    height: 40px;
    background: var(--component-background-light);
    border-radius: 4px;
    padding: 8px;
    text-align: center;
    box-shadow: 0 10px 20px #0000000d;

  ${(props) => !props.isSimpleIdea && `
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
  `}

  margin-left: -18px;
`;

const RedDot = styled.div`
  height: 20px;
  width: 20px;
  position: absolute;
  right: ${(props) => (!props.isSimpleIdea ? '-69' : '-19')}px;
  top: 0;
  bottom: 0;
  margin: auto;
  border: 6px solid var(--accent);
  border-radius: 100%;
  background: var(--white);
  z-index: 9;
`;

const SimpleIdeaContainer = styled.div`
  background: var(--white);
  border: 1px solid var(--borders);
  border-radius: 4px;
  padding: 6px 8px;
  width: ${(props) => (props.isMobile ? '100%' : '40%')};
  box-shadow: 0 10px 20px #0000000d;
  z-index: 0;

  path {
    fill: var(--icons) !important;
  }
`;

const IdeaContainer = styled.div`
  background: var(--white);
  border: 1px solid var(--borders);
  border-radius: 4px;
  width: 100%;
  box-shadow: 0 10px 20px #0000000d;
  z-index: 0;

  .idea-image {
    height: 200px;
    ${(props) => (props.isMobile && `
      width: 100%; 
      height: auto;
    `)}
  }

  path {
    fill: var(--icons) !important;
  }
`;

const StyledDescription = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.lineClamp};
  -moz-line-clamp: ${(props) => props.lineClamp};
  -ms-line-clamp: ${(props) => props.lineClamp};
  line-clamp: ${(props) => props.lineClamp};
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  -ms-box-orient: vertical;
  box-orient: vertical;
`;

const LinkContainer = styled.div`
    position: absolute;
    right: 16px;
    top: 16px;
    z-index: 9;
`;

function PublicItinerary({
  publicJourney,
  canDownloadItinerary,
}) {
  const { isMobile } = useWindowSize();
  const history = useHistory();
  const { userID } = useUser();
  const { getIdeasThisDay, dateRanges } = useItinerary(publicJourney?.data);
  const { duplicateJourneyStatus, status: journeyStatus } = useSelector(selectJourneysState);

  const { duplicateJourney } = useJourneys();

  const isJourneysLoading = journeyStatus === 'pending';
  const isJourneyDuplicatedLoading = duplicateJourneyStatus === 'pending';
  const isJourneyDuplicated = duplicateJourneyStatus === 'fulfilled';
  const isJourneyDuplicatedError = duplicateJourneyStatus === 'rejected';

  const {
    pk,
    data: {
      title, location, imgSrcKey, creator, itinerary: { dateRangeStart, dateRangeEnd },
    },
  } = publicJourney;

  const handleDownloadItinerary = async () => {
    if (!userID) {
      history.push(`/login?redirectTo=/public/itinerary/${pk}`);
    } else {
      resetDuplicateJourneyStatusReducer();
      duplicateJourney({ journeyID: pk });
    }
  };

  return (
    <Row justify="center" id="element-to-print">
      <Col style={{ maxWidth: '1200px', width: '100%' }}>
        <CardImageContainer
          imgSrcKey={imgSrcKey}
          render={((imgUrl) => (
            <Container>
              <Row>
                <Col
                  xs={{ span: 24, order: 2 }}
                  sm={{ span: 12, order: 1 }}
                  style={{ overflow: 'hidden', minHeight: '200px', maxHeight: '300px' }}
                >
                  <Row justify="center" align="middle">
                    <img className="journey-image" src={imgUrl} alt={title} />
                    <JourneyImgOverlay>
                      <Typography
                        type="whiteHeading"
                        size="1.75rem"
                        style={{
                          padding: '4px',
                          whiteSpace: 'nowrap',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                          textAlign: 'center',
                        }}
                      >
                        {title}
                      </Typography>
                    </JourneyImgOverlay>
                  </Row>
                </Col>
                <Col
                  xs={{ span: 24, order: 1 }}
                  sm={{ span: 12, order: 2 }}
                  style={{ overflow: 'hidden', minHeight: '200px', maxHeight: '300px' }}
                >
                  <Row justify="center" align="middle">
                    <img className="passero-background-image" src={background} alt="passero" />
                    <PasseroImgOverlay>
                      <Row
                        align="middle"
                        justify="center"
                        gutter={[24, 24]}
                        style={{ margin: '4px 0 0 0' }}
                      >
                        {canDownloadItinerary ? (
                          <Col>
                            {isJourneyDuplicated ? (
                              <Row justify="end" align="middle">
                                <CheckCircleOutlined style={{ color: 'var(--green)', margin: '0 8px 4px 0', fontSize: '1.5rem' }} />
                                <Typography type="whiteText" size="1rem">
                                  Success
                                </Typography>
                              </Row>
                            )
                              : (
                                <Button
                                  type="primary"
                                  style={{ border: 'none' }}
                                  disabled={isJourneyDuplicatedLoading}
                                  onClick={handleDownloadItinerary}
                                >
                                  {isJourneyDuplicatedLoading || isJourneysLoading
                                    ? (
                                      <div style={{ paddingRight: '8px' }}>
                                        <Spinner
                                          color="var(--grey)"
                                        />
                                      </div>
                                    )
                                    : <DownloadOutlined style={{ fontSize: '1.25rem' }} />}
                                  Download Itinerary
                                </Button>
                              )}
                          </Col>
                        ) : (
                          <Col flex="none">
                            <LogoIcon
                              width="4rem"
                              height="auto"
                            />
                          </Col>
                        )}
                        <Col>
                          <Button
                            type="default"
                            children={<Link to="/">See my Journeys</Link>}
                          />
                        </Col>
                      </Row>
                    </PasseroImgOverlay>
                  </Row>
                </Col>
              </Row>
              {isJourneyDuplicatedError && (
                <Col span={24} style={{ textAlign: 'right', padding: '8px' }}>
                  <Row justify="end" align="middle">
                    <Col>
                      <Typography type="errorText">Error downloading itinerary</Typography>
                    </Col>
                  </Row>
                </Col>
              )}
              <Row style={{ padding: '24px 36px' }}>
                {!!location && (
                  <Col span={24}>
                    <Row align="middle" wrap={false}>
                      <Col>
                        <PushpinFilled
                          style={{
                            fontSize: '1rem',
                            color: 'var(--accent)',
                            paddingRight: '4px',
                          }}
                        />
                      </Col>
                      <Col>
                        <Typography size="1rem" type="primaryText">
                          {location}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                )}

                {!!creator?.data && (
                  <Col span={24}>
                    <Row justify="center" align="middle">
                      <Col span="24">
                        <Typography size="1rem" type="secondarySubHeading">
                          {`By ${creator.data.firstName} ${creator.data.lastName}`}
                        </Typography>
                      </Col>
                    </Row>
                  </Col>
                )}
                {!!creator?.data && (
                <Col span={24} style={{ padding: '8px 0' }}>
                  <Row justify="center" align="middle">
                    <Col span="24">
                      <Typography size="1.5rem" type="primaryText">
                        {`${moment(dateRangeStart).format('MMMM Do')} - ${moment(dateRangeEnd).format('MMMM Do')}`}
                      </Typography>
                    </Col>
                  </Row>
                </Col>
                )}
                <Col span={24}>
                  {dateRanges.map((day, index) => {
                    const ideasThisDay = getIdeasThisDay(day);

                    return (
                      <Row gutter={[0, 4]} style={{ position: 'relative' }} key={`${day}-${index}`}>
                        <Col style={{ zIndex: '1' }}>
                          <DayContainer isEmpty={!ideasThisDay.length}>
                            <Typography size="1rem" type="whiteHeading">
                              {`Day ${index + 1}`}
                            </Typography>
                          </DayContainer>
                        </Col>
                        {ideasThisDay.map((idea, ideaIndex) => {
                          const {
                            ideaCategoryId,
                            title,
                            imgSrcKey,
                            duration,
                            location,
                            description,
                            price,
                            link,
                            itineraryTime,
                            endTime,
                            isEndIdea,
                            id,
                          } = idea;

                          const { Icon } = getIdeaCategory(ideaCategoryId);
                          const useSimpleIdea = isSimpleItineraryCard(ideaCategoryId)
                      || !imgSrcKey || imgSrcKey?.includes('/ideaboard');

                          return (
                            <Col span={24} key={`${id}-${ideaIndex}`}>
                              <VerticalLine
                                isFirst={ideaIndex < 1}
                                isLast={ideaIndex >= ideasThisDay.length - 1}
                                isSimpleIdea={useSimpleIdea}
                              />
                              <Row gutter={[16, 16]} wrap={false}>
                                <Col style={{ zIndex: '1', paddingLeft: '26px' }}>
                                  <TimeContainer isSimpleIdea={useSimpleIdea}>
                                    {((isEndIdea && endTime) || itineraryTime) && (
                                    <Typography size=".875rem" type="primaryText">
                                      {timeFormatted(isEndIdea ? endTime : itineraryTime)}
                                    </Typography>
                                    )}
                                  </TimeContainer>
                                  <RedDot isSimpleIdea={useSimpleIdea} />
                                </Col>
                                <Col flex="auto">
                                  {useSimpleIdea ? (
                                    <SimpleIdeaContainer isMobile={isMobile}>
                                      <Row justify="space-between" wrap={false}>
                                        <Col flex="auto">
                                          <div
                                            style={{
                                              whiteSpace: 'nowrap',
                                              textOverflow: 'ellipsis',
                                              overflow: 'hidden',
                                              paddingLeft: '12px',
                                            }}
                                          >
                                            <Typography
                                              type="subText"
                                            >
                                              {title}
                                            </Typography>
                                          </div>
                                        </Col>
                                        <Col flex="0 1">
                                          {Icon}
                                        </Col>
                                      </Row>
                                    </SimpleIdeaContainer>
                                  ) : (
                                    <CardImageContainer
                                      imgSrcKey={imgSrcKey}
                                      render={((ideaImgUrl) => (
                                        <IdeaContainer isMobile={isMobile}>
                                          <Row wrap={isMobile}>
                                            <Col
                                              flex={isMobile ? '1 0 100%' : '1 0 300px'}
                                              style={{
                                                overflow: 'hidden',
                                                minWidth: '200px',
                                                maxWidth: isMobile ? '100%' : '300px',
                                              }}
                                            >
                                              <Row
                                                justify="center"
                                                align="middle"
                                                style={{
                                                  margin: '12px',
                                                  overflow: 'hidden',
                                                  maxHeight: '216px',
                                                  flexFlow: 'unset',
                                                }}
                                              >
                                                <img
                                                  className="idea-image"
                                                  src={ideaImgUrl}
                                                  alt={title}
                                                />
                                              </Row>
                                            </Col>
                                            <Col
                                              flex="auto"
                                              style={{ display: 'flex', flexDirection: 'column', padding: '24px' }}
                                            >
                                              {!!link && (
                                              <LinkContainer>
                                                <Button
                                                  type="greyLink"
                                                  href={link}
                                                />
                                              </LinkContainer>
                                              )}
                                              <Row>
                                                <Col span={24}>
                                                  <Typography
                                                    size="1.125rem"
                                                    type="subText"
                                                  >
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                      }}
                                                    >
                                                      {duration}
                                                    </div>
                                                  </Typography>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col span={24}>
                                                  <Typography size="2rem" type="subText">
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                        lineHeight: '2.5rem',
                                                      }}
                                                    >
                                                      {title}
                                                    </div>
                                                  </Typography>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col span={24}>
                                                  <Typography size="1rem" type="accentText">
                                                    <div
                                                      style={{
                                                        whiteSpace: 'nowrap',
                                                        textOverflow: 'ellipsis',
                                                        overflow: 'hidden',
                                                      }}
                                                    >
                                                      {location}
                                                    </div>
                                                  </Typography>
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col span={24}>
                                                  <StyledDescription
                                                    size=".875rem"
                                                    type="primaryText"
                                                    lineClamp={price ? '2' : '4'}
                                                  >
                                                    {description}
                                                  </StyledDescription>
                                                </Col>
                                              </Row>
                                              <div style={{ flex: 1 }} />
                                              {!!price && (
                                              <Row>
                                                <Col>
                                                  <div
                                                    style={{
                                                      border: '2px solid var(--primary)',
                                                      padding: '2px 4px 0 4px',
                                                      borderRadius: '4px',
                                                    }}
                                                  >
                                                    <Typography
                                                      size="1rem"
                                                      type="subText"
                                                      style={{ margin: '0' }}
                                                    >
                                                      <div
                                                        style={{
                                                          whiteSpace: 'nowrap',
                                                          textOverflow: 'ellipsis',
                                                          overflow: 'hidden',
                                                        }}
                                                      >
                                                        {`$${price}`}
                                                      </div>
                                                    </Typography>
                                                  </div>
                                                </Col>
                                              </Row>
                                              )}
                                            </Col>
                                          </Row>
                                        </IdeaContainer>
                                      ))}
                                    />
                                  )}
                                </Col>
                              </Row>
                            </Col>
                          );
                        })}
                      </Row>
                    );
                  })}
                </Col>
              </Row>
            </Container>
          ))}
        />
      </Col>
    </Row>
  );
}

export default PublicItinerary;
