import React from 'react';
import { Typography } from '@travelpassero/khaos';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    window.dataLayer.push({
      event: 'event',
      eventProps: {
        category: 'errorBoundry',
        error,
        errorInfo,
      },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <div
          style={{
            marginTop: '10%',
            textAlign: 'center',
          }}
        >
          <Typography
            type="primaryHeading"
          >
            Something went wrong.
          </Typography>

          <Typography
            type="primaryText"
          >
            Try
            {' '}
            <a href="/">refreshing</a>
            {' '}
            and if the problem consists, please
            contact us at
            <a href="mailto:team@travelpassero.com"> team@travelpassero.com</a>
          </Typography>
        </div>
      );
    }

    return children;
  }
}
