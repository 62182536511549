import React, { useEffect, useState } from 'react';

import {
  Col,
  Form,
  Row,
  Divider,
} from 'antd';

import styled from 'styled-components';
import {
  Button, CenterModalTabs, CircleCheckbox, Search, Typography,
} from '@travelpassero/khaos';
import useFriend from '@hooks/useFriend';
import { useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import { selectFriendState } from '@context/friend/friendSlice';
import Spinner from '@components/Spinner';
import FriendList from './FriendList';
import { filterUsers } from '../../utils/filter';
import AddFriendsContainer from '../../components/sharedComponentContainers/AddFriendsContainer';

const FriendContainer = styled.div`
    padding: 16px;
    background: var(--page-background);
`;

function AddFriendsModal({
  onClose,
  journeyID,
}) {
  const {
    potentialFriends, sendFriendRequests, resetSentFriendRequestStatus,
  } = useFriend();
  const [potentialFriendsList, setPotentialFriendsList] = useState([...potentialFriends]);
  const [potentialFriendsListDisplay, setPotentialFriendsListDisplay] = useState([...potentialFriends]);
  const [searchQuery, setSearchQuery] = useState('');
  const [wasChanged, setWasChanged] = useState(false);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const { globalStateReducer } = useSelector(selectAppState);
  const { sentFriendRequestStatus } = useSelector(selectFriendState);
  const [form] = Form.useForm();
  const {
    isCenterModalVisible,
  } = globalStateReducer;

  const setChecked = (list) => {
    if (list.length) {
      const checkedFriends = list?.filter((potentialFriend) => potentialFriend.isChecked);
      if (checkedFriends.length >= list.length) {
        setIndeterminate(false);
        setCheckAll(true);
      } else {
        setIndeterminate(checkedFriends.length > 0);
        setCheckAll(false);
      }
    }
  };
  useEffect(() => {
    setPotentialFriendsList([...potentialFriends]);
    setChecked(potentialFriends);
  }, [potentialFriends]);

  useEffect(() => {
    setPotentialFriendsListDisplay(filterUsers(searchQuery, potentialFriendsList));
  }, [potentialFriendsList]);

  // when the add a new friend request modal completes its submission cycle
  useEffect(() => {
    if (sentFriendRequestStatus?.toLowerCase() === 'fulfilled') {
      setTimeout(() => {
        onClose();
        resetSentFriendRequestStatus();
      }, 3000);
    }
  }, [sentFriendRequestStatus]);

  const handleSearchPotentialFriends = (event) => {
    const query = event?.target?.value || event;

    setPotentialFriendsListDisplay(filterUsers(query, potentialFriendsList));
    setSearchQuery(query);
  };

  const handleOnSelect = (id) => {
    let isPotentialFriendChecked = false;
    const updatedPotentialFriendsList = potentialFriendsList.map((potentialFriend) => {
      const checkedPotentialFriend = { ...potentialFriend };
      if (checkedPotentialFriend.id === id) checkedPotentialFriend.isChecked = !checkedPotentialFriend.isChecked;

      if (checkedPotentialFriend.isChecked) isPotentialFriendChecked = true;
      return checkedPotentialFriend;
    });

    setPotentialFriendsList(updatedPotentialFriendsList);
    setChecked(updatedPotentialFriendsList);
    setWasChanged(isPotentialFriendChecked);
  };

  const selectNoCrewMembers = () => {
    setPotentialFriendsList(potentialFriendsList.map((potentialFriend) => {
      const checkedCrewMember = { ...potentialFriend };
      checkedCrewMember.isChecked = false;
      return checkedCrewMember;
    }));
    setIndeterminate(false);
    setWasChanged(false);
  };

  const selectAllCrewMembers = () => {
    setPotentialFriendsList(potentialFriendsList.map((potentialFriend) => {
      const checkedCrewMember = { ...potentialFriend };
      checkedCrewMember.isChecked = true;
      return checkedCrewMember;
    }));
    setIndeterminate(false);
    setWasChanged(true);
  };

  const onCheckAllChange = (e) => {
    const isChecked = e.target.checked;

    if (isChecked) selectAllCrewMembers();
    else selectNoCrewMembers();

    setCheckAll(isChecked);
  };

  const handleOnAddFriends = () => {
    sendFriendRequests(potentialFriendsList?.filter((potentialFriend) => potentialFriend.isChecked));
  };

  let SubmitStatusContent = null;

  if (sentFriendRequestStatus?.toLowerCase() === 'pending') {
    SubmitStatusContent = (
      <Row justify="center" align="middle" style={{ padding: '70px 0' }}>
        <Col>
          <Spinner size={104} />
        </Col>
      </Row>
    );
  }

  if (sentFriendRequestStatus?.toLowerCase() === 'fulfilled') {
    SubmitStatusContent = (
      <Row justify="center" align="middle" style={{ padding: '70px 0' }}>
        <Col>
          <Typography size="1.25rem" type="primarySubHeading">
            Friend requests sent.
          </Typography>
        </Col>
      </Row>
    );
  }

  return (
    <CenterModalTabs
      onClose={onClose}
      showModal={isCenterModalVisible}
      title="Add Friends"
      tabs={[{
        label: 'Add New',
        Content: (
          SubmitStatusContent || <AddFriendsContainer journeyID={journeyID} onClose={onClose} form={form} />
        ),
        buttons: [
          <Button onClick={onClose}>
            Cancel
          </Button>,
          <Button type="primary" onClick={form.submit}>
            Send Friend Requests
          </Button>,
        ],
      }, {
        label: 'Add from Journeys',
        isHidden: !potentialFriends?.length,
        Content: SubmitStatusContent || (
          <FriendContainer>
            <Row
              wrap={false}
              justify="space-between"
              align="middle"
              gutter={[24, 16]}
              style={{ padding: '8px' }}
            >
              <Col flex="auto">
                <Search
                  placeholder="Search for a friend"
                  onChange={handleSearchPotentialFriends}
                  onSearch={handleSearchPotentialFriends}
                />
              </Col>
              <Col>
                <CircleCheckbox
                  type="primary"
                  indeterminate={indeterminate}
                  onClick={onCheckAllChange}
                  isChecked={checkAll}
                  title="Check all"
                  style={{ whiteSpace: 'nowrap' }}
                />
              </Col>
            </Row>
            <Divider style={{ margin: '10px 0 16px 0' }} />
            <FriendList
              list={potentialFriendsListDisplay}
              onCheckboxClick={handleOnSelect}
              cardsPerPage={12}
            />
          </FriendContainer>
        ),
        buttons: !SubmitStatusContent && [
          <Button onClick={onClose}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleOnAddFriends} disabled={!wasChanged}>
            Send Friend Requests
          </Button>,
        ],
      }]}
    />
  );
}

export default AddFriendsModal;
