import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import {
  Card,
  Col,
  Empty,
  Row,
  Tooltip,
} from 'antd';
import CardBanner from '@components/containers/CardBanner';
import { Button, Typography } from '@travelpassero/khaos';
import { selectPublicState } from '@context/public/publicSlice';
import EditProfile from '@components/containers/EditProfile';
import { openDrawer } from '@context/actions';
import useUser from '@hooks/useUser';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useTracking } from '@hooks/useTracking';
import PageSpinner from '@components/PageSpinner';
import { getPublicUserJourneysThunk, getPublicProfileThunk } from '@context/public/publicThunk';

import { useWindowSize } from '@hooks/useWindowSize';
import PublicJourneyCards from '@components/containers/PublicJourneyCards';
import PageScrollContainer from '@pages/PageScrollContainer';
import useOnPageLoad from '@hooks/useOnPageLoad';
import NameSection from './NameSection';
import SocialLinksSection from './SocialLinksSection';
import ProfilePictureSection from './ProfilePictureSection';
import ProfileDescriptionSection from './ProfileDescriptionSection';
import PublicToggleSection from './PublicToggleSection';

const BannerContainer = styled.div`
  max-width:1285px;
  width:100%;
  margin:0 auto;
  padding:0 16px
`;
const ProfileRow = styled(Row)`
  display:flex; 
  flex-direction:column;
  gap: 32px;
  max-width:1150px;
  width:100%;
  margin: 0 auto;
  padding: 0 16px;

  @media(min-width:768px){
    padding:0 30px;
  }
`;
const InfoCard = styled(Card)`
  padding: 0;
  filter: drop-shadow(0px 3px 2px rgba(0, 0, 0, 0.08));
  width:100%;
  
  &.ant-card {
    padding:9px 16px;
    margin:-40px auto 0;
    border:0;
    width:100%;
    border-radius:10px;
    @media(min-width:992px){
      margin: -40px 0 0;
    }
  }
  && .ant-card-body {
    padding: 0;
  }
  `;

const InfoCardRow = styled(Row)`
    flex-wrap: nowrap;

    @media(max-width:300px){
      flex-wrap: wrap;
    }
  `;

const EditProfileButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  padding: 14px;
  `;

const NoUserContainer = styled.div`
padding: 36px 0;
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 16px;
  text-align: center;
`;

const ProfilePage = () => {
  const { username: usernameParam } = useParams();
  const history = useHistory();
  const { setPageView, setPage } = useTracking();
  setPageView('Public Profile');
  setPage('public profile');
  const { username: userUsername } = useUser();

  const {
    publicProfileStatus, publicUserJourneysStatus, profile, publicUserJourneys,
  } = useSelector(selectPublicState);
  const { isMobile } = useWindowSize();

  const {
    username,
    isProfilePublic,
    firstName,
    lastName,
    profileDescription,
    profilePortraitData,
    profileBackgroundData,
  } = profile || {};

  const isEditable = username === userUsername;
  const canView = isProfilePublic || isEditable;

  const { imgSrcKey: profilePortraitImgSrcKey } = profilePortraitData || {};
  const { imgSrcKey: profileBackgroundImgSrcKey } = profileBackgroundData || {};

  const dispatch = useDispatch();

  const handleDrawerAction = (action, id) => {
    dispatch(openDrawer(action, id));
  };

  const onPageLoadProfile = () => dispatch(getPublicProfileThunk({ username: usernameParam }));
  const onPageLoadPublicJourneys = () => dispatch(getPublicUserJourneysThunk({ username: usernameParam }));

  const onCheckProfileDataLoaded = () => !!profile;
  const onCheckPublicJourneysDataLoaded = () => !!publicUserJourneys?.length;

  useOnPageLoad({ status: publicProfileStatus, onPageLoad: onPageLoadProfile, onCheckDataLoaded: onCheckProfileDataLoaded });
  useOnPageLoad({
    status: publicUserJourneysStatus,
    onPageLoad: onPageLoadPublicJourneys,
    onCheckDataLoaded: onCheckPublicJourneysDataLoaded,
  });

  if ((publicProfileStatus === 'pending' || publicUserJourneysStatus === 'pending')) {
    return <PageSpinner />;
  }

  if (!canView || publicProfileStatus === 'rejected' || publicUserJourneysStatus === 'rejected') {
    return (
      <PageScrollContainer>
        <NoUserContainer>
          <Row align="center" style={{ display: 'flex', flexDirection: 'column' }}>
            <Col><Typography type="defaultText">User</Typography></Col>
            <Col>
              <Typography size="1.5rem" type="errorText">{username}</Typography>
            </Col>
            <Col><Typography type="defaultText">cannot be viewed.</Typography></Col>
          </Row>
          <Row align="center">
            <Col>
              <Empty description={false} />
            </Col>
          </Row>
          <Row align="center">
            <Col>
              <Button onClick={history.goBack}>Go Back</Button>
            </Col>
          </Row>
        </NoUserContainer>
      </PageScrollContainer>
    );
  }

  if (username && publicProfileStatus === 'fulfilled' && publicUserJourneysStatus === 'fulfilled') {
    return (
      <PageScrollContainer>
        <BannerContainer>
          <CardBanner
            imgSrc={profileBackgroundImgSrcKey}
            onEdit={() => handleDrawerAction('edit-banner')}
            isEditable={isEditable}
          />
        </BannerContainer>
        <ProfileRow>
          <Col>
            <InfoCard size="small">
              <PublicToggleSection isEditable={isEditable} />
              <InfoCardRow gutter={[16, { xs: 16, md: 0 }]} style={{ width: '100%' }}>
                <Col flex="none">
                  <ProfilePictureSection
                    firstName={firstName}
                    lastName={lastName}
                    profilePortraitImgSrcKey={profilePortraitImgSrcKey}
                    publicUserJourneys={publicUserJourneys}
                  />
                </Col>
                <Col flex="auto" style={{ minWidth: '0' }}>
                  <Row gutter={[4, 0]}>
                    <Col flex="auto">
                      <NameSection firstName={firstName} lastName={lastName} />
                      <SocialLinksSection isEditable={isEditable} />
                    </Col>
                    {!isMobile && (
                      <Col>
                        <ProfileDescriptionSection profileDescription={profileDescription} isEditable={isEditable} />
                      </Col>
                    )}
                  </Row>
                </Col>
              </InfoCardRow>

              {isMobile && (
                <Row>
                  <Col>
                    <ProfileDescriptionSection profileDescription={profileDescription} isEditable={isEditable} />
                  </Col>
                </Row>
              )}
              <EditProfileButton>
                {isEditable && (
                  <Tooltip title="Edit">
                    <div>
                      <Button
                        onClick={() => handleDrawerAction('edit-profile', 1234)}
                        type="greyEdit"
                      />
                    </div>
                  </Tooltip>
                )}
              </EditProfileButton>
            </InfoCard>
          </Col>
          <Col>
            <PublicJourneyCards publicUserJourneys={publicUserJourneys} title="My Journeys" isEditable={isEditable} />
          </Col>
        </ProfileRow>
        <EditProfile />
      </PageScrollContainer>
    );
  }

  return null;
};

export default ProfilePage;
