import React from 'react';
import IdeaBoardPage from './IdeaBoardPage';

const IdeaBoardPageContainer = ({ journeyID, hasBringPageButton, splitScreenSide }) => (
  <IdeaBoardPage
    journeyID={journeyID}
    hasBringPageButton={hasBringPageButton}
    splitScreenSide={splitScreenSide}
  />
);

export default IdeaBoardPageContainer;
