import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
// Redux
import { selectJourneysState } from '@context/journeys/journeysSlice';
// Components
import PageSpinner from '@components/PageSpinner';
import useJourneys from '@hooks/useJourneys';
import useFriend from '@hooks/useFriend';
import { selectNotificationState } from '@context/notification/notificationSlice';
import { selectFriendState } from '@context/friend/friendSlice';
import { getNotificationsThunk } from '@context/notification/notificationThunk';
import withAuth from '@libs/withAuth';
import useOnPageLoad from '@hooks/useOnPageLoad';

const Authenticator = ({ children }) => {
  const dispatch = useDispatch();
  const { status: notificationStatus, notifications } = useSelector(selectNotificationState);
  const { status: friendStatus, friends } = useSelector(selectFriendState);
  const { status: journeyStatus, journeys } = useSelector(selectJourneysState);
  const {
    getFriends,
    getReceivedFriendRequests,
    getSentFriendRequests,
    getDeclinedSentFriendRequests,
  } = useFriend();
  const { getJourneys } = useJourneys();

  const onPageLoadNotifications = () => dispatch(getNotificationsThunk());
  const onPageLoadFriends = () => {
    getFriends();
    getReceivedFriendRequests();
    getSentFriendRequests();
    getDeclinedSentFriendRequests();
  };
  const onPageLoadJourneys = () => getJourneys();

  const onCheckNotificationDataLoaded = () => !!notifications?.length;
  const onCheckFriendDataLoaded = () => !!friends?.length;
  const onCheckJourneyDataLoaded = () => !!journeys?.length;

  useOnPageLoad({ status: notificationStatus, onPageLoad: onPageLoadNotifications, onCheckDataLoaded: onCheckNotificationDataLoaded });
  useOnPageLoad({ status: friendStatus, onPageLoad: onPageLoadFriends, onCheckDataLoaded: onCheckFriendDataLoaded });
  useOnPageLoad({ status: journeyStatus, onPageLoad: onPageLoadJourneys, onCheckDataLoaded: onCheckJourneyDataLoaded });

  if (friendStatus === 'pending' || journeyStatus === 'pending') {
    return <PageSpinner />;
  }

  return children;
};

export default withAuth(Authenticator);
