import React from 'react';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  SurveyCard,
  AddCard,
  CenterModal,
  DeleteItemForm,
  EditItemForm,
  useWindowSize,
} from '@travelpassero/khaos';
import { dataLayerUtil } from '@utils/dataLayerUtil';
import {
  openCenterModal,
} from '@context/actions';

import { selectAppState } from '@context/global/appSlice';
import { useJourney } from '@hooks/useJourney';
import useUser from '@hooks/useUser';
import useLayout from '@hooks/useLayout';
import ModalSpinner from '../ModalSpinner';
import CardPositioner from './CardPositioner';

const Container = styled.div`
  padding: 24px;
  background: var(--page-background);
`;

function SurveyCardContainer({
  journeyID,
  useJourneyData,
  onCreateSurveyClick,
}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { globalStateReducer } = useSelector(selectAppState);
  const { userID } = useUser();
  const { handleCloseModal } = useLayout();
  const { surveys, updateJourney } = useJourneyData;
  const { isMobile } = useWindowSize();

  const {
    isCenterModalVisible,
    modalChildrenType,
    modalSelectedID,
    modalLoadingState,
  } = globalStateReducer;

  const journeyPath = `/journeys/${journeyID}`;

  const handleDeleteModalOpen = (id) => {
    dispatch(openCenterModal('delete', id));
  };

  const handleEditModalOpen = (id) => {
    dispatch(openCenterModal('edit', id));
  };

  const handleSurveyResultsClick = (id) => {
    history.push(`${journeyPath}/surveys/${id}`);
  };

  const getSelectedSurveyData = (id) => surveys && surveys.find((item) => item.id === id);
  const selectedSurveyData = getSelectedSurveyData(modalSelectedID);

  const handleDeleteItem = async () => {
    const surveysUpdated = surveys.filter((survey) => survey.id !== modalSelectedID);

    updateJourney({ surveys: surveysUpdated });

    dataLayerUtil('Delete Survey', { modalSelectedID });

    handleCloseModal();
  };

  const handleEditItem = async (formData) => {
    const { title } = formData;

    const surveysUpdated = surveys.map((survey) => {
      const newSurvey = { ...survey };
      if (survey.id === modalSelectedID) newSurvey.title = title;
      return newSurvey;
    });

    updateJourney({ surveys: surveysUpdated });
    handleCloseModal();
  };

  let ModalChildren;

  switch (modalChildrenType) {
    case 'delete':
      ModalChildren = (
        <DeleteItemForm
          item="Survey"
          handleOnClose={handleCloseModal}
          handleDeleteItem={handleDeleteItem}
        />
      );
      break;

    case 'edit':
      ModalChildren = (
        <EditItemForm
          item="Survey"
          onFinish={handleEditItem}
          title={selectedSurveyData && selectedSurveyData.title}
          handleOnClose={handleCloseModal}
        />
      );
      break;
    default:
      ModalChildren = null;
  }

  const surveyCards = [(
    <AddCard
      key="empty_survey--card"
      onClick={onCreateSurveyClick}
      type="survey"
    />
  )];

  if (surveys) {
    surveyCards.push(surveys.map((item, index) => {
      const {
        id, invited, completed, creator,
      } = item;

      let isFinishedSurvey;
      let removeUninvitedCards;
      let isCreator;
      if (!invited.includes(userID) && creator !== userID) {
        removeUninvitedCards = true;
      }

      if (creator === userID) {
        isFinishedSurvey = true;
        isCreator = true;
      }

      if (completed.includes(userID)) {
        isFinishedSurvey = true;
      }

      return (
        <>
          {removeUninvitedCards ? null : (
            <SurveyCard
              key={`${index}_survey--card`}
              onResultsClick={() => handleSurveyResultsClick(id)}
              onDeleteClick={() => handleDeleteModalOpen(id)}
              onEditClick={() => handleEditModalOpen(id)}
              isFinishedSurvey={isFinishedSurvey}
              isCreator={isCreator}
              {...item}
            />
          )}
        </>
      );
    }));
  }

  return (
    <>
      <Container>
        <CardPositioner
          cardMaxWidth={isMobile ? 500 : 350}
          cardMinWidth={250}
          cards={surveyCards}
        />
      </Container>

      {ModalChildren ? (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={modalLoadingState ? <ModalSpinner /> : ModalChildren}
          modalWidth="500px"
        />
      ) : null}
    </>
  );
}

export default SurveyCardContainer;
