export const useTracking = () => {
  const setPageView = (title) => {
    window.dataLayer.push({
      event: 'pageview',
      page: { title },
    });
  };

  // fixes a hotjar page tracking issue
  const setPage = (page) => {
    if (window?.page) {
      window.page(page);
    }
  };

  return {
    setPageView,
    setPage,
  };
};
