import { createAsyncThunk } from '@reduxjs/toolkit';
import * as HTTP from '@utils/http';

export const getPublicJourneyThunk = createAsyncThunk('getPublicJourney',
  async ({ journeyID }) => {
    try {
      return await HTTP.getPublic(`/public/journeys/${journeyID}`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getPublicProfileThunk = createAsyncThunk('getPublicProfile',
  async ({ username }) => {
    try {
      return await HTTP.getPublic(`/public/${username}/profile`);
    } catch (error) {
      throw new Error(error.message);
    }
  });

export const getPublicUserJourneysThunk = createAsyncThunk('getPublicUserJourneysThunk',
  async ({ username }) => {
    try {
      const { journeys } = await HTTP.getPublic(`/public/${username}/journeys`);

      return journeys;
    } catch (error) {
      throw new Error(error.message);
    }
  });
