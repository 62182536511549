import React from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from '@utils/dragTypes';
import IdeaFolderCard from '@components/sharedComponentContainers/IdeaFolderCard';

function IdeaCardFolderDnDContainer({
  imgSrc,
  handleOnFolderChange,
  OnIdeaItineraryAdd,
  onIdeaAddToSurvey,
  id,
  title,
  folderKey,
}) {
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.IDEACARD,
    item: { id, folderId: folderKey },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult && (dropResult.type === 'folder' || dropResult.type === 'idea')) {
        handleOnFolderChange(id, dropResult.name);
      }
      if (item && dropResult && dropResult.type === 'itinerary') {
        OnIdeaItineraryAdd(id, dropResult.name);
      }
      if (item && dropResult && dropResult.type === 'survey') {
        const { questionNumber } = dropResult;

        onIdeaAddToSurvey(id, questionNumber);
      }
      return null;
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  if (isDragging) {
    return <div ref={drag} />;
  }

  return (
    <IdeaFolderCard title={title} imgSrc={imgSrc} dragRef={drag} />
  );
}

export default IdeaCardFolderDnDContainer;
