import React from 'react';
import styled from 'styled-components';
import {
  Card,
  Col,
} from 'antd';
import {
  EnvironmentFilled,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Typography } from '@travelpassero/khaos';
import CardImageContainer from '@components/renderContainers/CardImageContainer';

const CardInnerHeader = styled.a`
color: var(--white);
font-size: 16px;
font-style: normal;
font-weight: 700;
line-height: 21px;
letter-spacing: 0.1px;
display:block;

&:hover {
  color: var(--white);
}
`;
const ContainerWrapIcon = styled.div`
display:flex;
align-items:center;
font-weight:500;
font-size:14px;
color:var(--white);
margin-right: 16px;
`;
const DaysContainer = styled.span`
background: var(--accent);
height: 23px;
width: 23px;
display:flex;
align-items:center;
justify-content:center;
border-radius: 6px;
color: var(--white);
font-weight:700;
margin-right:6px;
`;
const CardInnerDiv = styled.div`
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:2;
  display:flex;
  flex-direction:column;
  padding: 8px;
  justify-content:space-between;
`;
const CardDiv = styled(Card)`
  position:relative;
  display:block;
  height:220px;
  border-radius:12px;
  overflow:hidden;

  .ant-card-body { 
    padding: 0px;
  }

  .ant-card-cover {
    border-radius:12px;
    object-fit: cover;
    object-position: center;
    width:100%;
    height:100%;
    min-height: 220px;
  }
`;
const ImgOverlayTop = styled.div`
    position:absolute;
    width:100%;
    height:65px;
    top:0;
    left:0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.58) 100%);
    z-index:1;
    border-radius:12px;
`;
const ImgOverlayBottom = styled.div`
    position:absolute;
    width:100%;
    height:65px;
    bottom:0;
    left:0;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.58) 0%, rgba(0, 0, 0, 0.00) 100%);
    z-index:1;
    border-radius:12px;
  }
  `;
const CardInnerMetaDiv = styled.div`
  display:flex;
  align-items:center;
`;
const PlusIconDiv = styled.div`
  margin-left:auto;
`;

function PublicJourneyCard({ publicJourney }) {
  const { pk, data } = publicJourney;
  const {
    itinerary, title, location, imgSrcKey,
  } = data;

  const { dateRangeStart, dateRangeEnd } = itinerary || {};

  const totalDays = Math.floor((new Date(dateRangeEnd) - new Date(dateRangeStart)) / (1000 * 60 * 60 * 24));

  return (
    <CardDiv hoverable bordered={false}>
      <ImgOverlayTop />
      <ImgOverlayBottom />
      <CardImageContainer
        imgSrcKey={imgSrcKey}
        render={(imgSrcUrl) => (
          <>
            <img className="ant-card-cover" src={imgSrcUrl} alt={title} />
            <CardInnerHeader href={`/public/itinerary/${pk}`} target="_blank">
              <CardInnerDiv>
                {title}
                <CardInnerMetaDiv>
                  <ContainerWrapIcon>
                    <DaysContainer>{totalDays}</DaysContainer>
                    Days
                  </ContainerWrapIcon>
                  {!!location && (
                    <ContainerWrapIcon>
                      <EnvironmentFilled
                        style={{ color: 'var(--grey)', fontSize: '24px' }}
                      />
                      <Typography
                        type="whiteText"
                        size="1rem"
                        style={{
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          maxWidth: '100px',
                          letterSpacing: '0.1px',
                          textDecorationLine: 'underline',
                          paddingLeft: '6px',
                          fontWeight: 500,
                        }}
                      >
                        {location}
                      </Typography>
                    </ContainerWrapIcon>
                  )}
                  <PlusIconDiv>
                    <PlusCircleOutlined
                      style={{ color: 'var(--grey)', fontSize: '24px' }}
                    />
                  </PlusIconDiv>
                </CardInnerMetaDiv>
              </CardInnerDiv>
            </CardInnerHeader>
          </>
        )}
      />
    </CardDiv>
  );
}

export default PublicJourneyCard;
