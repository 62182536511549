import React, { useState } from 'react';
import {
  Form, Input, Row, Col, Divider,
} from 'antd';
import styled from 'styled-components';
import {
  GlobalOutlined,
  InstagramOutlined,
  UploadOutlined,
  YoutubeFilled,
} from '@ant-design/icons';
import {
  Typography,
  Button,
  Switch,
} from '@travelpassero/khaos';
import { v4 as uuidv4 } from 'uuid';
import useUser from '@hooks/useUser';
import { useDispatch, useSelector } from 'react-redux';
import { selectPhotoReducer, selectPhotoState } from '@context/photo/photoSlice';
import { deleteFileThunk, uploadFileThunk } from '@context/storage/storageThunk';
import { selectPublicState } from '@context/public/publicSlice';
import RichTextEditor from '@components/containers/RichTextEditor';
import Spinner from '@components/Spinner';
import Pinterst from '../../assets/pinterest.svg';
import Tiktok from '../../assets/tiktok.svg';

const FormInputItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    input, .ant-input-search-button {
      height: 40px;
    }
  }
 .ant-form-item{
    margin-bottom: 20px !important;
  }

  .ant-picker {
    width: 100%;
  }
`;

const ButtonContainerStyled = styled(Form.Item)`
  .ant-form-item-control-input-content {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
`;

const FormContainer = styled.div`
  width: 100%;
  max-width: 450px;
`;
const FormInputItemWithIcon = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 24px;
  .ant-form-item{
    margin-bottom: 0;
  }

  svg {
    fill: var(--icons);
  }
`;

const PortraitPreviewImg = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-top: 10px;
`;

function ProfileForm({
  onOpenUploadModal,
}) {
  const {
    updateProfile,
  } = useUser();
  const {
    profile,
  } = useSelector(selectPublicState);

  const {
    isProfilePublic,
    websiteUrl,
    instagramUrl,
    youtubeUrl,
    pinterestUrl,
    tiktokUrl,
    profileDescription,
    profilePortraitData,
  } = profile || {};

  const { imgSrcKey } = profilePortraitData || {};
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [newProfileDescription, setNewProfileDescription] = useState(profileDescription);
  const [isSaving, setIsSaving] = useState(false);

  const { photo } = useSelector(selectPhotoState);

  const postPhoto = async (folder) => {
    const { file } = photo;

    if (!file) return;

    return await dispatch(uploadFileThunk({
      key: `${folder}/${uuidv4()}.jpg`,
      file,
    })).unwrap();
  };

  const deleteOldPhoto = (key) => {
    dispatch(deleteFileThunk({
      key,
    }));
  };

  const handleOnFinish = async (formData) => {
    setIsSaving(true);

    const formattedData = {
      ...formData,
      profileDescription: newProfileDescription,
    };

    let { key } = photo;
    const { file } = photo;

    if (file) {
      if (!key) { key = await postPhoto('profile/portrait'); }

      if (key) {
        formattedData.profilePortraitData = {
          imgSrcKey: key,
        };
      }
    }

    updateProfile({
      ...formattedData,
    });

    deleteOldPhoto(imgSrcKey);
    dispatch(selectPhotoReducer({}));
    setIsSaving(false);
  };

  return (
    <Row justify="center" align="middle" style={{ height: '80%' }}>
      <Col>
        <Row justify="center" style={{ padding: '0 24px' }}>
          <Col span={24} style={{ width: '100%', padding: '68px 0 24px 0', textAlign: 'center' }}>
            <Typography
              size="1.5rem"
              type="primaryHeading"
            >
              Edit your Profile
            </Typography>
          </Col>
          <Col style={{ width: '100%' }}>
            <FormContainer>
              <Form form={form} name="control-hooks" onFinish={handleOnFinish}>
                <FormInputItem style={{ flex: 1 }} name="isProfilePublic">
                  <Switch label="Public Profile" defaultValue={isProfilePublic} />
                </FormInputItem>
                {/* <FormInputItem
                  name="username"
                  initialValue={username}
                >
                  <Input placeholder="@user-handle" />
                </FormInputItem> */}
                <FormInputItem style={{ flex: 1 }}>
                  <Row justify="start" align="middle" gutter={[36, 8]} wrap={false}>
                    <Col>
                      <Typography size="12px" type="subText">Profile Picture</Typography>
                      <Button style={{ marginTop: 10 }} onClick={onOpenUploadModal}>
                        <UploadOutlined />
                        Upload an image
                      </Button>
                    </Col>
                    <Col>
                      {!!photo.src && <PortraitPreviewImg src={photo.src} alt="" />}
                    </Col>
                  </Row>
                </FormInputItem>
                <Divider />
                <FormInputItemWithIcon>
                  <GlobalOutlined style={{ fontSize: '26px' }} />
                  <FormInputItem
                    name="websiteUrl"
                    initialValue={websiteUrl}
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Your website" />
                  </FormInputItem>
                </FormInputItemWithIcon>
                <FormInputItemWithIcon>
                  <InstagramOutlined style={{ fontSize: '26px' }} />
                  <FormInputItem
                    name="instagramUrl"
                    initialValue={instagramUrl}
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Instagram URL" />
                  </FormInputItem>
                </FormInputItemWithIcon>
                <FormInputItemWithIcon>
                  <Tiktok width="26" height="26" />
                  <FormInputItem
                    name="tiktokUrl"
                    initialValue={tiktokUrl}
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="TikTok URL" />
                  </FormInputItem>
                </FormInputItemWithIcon>
                <FormInputItemWithIcon>
                  <Pinterst width="26" height="26" />
                  <FormInputItem
                    initialValue={pinterestUrl}
                    name="pinterestUrl"
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Pinterest URL" />
                  </FormInputItem>
                </FormInputItemWithIcon>
                <FormInputItemWithIcon>
                  <YoutubeFilled style={{ fontSize: '26px' }} />
                  <FormInputItem
                    initialValue={youtubeUrl}
                    name="youtubeUrl"
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="YouTube URL" />
                  </FormInputItem>
                </FormInputItemWithIcon>
                {/* <FormInputItemWithIcon>
                  <MailFilled style={{ fontSize: '26px' }} />
                  <FormInputItem
                    initialValue={email}
                    name="email"
                    style={{ flex: 1 }}
                  >
                    <Input placeholder="Your email " />
                  </FormInputItem>
                </FormInputItemWithIcon> */}
                <Divider />
                <Row justify="start" align="middle" gutter={[36, 8]}>
                  <Col>
                    <Typography size="12px" type="subText">Description</Typography>
                  </Col>
                  <Col span={24}>
                    <FormInputItem style={{ flex: 1 }}>
                      <RichTextEditor value={profileDescription} onChange={setNewProfileDescription} />
                    </FormInputItem>
                  </Col>
                </Row>
                <ButtonContainerStyled
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  {isSaving ? (
                    <div>
                      <Spinner color="var(--accent)" size={40} />
                    </div>
                  )
                    : (
                      <Button type="primary" htmlType="submit">
                        Save
                      </Button>
                    )}
                </ButtonContainerStyled>
              </Form>
            </FormContainer>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

export default ProfileForm;
