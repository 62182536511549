import React, { useState } from 'react';
import styled from 'styled-components';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import * as R from 'remeda';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import {
  Col,
  Dropdown, Form, Input, Menu, Row,
} from 'antd';
import {
  Button,
  CenterModal,
  CircleProfileIcon,
  SurveyInviteCrewCard,
  SurveyPreCrewModalForm,
  Typography,
} from '@travelpassero/khaos';

import { closeModal } from '@context/actions';

import SurveyQuestionCard from '@components/SurveyQuestionCard';
import ModalSpinner from '@components/ModalSpinner';

import { useDispatch, useSelector } from 'react-redux';
import { selectAppState } from '@context/global/appSlice';
import { useJourney } from '@hooks/useJourney';
import useUser from '@hooks/useUser';
import useSurvey from '@hooks/useSurvey';
import { useHistory } from 'react-router-dom';
import CardPositioner from '@components/containers/CardPositioner';

export const FlexAlignContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const QuestionsContainer = styled.div`
  max-width: inherit;
`;

export const FormInputItem = styled(Form.Item)`
  .ant-form-item-control-input-content {
    input {
      border-radius: 5px;
      height: 40px;
    }
  }
`;

export const MenuItem = styled(Menu.Item)`
  display: flex;
  align-items: center;
  color: var(--primary);
  font-family: 'font-primary', sans-serif;
  font-size: 1rem;
  padding: 12px 16px;
`;

const SurveyCreatePage = ({
  ideas,
  crew,
  journeyID,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { globalStateReducer } = useSelector(selectAppState);
  const { data, surveys, updateJourney } = useJourney(journeyID);
  const { userID } = useUser();
  const { sendSurveyEmail } = useSurvey(data);

  const {
    surveySelectedIdeas,
    isCenterModalVisible,
    modalChildrenType,
    modalLoadingState,
  } = globalStateReducer;

  const [form] = Form.useForm();

  const crewMinusCreator = crew && crew.filter((i) => i.id !== userID);
  const [crewSurveyList, setCrewSurveyList] = useState(crewMinusCreator);
  const [questionList, setQuestionList] = useState([0]);

  const handleCloseModal = () => {
    dispatch(closeModal());
  };

  // TODO: need a new design on the add / confirm survey crew form
  // useEffect(() => {
  //   if (noPreModal) return;
  //   dispatch(openCenterModal('pre-survey'));
  // }, []); // eslint-disable-line

  const handleAddCrew = async (formData) => {
    // await addFriends({ formData, journeyID });
  };

  const handleAddQuestion = () => {
    setQuestionList((prevState) => [...prevState, questionList.length]);
  };

  const handleMinusQuestion = () => {
    setQuestionList((prevState) => {
      const newState = prevState.slice();

      if (prevState && prevState.length > 1) {
        newState.pop();
      }

      return newState;
    });
  };

  const onFinish = (formData) => {
    const { title } = formData;
    const crewIDs = crewSurveyList.map((c) => c.id);

    const questionTypes = R.groupBy(Object.keys(formData), (x) => {
      const sortKey = x.match(new RegExp('question-(.*)_'));

      return sortKey && sortKey[1];
    });

    const filterOutTitle = Object.keys(questionTypes).filter((i) => i && i !== 'null');

    const formatQuestions = filterOutTitle.map((num) => {
      const title = formData[
        questionTypes[num].filter((item) => item.includes('title'))[0]
      ];

      const choices = questionTypes[num].filter(
        (item) => !item.includes('title'),
      );

      let questionChoices;

      if (choices && choices[0] && choices[0].includes('chooseTiles')) {
        const tiles = formData[choices[0]];
        const regex = /question-/i;

        const getQuestionNumber = parseInt(
          choices[0].replace(regex, '').charAt(0),
        );

        const surveyQuestion = surveySelectedIdeas.find(
          (i) => i.questionNumber === getQuestionNumber,
        );
        const surveyQuestionIdeas = surveyQuestion.ideas || [];

        const filterTiles = tiles && tiles.filter((i) => i);
        const tileKeys = filterTiles && filterTiles.map((i) => i.key);
        const combineTiles = [
          ...new Set([...(tileKeys || []), ...(surveyQuestionIdeas || [])]),
        ].filter((i) => i);

        questionChoices = combineTiles.map((item) => ({
          id: uuidv4(),
          ideaID: item,
        }));
      } else if (choices && choices[0] && choices[0].includes('dateRange')) {
        questionChoices = choices
          .map((rangeChoice) => {
            const dateRanges = formData[rangeChoice];

            return {
              id: uuidv4(),
              dateRange: dateRanges,
            };
          })
          .filter((item) => item.dateRange);
      } else {
        questionChoices = choices
          .map((item) => ({
            id: uuidv4(),
            option: formData[item],
          }))
          .filter((item) => item.option);
      }

      let questionType;

      if (choices && choices.length === 0) {
        questionType = 'simple';
      } else if (choices && choices[0] && choices[0].includes('chooseTiles')) {
        questionType = 'tiles';
      } else if (choices && choices[0] && choices[0].includes('dateRange')) {
        questionType = 'date';
      } else if (choices && choices[0] && choices[0].includes('binary')) {
        questionType = 'checkbox';
      } else if (choices && choices[0] && choices[0].includes('option')) {
        questionType = 'multiple';
      } else {
        questionType = 'simple';
      }

      return {
        id: uuidv4(),
        title,
        type: questionType,
        choices: questionChoices,
      };
    });

    const surveyItem = {
      id: uuidv4(),
      title,
      creator: userID,
      invited: crewIDs,
      completed: [],
      createdDate: moment().format(),
      questions: formatQuestions,
    };

    let newSurveys = [];
    if (surveys) newSurveys = [...surveys];
    newSurveys.push(surveyItem);

    updateJourney({ surveys: newSurveys });
    sendSurveyEmail(surveyItem);
    history.push(`/journeys/${journeyID}/surveys`);
  };

  const handleRemoveCrewMember = (id) => {
    setCrewSurveyList((prevState) => prevState?.filter((crew) => crew.id !== id));
  };

  const crewListKeys = crewSurveyList && crewSurveyList.length && crewSurveyList.map((crew) => crew?.id);
  const handleSurveyCrewMenuClick = (menuData) => {
    const { key } = menuData;

    const foundMember = crew.find((i) => i.id === key);

    if (foundMember) {
      setCrewSurveyList((prevState) => [...prevState, foundMember]);
    }
  };

  const crewOptions = crewMinusCreator
    && crewMinusCreator.filter((item) => ![...crewListKeys || []].includes(item.id));

  const myCrewMenu = (
    <Menu onClick={handleSurveyCrewMenuClick}>
      {crewOptions && crewOptions.length ? (
        crewOptions.map((item) => {
          const {
            firstName, lastName, id, iconColor, isPendingFriend,
          } = item;

          return (
            <MenuItem key={id}>
              <CircleProfileIcon
                firstName={firstName}
                lastName={lastName}
                isPendingFriend={isPendingFriend}
                iconColor={iconColor}
              />
              {' '}
              <Typography style={{ paddingLeft: '12px' }} type="primaryText">
                {firstName}
                {' '}
                {lastName}
              </Typography>
            </MenuItem>
          );
        })
      ) : (
        <MenuItem>No more crew members to invite</MenuItem>
      )}
    </Menu>
  );

  let ModalChildren;
  switch (modalChildrenType) {
    case 'pre-survey':
      ModalChildren = (
        <SurveyPreCrewModalForm
          crewSurveyList={crewSurveyList}
          handleRemoveCrewMember={handleRemoveCrewMember}
          crewMemberOptions={crewOptions}
          handleSurveyCrewMenuClick={handleSurveyCrewMenuClick}
          onConfirmClick={handleCloseModal}
          handleOnFinish={handleAddCrew}
        />
      );
      break;

    default:
      ModalChildren = null;
  }

  const questionCards = [];

  if (questionList) {
    questionCards.push(...questionList.map((item) => (
      <SurveyQuestionCard
        key={`${item}_survey-question-card`}
        questionNumber={item}
        ideas={ideas}
      />
    )));
  }

  const crewSurveyCards = [];

  if (crewSurveyList?.length) {
    crewSurveyCards.push(...crewSurveyList.map((member, index) => {
      const {
        firstName, lastName, iconColor, id, isPendingFriend,
      } = member;

      return (
        <SurveyInviteCrewCard
          key={`${firstName}_${index}`}
          iconColor={iconColor}
          firstName={firstName}
          isPendingFriend={isPendingFriend}
          lastName={lastName}
          onDeleteClick={() => handleRemoveCrewMember(id)}
        />
      );
    }),
    (
      <Dropdown overlay={myCrewMenu}>
        <div
          key="add-survey-crew"
          style={{
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            padding: '0 8px',
            margin: '8px 0',
            height: '100px',
          }}
          className="card-sizing"
        >
          <Button type="circleFacePlus" />
        </div>
      </Dropdown>
    ));
  }

  return (
    <div style={{ padding: '60px 16px 160px 16px' }}>
      <div
        style={{
          marginBottom: '75px',
          marginTop: '36px',
        }}
      >
        <Typography
          style={{ textAlign: 'center' }}
          size="1.8rem"
          type="primaryHeading"
        >
          Let&apos;s
          {' '}
          <span style={{ color: 'var(--secondary)' }}>ask your crew</span>
          {' '}
          and
          see what they say.
        </Typography>
      </div>

      <Form form={form} name="survey-questions" onFinish={onFinish}>
        <div>
          <Row justify="center">
            <Col style={{ maxWidth: '700px', width: '100%', padding: '0 16px' }}>
              <FormInputItem
                rules={[{ required: true }]}
                name="title"
              >
                <Input placeholder="Survey Name" />
              </FormInputItem>
            </Col>
          </Row>
          <CardPositioner
            cardMaxWidth={700}
            cardMinWidth={700}
            rowWidthOffset={2}
            center
            cards={questionCards}
          />
          <QuestionsContainer>
            <FlexAlignContainer
              style={{ marginBottom: '46px', marginTop: '54px' }}
            >
              <Button onClick={handleMinusQuestion} type="grey">
                <MinusOutlined style={{ display: 'flex' }} />
              </Button>
              <div style={{ width: '12px' }} />
              <Button onClick={handleAddQuestion} type="grey">
                <PlusOutlined style={{ display: 'flex' }} />
                Add a question
              </Button>
            </FlexAlignContainer>

            <div style={{ display: 'flex', marginBottom: '32px' }}>
              {crewSurveyCards.length > 0 && (
                <CardPositioner
                  cardMaxWidth={124}
                  cardMinWidth={124}
                  center
                  cards={crewSurveyCards}
                />
              )}
              {(!crewSurveyList || crewSurveyList.length === 0) && (
                <Typography
                  style={{
                    width: '100%',
                    textAlign: 'center',
                  }}
                  type="primaryText"
                  size="1.25rem"
                >
                  You have to add crew members before you create a survey.
                </Typography>
              )}
            </div>
            <FlexAlignContainer>
              <Button htmlType="submit" type="primary">
                Send to crew
              </Button>
            </FlexAlignContainer>
          </QuestionsContainer>
        </div>
      </Form>

      {ModalChildren ? (
        <CenterModal
          footer={null}
          handleOnClose={handleCloseModal}
          showModal={isCenterModalVisible}
          children={modalLoadingState ? <ModalSpinner /> : ModalChildren}
          modalWidth="700px"
        />
      ) : null}
    </div>
  );
};

export default SurveyCreatePage;
