import React from 'react';
import { useDrag } from 'react-dnd';
import { FriendCard, DropCard } from '@travelpassero/khaos';
import { ItemTypes } from '@utils/dragTypes';
import DropContainer from '@components/containers/DropContainer';

const FriendCardDnDContainer = ({
  item,
  onReminderClick,
  onAddFriendClick,
  onCheckboxClick,
  onRemoveClick,
  removeConfirmText,
  onAddToCrew,
  onDeleteDrag,
  canDrag = false,
}) => {
  const {
    iconColor, firstName, lastName, email, id, isNotFriend, isChecked, isDisabled, isPendingFriend, isFriendRequestSent,
  } = item;

  let dragRef = {};

  if (canDrag) {
    const [{ isDragging }, drag] = useDrag({
      type: ItemTypes.IDEACARD,
      item: { id },
      end: (item, monitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult && dropResult.type === 'add-crew') {
          onAddToCrew(id, dropResult.name);
        }
        if (item && dropResult && dropResult.type === 'journey') {
          onAddToCrew(id, dropResult.name);
        }
        if (item && dropResult && dropResult.type === 'remove') {
          onDeleteDrag(id);
        }
        return null;
      },
      collect: (monitor) => ({
        isDragging: !!monitor.isDragging(),
      }),
    });

    if (isDragging) {
      return (
        <DropContainer ref={drag} type="" folderID="" style={{ padding: '8px' }}>
          <DropCard style={{
            position: 'relative',
            width: '100%',
            height: '68px',
          }}
          />
        </DropContainer>
      );
    }
    dragRef = drag;
  }

  return (
    <FriendCard
      onReminderClick={onReminderClick && isFriendRequestSent ? () => onReminderClick(item) : null}
      onAddFriendClick={onAddFriendClick && isNotFriend && !isPendingFriend ? () => onAddFriendClick(item) : null}
      onRemoveClick={onRemoveClick ? () => onRemoveClick(id) : null}
      removeConfirmText={removeConfirmText}
      onCheckboxClick={onCheckboxClick ? () => onCheckboxClick(id) : null}
      isChecked={isChecked}
      isDisabled={isDisabled}
      dragRef={canDrag && dragRef ? dragRef : null}
      iconColor={iconColor}
      firstName={firstName}
      lastName={lastName}
      email={!isNotFriend || isPendingFriend ? email : null}
      isPendingFriend={isPendingFriend}
    />
  );
};

export default FriendCardDnDContainer;
