import React from 'react';

import SurveyPage from './SurveyPage';

const SurveyPageContainer = ({
  journeyID, hasBringPageButton, splitScreenSide,
}) => (
  <SurveyPage
    journeyID={journeyID}
    hasBringPageButton={hasBringPageButton}
    splitScreenSide={splitScreenSide}
  />
);

export default SurveyPageContainer;
