import useUser from '@hooks/useUser';
import Layout from '@components/Layout';
import DiscoverPage from './DiscoverPage';

const DiscoverPageContainer = () => {
  const { userID } = useUser();

  if (userID) {
    return <Layout><DiscoverPage /></Layout>;
  }

  return <DiscoverPage />;
};

export default DiscoverPageContainer;
