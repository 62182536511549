import React from 'react';
import styled from 'styled-components';
import {
  Card,
  Col,
  Row,
} from 'antd';
import { Typography } from '@travelpassero/khaos';
import PublicJourneyCard from './PublicJourneyCard';

const JourneysCard = styled(Card)`
border-radius:12px;
margin-bottom: 32px;

& .ant-card-head {
  border-bottom:0;
  padding: 0 20px 0;
}
& .ant-card-body { 
  padding: 0px;
}`;
const NoItinerariesContainer = styled.div`
  position:relative;
  display:block;
  padding: 0 16px 16px;
  border-radius:10px;
  display:flex;
  flex-direction:column;
  gap: 16px;
`;

function PublicJourneyCards({ publicUserJourneys, title, isEditable }) {
  const hasPublicJourneys = publicUserJourneys?.length > 0;
  const isTitle = !!title;

  if (hasPublicJourneys || isEditable) {
    return (
      <JourneysCard title={isTitle && <Typography type="secondaryHeading" size="18px">My Journeys</Typography>}>
        {hasPublicJourneys ? (
          <Row
            gutter={[16, 16]}
            style={{ padding: '0 16px', marginTop: isTitle ? '0' : '8px' }}
          >
            {publicUserJourneys
              .map((publicJourney) => {
                const { pk } = publicJourney;
                return (
                  <Col key={pk} xs={24} sm={12} md={12} lg={8}>
                    <PublicJourneyCard publicJourney={publicJourney} />
                  </Col>
                );
              })}
          </Row>
        ) : isEditable && (
        <NoItinerariesContainer>
          <Row>
            <Col>
              <Typography type="defaultText">
                When you publish your itineraries, they will automatically show up here in the order it was created
                (latest first).
              </Typography>
            </Col>
          </Row>
          <Row>
            <Col>
              <Typography type="defaultText">
                Someone with your profile link can see & download your journey but won’t be able to see your email or personal
                information other than your name.
              </Typography>
            </Col>
          </Row>
        </NoItinerariesContainer>
        )}
      </JourneysCard>
    );
  }

  return null;
}

export default PublicJourneyCards;
