import { API, graphqlOperation } from 'aws-amplify';
import { v4 as uuidv4 } from 'uuid';
import { getJourney } from '@graphql/queries';
import { updateJourney } from '@graphql/mutations';

export const addJourneyFolder = async (id, title) => {
  const { data } = await API.graphql(graphqlOperation(getJourney, { id }));

  const getJourneyFolders = data && data.getJourney && data.getJourney.folders;

  const newlyCreatefolder = {
    id: uuidv4(),
    title,
  };

  const updatedFolders = getJourneyFolders
    ? [...getJourneyFolders, newlyCreatefolder]
    : [newlyCreatefolder];

  const updateJourneyAttributes = {
    id,
    folders: updatedFolders,
  };

  return API.graphql(
    graphqlOperation(updateJourney, {
      input: updateJourneyAttributes,
    }),
  );
};

export default addJourneyFolder;
